import {getExternalEmailValidator} from './../../validators/email.validator';
import {OrderService} from './../order.service';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Order} from '../../models/order.model';
import {OrderUpdateResponse} from '../../models/responses/orderUpdateResponse.model';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {mobileNumberValidator, phoneNumberValidator} from '../../validators/phone-number.validator';
import {contactDetailsRequiredValidator} from '../../validators/contact-details-required.validator';
import {DomHandler} from 'primeng/dom';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {getExpiryChangeReasons, getExpiryHoldReasons, getExpiryIndependentPaymentOptions, getExpiryPaymentInOutOptions} from '../../lookups/expiryChange';
import {ExpiryDateChangeRequest} from '../../models/requests/expiryDateChangeRequest.model';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-renewal-update[orderData][newExpiryDate][closeDialog][updateOrderOnExpiryDateChange]',
  templateUrl: './renewal-update.component.html',
  styleUrls: ['./renewal-update.component.scss'],
  providers: [],
})
export class RenewalUpdateComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
  ) { }
  display = true;
  @Input() orderData: Order = undefined;
  @Input() newExpiryDate: string = undefined;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateOrderOnExpiryDateChange: EventEmitter<OrderUpdateResponse> = new EventEmitter<OrderUpdateResponse>();
  renewalDateForm: FormGroup;
  numberOnly = numberOnly;
  expiryDateChangeReasons: SelectItem<string>[];
  inboundOutboundPaymentModeOptions: SelectItem<string>[];
  phoneNumberList: SelectItem<string>[] = [];
  phoneNumbersForSelect: SelectItem<string>[] = [];
  independentCustomerPaymentOptions: SelectItem<string>[];

  // Leave these two for now as code and name are used server side
  accountOnHoldMonthOptions = [
    {name: '1 Months', code: 1},
    {name: '2 Months', code: 2},
    {name: '3 Months', code: 3},
    {name: '4 Months', code: 4},
    {name: '5 Months', code: 5},
    {name: '6 Months', code: 6},
    {name: '7 Months', code: 7},
    {name: '8 Months', code: 8},
    {name: '9 Months', code: 9},
    {name: '10 Months', code: 10},
    {name: '11 Months', code: 11},
    {name: '12 Months', code: 12},
  ];
  complaintFreeMonthOptions = [
    {name: 'None', code: 0},
    {name: '1 Months', code: 1},
    {name: '2 Months', code: 2},
    {name: '3 Months', code: 3},
    {name: '4 Months', code: 4},
    {name: '5 Months', code: 5},
    {name: '6 Months', code: 6},
    {name: '7 Months', code: 7},
    {name: '8 Months', code: 8},
    {name: '9 Months', code: 9},
    {name: '10 Months', code: 10},
    {name: '11 Months', code: 11},
    {name: '12 Months', code: 12},
  ];

  accountOnHoldReasons: SelectItem<string>[];
  isFormSubmitted: boolean = false;
  isSubmitButtonDisabled: boolean = false;

  addPhoneNumberToSelection(phoneNumber: string, name: string) {
    if (!phoneNumber) {
      return;
    }
    if (!this.phoneNumberList.some(value => (value.value === phoneNumber))) {
      this.phoneNumberList.push({
        'label': name? `${name}: ${phoneNumber}`: phoneNumber,
        'value': phoneNumber,
      });
    }
  }

  ngOnInit() {
    this.expiryDateChangeReasons = getExpiryChangeReasons();
    this.inboundOutboundPaymentModeOptions = getExpiryPaymentInOutOptions();
    this.independentCustomerPaymentOptions = getExpiryIndependentPaymentOptions();
    this.accountOnHoldReasons = getExpiryHoldReasons();
    this.renewalDateForm = this.formBuilder.group({
      'selectedExpiryDateChangeReason': [null, Validators.required],
      'selectedInboundOutboundPaymentMode': [null],
      'selectedUserPhoneNumber': [null],
      'selectedIndependentCustomerPayment': [null],
      'freeMonths': [null],
      'selectedReasonForAccountOnHold': [null],
      'otherReasonForAccountOnHold': [''],
      'otherReasonForRenewalDateChange': [''],
      'accFirstName': [''],
      'accOtherName': [''],
      'accLastName': [''],
      'accMobileNumberToAdd': [''],
      'accEmail': [''],
      'accTelephoneNumberToAdd': [''],
      'accRelationship': [''],
      'additionalNote': [''],
    });

    this.orderData.accountContacts.forEach(accountContact => {
      this.addPhoneNumberToSelection(accountContact.accMobile,
        `${accountContact.accFirstName} ${accountContact.accLastName}`);
      this.addPhoneNumberToSelection(accountContact.accTelephone,
        `${accountContact.accFirstName} ${accountContact.accLastName}`);
    });
    this.addPhoneNumberToSelection(this.orderData.alarmUserDetails.telephone,
      `${this.orderData.alarmUserDetails.firstName} ${this.orderData.alarmUserDetails.lastName}`);
    this.addPhoneNumberToSelection(this.orderData.alarmUserDetails.mobile,
      `${this.orderData.alarmUserDetails.firstName} ${this.orderData.alarmUserDetails.lastName}`);
    this.addPhoneNumberToSelection('Email', '');
    this.addPhoneNumberToSelection('Other', '');
    this.phoneNumbersForSelect = [...this.phoneNumberList]
  }

  get selectedExpiryDateChangeReason(): AbstractControl {
    return this.renewalDateForm.get('selectedExpiryDateChangeReason');
  }

  get selectedInboundOutboundPaymentMode(): AbstractControl {
    return this.renewalDateForm.get('selectedInboundOutboundPaymentMode');
  }

  get selectedUserPhoneNumber(): AbstractControl {
    return this.renewalDateForm.get('selectedUserPhoneNumber');
  }

  get selectedIndependentCustomerPayment(): AbstractControl {
    return this.renewalDateForm.get('selectedIndependentCustomerPayment');
  }

  get freeMonths(): AbstractControl {
    return this.renewalDateForm.get('freeMonths');
  }

  get selectedReasonForAccountOnHold(): AbstractControl {
    return this.renewalDateForm.get('selectedReasonForAccountOnHold');
  }

  get otherReasonForAccountOnHold(): AbstractControl {
    return this.renewalDateForm.get('otherReasonForAccountOnHold');
  }

  get otherReasonForRenewalDateChange(): AbstractControl {
    return this.renewalDateForm.get('otherReasonForRenewalDateChange');
  }

  get accFirstName(): AbstractControl {
    return this.renewalDateForm.get('accFirstName');
  }

  get accOtherName(): AbstractControl {
    return this.renewalDateForm.get('accOtherName');
  }

  get accLastName(): AbstractControl {
    return this.renewalDateForm.get('accLastName');
  }

  get accMobileNumberToAdd(): AbstractControl {
    return this.renewalDateForm.get('accMobileNumberToAdd');
  }

  get accEmail(): AbstractControl {
    return this.renewalDateForm.get('accEmail');
  }

  get accTelephoneNumberToAdd(): AbstractControl {
    return this.renewalDateForm.get('accTelephoneNumberToAdd');
  }

  get accRelationship(): AbstractControl {
    return this.renewalDateForm.get('accRelationship');
  }

  get additionalNote(): AbstractControl {
    return this.renewalDateForm.get('additionalNote');
  }

  submitData() {
    if (this.selectedUserPhoneNumber.value == 'Other' && this.accMobileNumberToAdd.invalid && this.accTelephoneNumberToAdd.invalid) {
      this.isFormSubmitted = true;
      return;
    }
    let contactName: string = '';
    if (this.selectedUserPhoneNumber.value != 'Other') {
      const contactNameSelect: SelectItem<string> = this.phoneNumberList.find(phoneNumber => 
        phoneNumber.value == this.selectedUserPhoneNumber.value
      );
      if (contactNameSelect) {
        contactName = contactNameSelect.label.split(':')[0];
      }
    } else if (this.selectedUserPhoneNumber.value == 'Other') {
      contactName = `${this.accFirstName.value} ${this.accLastName.value}`;
    }
     
    this.isSubmitButtonDisabled = true;
    const params: ExpiryDateChangeRequest = {
      'orderId': this.orderData._id,
      'oldExpiryDate': this.orderData.renewalInformation.renewalDate,
      'newExpiryDate': this.newExpiryDate,

      'selectedExpiryDateChangeReason': this.selectedExpiryDateChangeReason.value,
      'selectedPhoneNumber': this.selectedUserPhoneNumber.value,
      'contactName': contactName,
      'userName': localStorage.getItem('userName'),
      'selectedInboundOutboundPaymentMode': this.selectedInboundOutboundPaymentMode.value,
      'selectedIndependentCustomerPayment': this.selectedIndependentCustomerPayment.value,
      // Store the object with name and code as server side needs both
      'freeMonths': this.freeMonths.value,
      'selectedReasonForAccountOnHold': this.selectedReasonForAccountOnHold.value,
      'otherReasonForAccountOnHold': this.otherReasonForAccountOnHold.value,
      'accFirstName': this.accFirstName.value,
      'accLastName': this.accLastName.value,
      'accOtherName': this.accOtherName.value,
      'accMobileNumberToAdd': this.accMobileNumberToAdd.value.trim(),
      'accTelephoneNumberToAdd': this.accTelephoneNumberToAdd.value.trim(),
      'accEmail': this.accEmail.value,
      'accRelationship': this.accRelationship.value,
      'otherReasonForRenewalDateChange': this.otherReasonForRenewalDateChange.value,
      'additionalNote': this.additionalNote.value,
      'orderLink': `${environment.protocol}${environment.IPAddress}/order/${this.orderData._id}`,
    };
    this.orderService.updateOrderExpiryDate(params)
      .subscribe((response: OrderUpdateResponse) => {
        if (!response.success) {
          this.showErrorPopUp('Error Updating Renewal Date', `There was an error updating the renewal date. ${response.message}.`);
          this.isSubmitButtonDisabled = false;
        } else {
          this.updateOrderOnExpiryDateChange.emit(response);
        }
      }, (err: Error) => {
        this.showErrorPopUp('Error Updating Renewal Date',
          `There was an error updating the renewal date. Error: ${err? err.message: 'uknown error'}`
        );
        this.isSubmitButtonDisabled = false;
      });
  }
  
  expiryDateChangeReasonChange() {
    if (this.selectedExpiryDateChangeReason.value == 'Successful Inbound Payment') {
      this.phoneNumbersForSelect = [
        ...this.phoneNumberList,
        { label: 'Withheld', value: 'Withheld' }
      ];
    } else {
      this.phoneNumbersForSelect = [
        ...this.phoneNumberList
      ];
      if (this.selectedExpiryDateChangeReason.value == 'Complaint') {
        this.freeMonths.setValue(this.complaintFreeMonthOptions[0]);
      }
    }

    if ((this.selectedExpiryDateChangeReason.value == 'Successful Outbound Payment') ||
        (this.selectedExpiryDateChangeReason.value == 'Successful Inbound Payment')) {
      this.selectedInboundOutboundPaymentMode.setValidators(Validators.required);
      this.selectedUserPhoneNumber.setValidators(Validators.required);
    } else {
      this.selectedInboundOutboundPaymentMode.clearValidators();
      this.selectedUserPhoneNumber.clearValidators();
    }

    if (this.selectedExpiryDateChangeReason.value == 'Independent Customer Payment') {
      this.selectedIndependentCustomerPayment.setValidators(Validators.required);
    } else {
      this.selectedIndependentCustomerPayment.clearValidators();
    }

    if ((this.selectedExpiryDateChangeReason.value == 'Complaint') ||
        (this.selectedExpiryDateChangeReason.value == 'Account on Hold')) {
      this.freeMonths.setValidators(Validators.required);
      if (this.selectedExpiryDateChangeReason.value == 'Account on Hold') {
        this.selectedReasonForAccountOnHold.setValidators(Validators.required);
      } else {
        this.selectedReasonForAccountOnHold.clearValidators();
      }
    } else {
      this.freeMonths.clearValidators();
    }

    if (this.selectedExpiryDateChangeReason.value == 'Other') {
      this.otherReasonForRenewalDateChange.setValidators(Validators.required);
    } else {
      this.otherReasonForRenewalDateChange.clearValidators();
    }
    // Have to do this to re-evalutate valid status
    this.selectedInboundOutboundPaymentMode.updateValueAndValidity();
    this.selectedUserPhoneNumber.updateValueAndValidity();
    this.selectedIndependentCustomerPayment.updateValueAndValidity();
    this.freeMonths.updateValueAndValidity();
    this.selectedReasonForAccountOnHold.updateValueAndValidity();
    this.otherReasonForRenewalDateChange.updateValueAndValidity();
  }

  selectedNumberChange() {
    if (this.selectedUserPhoneNumber.value == 'Other') {
      this.accEmail.setValidators(getExternalEmailValidator(false));
      this.accMobileNumberToAdd.setValidators(mobileNumberValidator);
      this.accTelephoneNumberToAdd.setValidators(phoneNumberValidator);
      this.renewalDateForm.setValidators(contactDetailsRequiredValidator('New Contact', 'accTelephoneNumberToAdd', 'accMobileNumberToAdd'));
    } else {
      this.accEmail.clearValidators();
      this.accMobileNumberToAdd.clearValidators();
      this.accTelephoneNumberToAdd.clearValidators();
      this.renewalDateForm.clearValidators();
    }
    // Have to do this to re-evalutate valid status
    this.accEmail.updateValueAndValidity();
    this.accMobileNumberToAdd.updateValueAndValidity();
    this.accTelephoneNumberToAdd.updateValueAndValidity();
    this.renewalDateForm.updateValueAndValidity();
  }

  accountHoldReasonChange() {
    if (this.selectedReasonForAccountOnHold.value == 'Other') {
      this.otherReasonForAccountOnHold.setValidators(Validators.required);
    } else {
      this.otherReasonForAccountOnHold.clearValidators();
    }
    // Have to do this to re-evalutate valid status
    this.otherReasonForAccountOnHold.updateValueAndValidity();
  }

  onHideDialog() {
    this.closeDialog.emit();
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
