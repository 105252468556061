import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {DomHandler} from 'primeng/dom';
import {environment} from '../../../environments/environment';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {getRefundMethodOptions} from '../../lookups/refunds';
import {Order} from '../../models/order.model';
import {SendEmailRequest} from '../../models/requests/sendEmailRequest.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {NotificationService} from '../../notifications/notification.service';

@Component({
  selector: 'app-refund-request[order][closeModal]',
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.scss']
})
export class RefundRequestComponent implements OnInit {
  @Input() order: Order;
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  sendDisabled: boolean;
  dialogVisible: boolean;
  resultMessage: string;
  resultSuccess: boolean;
  noteContent: string;
  refundForm: FormGroup;
  refundMethods: SelectItem<string>[];
  username: string;
  numberOnly = numberOnly;
  showAccountInputs: boolean;

  constructor(
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.sendDisabled = true;
    this.dialogVisible = true;
    this.showAccountInputs = false;
    this.noteContent = '';
    this.username = localStorage.getItem('userName');
    this.refundMethods = getRefundMethodOptions();

    this.refundForm = this.formBuilder.group({
      'refundReason': [null, Validators.required],
      'refundAmount': [null, Validators.required],
      'refundMethod': [null, Validators.required],
      'refundDue': [null, Validators.required],
      'accountName': [null],
      'sortCode': [null, [Validators.pattern(/^\d{2}[\s-]?\d{2}[\s-]?\d{2}$/)]],
      'accountNo': [null, [Validators.pattern(/^\d{8}$/)]],
    });
    this.sendDisabled = false;
  }

  get refundDue(): AbstractControl {
    return this.refundForm.get('refundDue');
  }

  changeRefundMethod(newMethod: string): void {
    this.showAccountInputs = newMethod && !newMethod.includes('Stripe');
    if (this.showAccountInputs) {
      this.refundForm.get('accountName').enable();
      this.refundForm.get('sortCode').enable();
      this.refundForm.get('accountNo').enable();
    } else {
      this.refundForm.get('accountName').disable();
      this.refundForm.get('sortCode').disable();
      this.refundForm.get('accountNo').disable();
    }
  }

  getAccountDetails(plainText: boolean): string {
    if (!this.showAccountInputs) {
      return '';
    }
    if (plainText) {
      return `Account Name: ${this.refundForm.value.accountName}\nSort Code: ${this.refundForm.value.sortCode}\n` +
        `Account Number: ${this.refundForm.value.accountNo}\n`;
    } else {
      return `<p>Account Name: ${this.refundForm.value.accountName}<br/>` +
        `Sort Code: ${this.refundForm.value.sortCode}<br/>Account Number: ${this.refundForm.value.accountNo}</p>`;
    }
  }

  sendRefundRequest(): void {
    this.sendDisabled = true;
    this.resultMessage = '';
    
    const tdCode: string = this.order.alarmUserDetails.tdCode;
    const orderLink: string = `${environment.protocol}${environment.IPAddress}/order/${this.order._id}`;
    const recipientsString: string = this.refundForm.value.refundDue?
        localStorage.getItem('email: Due Refund') : localStorage.getItem('email: Not Due Refund');
    const emailParams: SendEmailRequest = { 
      recipients: JSON.parse(recipientsString),
      subject: 'Refund Request',
      plainTextMsg: `Hi Team,\na refund is requested for the below order.\n` +
        `Reason: ${this.refundForm.value.refundReason}\n` +
        `Amount: ${this.refundForm.value.refundAmount}\n` +
        `Paid Via: ${this.refundForm.value.refundMethod}\n` +
        `Due a Refund: ${this.refundForm.value.refundDue? 'Yes': 'No'}\n` +
        this.getAccountDetails(true) +
        `Customer Order (TD): ${tdCode} ${orderLink}\n` +
        `Thanks,\n${this.username}`,
      htmlMsg: `<p>Hi Team,</p><p>a refund is requested for the below order.</p>` + 
        `<p>Reason: ${this.refundForm.value.refundReason}<br/>` +
        `Amount: ${this.refundForm.value.refundAmount}<br/>` +
        `Paid Via: ${this.refundForm.value.refundMethod}<br/>` +
        `Due a Refund: ${this.refundForm.value.refundDue? 'Yes': 'No'}</p>` +
        this.getAccountDetails(false) +
        `<p>Customer Order (TD): <a href="${orderLink}" target="_blank">${tdCode}</a></p>` +
        `<p>Thanks,<br/>${this.username}</p>`,
    };

    this.noteContent = `${this.username} requested a refund:\n` +
        `Reason: ${this.refundForm.value.refundReason}\n` +
        `Amount: ${this.refundForm.value.refundAmount}\n` +
        `Paid Via: ${this.refundForm.value.refundMethod}\n` +
        `Due a Refund: ${this.refundForm.value.refundDue? 'Yes': 'No'}\n`;
    this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
      if (response.success) {
        if (response.data.length == 0) {
          this.resultMessage = 'Email sent';
          this.resultSuccess = true;
          this.noteContent = `${this.noteContent}An email was sent to ${emailParams.recipients.join(';')}.`;
          this.closeModal.emit(this.noteContent);
        } else {
          const recipients: string[] = emailParams.recipients.filter((recipient: string) => 
            !response.data.includes(recipient)
          );
          // Sent successfully to some
          if (recipients.length > 0) {
            this.resultMessage = 
                `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}.` +
                'Please retry or send the email yourself.';
            this.noteContent =
              `${this.noteContent}An email was sent to ${recipients.join(';')} and failed to send to ${response.data.join(';')} because ${response.message}`;
          } else {
            this.resultMessage = `Email could not be sent to ${response.data.join(';')}. Please retry or send the email yourself.`;
            this.noteContent = `${this.noteContent}The email to ${response.data.join(';')} failed to send.`;
          }
          this.sendDisabled = false;
        }
      } else {
        this.resultMessage = 
            `Email could not be sent to ${emailParams.recipients.join(';')}. Error: ${response.message}.` +
            'Please retry or send the email yourself.';
        this.noteContent = `${this.noteContent}The email to ${emailParams.recipients.join(';')} failed to send.`;
        this.sendDisabled = true;
      }
    }, (error: any) => {
      this.resultMessage = 
          `Email could not be sent to ${emailParams.recipients.join(';')}. Error: ${error.message}.` +
          'Please retry or send the email yourself.';
      this.noteContent = `${this.noteContent}The email to ${emailParams.recipients.join(';')} failed to send.`;
      this.sendDisabled = true;
    });
  }

  isOkToSend(): boolean {
    return this.refundForm.valid && !this.sendDisabled;
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  closeDialog() {
    this.closeModal.emit(this.noteContent);
  }

}
