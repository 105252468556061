import {Component, OnInit, ViewChild} from '@angular/core';
import {LogsService} from "./logs.service";
import * as moment from 'moment-timezone';
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {MessageService, SelectItem} from 'primeng/api';
import {BrandCfg, getBrandConfigs, getBrandCodes, getBrandSelectItems} from '../../lookups/brands';
import {Table} from 'primeng/table';
import {Column} from '../../models/column.model';
import {Title} from '@angular/platform-browser';
import {ExcelExportService} from '../../post-order/excel-export.service';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Log} from '../../models/log.model';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  providers: [MessageService],
})
export class LogsComponent implements OnInit {
  logs: Log[];
  cols: Column[];
  minDateStr: string;
  maxDateStr: string;
  defaultStartDateStr: string;
  logsLoading: boolean = false;
  logsPagesToLoad: number = 0;
  filterMinDate: Date;
  filterMaxDate: Date;
  filterYearRange: string;
  dateFilters: Date[];
  brands: SelectItem<string>[];
  brandConfigs: BrandCfg;
  // For the brands column (upload/export)
  selectedBrands: string[];
  // For the brand column (order)
  selectedBrand: string[];
  @ViewChild('tt', {static: false})
  logTable: Table;
  objectKeys: (o: {}) => string[];

  constructor(
    private logsService: LogsService,
    private messageService: MessageService,
    private title: Title,
    private excelService: ExcelExportService,
  ) {
  }

  ngOnInit() {
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();

    this.title.setTitle('CRM Logs');

    this.objectKeys = Object.keys;
    this.logs = [];

    this.cols = [
      {field: 'source', header: 'Source'},
      {field: 'date', header: 'Date'},
      {field: 'brand', header: 'Brand'},
      {field: 'firstName', header: 'First Name'},
      {field: 'lastName', header: 'Last Name'},
      {field: 'code', header: 'TD code'},
      {field: 'action', header: 'Action'},
      {field: 'user', header: 'Staff member'},
      {field: 'key', header: 'Field'},
      {field: 'fields', header: 'Fields'},
      {field: 'before', header: 'Before'},
      {field: 'after', header: 'After'},
      {field: 'nbRecorders', header: 'Total Rows'},
      {field: 'recorderPerBrand', header: 'Brands'},
      {field: 'recorderPerBrand', header: 'Records Per Brand'},
      {field: 'fileName', header: 'File Name'},

    ];
    this.minDateStr = moment.tz('Europe/London').subtract(12, 'months').format('YYYY-MM-DD');
    this.defaultStartDateStr = moment.tz('Europe/London').subtract(1, 'months').format('YYYY-MM-DD');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');

    this.selectedBrands = getBrandCodes();
    this.selectedBrand = getBrandCodes();
  }

  showPageCountError(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: 'Error getting page count' + errorMessage,
    });
  }

  loadChangeLogs(startDate: string, endDate: string) {
    this.logsLoading = true;
    this.logs = [];
    this.logsPagesToLoad = 0;
    this.filterMinDate = new Date(startDate);
    this.filterMaxDate = new Date(endDate);
    this.filterYearRange = `${this.filterMinDate.getFullYear()}:${this.filterMaxDate.getFullYear()}`;
    this.loadChangeLog('order', startDate, endDate);
    this.loadChangeLog('upload', startDate, endDate);
  }


  loadChangeLog(source: string, startDate: string, endDate: string) {
    this.logsService.getLogPageCount(source, startDate, endDate).subscribe(
      (response: PageCountResponse) => {
        if (!response.success) {
          this.logsLoading = (this.logsPagesToLoad !== 0);
          this.showPageCountError(response.message);
        } else {
          this.logsPagesToLoad = this.logsPagesToLoad + response.pageCount!;
          if (this.logsPagesToLoad === 0) {
            this.logsLoading = false;
          }
          for (let page: number = 1; page <= response.pageCount!; page++) {
            this.loadPage(source, startDate, endDate, page);
          }
        }
      }
    );
  }

  loadPage(source: string, startDate: string, endDate: string, page: number) {
    this.logsService
      .getLogPage(source, startDate, endDate, page)
      .subscribe((response: MultiRecordResponse<Log>) => {
        this.logs = this.logs.concat(response.data!.map((log: Log) => {
          if (log.key) {
            log.key = log.key.replace('renewalDateTaken', 'actionDueDate');
          }
          return log;
        }));
        this.logsPagesToLoad--;
        if (this.logsPagesToLoad === 0) {
          this.logsLoading = false;
        }
      });
  }

  globalFilter($event: Event, filterType: string): void {
    this.logTable.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.logTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  exportLogs() {
    const logsToExport: Log[] = this.logTable.filteredValue? this.logTable.filteredValue : this.logs;
    const dataToExport: any[] = logsToExport.map((log: Log) => {
      return {
        'source': log.source,
        'id': log.id,
        'user': log.user,
        'brand': log.brand,
        'TD code': log.code,
        'first name': log.firstName,
        'last name': log.lastName,
        'date': moment.tz(log.date, 'Europe/London').format('DD/MM/YYYY HH:mm:ss'),
        'field': log.key,
        'action': log.action,
        'before': log.before,
        'after': log.after,
        'file name': log.fileName,
        'total rows': log.nbRecorders,
        'fields': log.fields.join('\n'),
        'records per brand': log.recorderPerBrand? 
          Object.keys(log.recorderPerBrand).map((brand: string) => 
            `${brand}: ${log.recorderPerBrand[brand]}`
          ).join('\n'): '',
      }
    });
    this.excelService.exportAsExcelFile({ 'Log': dataToExport }, 'Log');
  }

}
