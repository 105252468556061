import {FormControl} from '@angular/forms';
import {OrderService} from './../order.service';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment-timezone';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {mobileNumberValidator, phoneNumberValidator} from '../../validators/phone-number.validator';
import {contactDetailsRequiredValidator} from '../../validators/contact-details-required.validator';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {DomHandler} from 'primeng/dom';
import {getExternalEmailValidator} from '../../validators/email.validator';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {Order} from '../../models/order.model';
import {OutstandingAction} from '../../models/outstandingAction.model';
import {getNoteTemplates, NoteTemplate} from '../../lookups/noteTemplate';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';

@Component({
  selector: 'app-contact-attempt[orderData][outstandingActionName][closeModal][updateOrder]',
  templateUrl: './contact-attempt.component.html',
  styleUrls: ['./contact-attempt.component.scss']
})
export class ContactAttemptComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
  ) { }
  display: boolean = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateOrder: EventEmitter<SimpleResponse> = new EventEmitter<SimpleResponse>();
  @Input() orderData: Order;
  @Input() outstandingActionName: string;
  contactOptions: SelectItem<string>[] = [
    {
      label: 'Successful Contact - Action Complete',
      value: 'Successful Contact - Action Complete',
    },
    {
      label: 'Successful Contact - Action Not Complete',
      value:'Successful Contact - Action Not Complete'
    },
    {
      label: 'Unsuccessful Contact Attempt',
      value: 'Unsuccessful Contact Attempt'
    },
    {
      label: 'Other - Override Action Date Manually',
      value: 'Other - Override Action Date Manually',
    }
  ];
  contactAttemptForm: FormGroup;
  dueDateForm: FormGroup;
  phoneNumbersForSelect: SelectItem<string>[] = [];
  phoneNumberList: SelectItem<string>[] = [];
  currentOutstandingAction: OutstandingAction;
  isDateChange: boolean = false;
  placeholderForContactAttemptOption: string = "";
  isFormSubmitted: boolean = false;
  isSubmitButtonDisabled: boolean = false;
  isDateChangeSubmitButtonDisabled: boolean = false;
  today: Date = moment.tz('Europe/London').startOf('day').toDate();
  numberOnly = numberOnly;
  noteTemplates: NoteTemplate[];

  addPhoneNumberToSelection(phoneNumber: string, name: string) {
    if (!phoneNumber) {
      return;
    }
    if (!this.phoneNumberList.some(value => (value.value === phoneNumber))) {
      this.phoneNumberList.push({
        'label': name? `${name}: ${phoneNumber}`: phoneNumber,
        'value': phoneNumber,
      });
    }
  }

  ngOnInit() {
    this.contactAttemptForm = this.formBuilder.group({
      'selectedContactOption': [null, Validators.required],
      'selectedNumber': [null],
      'reasonToChangeDateManually': [''],
      'accFirstName': [''],
      'accOtherName': [''],
      'accLastName': [''],
      'accEmail': [''],
      'accRelationship': [''],
      'accMobileNumberToAdd': [''],
      'accTelephoneNumberToAdd': [''],
      'additionalNote': [''],
      'isMessageLeftOnUnsuccessfulAttempt': [false],
    });
    if (this.outstandingActionName == 'Low Battery') {
      this.contactAttemptForm.addControl('replacementItemRequired', new FormControl(null));
    }
    this.dueDateForm = this.formBuilder.group({
      'changedActionDueDate': [null, Validators.required],
    });

    this.currentOutstandingAction = this.orderData.outstandingActions.find((action: OutstandingAction) => 
      action.outstandingName == this.outstandingActionName
    );
    this.orderData.accountContacts.forEach(accountContact => {
      this.addPhoneNumberToSelection(accountContact.accMobile,
        `${accountContact.accFirstName} ${accountContact.accLastName}`);
      this.addPhoneNumberToSelection(accountContact.accTelephone,
        `${accountContact.accFirstName} ${accountContact.accLastName}`);
    });
    this.addPhoneNumberToSelection(this.orderData.alarmUserDetails.telephone,
      `${this.orderData.alarmUserDetails.firstName} ${this.orderData.alarmUserDetails.lastName}`);
    this.addPhoneNumberToSelection(this.orderData.alarmUserDetails.mobile,
      `${this.orderData.alarmUserDetails.firstName} ${this.orderData.alarmUserDetails.lastName}`);
    this.addPhoneNumberToSelection('Email','');
    if (['ALB Test Call Needed', 'Low Battery'].includes(this.outstandingActionName)) {
      this.addPhoneNumberToSelection('Jontek Contact','');
    }
    this.addPhoneNumberToSelection('Other', '');
    this.phoneNumbersForSelect = [...this.phoneNumberList];
    this.noteTemplates = getNoteTemplates();
  }
  
  closeDialog() {
    this.closeModal.emit();
  }

  get selectedContactOption(): AbstractControl {
    return this.contactAttemptForm.get('selectedContactOption');
  }

  get selectedNumber(): AbstractControl {
    return this.contactAttemptForm.get('selectedNumber');
  }

  get reasonToChangeDateManually(): AbstractControl {
    return this.contactAttemptForm.get('reasonToChangeDateManually');
  }

  get accFirstName(): AbstractControl {
    return this.contactAttemptForm.get('accFirstName');
  }

  get accOtherName(): AbstractControl {
    return this.contactAttemptForm.get('accOtherName');
  }

  get accLastName(): AbstractControl {
    return this.contactAttemptForm.get('accLastName');
  }

  get accEmail(): AbstractControl {
    return this.contactAttemptForm.get('accEmail');
  }

  get accRelationship(): AbstractControl {
    return this.contactAttemptForm.get('accRelationship');
  }

  get accMobileNumberToAdd(): AbstractControl {
    return this.contactAttemptForm.get('accMobileNumberToAdd');
  }

  get accTelephoneNumberToAdd(): AbstractControl {
    return this.contactAttemptForm.get('accTelephoneNumberToAdd');
  }

  get additionalNote(): AbstractControl {
    return this.contactAttemptForm.get('additionalNote');
  }

  get isMessageLeftOnUnsuccessfulAttempt(): AbstractControl {
    return this.contactAttemptForm.get('isMessageLeftOnUnsuccessfulAttempt');
  }

  get changedActionDueDate(): AbstractControl {
    return this.dueDateForm.get('changedActionDueDate');
  }

  get replacementItemRequired(): AbstractControl{
    return this.contactAttemptForm.get('replacementItemRequired');
  }

  submitData() {
    if ((this.selectedNumber.value == 'Other') && 
        this.accMobileNumberToAdd.invalid && this.accTelephoneNumberToAdd.invalid) {
      this.isFormSubmitted = true;
      return;
    }
    // We need the user to select a new action date, before we submit the update
    if (this.selectedContactOption.value == "Other - Override Action Date Manually") {
      this.isDateChange = true;
      return;
    }
    this.isSubmitButtonDisabled = true;
    this.updateOutstandingAction(false);
  }

  contactOptionChange() {
    if (this.selectedContactOption.value) {
      if (this.selectedContactOption.value == "Unsuccessful Contact Attempt") {
        this.phoneNumbersForSelect = this.phoneNumberList.filter(phoneNumber => phoneNumber.label != 'Other');
        this.placeholderForContactAttemptOption = "Which number were you unsuccessful on?";
        this.selectedNumber.setValidators(Validators.required);
        this.reasonToChangeDateManually.clearValidators();
      } else if (this.selectedContactOption.value == "Other - Override Action Date Manually") {
        this.selectedNumber.clearValidators();
        this.reasonToChangeDateManually.setValidators(Validators.required);
      } else {
        this.phoneNumbersForSelect = [...this.phoneNumberList];
        this.placeholderForContactAttemptOption = "Which number did the customer answer on?"
        this.selectedNumber.setValidators(Validators.required);
        this.reasonToChangeDateManually.clearValidators();
      }
      // Have to do this to re-evalutate valid status
      this.selectedNumber.updateValueAndValidity();
      this.reasonToChangeDateManually.updateValueAndValidity();
    }
  }

  selectedNumberChange() {
    if (this.selectedNumber.value == 'Other') {
      this.accEmail.setValidators(getExternalEmailValidator(false));
      this.accMobileNumberToAdd.setValidators(mobileNumberValidator);
      this.accTelephoneNumberToAdd.setValidators(phoneNumberValidator);
      this.contactAttemptForm.setValidators(contactDetailsRequiredValidator('New Contact', 'accTelephoneNumberToAdd', 'accMobileNumberToAdd'));
    } else {
      this.accEmail.clearValidators();
      this.accMobileNumberToAdd.clearValidators();
      this.accTelephoneNumberToAdd.clearValidators();
      this.contactAttemptForm.clearValidators();
    }
    // Have to do this to re-evalutate valid status
    this.accEmail.updateValueAndValidity();
    this.accMobileNumberToAdd.updateValueAndValidity();
    this.accTelephoneNumberToAdd.updateValueAndValidity();
    this.contactAttemptForm.updateValueAndValidity();
  }

  submitDateChange() {
    this.isDateChangeSubmitButtonDisabled = true;
    this.updateOutstandingAction(true);
  }

  updateOutstandingAction(withDateChange: boolean) {
    let selectedContactName: any;
      
    if (this.selectedNumber.value != 'Other') {
      selectedContactName = this.phoneNumberList.find(con => con.value == this.selectedNumber.value);
      if (selectedContactName) {
        selectedContactName = selectedContactName.label.split(':')[0];
      }
    } else if (this.selectedNumber.value == 'Other') {
      selectedContactName = `${this.accFirstName.value} ${this.accLastName.value}`;
    }
    const params: any = {
      'orderId': this.orderData._id,
      'brand': this.orderData.website.title,
      'actionId': this.currentOutstandingAction._id,
      'outstandingName': this.currentOutstandingAction.outstandingName,
      'contactAttemptOption': this.selectedContactOption.value,
      'selectedNumber': this.selectedNumber.value,
      'userName': localStorage.getItem('userName'),
      'isMessageLeftOnUnsuccessfulAttempt': this.isMessageLeftOnUnsuccessfulAttempt.value,
      'additionalNote': this.additionalNote.value,
      'contactName': selectedContactName,
      'body': {
        'accFirstName': this.accFirstName.value,
        'accLastName': this.accLastName.value,
        'accMobileNumberToAdd': this.accMobileNumberToAdd.value,
        'accTelephoneNumberToAdd': this.accTelephoneNumberToAdd.value,
        'accEmail': this.accEmail.value,
        'reasonToChangeDateManually': this.reasonToChangeDateManually.value,
        'accOtherName': this.accOtherName.value,
        'accRelationship': this.accRelationship.value,
      }
    };
    if (withDateChange) {
      // datetime-local excludes timezone, so do this to get timezone included
      params.newDueDate = moment(this.changedActionDueDate.value).toDate();
    }
    this.orderService.updateOutstandingAction(params)
      .subscribe((response: SimpleResponse) => {
        if (!response.success) {
          this.showErrorPopUp('Error Recording Contact Attempt', `There was an error recording the contact attempt. ${response.message}. ` +
            `${response.error? 'Error: ' + response.error.message: 'Error: uknown error'}`
          );
          this.isSubmitButtonDisabled = false;
          this.isDateChangeSubmitButtonDisabled = false;
        } else if (this.outstandingActionName == 'Low Battery' && (this.replacementItemRequired?.value == true)) {
          const event: any = { ...response };
          event.displayReplacementItemPopup = true;
          this.updateOrder.emit(event);
        } else {
          this.updateOrder.emit(response);
        }
      }, err => {
        this.showErrorPopUp('Error Recording Contact Attempt',
          `There was an error recording the contact attempt. Error: ${err? err.message: 'uknown error'}`
        );
        this.isSubmitButtonDisabled = false;
        this.isDateChangeSubmitButtonDisabled = false;
      });
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.contactAttemptForm.patchValue({
        additionalNote: event.value.value,
      });
    }
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
