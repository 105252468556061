import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Group} from '../../models/group.model';
import {UsersAndRolesResponse} from '../../models/responses/usersAndRolesResponse.model';
import {UserResponse} from '../../models/responses/userResponse.model';
import {importTypes} from '../../lookups/importTypes';
import {Role} from '../../models/role.model';
import {UpdateUserRightsNotificationRequest} from '../../models/requests/updateUserRightsNotificationRequest.model';
import {JwtPayload} from '../../models/jwtPayload.model';
import {SaveColumnsRequest} from '../../models/requests/saveColumnsRequest.model';
import {LoginRequest} from '../../models/requests/loginRequest.model';
import {CrmLoginResponse} from '../../models/responses/crmLoginResponse.model';
import {ConfigDataResponse} from '../../models/responses/configDataResponse.model';
import {RegistrationRequest} from '../../models/requests/registrationRequest.model';
import {UserProfileChangeRequest} from '../../models/requests/userProfileChangeRequest.model';
import {SaveNoteCategoryRequest} from '../../models/requests/saveNoteCategoryRequest.model';
import {SaveNoteTemplateRequest} from '../../models/requests/saveNoteTemplateRequest.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private API_URL = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<UsersAndRolesResponse> {
    return this.http.get<UsersAndRolesResponse>(`${this.API_URL}/users`);
  }
  getUser(id: string): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.API_URL}/users/${id}`);
  }

  updateUser(id: string, UserParams): Observable<UserResponse> {
    return this.http.put<UserResponse>(`${this.API_URL}/users/${id}`,UserParams);
  }

  deleteUser(id: string): Observable<UserResponse> {
    return this.http.delete<UserResponse>(`${this.API_URL}/users/${id}`);
  }

  addGroup(params): Observable<SingleRecordResponse<Group>> {
    return this.http.post<SingleRecordResponse<Group>>(`${this.API_URL}/users/add/group`, params);
  }

  getGroups(): Observable<MultiRecordResponse<Group>> {
    return this.http.get<MultiRecordResponse<Group>>(`${this.API_URL}/users/all/groups`)
  }

  deleteGroup(groupId: string): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(`${this.API_URL}/users/delete/group/`+groupId);
  }

  addUsersToGroup(params): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/users/add/user/group`, params);
  }

  updateGroup(params): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/users/update/group`, params);
  }

  changeRightsAutoNotification(params: UpdateUserRightsNotificationRequest) {
    return this.http.post(`${this.API_URL}/notifications/change-rights/auto-notification`, params);
  }

  saveColumns(params: SaveColumnsRequest): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(`${this.API_URL}/accounts/save-columns`, params);
  }

  saveNoteCategories(params: SaveNoteCategoryRequest): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(`${this.API_URL}/users/save-note-categories`, params);
  }

  saveNoteTemplate(params: SaveNoteTemplateRequest): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(`${this.API_URL}/users/update-note-template`, params);
  }

  registerUser(registrationParams: RegistrationRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/accounts/signup`, registrationParams);
  }

  login(loginParams: LoginRequest): Observable<CrmLoginResponse> {
    return this.http.post<CrmLoginResponse>(`${this.API_URL}/accounts/login`, loginParams);
  }

  getOnLoginData(userId: string): Observable<ConfigDataResponse> {
    return this.http.get<ConfigDataResponse>(`${this.API_URL}/accounts/load-data/${userId}`);
  }

  updateOwnUserProfile(profileChangeParams: UserProfileChangeRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/accounts/profile`, profileChangeParams);
  }

  setup2fa(): Observable<SingleRecordResponse<string>> {
    return this.http.get<SingleRecordResponse<string>>(`${this.API_URL}/accounts/setup2fa`);
  }

  validate2fa(authCode: string): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/accounts/verify2fa`, {otp: authCode});
  }

  login2fa(token: string, authCode: string, deviceId: string): Observable<CrmLoginResponse> {
    return this.http.post<CrmLoginResponse>(`${this.API_URL}/accounts/login2fa`, {token: token, otp: authCode, deviceId: deviceId});
  }

  userHasPermission(permission: string): boolean {
    return this.userHasEveryPermission([permission]);
  }

  userHasEveryPermission(permissionsToCheck: string[]): boolean {
    try {
      // Use permissions from JWT as it adds additional security from tampering
      const token: string = localStorage.getItem('token');
      if (!token) {
        return false;
      }
      const parsedTokenPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      const permissions: string[] = parsedTokenPayload.user.permissions!;
      // Super Admins have permission to everything
      if (permissions.includes('Super Admin')) {
        return true;
      }
      let hasAllPermissions: boolean = true;
      permissionsToCheck.forEach((permission: string) => {
        if (!permissions.includes(permission)) {
          hasAllPermissions = false;
        }
      });
      return hasAllPermissions;
    } catch (error) {
      // Token isn't in valid JWT format
      return false;
    }
  }

  userHasRole(roleId: string): boolean {
    try {
      // Use roles from JWT as it adds additional security from tampering
      const token: string = localStorage.getItem('token');
      if (!token) {
        return false;
      }
      const parsedTokenPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      const roles: Role[] = parsedTokenPayload.user.roles!;
      // Super Admins have permission to everything
      return (roles.some((role: Role) => role._id == roleId) || this.userHasPermission('Super Admin'));
    } catch (error) {
      // Token isn't in valid JWT format
      return false;
    }
  }

  /**
   * Check if user has permission for at least one of the imports
   * @returns whether the user has at least 1 import permission
   */
  userHasImportPermission(): boolean {
    try {
      // Use permissions from JWT as it adds additional security from tampering
      const token: string = localStorage.getItem('token');
      if (!token) {
        return false;
      }
      const parsedTokenPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      const permissions: string[] = parsedTokenPayload.user.permissions!;
      // Super Admins have permission to everything
      if (this.userHasPermission('Super Admin')) {
        return true;
      }
      for (let i: number = 0; i < importTypes.length; i++) {
        if (permissions.includes(importTypes[i].label)) {
          return true;
        }
      }
      return false;
    } catch (error) {
      // Token isn't in valid JWT format
      return false;
    }
  }

}
