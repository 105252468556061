<p-blockUI *ngIf="documentBlocked" [blocked]="documentBlocked">
  <div style="width: 100%; height: 200px;background-color: #ff3f4e; position: absolute;top:0; bottom: 0; left: 0; right: 0; margin: auto;">
    <div class="row">
      <div class="col text-center">
        <h1 style="color: #fff; padding: 20px;">
          This lead is currently being reviewed by {{leadEditor.user}}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-lock fa-3x" (click)="unlock()">
          Unlock
        </i>
        &nbsp;
        <i class="fa fa-ban fa-3x" (click)="closeDialog()">
          Cancel
        </i>
      </div>
    </div>
  </div>
</p-blockUI>
<div *ngIf="!documentBlocked" class="custom-container">
  <p-dialog header="Lead Detail" (onHide)="closeDialog()" [(visible)]="displayModal" [modal]="true"
      [style]="{width: '90vw'}" [draggable]="false" [resizable]="false" [maximizable]="true">
    <div *ngIf="loading && openMode != 'add'" class="loading-indicator d-flex align-items-center justify-content-center"
        style="height: 200px;">
      <p-progressSpinner *ngIf="loading"></p-progressSpinner>
    </div>
    <form *ngIf="!loading" [formGroup]="leadForm" #form="ngForm">
      <ng-template #leadFormRef>
        <div class="col-12 col-lg-12">
          <div class="formsect pt-1 mt-0">
            <div>
              <div class="form-row">
                <div class="col-2 col-lg-2 form-group">
                  <label>Lead Type</label>
                  <p-dropdown [options]="leadTypes" name="leadType" formControlName="leadType" [showClear]="true"
                    placeholder="Select a Lead Type" [required]="true" (onChange)="changeLeadType()">
                  </p-dropdown>
                </div>
                <div class="col-2 col-lg-2 form-group">
                  <label>Who Referred?</label>
                  <p-dropdown [options]="whoReferredOptions" placeholder="Who referred?" [showClear]="true" [filter]="true"
                    [panelStyle]="{minWidth:'12em'}" scrollHeigh="600px" selectedItemsLabel="{0} selected"
                    [style]="{'display':'flex'}" formControlName="whoReferred" [required]="true">
                  </p-dropdown>
                </div>
                <div class="col-1 col-lg-1 form-group">
                  <label>Select Brand</label>
                  <p-dropdown [options]="brands" name="brand" formControlName="brand" [filter]="true"
                    filterBy="label" [showClear]="true" placeholder="Select a Brand" [required]="true">
                    <ng-template pTemplate="selectedItem">
                      <div *ngIf="selectedBrand.value">
                        <div>{{selectedBrand.value}}</div>
                      </div>
                    </ng-template>
                    <ng-template let-brand pTemplate="item">
                      <span class="brandBar"
                        [ngStyle]="{'background-color': brandConfigs[brand.value].background, 'color':brandConfigs[brand.value].color}">
                        &nbsp;{{brand.value}}
                      </span>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col-2 col-lg-2 form-group">
                  <label>Referral Date</label>
                  <input type="date" name="referrerDate" class="form-control" formControlName="referralDate" required>
                </div>
                <div class="col-2 col-lg-2 form-group">
                  <label>Select Owner</label>
                  <p-dropdown [options]="ownerNames" placeholder="Select a user" [showClear]="true" [filter]="true"
                    [panelStyle]="{minWidth:'12em'}" scrollHeigh="600px" selectedItemsLabel="{0} selected"
                    [style]="{'display':'flex'}" formControlName="ownerName">
                  </p-dropdown>
                </div>
                <ng-container *ngIf="openMode != 'add'">
                  <div class="col-2 col-lg-2 form-group">
                    <label>Order Link</label>
                    <input type="text" name="orderLink" class="form-control" value="{{leadDetail.tdCode}}" readonly>
                  </div>
                  <div class="col-1 col-lg-1 form-group">
                    <label style="width: 100%;">
                        &nbsp;
                    </label>
                    <a routerLink="/order/{{leadDetail.orderId}}" target="_blank" *ngIf="leadDetail.orderId; else noOrderLink" title="Open Order">
                      <i class="pi pi-external-link"></i>
                    </a>
                    <ng-template #noOrderLink>
                      <div title="No Order linked" class="mt-1">
                        <i class="pi pi-external-link disabledLink"></i>
                      </div>
                    </ng-template>
                  </div>
                 </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="formsect" formGroupName="alarmUserDetails">
            <div class="formbar row no-gutters">
              <div class="col-11">
                User Details
              </div>
              <div class="col-1">
                <button type="button" (click)="validateAndCopyToClipBoard(alarmUserDetails.value)" class="iconButton mr-2"
                    title="Copy Alarm User Name and Address">
                  <i class="fa fa-copy"></i>
                </button>
              </div>
            </div>
            <div>
              <div class="form-row">
                <div class="form-group col-4">
                  <label>First Name</label>
                  <input type="text" class="form-control" name="firstName" formControlName="firstName"
                    placeholder="Enter first name" required>
                </div>
                <div class="form-group col-4">
                  <label>Other Name</label>
                  <input type="text" class="form-control" name="otherName" formControlName="otherName"
                    placeholder="Enter other name">
                </div>
                <div class="form-group col-4">
                  <label>Last Name</label>
                  <input type="text" class="form-control" name="lastName" formControlName="lastName"
                    placeholder="Enter last name" required>
                </div>
                <div class="form-group col-6">
                  <label>Preferred Name</label>
                  <input type="text" class="form-control" name="preferredName"
                    formControlName="preferredName" placeholder="Enter preferred name">
                </div>
                <div class="form-group col-6">
                  <label>Email</label>
                  <input type="text" class="form-control" name="email" formControlName="email" placeholder="Enter email" [required]="isEmailRequired">
                </div>
              </div>
              <div class="form-row" formGroupName="userAddress">
                <div class="form-group col-12" id="postcode_lookup_lead">
                <input type="text" id="getaddress_input_lead" autocomplete="off" [(ngModel)]="leadAddressSearchPostCode" [ngModelOptions]="{standalone:true}">
                <button type="button" id="getaddress_button_lead" (click)="leadAddressSearch()" [disabled]="!leadAddressSearchPostCode">
                  Find Postcode
                </button>
                </div>
                <div *ngIf="leadSearchError" class="form-group col-12">
                  <p class="alert alert-danger">{{leadSearchError}}</p>
                </div>
                <div *ngIf="leadAddressResults && (leadAddressResults.length > 0)" class="form-group col-12">
                  <p-dropdown [options]="leadAddressResults" [style]="{'width':'100%'}" (onChange)="setLeadAddress($event)">
                  </p-dropdown>
                </div>
                <div class="form-group col-12">
                  <label>Address 1</label>
                  <input type="text" name="addressOne" class="form-control" formControlName="addressOne" placeholder="Enter Address Line 1" 
                    [readOnly]="!allowAddressManualEntry">
                </div>
                <div class="form-group col-12">
                  <label>Address 2</label>
                  <input type="text" name="addressTwo" class="form-control" formControlName="addressTwo" placeholder="Enter Address Line 2"
                    [readOnly]="!allowAddressManualEntry">
                </div>
                <div class="form-group col-6">
                  <label>City</label>
                  <input type="text" name="city" class="form-control" formControlName="city" placeholder="Enter city"
                    [readOnly]="!allowAddressManualEntry">
                </div>
                <div class="form-group col-6">
                  <label>County</label>
                  <input type="text" name="county" class="form-control" formControlName="county" placeholder="Enter county"
                    [readOnly]="!allowAddressManualEntry">
                </div>
                <div class="form-group col-6">
                  <label>Post Code</label>
                  <input type="text" name="postcode" class="form-control" formControlName="postcode" placeholder="Enter post code"
                    [readOnly]="!allowAddressManualEntry">
                </div>
                <div class="form-group col-6">
                  <label>Date Of Birth</label>
                  <input type="date" name="dob" class="form-control" formControlName="dob" [max]="today | date:'yyyy-MM-dd'">
                </div>
              </div>
              <div class="form-row"
                  [ngClass]="alarmUserDetails.errors?.contactDetailsRequired? 'valueRequired': (!isEmailRequired? 'valueProvided':'')" >
                <div class="form-group col-6">
                  <label>Telephone</label>
                  <input type="text" name="telephone" class="form-control validated" formControlName="telephone"
                    (keypress)="numberOnly($event, false)" placeholder="Enter telephone">
                </div>
                <div class="form-group col-6">
                  <label>Mobile</label>
                  <input type="text" name="mobile" class="form-control validated" formControlName="mobile"
                    (keypress)="numberOnly($event, false)" placeholder="Enter mobile">
                </div>
              </div>
              <div class="form-row mt-2" *ngIf="isProfessionalReferral">
                <div class="form-group col-4">
                  <legend class="col-form-label pt-0">Sales Contact</legend>
                </div>
                <div class="form-group col-8">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userSalesContactYes" value="Yes"
                      formControlName="salesContact" (change)="changeSalesContact()">
                    <label class="form-check-label" for="userSalesContactYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userSalesContactNo" value="No"
                      formControlName="salesContact" (change)="changeSalesContact()">
                    <label class="form-check-label" for="userSalesContactNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="formsect">
            <div class="formbar row no-gutters">
              <div class="col-12">
                Referrer Details
              </div>
            </div>
            <div formGroupName="referrerDetails">
              <div class="form-row">
                <div class="form-group col-4">
                  <label>First Name</label>
                  <input type="text" name="firstName" class="form-control" formControlName="firstName" placeholder="Enter first name">
                </div>
                <div class="form-group col-4">
                  <label>Other Name</label>
                  <input type="text" name="otherName" class="form-control" formControlName="otherName" placeholder="Enter other name">
                </div>
                <div class="form-group col-4">
                  <label>Last Name</label>
                  <input type="text" name="lastName" class="form-control" formControlName="lastName" placeholder="Enter last name">
                </div>
                <div class="form-group col-6">
                  <label>Email</label>
                  <input type="email" name="email" class="form-control" formControlName="email" placeholder="Enter email">
                </div>
                <div class="form-group col-6">
                  <label>Relationship</label>
                  <input type="text" name="relationship" class="form-control" formControlName="relationship" placeholder="Enter relationship">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label>Telephone</label>
                  <input type="text" name="telephone" class="form-control" formControlName="telephone"
                    (keypress)="numberOnly($event, false)" placeholder="Enter telephone">
                </div>
                <div class="form-group col-6">
                  <label>Mobile</label>
                  <input type="text" name="mobile" class="form-control" formControlName="mobile"
                    (keypress)="numberOnly($event, false)" placeholder="Enter mobile">
                </div>
              </div>
              <div class="form-row mt-2">
                <div class="form-group col-4">
                  <legend class="col-form-label pt-0">Referred By</legend>
                </div>
                <div class="form-group col-8">
                  <input type="text" name="referredBy" class="form-control" formControlName="referredBy">
                </div>
                <ng-container *ngIf="isProfessionalReferral">
                  <div class="form-group col-4">
                    <legend class="col-form-label pt-0">Sales Contact</legend>
                  </div>
                  <div class="form-group col-8">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="referrerSalesContactYes" value="Yes"
                        formControlName="salesContact" (change)="changeSalesContact()">
                      <label class="form-check-label" for="referrerSalesContactYes">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="referrerSalesContactNo" value="No"
                        formControlName="salesContact" (change)="changeSalesContact()">
                      <label class="form-check-label" for="referrerSalesContactNo">
                        No
                      </label>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group col-4">
                  <legend class="col-form-label pt-0">Account Contact</legend>
                </div>
                <div class="form-group col-8">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="referrerAcYes" value="Yes"
                      formControlName="accountContactForCRM">
                    <label class="form-check-label" for="referrerAcYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="referrerAcNo" value="No"
                      formControlName="accountContactForCRM">
                    <label class="form-check-label" for="referrerAcNo">
                      No
                    </label>
                  </div>
                </div>
                <div class="form-group col-4">
                  <legend class="col-form-label pt-0">User Aware Of Referral</legend>
                </div>
                <div class="form-group col-8">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userAwareOfReferralYes" value="Yes"
                      formControlName="userAwareOfReferral">
                    <label class="form-check-label" for="userAwareOfReferralYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="userAwareOfReferralNo" value="No"
                      formControlName="userAwareOfReferral">
                    <label class="form-check-label" for="userAwareOfReferralNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" *ngIf="isProfessionalReferral">
          <div class="formsect sales-contact-details">
            <div class="formbar row no-gutters">
              <div class="col-6">
                Sales Contact Details
              </div>
            </div>
            <div formGroupName="salesContactDetails">
              <div class="form-row">
                <div class="form-group col-4">
                  <label>First Name</label>
                  <input type="text" name="firstName" class="form-control" formControlName="firstName"
                    placeholder="Enter first name" [required]="salesContactNeeded" [readonly]="!salesContactNeeded">
                </div>
                <div class="form-group col-4">
                  <label>Other Name</label>
                  <input type="text" name="otherName" class="form-control" formControlName="otherName"
                    placeholder="Enter other name" [readonly]="!salesContactNeeded">
                </div>
                <div class="form-group col-4">
                  <label>Last Name</label>
                  <input type="text" name="lastName" class="form-control" formControlName="lastName"
                    placeholder="Enter last name" [required]="salesContactNeeded" [readonly]="!salesContactNeeded">
                </div>
                <div class="form-group col-6">
                  <label>Email</label>
                  <input type="text" name="email" class="form-control" formControlName="email"
                    placeholder="Enter email" [required]="salesContactNeeded" [readonly]="!salesContactNeeded">
                </div>
                <div class="form-group col-6">
                  <label>Relationship</label>
                  <input type="text" name="relationship" class="form-control"
                    formControlName="relationship" placeholder="Enter relationship"
                    [readonly]="!salesContactNeeded">
                </div>
              </div>
              <div class="form-row" [ngClass]="!salesContactNeeded? undefined: (salesContactDetails.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided')">
                <div class="form-group col-6">
                  <label>Telephone</label>
                  <input type="text" name="telephone" class="form-control" [ngClass]="salesContactNeeded? 'validated': undefined" formControlName="telephone"
                    (keypress)="numberOnly($event, false)" placeholder="Enter telephone"
                    [readonly]="!salesContactNeeded">
                </div>
                <div class="form-group col-6">
                  <label>Mobile</label>
                  <input type="text" name="mobile" class="form-control" [ngClass]="salesContactNeeded? 'validated': undefined" formControlName="mobile"
                    (keypress)="numberOnly($event , false)" placeholder="Enter mobile"
                    [readonly]="!salesContactNeeded">
                </div>
              </div>
              <div class="form-row mt-2">
                <div class="form-group col-4">
                  <legend class="col-form-label pt-0">Account Contact</legend>
                </div>
                <div class="form-group col-8">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="salesAccContactForCRMYes"
                      value="Yes" formControlName="accountContactForCRM" (click)="salesContactNeeded">
                    <label class="form-check-label" for="salesAccContactForCRMYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="salesAccContactForCRMNo" value="No"
                      formControlName="accountContactForCRM" (click)="salesContactNeeded">
                    <label class="form-check-label" for="salesAccContactForCRMNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="formsect">
            <div class="formbar row no-gutters">
              <div class="col-6">
                Additional Details
              </div>
            </div>
            <div>
              <div class="form-row">
                <ng-container *ngIf="openMode !='add'">
                  <div class="col-12 form-group">
                    <legend class="col-form-label pt-0">Customer Asked For Info Pack</legend>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="askedForInfoPack" id="askedInfoPackYes" value="Yes"
                        formControlName="askedForInfoPack">
                      <label class="form-check-label" for="askedInfoPackYes">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="askedForInfoPack" id="askedInfoPackNo" value="No"
                        formControlName="askedForInfoPack">
                      <label class="form-check-label" for="askedInfoPackNo">
                        No
                      </label>
                    </div>
                    <div *ngIf="false" class="form-check form-check-inline">
                      <button type="button" title="Create post sheet" class="btn btn-primary" (click)="showAddPost()" >
                       Create post sheet
                      </button>
                    </div>
                    <div *ngIf="false" class="form-check form-check-inline">
                      <button type="button" title="Send Info Pack Email" class="btn btn-primary" (click)="emailInfoPack()" 
                        [disabled]="emailInfoPackDisabled">
                       Email info pack
                      </button>
                    </div>
                  </div>
                  <div class="col-6 form-group">
                    <label for="status">Status</label>
                    <p-dropdown [options]="statusSelects" id='status' formControlName="status" (onChange)="statusChange()" [required]="true">
                    </p-dropdown>
                  </div>
                  <div class="col-4 form-group">
                    <ng-container *ngIf="['Pending Payment'].includes(status.value) || leadForm.value.paymentRef">
                      <label for='paymentRef'>Payment Ref</label>
                      <input formControlName='paymentRef' class="form-control" readonly />
                    </ng-container>
                  </div>
                  <div class="col-2 form-group">
                    <ng-container *ngIf="['Pending Payment'].includes(status.value) && !leadForm.value.paymentRef">
                      <button type="button" title="Get Payment Ref" id='paymentRefBtn' class="btn btn-primary" (click)="getPaymentRef()">
                        Get Ref
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="['Declined'].includes(status.value)">
                  <div class="col-6 form-group">
                    <label>Why do they not want a call back?</label>
                    <p-dropdown [options]="leadDeclinedOptions" name="leadDeclinedReason" formControlName="leadDeclinedReason"
                      placeholder="Select a reason" [showClear]="true" [required]="true" appendTo="body">
                    </p-dropdown>
                  </div>
                  <div *ngIf="leadDeclinedReason.value == 'Other'" class="col-6 form-group">
                    <label>Other Reason</label>
                    <input type="text" name="leadDeclinedReason2" class="form-control" formControlName="leadDeclinedReason2"
                      placeholder="Enter why lead is not proceeding" required>
                  </div>
                </ng-container>
                <ng-container *ngIf="['Active', 'Pending Payment', 'Considering', 'Unable to Contact'].includes(status.value)">
                  <div class="col-6 form-group">
                    <label>Call Shift</label>
                    <p-dropdown [options]="callDateShifts" name="callDateShift" formControlName="callDateShift"
                      placeholder="Select time to call" [showClear]="true" [required]="true" (onChange)="shiftChange($event)" 
                      appendTo="body">
                    </p-dropdown>
                  </div>
                  <div class="col-6 form-group">
                    <label>Select Date</label>
                    <input [type]="callDateType" name="callDate" class="form-control" formControlName="callDate"
                      [min]="today | date:(dateType == 'datetime-local'?'yyyy-MM-ddTHH:mm':'yyyy-MM-dd')" 
                      required value=''>
                  </div>
                </ng-container>
              </div>
              <br>
              <legend class="col-form-label pt-0">Additional Notes</legend>
              <div class="formbox" formArrayName="comments">
                <div class="form-row note-list" id="note" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                  <li class="note-l" *ngFor="let note of commentForms.controls; let i=index" [formGroupName]="i">
                    <div class="note-m">
                      Added By {{note.value.createdBy}}
                    </div>
                    <div class="note-m">
                      {{note.value.createdAt | date:'EEEE, dd/MM/y - HH:mm'}}
                    </div>
                    <textarea class="noteContent" [rows]="2" [cols]="60" pInputTextareaname="comments" formControlName="comments" readonly>
                    </textarea>
                  </li>
                </div>
                <div class="form-row mt-2" [formGroup]="addCommentForm">
                  <div class="form-group col-12 col-lg-12">
                    <textarea class="form-control" formControlName="comments" name="comments" [rows]="2" [cols]="15" pInputTextarea>
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <div class="formsect notes">
            <div class="formbar row no-gutters">
              <div class="col-6">
                Call Log
              </div>
            </div>
            <div class="formbox" formArrayName="callNotes">
              <div *ngIf="openMode != 'add'" class="form-row note-list" id="note" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                <li class="note-l" *ngFor="let note of callNotesForms.controls;let i=index" [formGroupName]="i">
                  <div class="note-m">
                    Added By {{note.value.createdBy}}
                  </div>
                  <div class="note-m">
                    {{note.value.createdAt | date:'EEEE, dd/MM/y - HH:mm'}}
                  </div>
                  <textarea class="noteContent" [rows]="2" [cols]="30" pInputTextareaname="content" formControlName="content"
                    readonly></textarea>
                </li>
              </div>
              <div class="form-row mt-2" [formGroup]="addCallNoteForm">
                <div class="form-group col-12 col-lg-12">
                  <label>Call Log</label>
                  <textarea class="form-control" formControlName="content" name="content" [rows]="2" [cols]="30" pInputTextarea
                    [required]="isCallLogRequired">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(matchedOrderForms.length > 0)" class="formsect checkLead">
            <div class="formbar row no-gutters">
              <div class="col-6">
                Orders with the same Information
              </div>
            </div>
            <p-table [value]="matchedOrderForms.controls" [columns]="leadsToCheckColumns" [rows]="5" [sortOrder]="1">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns let-rowIndex="rowIndex">
                <ng-container formArrayName="matchedOrders">
                  <tr [pSelectableRow]="rowData" [formGroupName]="rowIndex">
                    <td *ngFor="let col of leadsToCheckColumns" [ngSwitch]="col['field']">
                      <div *ngSwitchCase="'orderChecked'">
                        <p-checkbox formControlName="orderChecked" [binary]="true"></p-checkbox>
                      </div>
                      <div *ngSwitchCase="'name'">
                        {{rowData.value.alarmUserName}}
                      </div>
                      <div *ngSwitchCase="'orderCreationDate'">
                        {{rowData.value.orderCreationDate | date: 'dd/MM/yyyy'}}
                      </div>
                      <div *ngSwitchCase="'tdCode'">
                        <a routerLink="/order/{{rowData.value.orderId}}" target="_blank">
                        {{rowData.value.tdCode}}
                        <div *ngIf="isOrderLocked(rowData.value.orderId)">
                          {{orderLockedBy(rowData.value.orderId)}}
                        </div>
                        </a>
                      </div>
                      <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
      <div *ngIf="openMode == 'add'" class="row">
        <ng-container *ngTemplateOutlet="leadFormRef"></ng-container>
      </div>
      <div *ngIf="!loading && openMode != 'add'">
        <fieldset *ngIf="openMode == 'view'" [disabled]="true">
          <div class="row">
            <ng-container *ngTemplateOutlet="leadFormRef"></ng-container>
          </div>
        </fieldset>
        <div class="row" *ngIf="openMode == 'edit'">
          <ng-container *ngTemplateOutlet="leadFormRef"></ng-container>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-warning" (click)="closeDialog()">
        Cancel
      </button>
      <button type="button" *ngIf="openMode != 'view'" class="btn btn-primary mr-2" (click)="submit()" [disabled]="!isOkToSubmit">
        Submit
      </button>
    </ng-template>
  </p-dialog>
</div>
<app-add-post-sheet *ngIf="showPostSheetAdd" openMode="addFromLead" [lead]="leadDetail" (closeAddPostSheet)="closeAddPostSheet($event)">
</app-add-post-sheet>
<p-confirmDialog [baseZIndex]="2000"></p-confirmDialog>
<router-outlet (window:unload)="unload()"></router-outlet>