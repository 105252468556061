<p-dialog (onHide)="onHideDialog()" header="Change Price" [(visible)]="display" [modal]="true"
    [style]="{width: '50vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    id="renewalPriceDialog" (onShow)="removeScrollBlock()">
  <div id="renewalPriceDialogBody">
    <form (ngSubmit)="submitData()" #form="ngForm">
      <div class="row">
        <div class="col-md-12 form-group">
          <p-dropdown [options]="priceChangeReasons"
            [(ngModel)]="selectedPriceChangeReason" 
            placeholder="Why are you changing the Renewal Price?**" [style]="{width:'100%'}" [showClear]="true"
            name="selectedPriceChangeReason" #selectedPriceChangeReasonRef="ngModel" [required]=true>
          </p-dropdown>
          <div *ngIf="selectedPriceChangeReasonRef?.touched && selectedPriceChangeReasonRef?.invalid"
              class="alert alert-danger">
            The reason for changing the Renewal Price is required
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedPriceChangeReason == 'Complaint'" >
        <div class="row">
          <div class="col-md-12 form-group">
            <p-dropdown [options]="complaintReasons" [(ngModel)]="selectedComplaintReason"
              placeholder="Why is the customer making a complaint?**" [style]="{width:'100%'}" [showClear]="true"
              name="selectedComplaintReason" #selectedComplaintReasonRef="ngModel" [required]=true>
            </p-dropdown>
            <div *ngIf="selectedComplaintReasonRef?.touched &&  selectedComplaintReasonRef?.invalid" class="alert alert-danger">
              The Complaint Reason is required
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="selectedComplaintReason == 'Other'" class="col-md-12 form-group">
            <span class="p-float-label" >
              <input id="float-complaint" class="form-control" type="text" pInputText [(ngModel)]="otherComplaintReason" #otherComplaintReasonRef="ngModel"
                name="otherComplaintReason" required>
              <label for="float-complaint" >
                Enter why the customer is making a complaint
              </label>
            </span>
            <div *ngIf="otherComplaintReasonRef?.touched &&  otherComplaintReasonRef?.invalid" class="alert alert-danger">
              The Complaint Reason is required
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedPriceChangeReason == 'Discount'">
        <div class="row">
          <div class="col-md-12 form-group">
            <p-dropdown [options]="discountOptions" [(ngModel)]="selectedDiscountOption"
              placeholder="Choose" [style]="{width:'100%'}" [showClear]="true"
              name="selectedDiscountOption" #selectedDiscountOptionRef="ngModel" [required]=true>
            </p-dropdown>
            <div *ngIf="selectedDiscountOptionRef?.touched &&  selectedDiscountOptionRef?.invalid" class="alert alert-danger">
              A discount reason is required
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="selectedDiscountOption == 'Other'" class="col-md-12 form-group">
            <span class="p-float-label">
              <input id="float-discount" class="form-control" type="text" pInputText [(ngModel)]="otherDiscountOption"
                #otherDiscountOptionRef="ngModel" name="otherDiscountOption" required>
              <label for="float-discount">
                Enter Discount
              </label>
            </span>
            <div *ngIf="otherDiscountOptionRef?.touched &&  otherDiscountOptionRef?.invalid" class="alert alert-danger">
              A discount reason is required
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="selectedPriceChangeReason == 'Other'" class="row">
        <div class="col-md-12 form-group">
          <span class="p-float-label" width="100%">
            <input id="float-price" class="form-control" type="text" width="100%" pInputText
              [(ngModel)]="otherPriceChangeReason" #otherPriceChangeReasonRef="ngModel" name="otherPriceChangeReason" required>
            <label for="float-price" width="100%">
              Why are you changing Renewal Price
            </label>
          </span>
          <div *ngIf="otherPriceChangeReasonRef?.touched &&  otherPriceChangeReasonRef?.invalid" class="alert alert-danger">
            A price change Reason is required
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!!selectedPriceChangeReason">
        <div class="col-md-12">
          <h6>Additional Note</h6>
          <textarea [rows]="2" [cols]="30" id="float-textarea" name="additionaNote" pInputTextarea
            [(ngModel)]="additionalNote"></textarea>
        </div>
      </div>
      <div class="row">
        <div *ngIf="orderData.renewalDiscounts.length > 0" class="col-md-12 form-group">
          <div class="alert alert-danger">
            Please review the discounts afterwards to see if any need adjusting.
          </div>
        </div>
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isSubmitButtonDisabled">Submit</button>
          <button type="button" class="btn btn-warning ml-2" (click)="onHideDialog()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>