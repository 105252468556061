import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {WebsiteResponse} from '../../models/responses/websiteResponse.model';
import {WebsitesResponse} from '../../models/responses/websitesResponse.model';
import {Website} from '../../models/website.model';
import {WebsiteService} from './website.service';
import {isValidObjectId} from '../../helpers/helperFunctions';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss'],
  providers: [MessageService]
})

export class WebsitesComponent implements OnInit {
  websiteForm: FormGroup;
  processing: boolean = false;
  website = new Website();
  websites: Website[];
  btnDisabled = false;
  formHeading: string;
  action: string;
  skus: string;
  id: string;

  constructor(
    private fb: FormBuilder,
    private websiteService:WebsiteService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private title: Title,
  ) {
  }
  
  importNewProducts()   {
    if (!isValidObjectId(this.id)) {
      return;
    }
    this.websiteService
      .importNewProductsForWebsite(this.id)
      .subscribe((response: WebsiteResponse) => {
        if (!response.success) {
          this.showError(`Error importing products ${response.message}`);
        } else {
          this.showSuccess('SKUs import started');
        }
      }, err => {
        this.showError(`Error importing products ${err.message}`);
      });
  };

  importSpecificProductsForWebsite()   {
    if (!isValidObjectId(this.id)) {
      return;
    }
    if (!this.skus && !this.skus.trim()) {
      this.showError('You must specify the SKUs to import');
    }
    this.websiteService
      .importSpecificProductsForWebsite(this.id, this.skus.split('\n'))
      .subscribe((response: WebsiteResponse) => {
        if (!response.success) {
          this.showError(`Error importing products ${response.message}`);
        } else {
          this.showSuccess('SKUs import started');
          this.skus = '';
        }
      }, err => {
        this.showError(`Error importing products ${err.message}`);
      });
  };

  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: message
    });
  }

  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: message,
    });
  }

  private initForm() {
    this.websiteForm = this.fb.group({
      domain: [''],
      title: ['', Validators.required],
      background: ['', Validators.required],
      color: ['', Validators.required],
      titleBarColor:['',Validators.required],
      order: [0],
      accountingCode: [''],
      api: this.fb.group({
        url: ['', Validators.required],
        secret: ['', Validators.required],
        key: ['', Validators.required],
        apiKeyGF: [''],
        privateKeyGF: [''],
      })
    });
  }

  ngOnInit() {
    this.initForm();
    this.skus = '';

    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      this.websiteService
        .getWebsites()
        .subscribe((websites: WebsitesResponse) => {
          this.websites = websites.websites;
        }, err => {
          this.showError(`Error getting website details ${err.message}`);
        });
      if (!!this.id && isValidObjectId(this.id)) {
        this.websiteService
          .getWebsite(this.id)
          .subscribe(website => {
            this.website = website['website'];
            this.websiteForm.patchValue({
              domain: this.website.domain,
              title: this.website.title,
              api: this.website.api,
              background: this.website.background,
              color: this.website.color,
              titleBarColor:this.website.titleBarColor,
              order: this.website.order,
              accountingCode: this.website.accountingCode,
            });
          }, err => {
            this.showError(`Error getting website details ${err.message}`);
          });
      }
    });
    this.formHeading = this.id ? `Edit website!` : 'Create New website!';
    this.action = this.id ? 'Update Website' : 'Create Website';
    this.title.setTitle('CRM Websites');
  }


  submitForm() {
    let result;
    let websiteParams = {
      website: this.websiteForm.value
    };
    this.processing = true;
    if (this.website._id) {
      result = this.websiteService.updateWebsite(this.website._id, websiteParams);
    } else {
      result = this.websiteService.createWebsite(websiteParams);
    }
    result.subscribe((rsp: SimpleResponse) => {
      if (!rsp.success) {
        this.showError(`Error updating website ${rsp.message}`);
      } else {
        this.showSuccess('Website updated successfully');
      }
      this.processing = false;
      this.ngOnInit();
    }, err => {
      this.processing = false;
      this.showError(`Error updating website details ${err.message}`);
      return;
    })
  }

  get allowBrandCodeChange(): boolean {
    return !this.id || !this.website.title;
  }
}
