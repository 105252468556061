import {Component, Host, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {numberOnly} from '../../../helpers/keyboardHelpers';
import {getStatusBackgroundColour, getStatusColour} from '../../../lookups/statuses';
import {Order} from '../../../models/order.model';
import {ProposedMessage} from '../../../models/proposedMessage.model';

@Component({
  selector: 'app-cancellation-invoice[message][order][isSmallerThanLarge]',
  templateUrl: './cancellation-invoice.component.html',
  styleUrls: ['./cancellation-invoice.component.scss']
})
export class CancellationInvoiceComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    @Host() private parentFormDirective: FormGroupDirective,
  ) { }
  @Input() message: ProposedMessage;
  @Input() order: Order;
  @Input() isSmallerThanLarge: boolean;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  numberOnly = numberOnly;

  ngOnInit() {
    this.messageForm.addControl('invoice',
      this.formBuilder.group({
        'unitCost': [this.message.cancellationInfo.unitCost, [Validators.required]],
        'vatCost': [this.message.cancellationInfo.vatCost, [Validators.required]],
        'equipCost': [this.message.cancellationInfo.equipCost, [Validators.required]],
        'equipName': [this.message.cancellationInfo.equipName, [Validators.required]],
      })
    );
  }

  get messageForm(): FormGroup {
    return this.parentFormDirective.form;
  }
}
