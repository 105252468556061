<p-dialog (onHide)="closeDialog()" header="NCF Status Change Details" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    (onShow)="removeScrollBlock()">
  <form [formGroup]="ncfForm">
    <div class="form-row">
      <div class="form-group col-12">
        <label for='ncfMethod'>New NCF To Be Sent By</label>
        <p-dropdown id='ncfMethod' [showClear]="true" [options]="ncfMethods" appendTo="body"
          [filter]="true" [resetFilterOnHide]="true" placeholder="Select how new NCF will be sent"
          [baseZIndex]="3200" [required]="true" formControlName="ncfMethod">
        </p-dropdown>
      </div>
      <div class="form-group col-12">
        <label fpr='canOther'>Additional Information</label>
        <p-checkbox name="isCustomerRip" [binary]="true" label="Has the customer passed away?"
          formControlName="isCustomerRip">
        </p-checkbox>
      </div>
      <div class="form-group col-12" *ngIf="ncfMethod == 'Post'">
        <div class="alert alert-warning">
          Remember to add the paper copy NCF request to the post sheet.
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12 form-group text-right">
      <button type="button" class="btn btn-primary" (click)="saveNcfDetails()" [disabled]="!isOkToSave()">
        Save
      </button>
      <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
    </div>
  </div>
</p-dialog>