<p-dialog (onHide)="onHideDialog()" header="Price Book" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div class="formsect">
    <div class="row formbar no-gutters">
      <div class="col-12">
        Current Details
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-xl-2">
        <label for="currentPlanCode">Plan Code:</label>
        <input type="text" pInputText id="currentPlanCode" name="currentPlanCode" value="{{order.accountDetails.plan}}" readonly="true">
      </div>
      <div class="col-6 col-xl-2">
        <label for="currentPrice">Price:</label>
        <input type="text" pInputText id="currentPrice" name="currentPrice" value="{{order.renewalInformation.renewalPrice}}" readonly="true">
      </div>
      <div class="col-6 col-xl-2">
        <label for="currentVatStatus">VAT Status:</label>
        <input type="text" pInputText id="currentVatStatus" name="currentVatStatus" value="{{order.accountDetails.vat}}" readonly="true">
      </div>
      <div class="col-6 col-xl-6">
        <div *ngIf='hasSixWeeksFree' class="alert alert-success mt-2 mb-0">
          Customer is Business Development customer that qualifies for 6 weeks free.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="orderDate">Order Date:</label>
        <input type="text" pInputText id="orderDate" name="orderDate" value="{{order.created | date:'dd/MM/yyyy'}}" readonly="true">
      </div>
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="freeMonths">Free Months:</label>
        <input type="text" pInputText id="freeMonths" name="freeMonths" value="{{order.renewalInformation.freemonths}}" readonly="true">
      </div>
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="currentPlanType">Plan Type:</label>
        <input type="text" pInputText id="currentPlanType" name="currentPlanType" value="{{order.accountDetails.planType}}" readonly="true">
      </div>
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="crmRenewalDate">CRM Renewal Date:</label>
        <input type="date" id="crmRenewalDate" name="crmRenewalDate" value="{{crmRenewalDate}}" readonly="true">
      </div>
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="nextRenewalDate">Next Renewal Date:</label>
        <input type="date" id="nextRenewalDate" name="nextRenewalDate" value="{{nextRenewalDate}}" readonly="true">
      </div>
      <div class="col-6 col-md-4 col-xl-2 form-group">
        <label for="proRataRenewalDate">Pro-rata Charge to Date:</label>
        <input type="date" id="proRataRenewalDate" name="proRataRenewalDate" value="{{proRataRenewalDate}}" readonly="true">
      </div>
      <div class="col-12">
        <div *ngIf="isLifetimeOrder(); else notCurrentlyLifetime" class="alert alert-danger">
          Renewal dates and pro-rata values cannot be caluculated for Lifetime orders.
        </div>
        <ng-template #notCurrentlyLifetime>
          <div class="alert alert-warning">
            If there is no renewal date in the CRM the "Next Renewal Date" and "Pro-rata Charge to Date" are calculated from the
            order date, free months and renewal period.<br/>
            The "Pro-rata Charge to Date" is the first renewal that there is time to change payment collection for.
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row formbar no-gutters">
      <div class="col-12">
        Details To Quote For
      </div>
    </div>
    <div class="row hardware">
      <div class="col-3 form-group">
        <div class="row">
          <div class="col-12 form-group">
            <label for="addHardwareSet">Sets/Plans:</label>
            <p-dropdown styleClass="addHardwareSet" [options]="hardwareSetList" placeholder="Select hardware set" [(ngModel)]="newHardwareSet"
                [filter]="true" [showClear]="true" appendTo="body" [baseZIndex]="3200">
            </p-dropdown>
            <button type="button" class="btn btn-primary addBtn" (click)="addHardwareSet()">
              <i class="fas fa-plus-square"></i>
            </button>
          </div>
          <div *ngFor="let hardwareSet of hardwareSetsToQuoteFor; let setIndex = index" class="col-12">
            <div>
              {{hardwareSet.title}}
              <button type="button" class="btn deleteBtn" (click)="deleteHardwareSet(setIndex)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 form-group">
        <div class="row">
          <div class="col-12 form-group">
            <label for="addHardware">Additional Hardware:</label>
            <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select additional hardware" [(ngModel)]="newHardware"
              [filter]="true" [showClear]="true" appendTo="body" [baseZIndex]="3200">
            </p-dropdown>
            <button type="button" class="btn btn-primary addBtn" (click)="addHardware()">
              <i class="fas fa-plus-square"></i>
            </button>
          </div>
          <div *ngFor="let hardware of hardwareToQuoteFor; let hardwareIndex = index" class="col-12">
            <div>
              {{hardware.title}}
              <button type="button" class="btn deleteBtn" (click)="deleteHardware(hardwareIndex)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 form-group">
        <div class="row">
          <div class="col-12 form-group">
            <label for="service">Services:</label>
            <p-dropdown styleClass="addService" [options]="serviceList" placeholder="Select Services" [(ngModel)]="newService"
              [filter]="true" [showClear]="true" appendTo="body" [baseZIndex]="3200">
            </p-dropdown>
            <button type="button" class="btn btn-primary addBtn" (click)="addService()">
              <i class="fas fa-plus-square"></i>
            </button>
          </div>
          <div *ngFor="let service of servicesToQuoteFor; let serviceIndex = index" class="col-12">
            <div>
              {{service.title}}
              <button type="button" class="btn deleteBtn" (click)="deleteService(serviceIndex)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 form-group">
        <div class="row">
          <div class="col-12 form-group">
            <label for="newDiscount">Additional Discount to Inclue:</label>
            <input step="0.01" type="number" pInputText id="newDiscount" name="newDiscount" (keypress)="numberOnly($event, true)" [(ngModel)]="newDiscount">
            <div *ngIf="existingDiscounts.length > 0">
              Existing Discounts:
            </div>
          </div>
          <ng-container *ngFor="let renewalDisc of existingDiscounts; let discountIndex = index">
            <div class="col-8">
              {{renewalDisc.otherReasonText? renewalDisc.otherReasonText: renewalDisc.reason}}
              {{renewalDisc.discount.toFixed(2)}} {{renewalDisc.discountExpiry? 'Expires: ' + renewalDisc.discountExpiry: ''}}
            </div>
            <div class="col-4">
              <p-checkbox name="includeDicount" label="Include" [(ngModel)]="includeDiscount[discountIndex]" [binary]="true" [required]=true>
              </p-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div>
          The Plan Type and VAT Status below will default to being the current values.
          You change them if the customer wants to change plan type or is changing VAT status.
        </div>
      </div>
      <div class="col-4 form-group">
        <label for="newPlanType">Plan Type:</label>
        <select name="newPlanType" required [(ngModel)]="newPlanType">
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="annual">Annual</option>
          <!--option value="lifetime">Lifetime</option -->
        </select>
      </div>
      <div class="col-4 form-group">
        <label for="currentVatStatus">VAT Status:</label>
        <select name="currentVatStatus" required [(ngModel)]="newVatStatus">
          <option *ngFor="let vatStatus of vatStatuses" value="{{vatStatus.value}}">
            {{vatStatus.label}}
          </option>
        </select>
      </div>
      <div class="col-4 form-group">
        <label>&nbsp;</label>
        <button type="button" class="btn btn-primary calcBtn" (click)="calculate()" [disabled]="!canCalculate()">
          Calculate <i class="fas fa-calculator"></i>
        </button>
        <button type="button" class="btn btn-primary calcBtn ml-2" (click)="calculateLostEquipCost()" [disabled]="!canCalculateLostEquipCost()">
          Lost Equipment Cost <i class="fas fa-calculator"></i>
        </button>
      </div>
      <div *ngIf="showDiscountWarning" class="col-12">
        <div class="alert alert-warning">
          The new discount is not equivalent to the current discount, taking into account the change in plan type.
          If this is intentional you can ignore this message, otherwise please review the discounts.<br/>
          Remember discounts apply per renewal period, so existing ones will need adjusting as the period has changed.
        </div>
      </div>
    </div>
    <ng-container *ngIf="newPlanCodeAndPrice">
      <div class="row formbar no-gutters">
        <div class="col-12">
          New Details
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="newPlanCodeAndPrice.errors.length; else newDetails">
          <div class="col-12 form-group">
            <div class="alert alert-danger">
              Errors exist that stop the plan code and price being calculated correctly. Please correct and try again:
              <div *ngFor="let error of newPlanCodeAndPrice.errors">
                {{error}}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #newDetails>
          <div class="col-4 form-group">
            <label for="newPlanCode">New Plan Code:</label>
            <input type="text" pInputText id="newPlanCode" name="newPlanCode" value="{{newPlanCodeAndPrice.planCode}}" readonly="true">
          </div>
          <div class="col-4 form-group">
            <label for="newPrice">New Price (per {{newPlanType | planTypeToPeriod}}):</label>
            <input type="text" pInputText id="newPrice" name="newPrice" value="{{newPlanCodeAndPrice.renewalPrice}}" readonly="true">
          </div>
          <div class="col-4 form-group">
            <ng-container *ngIf="!showPlanTypeChangeWarning && !isLifetimeOrder()">
              <label for="priceDiff">Difference (per {{newPlanType | planTypeToPeriod}}):</label>
              <input type="text" pInputText id="priceDiff" name="priceDiff" value="{{priceDiffForPeriod}}" readonly="true">
            </ng-container>
          </div>
          <ng-container *ngIf="daysToGo > 0; else noDaysToGo">
            <div class="col-4 form-group">
              <label for="curDate">Current Date:</label>
              <input type="text" pInputText id="curDate" name="curDate" value="{{currentMoment.format('DD/MM/YYYY')}}" readonly="true">
            </div>
            <div class="col-4 form-group">
              <label for="renewalDate">Pro-rata to Renewal Date:</label>
              <input type="date" pInputText id="renewalDate" name="renewalDate" value="{{proRataRenewalDate}}" readonly="true">
            </div>
            <div class="col-4 form-group">
              <label for="daysToGo">Days to end of current {{order.accountDetails.planType | planTypeToPeriod}}:</label>
              <input type="text" pInputText id="daysToGo" name="daysToGo" value="{{daysToGo}}" readonly="true">
            </div>
            <div class="col-4 form-group">
              <label for="oldProRataPrice">Old Pro-Rata Price:</label>
              <input type="text" pInputText id="oldProRataPrice" name="oldProRataPrice" value="{{proRataOldPrice}}" readonly="true">
            </div>
            <div class="col-4 form-group">
              <label for="newProRataPrice">New Pro-Rata Price:</label>
              <input type="text" pInputText id="newProRataPrice" name="newProRataPrice" value="{{proRataNewPrice}}" readonly="true">
            </div>
            <div class="col-4 form-group">
              <label for="proRataDiff">Difference (to {{proRataRenewalDate  | date:'dd/MM/yyyy'}}):</label>
              <input type="text" pInputText id="proRataDiff" name="proRataDiff" value="{{proRataDiff}}" readonly="true">
            </div>
            <div *ngIf="showPlanTypeChangeWarning" class="col-12">
              <div class="alert alert-warning">
                Plan Type has changed!<br/>
                The "New Pro-Rata Price" is for the new details, aside from the change in plan type,
                so that it gives the correct value to {{proRataRenewalDate  | date:'dd/MM/yyyy'}}.
                The "New Price (per {{newPlanType | planTypeToPeriod}})" should be charged from then on.
              </div>
            </div>
            <div class="col-12">
              <div class="alert alert-info">
                Pro-rata details assume a 30 day period for monthly plans, 91 for quarterly and 365 for annual.<br/>
                <ng-container *ngIf='proRataWholePeriods > 0'>
                  The current {{order.accountDetails.planType | planTypeToPeriod}} and next {{proRataWholePeriods}}
                  {{order.accountDetails.planType | planTypeToPeriod:proRataWholePeriods}} are included in the pro-rata
                  price as there is not enough time to update payment details before they are collected.
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #noDaysToGo>
            <div *ngIf='!isLifetimeOrder()' class="col-12">
              <div class="alert alert-info">Pro-rata details not calculated as renewal date is not set, is today, or is in the past.</div>
            </div>
            <div *ngIf='isLifetimeOrder()' class="col-12">
              <div class="alert alert-info">Pro-rata details not calculated as order is currently a lifetime order.</div>
            </div>
          </ng-template>
          <div class="col-12 form-group"></div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="lostEquipmentCost">
      <div class="row formbar no-gutters">
        <div class="col-12">
          Lost Equipment Charge
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <label for="lostCost">Equipment Charge:</label>
          <input type="text" pInputText id="lostCost" name="lostCost" value="{{lostEquipmentCost}}" readonly="true">
        </div>
        <div *ngIf="hasMonitoringEquip" class="col-12 form-group">
          <div class="alert alert-danger">
            Monitoring equipment should not be charged for, monitoring items excluded from calculation.
          </div>
        </div>
        <div class="col-12 form-group">
          <div class="alert alert-info">
            Discounts and Plan type do not affect this figure. Only the equipment to quote for and VAT status have an impact.
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</p-dialog>