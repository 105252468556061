<p-dialog (onHide)="closeDialog()" header="Cancellation Details" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    (onShow)="removeScrollBlock()">
  <form [formGroup]="cancellingForm">
    <ng-container formGroupName="data">
      <div class="form-row">
        <div class="form-group col-4">
          <label for='canReason'>Cancellation Main Reason</label>
          <p-dropdown id='canReason' [showClear]="true" [options]="cancellationCategoriesSelectItems" appendTo="body"
            [filter]="true" [resetFilterOnHide]="true" placeholder="Select Main Reason for Cancellation"
            [baseZIndex]="3200" [required]="true" formControlName="cancellationReason" (onChange)="changeCancellationCategory($event.value)">
          </p-dropdown>
        </div>
        <div class="form-group col-4" *ngIf="cancellationReasonsSelectItems.length > 0">
          <label for='canDetailedReason'>Cancellation Detailed Reason</label>
          <p-dropdown id='canDetailedReason' [showClear]="true" [options]="cancellationReasonsSelectItems" appendTo="body"
            [filter]="true" [resetFilterOnHide]="true" placeholder="Select Detailed Reason for Cancellation"
            [baseZIndex]="3200" [required]="true" formControlName="detailedCancellationReason" (onChange)="changeCancellationReason($event.value)">
          </p-dropdown>
        </div>
        <div class="form-group col-4" *ngIf="cancellationData.get('otherReason').enabled">
          <label fpr='canOther'>Additional Information</label>
          <input id='canOther' type="text" name="otherReason" formControlName="otherReason" required pInputText/>
        </div>
      </div>
      <div class="form-row" *ngIf="cancellationData.get('personReturning').enabled">
        <div class="form-group col-4">
          <label for='canPerson'>Person Returning</label>
          <input id='canPerson' type="text" name="personReturning" formControlName="personReturning" pInputText required/>
        </div>
        <div class="form-group col-4">
          <label for='canTel'>Telephone Number</label>
          <input id='canTel' type="text" name="telephoneNumber" formControlName="telephoneNumber"
            (keypress)="numberOnly($event, false)" pInputText required/>
        </div>
        <div class="form-group col-4">
          <label for='canEmail'>Cancellation Email</label>
          <input id='canEmail' class="validated" type="text" name="cancellationEmail" formControlName="cancellationEmail" pInputText/>
        </div>
      </div>
    </ng-container>
    <div class="form-row">
      <ng-container *ngIf="emailTemplateExists && !noEmailTagName">
        <div class="form-group col-12">
          When you save the cancellation details the "Email Customer" will open for the below template,
          unless you select a reason why an FPL should be sent by post. No not select a reason if the
          customer is happy to receive the FPL by email.
          If you select a reason the FPL must be posted then the Admin team will be emailed instead.
        </div>
        <div class="form-group col-6">
          <label for='emailType'>Email Template</label>
          <input id='emailType' type="text" name="emailType" formControlName="emailType" pInputText readonly/>
        </div>
        <div class="form-group col-6">
          <label for='reasonForNoEmail'>FPL by post required because</label>
          <p-dropdown id='reasonForNoEmail' [showClear]="true" [options]="noEmailReasons" appendTo="body"
            placeholder="Reason FPL needs sending by post" [baseZIndex]="3200" formControlName="reasonForNoEmail">
          </p-dropdown>
        </div>
      </ng-container>
      <ng-container *ngIf="(!emailTemplateExists || noEmailTagName) && cancellingForm.get('reasonForNoEmail').value">
        <div class="form-group col-12">
          <ng-container *ngIf='isBulkCustomer'>
            No email will be sent when you save because
          </ng-container>
          <ng-template #notBulk>
            When you save the cancellation details an email will be sent to the Admin team because
          </ng-template>
          {{cancellingForm.get('reasonForNoEmail').value}}
        </div>
      </ng-container>
      <ng-container *ngIf="!emailTemplateExists && cancellationReason && !cancellingForm.get('reasonForNoEmail').value">
        <div class="form-group col-12">
          No email will be sent when you save as the selected reason does not require one.
        </div>
      </ng-container>
      <div class="form-group col-6">
        <label for='noteTemplates'>Note Template</label>
        <p-dropdown [options]="noteTemplates" name="noteTemplates" [showClear]="true" placeholder="Select a template" id="noteTemplates"
          (onChange)="changeNoteTemplate($event)" [filter]=true [resetFilterOnHide]="true" appendTo="body" [optionLabel]="'label'" [baseZIndex]="3200">
        </p-dropdown>
      </div>
      <div class="form-group col-6">
        <label for='noteCategories'>Note Categories</label>
        <p-multiSelect [options]="categoriesToAddNote" formControlName="noteCategories" scrollHeight="$1"
          [panelStyle]="{minWidth:'12em'}" placeholder="Choose Categories" [required]="true" appendTo="body" [baseZIndex]="3200">
        </p-multiSelect>
      </div>
      <div class="form-group col-12">
        <label for='canNote'>Note (no note will be added to the order, unless any text is added below)</label>
        <textarea id='canNote' [rows]="5" [cols]="30" class="form-control" name="note" formControlName="note" pInputTextarea>
        </textarea>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12 form-group text-right">
      <button type="button" class="btn btn-primary" (click)="saveCancellingDetails()" [disabled]="!isOkToSave()">
        Save
      </button>
      <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
    </div>
  </div>
</p-dialog>