import {DataService} from './../data.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild, OnDestroy, HostListener, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, AbstractControl} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AccountContact, AdditionalUser, KeySafe, Order, OrderNote,
  OrderTag, Vim, CorrespondenceDetails, Plan, AdditionalOrReplacementEquipment, Service} from '../models/order.model';
import {OrderService} from './order.service';
import {LogsService} from '../reporting/logs/logs.service';
import {ExcelExportService} from './excel-export.service';
import {TagsService} from '../setup/tags/tags.service';
import * as moment from 'moment-timezone';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {TreeNode} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {ActionCfg, getActionNames, getActionConfigs, getActionColour, getActionBackgroundColour, ActionConfig, ActionReasonLookups, getActionReasonLookups} from '../lookups/actions';
import {getBrandColour, getBrandTitleBarColour} from '../lookups/brands';
import {StatusCfg, statusConfigs, getStatusColour, getStatusBackgroundColour, statuses, equipmentStatuses, EquipStatusCfg,
  keySafeStatusConfig, keySafeStatuses, serviceStatuses} from '../lookups/statuses';
import {PageSection} from '../models/page-section.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {convertOrderToData} from '../helpers/convertOrderToData';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {getTree} from './changeLogTree';
import {OrderUpdateResponse} from '../models/responses/orderUpdateResponse.model';
import {contactDetailsRequiredValidator} from '../validators/contact-details-required.validator';
import {mobileNumberValidator, phoneNumberValidator} from '../validators/phone-number.validator';
import {Observable, Subscription} from 'rxjs';
import {emailValidator, getExternalEmailValidator} from '../validators/email.validator';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {OutstandingAction} from '../models/outstandingAction.model';
import {ContactAttempt} from '../models/contactAttempt.model';
import {Address, getUserAddress, getBestAddress, getCorrespondenceAsUserWithAddress} from '../helpers/getUserAddress';
import {Table} from 'primeng/table';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {UsersService} from '../setup/users/users.service';
import {renewalTypeSelectItems} from '../lookups/renewalTypes';
import {HardwareService} from '../setup/hardware.service';
import {Hardware} from '../models/hardware.model';
import {Column} from '../models/column.model';
import {TagsResponse} from '../models/responses/tagsResponse.model';
import {Tag} from '../models/tag.model';
import {ProductsService} from '../setup/products/products.service';
import {PopulatedProduct} from '../models/product.model';
import {OrderResponse} from '../models/responses/orderResponse.model';
import {DiffHistory} from '../models/changelog/diffHistory.model';
import {vatStatuses} from '../lookups/vatStatuses';
import {orderSpreadsheetCols} from '../lookups/spreadsheetColumns';
import {defaultLeftColumn, defaultRightColumn} from '../lookups/defaultPageSections';
import {HardwareSet} from '../models/hardwareSet.model';
import {reasonRequiredValidator} from '../validators/reason-required.validator';
import {getDiscountReasons} from '../lookups/discountReasons';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {getManualSendEmailTypesForWebsite, getManualSendLetterTypesForWebsite, getManualSendSmsTypesForWebsite} from '../lookups/manualSendMessageTypes';
import {JontekCodeMatches} from '../models/jontekCodeMatches.model';
import {PostSheet} from '../models/postSheet.model';
import {numberOnly, wasCtrlPlusEnterPressed} from '../helpers/keyboardHelpers';
import {CrmLoginResponse} from '../models/responses/crmLoginResponse.model';
import {MultiSelect} from 'primeng/multiselect';
import {Website} from '../models/website.model';
import {environment} from '../../environments/environment';
import {NotificationService} from '../notifications/notification.service';
import {SendEmailRequest} from '../models/requests/sendEmailRequest.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {getLookupFromGetAddressResult, validateAddress} from '../helpers/getAddressHelper';
import {getNoteCategories, NoteCategory} from '../lookups/noteCategory';
import {getNoteTemplates, NoteTemplate} from '../lookups/noteTemplate';
import {OrderLockData} from '../models/socket-io/orderLockData.model';
import {OrderFormTag} from '../models/orderFormTag.model';
import {LogWithUpload} from '../models/log.model';
import {Dropdown} from 'primeng/dropdown';
import {RenewalDiscount} from '../models/renewalDiscount.model';
import {AddNoteParams} from '../models/addNoteParams.model';
import {getEquipmentManualFilename, getEquipmentPriceAndDescription} from '../lookups/equipmentSerial';
import {getMonitoringOptions} from '../lookups/monitoring';
import {PostOrderService} from './post-order.service';
import {VALID_ALARM_CODE, doPlanCodeAndTypeMatch, doPlanCodeAndVatStatusMatch, getDaysBetween, isValidObjectId} from '../helpers/helperFunctions';
import {DropDownChangeEvent} from '../models/primeng/dropdownChangeEvent.model';
import {Environment} from '../models/environment.model';
import {getUserPreferences} from '../lookups/userPreference';
import {getSmartDebitAccounts} from '../lookups/smartDebit';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';
import {AccountService} from '../models/accountService.model';
import {BrandOrTypeSelectItem, getBrandsOrTypesForPlanCode} from '../lookups/hardwareBrandsOrTypes';
import {ServiceUser} from '../models/serviceUser.model';
import {FriendsAndFamilyStatusUpdateRequest} from '../models/requests/friendsAndFamilyStatusUpdateRequest.model';
import {ProposedMessageService} from '../messages-list/proposed-message.service';
import {ProposedMessage} from '../models/proposedMessage.model';
import {Clipboard} from '@angular/cdk/clipboard';
import {UserWithAddress} from '../models/address.model';
import {marketingOptInSelects} from '../lookups/marketingOptIn';
import {ExternalId} from '../models/externalId.model';
import {CancellingDialogData} from '../models/cancellingDialogData.model';
import {SMART_DEBIT_ACCOUNTS} from '../lookups/smartDebitAccounts';
import {PaymentAccount} from '../models/payments/paymentAccount.model';
import {RetentionDialogReturn} from '../models/function-params/retentionDialogReturn.model';
import {CustomerFeedback} from '../models/customerFeedback.model';
import {CustomerFeedbackService} from '../customer-feedback/customer-feedback.service';
import {NcfDialogData} from '../models/ncfDialogData.model';

const NCF_FOLDER_ROOT: string = 'https://appello.sharepoint.com/teams/LL24CustomerRecords/Shared%20Documents/Customer%20Records/CRM%20Files/';
const TDCODE_REGEX: RegExp = /^(?:(?:LL|CL|CK|TC|LLIE)-)?(?:TD|ON-|ON-TD-|CSE-|WEB-|WEB-TD-|XLS-)?(?:RC|KL|KLKL)?(\d+)\D*$/;
const BATCH_SIZE: number = 10000;
const PAYMENT_TAG_REGEX: RegExp = /.*All payments? on (.*) Stripe Account.*/i;

@Component({
  selector: 'app-post-order',
  templateUrl: './post-order.component.html',
  styleUrls: ['./post-order-sections.scss', './post-order.component.scss'],
  providers: [ConfirmationService, MessageService, PostOrderService]
})
export class PostOrderComponent implements OnInit, OnDestroy {

  @ViewChild('alarmUserDetailsSection') alarmUserDetailsSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('accountContactsSection') accountContactsSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('accountDetailsSection') accountDetailsSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('renewalSection') renewalSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('statusSection') statusSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('actionsSection') actionsSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('notesSection') notesSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('tagsSection') tagsSection: TemplateRef<{pageSection: PageSection}>;
  @ViewChild('changeLogTable', {static: false}) changeLogTable: Table;
  @ViewChild('noteCategoriesSelect') noteCategoriesSelect: MultiSelect;
  @ViewChild('templateDropdown') noteTemplateDropdown: Dropdown;

  orderDbId: string;
  feedbackIdToEdit: string;
  inNoteCategoryOnHideEvent: boolean = false;
  orderForm: FormGroup;
  orderFormOld: any;
  // FormGroups for adding
  actionForm: FormGroup;
  addHardwareForm: FormGroup;
  // TODO add for hardware version
  /*
  addHardwareSetForm: FormGroup;
  */
  discountForm: FormGroup;
  tagForm: FormGroup;
  noteForm: FormGroup;
  vimForm: FormGroup;
  addServiceUserForm: FormGroup;
  serviceUsers: ServiceUser[] = [];
  delayMins: number = 20;
  delayValue: number = 1000 * 60 * this.delayMins;
  processing: boolean = false;
  loading: boolean = true;
  enable: boolean = false;
  outstandingActionsErr: boolean = false;
  tagsErr: boolean = false;
  attention: boolean = false;
  deleteSidebar: boolean = false;
  histories: DiffHistory[];
  historiesShown: boolean = false;
  historiesLoading: boolean = false;
  socketId: string;
  historiesTree: TreeNode[];
  historiesCols: Column[];
  historiesFilterFields: string[]
  blockedTD: boolean = true;
  blockedDocument: boolean = false;
  searchLog: string;
  searchText: string;
  selectedCategory: string[];
  categories: NoteCategory[];
  filterType: string;
  noteTemplates: NoteTemplate[];
  allHardware: Hardware[] = [];
  hardwareByCategory: {[category: string]: SelectItem<Hardware>[]} = {};
  allHardwareSets: HardwareSet[] = [];
  hardwareSetsByCategory: {[category: string]: HardwareSet[]} = {};
  allServices: AccountService[] = [];
  keySafes: PopulatedProduct[] = [];
  userName: string;
  otherUser: OrderLockData;
  tags: SelectItem<Tag>[];
  addProductSidebar: boolean;
  fromDate: string = null;
  toDate: string = null;
  password: string;
  email: string;
  ncfLink: string;
  domain: string;
  countdown: number = 30;
  private timeoutVar: NodeJS.Timeout;
  private oldStatus: string;
  public showEmailTwo: boolean = false;
  public buttonName: string = '+';
  isOpenContactAttempt: boolean = false;
  isRenewalInformationChangeDialogOpen: boolean = false;
  isFreeMonthsChangeDialogOpen: boolean = false;
  // TODO remove for hardware version
  isRenewalPriceChangeDialogOpen: boolean = false;
  // TODO add for hardware version
  // isFrozenPriceChangeDialogOpen: boolean = false;
  outstandingActionNameForContactAttempt: string = null;
  isThumbsUpDownDisabled: boolean = false;
  categoriesToAddNote: NoteCategory[] = [];
  defaultNoteCategories: string[] = [];
  vimPopupOpen: boolean = false;
  vimPopupClosed: boolean = false;
  tomorrow: Date = moment().tz('Europe/London').add(1, 'day').toDate();
  todayDateStr: string = moment().tz('Europe/London').format('YYYY-MM-DD');
  actionNames: SelectItem<string>[];
  actions: ActionCfg;
  statuses: string[] = statuses;
  readonly statusConfigs: StatusCfg = statusConfigs;
  readonly equipmentStatuses = equipmentStatuses;
  readonly keySafeStatuses = keySafeStatuses;
  readonly serviceStatuses = serviceStatuses;
  readonly renewalTypeSelectItems: SelectItem<string>[] = renewalTypeSelectItems;
  discountReasonSelectItems: SelectItem<string>[] = [];
  // TODO add for hardware version
  /*
  readonly hardwareStatusConfig: EquipStatusCfg = hardwareStatusConfig;
  */
  readonly keySafeStatusConfig: EquipStatusCfg = keySafeStatusConfig;
  getActionColour = getActionColour;
  getActionBackgroundColour = getActionBackgroundColour;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  getBrandTitleBarColour = getBrandTitleBarColour;
  getBrandColour = getBrandColour;
  numberOnly = numberOnly;
  marketingOptInSelects: SelectItem<string>[] = marketingOptInSelects;
  environment: Environment = environment;
  isSmallerThanLarge: boolean;
  leftColumnSections: PageSection[] = [];
  rightColumnSections: PageSection[] = [];
  orderDataToExport: HomepageFormatOrder;
  canExitOrderPage: boolean = false;
  closing: boolean = false;
  isNoteEditing: any = {};
  isMessageReportOpen: boolean = false;
  widthChangeSubscription: Subscription;
  isAddFaultDialogOpen: boolean = false;
  // TODO remove for hardware version
  equipDetailsForAddFault: {'serialNumber': string, 'name': string, 'status': string, 'isPendant': boolean} = null;
  // TODO add for hardware version
  // equipDetailsForAddFault: {'serialNumber': string, 'name': string, 'status': string} = null;
  displayReplacementItemPopup: boolean = false;
  selectedKeySafe: PopulatedProduct = undefined;
  selectedService: AccountService = undefined;
  vatStatuses: SelectItem<string>[] = vatStatuses;
  isInactiveKeySafesMinimized: boolean = true;
  isInactiveServicesMinimized: boolean = true;
  // TODO add for hardware version
  /*
  isInactiveHardwareMinimized: boolean = true;
  isOldStyleEquipMinimized: boolean = true;
  */
  isExpiredDiscountsMinimized: boolean = true;
  isCorrespondenceMinimized: boolean = true;
  manualSendEmailMessageTypes: SelectItem<string>[] = [];
  manualSendFieldInitialisation: any;
  emailTemplates: SelectItem<string>[] = [];
  manualSendLetterMessageTypes: SelectItem<string>[] = [];
  letterTemplates: SelectItem<string>[] = [];
  manualSendSmsMessageTypes: SelectItem<string>[] = [];
  smsTemplates: SelectItem<string>[] = [];
  hasReadyToTestEmail: boolean = false;
  showPostSheetAdd: boolean = false;
  jontekCodesString: string = '';
  oldAlarmCodesString: string = '';
  equipmentAdded: boolean = false;
  promptForEquipmentTestEmail: boolean = false;
  promptForDamagedEquipmentEmail: boolean = false;
  currentYear: number = moment().get('year');
  allowContactChangesEmail: boolean = true;
  showRefundRequestDialog: boolean = false;
  getAddrClient: getAddressClient;
  alarmUserSearchPostCode: string;
  alarmUserSearchError: string;
  alarmUserAddressResults: SelectItem<Address>[];
  allowAlarmUserAddressManualEntry: boolean;
  correspondenceSearchPostCode: string;
  correspondenceAddressResults: SelectItem<Address>[];
  correspondenceSearchError: string;
  allowCorrespondenceAddressManualEntry: boolean;
  contactDetailChanges: string[];
  isPriceBookOpen: boolean = false;
  actionReasonLookups: ActionReasonLookups;
  hasDiscountMsgBeenDisplayed: boolean;
  noteToEmailContent: string;
  isEmailNoteOpen: boolean;
  orderLink: string;
  monitoringOptions: SelectItem<string>[];
  smartDebitAccounts: SelectItem<string>[];
  isRetentionDialogOpen: boolean = false;
  showPaymentDataDialog: boolean = false;
  showCategoryNotes: boolean = true;
  showCancellingDialog: boolean = false;
  showNcfDialog: boolean = false;
  initialCancellationEmailTemplates: SelectItem<string>[] = [];
  actionToRemoveOnCancellingOrCancelled: string[] = [
    'Technical Support',
    'On Hold',
    'No VAT Form – No Medical – No VAT Paid',
    'No Form/ Form says not exempt – Yes Medical – No VAT Paid',
    'No Form/Form says not exempt – Yes Medical – VAT Paid',
    'Form says not exempt - No Medical - No VAT Paid',
    'Exempt on Form – VAT Paid',
    'Retention',
    'Due Payment',
    'Overdue Payment',
    'Renewal Payment Failure',
    'Replacement Equip Needs Testing',
  ];
  evoDeviceIds: ExternalId[] = [];
  noCancellingEmailTag: string = '';
  addNoteContent: string;
  correctSdAccount: string;
  correctPslid: string;
  retentionRecorded: boolean;

  constructor(
    private fb: FormBuilder,
    private orderService: OrderService,
    private logsService: LogsService,
    private hardwareService: HardwareService,
    private productsService: ProductsService,
    private tagsService: TagsService,
    private excelService: ExcelExportService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private locksSocket: LocksSocketService,
    private pageTitle: Title,
    private confirmationService: ConfirmationService,
    private breakpointObserver: BreakpointObserver,
    private data: DataService,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private postOrderService: PostOrderService,
    private proposedMessageService: ProposedMessageService,
    private clipboard: Clipboard,
    private customerFeedbackService: CustomerFeedbackService,
  ) {
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event) {
    if (!this.isOkToDeactivate(true, '')) {
      $event.returnValue = true;
      $event.preventDefault();
    }
  }

  get order(): Order {
    return this.postOrderService.order;
  }

  get pendingUpdates(): number {
    return this.postOrderService.pendingUpdateCount;
  }

  isOkToDeactivate(fromClose: boolean, newLocation: string): boolean {
    /*
      If the idle timeout is trying to take us to the homepage the timeout will have expired.
      This resets it in case the user cancels and clears the issue stopping the exit and leaves the page idle again
    */
    this.timeOut();
    if (this.postOrderService.pendingUpdateCount > 0) {
      this.showInfoPopUp('Pending Updates',
        `There are ${this.pendingUpdates} updates that have not yet saved, please wait for "Pending Updates" to be zero before closing the order.`
      );
      // stop trying to create more than one popup
      return false;
    }

    // If the form hasn't initialised the order hasn't loaded, so no need to check anything
    if (!this.orderForm) {
      return true;
    }

    // TODO remove for hardware version
    if (this.orderForm.get('renewalInformation').get('renewalPrice').dirty) {
      this.orderForm.get('renewalInformation').get('renewalPrice').markAsPristine();
      this.changeRenewalPrice();
      // stop trying to create more than one popup
      return false;
    }

    // TODO add for hardware version
    /*
    if (this.orderForm.get('renewalInformation').get('frozenPrice').dirty) {
      this.orderForm.get('renewalInformation').get('frozenPrice').markAsPristine();
      this.changeFrozenPrice();
      // stop trying to create more than one popup
      return false;
    }
    */

    if (!!this.noteForm.value.content && this.noteForm.value.content.length > 0) {
      this.showInfoPopUp('Unsaved note', 'You have an unsaved note, please either add it, or delete it before closing the page.');
      // stop trying to create more than one popup
      return false;
    }

    // TODO add for hardware version
    /*
    let invalidHardwareSet: boolean = false;
    (this.orderForm.get('hardwareSets') as FormArray).controls.forEach((control: AbstractControl) => {
      if (control.invalid) {
        invalidHardwareSet = true;
      }
    });
    if (invalidHardwareSet) {
      this.showInfoPopUp('Invalid Hardware Set', 'One or more hardware sets do not have the correct equipment (or equipment at the correct statuses) assigned.');
      $event.returnValue = true;
      $event.preventDefault();
      return;
    }
    */

    if (!this.canExitOrderPage) {
      const missingOrInvalidFields: string[] = [];
      // Treat invalid the same as missing - this allows us to add additional validation
      if (this.orderForm.get('alarmUserDetails').get('firstName').invalid) {
        missingOrInvalidFields.push('First Name')
      }
      if (this.orderForm.get('alarmUserDetails').get('lastName').invalid) {
        missingOrInvalidFields.push('Last Name');
      }
      if (this.orderForm.get('alarmUserDetails').get('userAddress').get('addressOne').invalid) {
        missingOrInvalidFields.push('Address One');
      }
      if (this.orderForm.get('alarmUserDetails').get('userAddress').get('city').invalid) {
        missingOrInvalidFields.push('City');
      }
      if (this.orderForm.get('alarmUserDetails').get('userAddress').get('postcode').invalid) {
        missingOrInvalidFields.push('Post Code');
      }
      // This checks that EITHER a valid landline or mobile is provided
      if (this.alarmUserDetails.errors && this.alarmUserDetails.errors.contactDetailsRequired) {
        missingOrInvalidFields.push('Contact Number');
      }
      // TODO remove for hardware version
      if (this.orderForm.get('accountDetails').get('plan').invalid) {
        missingOrInvalidFields.push('Plan');
      }
      if (this.orderForm.get('accountDetails').get('planType').invalid) {
        missingOrInvalidFields.push('Plan Type')
      }
      // TODO remove for hardware version
      if (this.orderForm.get('renewalInformation').get('renewalPrice').invalid) {
        missingOrInvalidFields.push('Renewal Price');
      }
      // TODO add for hardware version
      /*
      if (this.isPriceFrozen) {
        if (this.orderForm.get('renewalInformation').get('frozenPrice').invalid) {
          missingOrInvalidFields.push('Frozen Price');
        }
      }
      */
      if (missingOrInvalidFields.length > 0) {
        let message: string;
        if (missingOrInvalidFields.length == 1) {
          message = `${missingOrInvalidFields[0]} is a madatory field and is missing/invalid please correct them`;
        } else {
          message = missingOrInvalidFields.slice(0, missingOrInvalidFields.length - 1).join(', ') +
            ` and ${missingOrInvalidFields[missingOrInvalidFields.length - 1]} ` +
            ' are mandatory fields and are missing/invalid please correct them';
        }
        message = message + '. Do you still want to exit from the page?';
        this.showPopUpOnRequiredFieldsBlank('Required Field(s) are blank/invalid', message, fromClose, newLocation);
        // stop trying to create more than one popup
        return false;
      }
    }

    if (this.postOrderService.jontekCodesStringOld != this.jontekCodesString.trim()) {
      this.showInfoPopUp('Unsaved Alarm Code changes',
        'You have an unsaved alarm code changes now being saved wait for this to complete.');
      // stop trying to create more than one popup
      return false;
    }
    clearTimeout(this.timeoutVar);

    return true;
  }

  get alarmUserDetails(): FormGroup {
    return this.orderForm.get('alarmUserDetails') as FormGroup;
  }

  get isNotLLMonitored(): boolean {
    return (!!this.orderForm.get('accountDetails').get('monitoring').value) && 
      (this.orderForm.get('accountDetails').get('monitoring').value != 'lifeline24');
  }

  get monitoringCompany(): string {
    return this.orderForm.get('accountDetails').get('monitoring').value;
  }

  // TODO add for hardware version
  /*
  get isPriceFrozen(): boolean {
    const priceFrozenTag: AbstractControl = this.tagsForms.controls.find((tagControl: AbstractControl) =>
      tagControl.value.content == 'Price Frozen'
    );
    // If price freeze tag exists and it doesn't expire, or hasn't yet expired
    if (!!priceFrozenTag &&
        (!priceFrozenTag.value.expiryDate || (priceFrozenTag.value.expiryDate > this.todayDateStr))) {
      return true;
    }
    return false;
  }
  */

  conveJSON(obj) {
    const res = [];
    if ((obj != null) && (typeof obj == 'object')) {
      const objKeys = [...Object.keys(obj)]
      for (const objKey of objKeys) {
        const subObj = obj[objKey];
        if ((subObj != null) && (typeof subObj == 'object')) {
          const subObjKeys = [...Object.keys(subObj)];
          for (const subObjKey of subObjKeys) {
            const subSubObj = subObj[subObjKey];
            if ((subSubObj != null) && (typeof subSubObj == 'object')) {
              const subSubObjKeys = [...Object.keys(subSubObj)]
              if (subSubObjKeys.includes('action')) {
                res.push({'key': objKey + '.' + subObjKey, value: subSubObj});
              } else {
                for (const subSubObjKey of subSubObjKeys) {
                  const subSubSubObj = subSubObj[subSubObjKey];
                  if ((subSubSubObj != null) && (typeof subSubSubObj == 'object')) {
                    const subSubSubObjKeys = [...Object.keys(subSubSubObj)];
                    if (subSubSubObjKeys.includes('action')) {
                      res.push({'key': objKey + '.' + subObjKey + '.' + subSubObjKey, value: subSubSubObj});
                    } else {
                      for (const subSubSubObjKey of subSubSubObjKeys) {
                        const subSubSubSubObj = subSubSubObj[subSubSubObjKey];
                        if ((subSubSubSubObj != null) && (typeof subSubSubSubObj == 'object')) {
                          const subSubSubSubObjKeys = [...Object.keys(subSubSubSubObj)];
                          if (subSubSubSubObjKeys.includes('action')) {
                            res.push({'key': objKey + '.' + subObjKey + '.' + subSubObjKey + '.' + subSubSubObjKey, value: subSubSubSubObj});
                          } else {
                            for (const subSubSubSubObjKey of subSubSubSubObjKeys) {
                              const subSubSubSubSubObj = subSubSubSubObj[subSubSubSubObjKey];
                              if ((subSubSubSubSubObj != null) && (typeof subSubSubSubSubObj == 'object')) {
                                const subSubSubSubSubObjKeys = [...Object.keys(subSubSubSubSubObj)];
                                if (subSubSubSubSubObjKeys.includes('action')) {
                                  res.push({
                                    'key': objKey + '.' + subObjKey + '.' + subSubObjKey + '.' + subSubSubObjKey+ '.' + subSubSubSubObjKey,
                                    'value': subSubSubSubSubObj});
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

          }
        }

      }
    }
    return res;
  }

  filterFN(obj1, obj2) {
    const result = {};
    let keys: string[] = [];
    if (obj1) {
      keys = [...Object.keys(obj1)]
    }
    if (obj2) {
      keys = [...keys, ...Object.keys(obj2)]
    }
    keys = keys.filter(function (elem, index, self) {
      return index == self.indexOf(elem);
    })

    for (const key of keys) {

      if (obj1 && (obj1[key] != null) && (typeof obj1[key] == 'object') && (Array.isArray(obj1[key]) == false)) {
        const p = this.filterFN(obj1[key], obj2[key]);
        if (Object.keys(p).length) {
          result [key] = p;
        }
      } else if (obj1 && Array.isArray(obj1[key])) {
        let curent: number = 0;
        const arrRes = [];
        const allobj2 = [...obj2[key]];

        let idKey: string = '_id';
        let idValue: string = '_id';
        if (key == 'items' || key == 'plans' || key == 'keySafes' || key == 'additionalEquipment') {
          idKey = '_id';
          idValue = 'equipment';
        } else if (key == 'outstandingActions') {
          idKey = 'outstandingName';
          idValue = 'outstandingName';
        } else if (key == 'tags') {
          idKey = 'tagID';
          idValue = 'content';
        } else if (key == 'notes') {
          idKey = '_id';
          idValue = 'content';
        } else if (key == 'vim') {
          idKey = '_id';
          idValue = 'content';
        } else if (key == 'users') {
          idKey = '_id';
          idValue = 'firstName';
        } else if (key == 'replacementEquipment') {
          idKey = '_id';
          idValue = 'equipment';
        } else if (key == 'hardwareSets') {
          idKey = '_id';
          idValue = 'hardwareSetName';
        } else if ((key == 'hardwareInSet') || (key == 'hardware')) {
          idKey = '_id';
          idValue = 'hardwareName';
        } else if (key == 'accountContacts') {
          idKey = '_id';
          idValue = 'accFirstName';
        } else if (key == 'services') {
          idKey = '_id';
          idValue = 'serviceName';
        }

        for (const subObj of obj1[key]) {
          if (subObj) {
            curent += 1;
            if (typeof subObj == 'string') {
              const pos: number = allobj2.indexOf(subObj);
              // Item hasn't changed, so just ignore
              if (pos > -1) {
                allobj2.splice(pos, 1);
              } else {
                // RH Abdul added this, but is causing changelog on order object to get logged into logs.
                // arrRes[curent] = ({'action': 'remove', 'old': subObj?subObj[idValue]:''});
                arrRes[curent] = ({'action': 'remove', 'old': subObj[idValue]});
              }
            } else {
              // RH Abdul added this, but is causing changelog on order object to get logged into logs.
              // allobj2 = allobj2.filter(e => e != null);
              // let pos = subObj?allobj2.map(el => {
              //   return el[idKey]
              // }).indexOf(subObj[idKey]):-1;
              const pos: number = allobj2.findIndex(el => subObj[idKey] == el[idKey]);
              if (pos > -1) {
                const p = this.filterFN(subObj, allobj2[pos]);
                if (Object.keys(p).length) {
                  arrRes[curent] = p;
                }
                allobj2.splice(pos, 1);

              } else {
                // RH Abdul added this, but is causing changelog on order object to get logged into logs.
                // arrRes[curent] = ({'action': 'remove', 'old': subObj?subObj[idValue]:''});
                arrRes[curent] = ({'action': 'remove', 'old': subObj[idValue]});
              }
            }
          }

        }

        for (const subObj of allobj2) {
          if (subObj) {
            curent += 1;
            if (typeof subObj == 'string') {
              arrRes[curent] = ({'action': 'add', 'new': subObj});
            } else {
              // RH Abdul added this, but is causing changelog on order object to get logged into logs.
              // arrRes[curent] = ({'action': 'add', 'new': subObj?subObj[idValue]:''});
              arrRes[curent] = ({'action': 'add', 'new': subObj[idValue]});
            }
          }

        }
        if (arrRes.length) {
          result [key] = arrRes;
        }
      } else {
        if (obj2 && obj1 && (obj2[key] || obj1[key]) && (obj2[key] != obj1[key])) {
          result[key] = ({'action': 'update', 'old': obj1[key], 'new': obj2[key]});
        }
      }
    }
    return result;
  }

  showWarn() {
    this.messageService.add({
      severity: 'warn',
      life: 300000,
      summary: 'Update Cancellation!',
      detail: 'Changes Not Applied'
    });
  }

  showWarnUpdate(summary: string, detail: string) {
    this.messageService.add({
      severity: 'warn',
      life: 300000,
      summary: summary,
      detail: detail
    });
  }

  showSuccess(summary: string = 'Update Success!', message: string = 'Changes Successfully Applied') {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: summary,
      detail: message,
    });
  }

  confirmContactsAsUser(i: number) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure that you want to copy the contact details?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        acceptClicked = true;
        this.sameContactsAsuser(i);
      },
      reject: () => {
        if (!acceptClicked) {
          this.showWarn();
        }
      }
    });
  }

  confirmdeletevim(vimIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this Vim?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deletevim(vimIndex);
      },
      reject: () => {}
    });
  }

  displayVimAsPopup() {
    // page is still loading, or nothing to display
    if (this.vimPopupOpen || this.vimPopupClosed || (this.vimForms.controls.length === 0)) {
      return;
    }
    this.vimPopupOpen = true;
  }

  closeVimPopup() {
    this.vimPopupClosed = true;
    this.vimPopupOpen = false;
  }

  confirmDeleteOutstandingAction(actionIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this action?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deleteOutstandingAction(actionIndex);
      },
      reject: () => {}
    });
  }

  confirmChangeStatusFromCancellingToActive() {
    this.showInfoPopUp('Equipment Status', 'Remember to change the equipment status!');
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: 'general',
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  showPopUpOnRequiredFieldsBlank(header: string, message: string, fromClose: boolean, newLocation: string) {
    this.confirmationService.confirm({
      key: 'general',
      message: message,
      header: header,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      rejectVisible: true,
      icon: 'pi pi-info-circle',
      accept: () => {
        this.canExitOrderPage = true;
        if (fromClose) {
          window.close();
        } else {
          this.router.navigate([newLocation]);
        }
      },
      reject: () => {
        this.canExitOrderPage = false;
      }
    });
  }

  blockedDocuments() {
    this.blockedDocument = true;
  }

  unblockedDocuments() {
    this.blockedDocument = false;
  }

  get userAddressFormGroup(): FormGroup {
    return (this.alarmUserDetails.get('userAddress') as FormGroup);
  }

  get correspondenceAddressFormGroup(): FormGroup {
    return (this.alarmUserDetails.get('correspondenceDetails') as FormGroup);
  }

  autosave(event: Event|string = ''): void {
    const ccfirstName: AbstractControl = this.orderForm.get('alarmUserDetails.firstName');
    const cclastName: AbstractControl = this.orderForm.get('alarmUserDetails.lastName');
    const ccotherName: AbstractControl = this.orderForm.get('alarmUserDetails.otherName');
    const ccpreferredName: AbstractControl = this.orderForm.get('alarmUserDetails.preferredName');
    this.upperCaseField(ccfirstName);
    this.upperCaseField(cclastName);
    this.upperCaseField(ccotherName);
    this.upperCaseField(ccpreferredName);

    if ((event == '') || (!event['srcElement'].className.includes('noAutoSave') &&
        !event['srcElement'].className.includes('p-dropdown-filter') &&
        !event['srcElement'].className.includes('p-multiselect-filter')) ||
        ((event['type'] === 'blur') && (event['target'].name === 'dob') &&
        this.orderForm.get('alarmUserDetails.userAddress.dob').dirty)) {
      if (this.userAddressFormGroup.get('unknown').value && (this.userAddressFormGroup.get('addressOne').dirty ||
          this.userAddressFormGroup.get('addressTwo').dirty || this.userAddressFormGroup.get('city').dirty ||
          this.userAddressFormGroup.get('county').dirty ||this.userAddressFormGroup.get('postcode').dirty)) {
        this.userAddressFormGroup.get('unknown').setValue(false);
      }
      this.userAddressFormGroup.markAsPristine();
      this.submitForm();
    }
    this.timeOut();
  }

  checkJontekCodes() {
    // Do this to show an update whilst running checks before update which might take some time
    this.postOrderService.incPendingUpdates();
    let hasInvalidJontekCode: boolean = false;
    let hasDuplicateJontekCode: boolean = false;
    const jontekCodes: string[] = [];
    let removedCodes: string[]= [];
    // This is to grab a copy for the email before it's changed
    const previousCurrentCodesCopy: string = this.postOrderService.jontekCodesStringOld;
    const previousCurrentCodesArray: string[] = [];
    const hadOldCodes: boolean = !!this.oldAlarmCodesString;
    previousCurrentCodesCopy.split('\n').forEach((previousCurrentAlarmCode: string) => {
      previousCurrentAlarmCode = previousCurrentAlarmCode.replace(/\s/g, '').toLocaleUpperCase();
      if (previousCurrentAlarmCode.length == 0) {
        return;
      }
      previousCurrentCodesArray.push(previousCurrentAlarmCode);
    });
    this.jontekCodesString.split('\n').forEach((jontekCode: string) => {
      jontekCode = jontekCode.replace(/\s/g, '').toLocaleUpperCase();
      // Stop empty lines displaying the error
      if (jontekCode.length == 0) {
        return;
      }
      if (!VALID_ALARM_CODE.test(jontekCode)) {
        hasInvalidJontekCode = true;
      } else if (jontekCodes.includes(jontekCode)) {
        hasDuplicateJontekCode = true;
      } else {
        jontekCodes.push(jontekCode);
      }
    });
    //tidy up the text area
    this.jontekCodesString = jontekCodes.join('\n');
    if (hasInvalidJontekCode) {
      this.showErrorPopUp('Invalid Alarm Codes', 'Alarm codes should be TD, SC, HP or GY followed by numbers. ' +
        'Nothing else should be entered into the Alarm Codes field. Please remove any other text, codes reverted.');
      this.jontekCodesString = this.postOrderService.jontekCodesStringOld;
      this.postOrderService.decPendingUpdates();
      return;
    }
    if (hasDuplicateJontekCode) {
      this.showErrorPopUp('Duplicate Alarm Code(s)', 'One or more Alarm codes duplicated on this order have been removed');
    }
    // Check for changes after trimming and filtering out empty lines
    if ((jontekCodes.length == 0)) {
      // Clear the pending update we set
      this.postOrderService.decPendingUpdates();
      if (this.postOrderService.jontekCodesStringOld != '') {
        // All the codes that were on the order now need to be added to the old alarm codes
        this.oldAlarmCodesString = `${this.oldAlarmCodesString}\n${this.postOrderService.jontekCodesStringOld}`.trim();
        // All codes cleared, so no need to check whether the codes are in use, but still need to update the order
        this.orderForm.patchValue({
          'jontekCodes': [],
          'oldAlarmCodes': this.oldAlarmCodesString.split('\n'),
        });
        this.submitForm();
        const sendJontekEmailCallback: () => void = () => {
          this.sendNotificationEmail('email: ARC Admin Team', 'has had Alarm Code(s) changed',
            'Please be advised that the following order has had the Alarm Codes changed from:\n' +
            `${previousCurrentCodesCopy}\n to:\n${this.jontekCodesString}.`,
            'to notify them of the Alarm Code changes');
        }
        this.showConfirmationPopup('Alarm Codes Changed',
          'You have changed Alarm Codes, would you like an email to be sent to ARC admin to let them know?',
          sendJontekEmailCallback);
      }
    } else if (this.postOrderService.jontekCodesStringOld != this.jontekCodesString) {
      this.orderService.checkJontekCodes({
        'orderId': this.orderDbId,
        'jontekCodes': jontekCodes,
      }).subscribe((response: MultiRecordResponse<JontekCodeMatches>) => {
        let codesChanged: boolean = false;
        // Clear the pending update we set
        this.postOrderService.decPendingUpdates();
        if (!response.success) {
          this.showErrorPopUp('Error checking Alarm Codes',
            'Unable to check Alarm Codes to make sure they are not in use on other orders, codes reverted. ' +
            `Please reload the page and try again. Error: ${response.error.message}`);
          this.jontekCodesString = this.postOrderService.jontekCodesStringOld;
        } else if (response.data.length > 0) {
          this.showErrorPopUp('Alarm Code(s) already in use',
            'One or more of the Alarm codes you have entered are already in use. Please correct and try again, codes reverted.');
          this.jontekCodesString = this.postOrderService.jontekCodesStringOld;
        } else {
          removedCodes = previousCurrentCodesArray.filter((alarmCode: string) => 
            !jontekCodes.includes(alarmCode)
          );
          if (removedCodes.length > 0) {
            this.oldAlarmCodesString = `${this.oldAlarmCodesString}\n${removedCodes.join('\n')}`.trim();
          } else {
            jontekCodes.forEach((alarmCode: string) => {
              if (!previousCurrentCodesArray.includes(alarmCode)) {
                codesChanged = true;
              }
            });
          }
          this.orderForm.patchValue({
            'jontekCodes': jontekCodes,
            'oldAlarmCodes': this.oldAlarmCodesString.split('\n'),
          });
          this.submitForm();
        }
        // Only send an email if codes removed, or codes added, but only if there were alreaday codes
        if ((removedCodes.length > 0) || (codesChanged && ((previousCurrentCodesArray.length > 0) || hadOldCodes))) {
          const sendJontekEmailCallback: () => void = () => {
            this.sendNotificationEmail('email: ARC Admin Team', 'has had Alarm Code(s) changed',
              'Please be advised that the following order has had the Alarm Codes changed from:\n' +
              `${previousCurrentCodesCopy}\n to:\n${this.jontekCodesString}.`,
              'to notify them of the Alarm Code changes');
          }
          this.showConfirmationPopup('Alarm Codes Changed',
            'You have changed Alarm Codes, would you like an email to be sent to ARC admin to let them know?',
            sendJontekEmailCallback);
        }
      }, (error: any) => {
        this.postOrderService.decPendingUpdates();
        this.showErrorPopUp('Error checking Alarm Codes',
          'Unable to check Alarm Codes to make sure they are not in use on other orders, change not saved. ' +
          `Please reload the page and try again. Error: ${error.message}`);
      });
    } else {
      this.postOrderService.decPendingUpdates();
    }
  }

  updateOldJontekCodes() {
    // Do this to show an update whilst running checks before update which might take some time
    this.postOrderService.incPendingUpdates();
    let hasInvalidAlarmCode: boolean = false;
    let hasDuplicateAlarmCode: boolean = false;
    const alarmCodes: string[] = [];
    const previousValue: string = this.order.oldAlarmCodes.join('\n');
    this.oldAlarmCodesString.split('\n').forEach((alarmCode: string) => {
      alarmCode = alarmCode.replace(/\s/g, '').toLocaleUpperCase();
      // Stop empty lines displaying the error
      if (alarmCode.length == 0) {
        return;
      }
      if (!VALID_ALARM_CODE.test(alarmCode)) {
        hasInvalidAlarmCode = true;
      } else if (alarmCodes.includes(alarmCode)) {
        hasDuplicateAlarmCode = true;
      } else {
        alarmCodes.push(alarmCode);
      }
    });
    //tidy up the text area
    this.oldAlarmCodesString = alarmCodes.join('\n');
    if (hasInvalidAlarmCode) {
      this.showErrorPopUp('Invalid Old Alarm Codes', 'Alarm codes should be TD or SC followed by numbers. ' +
        'Nothing else should be entered into the Old Alarm Codes field. Please remove any other text, codes reverted.');
      this.oldAlarmCodesString = this.postOrderService.jontekCodesStringOld;
      this.postOrderService.decPendingUpdates();
      return;
    }
    if (hasDuplicateAlarmCode) {
      this.showErrorPopUp('Duplicate Alarm Code(s)', 'One or more Old Alarm codes duplicated on this order have been removed');
    }
    this.postOrderService.decPendingUpdates();
    // Check for changes after trimming and filtering out empty lines
    if ((alarmCodes.length == 0)) {
      // Clear the pending update we set
      // All codes cleared, so no need to check whether the codes are in use, but still need to update the order
      this.orderForm.patchValue({
        'oldAlarmCodes': [],
      });
      if (previousValue != '') {
        this.submitForm();
      }
    } else if (this.oldAlarmCodesString != previousValue) {
      this.orderForm.patchValue({
        'oldAlarmCodes': alarmCodes,
      });
      this.submitForm();
    }
  }

  confirmdeleteUser(user: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this addtional user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deleteUser(user);
      },
      reject: () => {}
    });
  }

  //###############################
  // confirmDeletePlan(plan) {
  //   this.confirmationService.confirm({
  //     key: 'general',
  //     message: 'Please check the renewal price',
  //     header: 'Delete Confirmation',
  //     icon: 'pi pi-info-circle',
  //     rejectVisible: true,
  //     accept: () => {
  //       this.deletePlan(plan);
  //     },
  //     reject: () => {}
  //   });
  // }

  confirmdeleteAdditionalEquipment(additioequi) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Please check the renewal price',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deleteAdditionalEquipment(additioequi);
      },
      reject: () => {}
    });
  }

  //################################
  confirmdeleteNote(noteIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this note?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deleteNote(noteIndex);
      },
      reject: () => {}
    });
  }

  confirmServiceUserDelete(serviceUserIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this service User?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.postOrderService.incPendingUpdates();
        const serviceUserToDelete: ServiceUser = this.serviceUsers.splice(serviceUserIndex, 1)[0];
        this.orderService.deleteServiceUser(serviceUserToDelete._id).subscribe((deleteResponse: SingleRecordResponse<ServiceUser>) => {
          if (!deleteResponse.success) {
            this.showErrorPopUp('Failed to Delete Service User',
              `There was an error trying to delete service user ${serviceUserToDelete.srvUserFirstName} ${serviceUserToDelete.srvUserLastName}.` +
              `Error: ${deleteResponse.error.message}`);
            this.serviceUsers.push(serviceUserToDelete);
          } else {
            this.showSuccess();
          }
          this.postOrderService.decPendingUpdates();
        }, (error: any) => {
          this.showErrorPopUp('Failed to Delete Service User',
              `There was an error trying to delete service user ${serviceUserToDelete.srvUserFirstName} ${serviceUserToDelete.srvUserLastName}.` +
              `Error: ${error.message}`);
          this.serviceUsers.push(serviceUserToDelete);
          this.postOrderService.decPendingUpdates();
        });
      },
      reject: () => {}
    });
  }

  resendInviteEmail(serviceUserIndex: number) {
    this.postOrderService.incPendingUpdates();
    const serviceUserToSendTo: ServiceUser = this.serviceUsers[serviceUserIndex];
    const messageToSend: ProposedMessage = {
      'brand': this.postOrderService.order.website.title,
      'messageType': 'Friends and Family Invite',
      'methods': ['email'],
      'tdCode': this.postOrderService.order.alarmUserDetails.tdCode,
      'orderId': this.orderDbId,
      'status': 'Ready to Send',
      'contactDetails': {
        'source': 'Service User',
        'firstName': serviceUserToSendTo.srvUserFirstName,
        'lastName': serviceUserToSendTo.srvUserLastName,
        'email': serviceUserToSendTo.srvUserEmail,
        'mobile': '',
        'address': [],
      },
      'inviteCode': serviceUserToSendTo.invitationCode,
    };
    this.proposedMessageService.addMessage(messageToSend).subscribe((msgResult: SingleRecordResponse<ProposedMessage>) => {
      if (!msgResult.success) {
        this.showErrorPopUp('Failed to Queue email Resend',
          `There was an error trying to queue the request to resend the invitation email, please try again. Error: ${msgResult.error.message}`);
      } else {
        this.showSuccess();
      }
      this.postOrderService.decPendingUpdates();
    }, (error: any) => {
      this.showErrorPopUp('Failed to Queue email Resend',
        `There was an error trying to queue the request to resend the invitation email, please try again. Error: ${error.message}`);
      this.postOrderService.decPendingUpdates();
    });
  }

  isOkToServiceUser(): boolean {
    return this.addServiceUserForm.valid;
  }

  addServiceUser() {
    const serviceUserToAdd: ServiceUser = this.addServiceUserForm.value;
    if (this.serviceUsers.some((serviceUser: ServiceUser) => serviceUser.srvUserEmail.toLocaleLowerCase() == serviceUserToAdd.srvUserEmail.toLocaleLowerCase())) {
      this.showErrorPopUp('Service user already exists', 'You cannot create more than one service user with the same email address.');
      return;
    }
    serviceUserToAdd.srvUserEmail = serviceUserToAdd.srvUserEmail.toLocaleLowerCase();
    this.postOrderService.incPendingUpdates();
    // Form doesn't hold the orderId as it's not set when the form is built
    serviceUserToAdd.orderId = this.orderDbId;
    this.orderService.addServiceUser(serviceUserToAdd, this.serviceIsActive('Friends and Family'))
      .subscribe((addResponse: SingleRecordResponse<ServiceUser>) => {
        if (!addResponse.success || !addResponse.data) {
          if (addResponse.errorCode == 11000) {
            this.showErrorPopUp('Failed to Add Service User',
              'This email address is in use for a service user on another account. An email address can only be used for one service user.');
          } else {
            this.showErrorPopUp('Failed to Add Service User',
              `There was an error trying to add the service user ${this.addServiceUserForm.value.srvUserFirstName} ${this.addServiceUserForm.value.srvUserLastName}.` +
              `Error: ${addResponse.message}`);
          }
        } else {
          this.showSuccess();
          this.serviceUsers.push(addResponse.data);
          this.addServiceUserForm.reset();
          this.addServiceUserForm.patchValue({
            'serviceName': 'Friends and Family',
            'invitationCode': '',
          });
        }
        this.postOrderService.decPendingUpdates();
      }, (error: any) => {
        this.showErrorPopUp('Failed to Add Service User',
            `There was an error trying to add service user ${this.addServiceUserForm.value.srvUserFirstName} ${this.addServiceUserForm.value.srvUserLastName}.` +
            `Error: ${error.message}`);
        this.postOrderService.decPendingUpdates();
      });
  }

  confirmUpdateRenewalType() {
    if (this.orderForm.value.renewalInformation.renewalType == 'directDebit') {
      let acceptClicked: boolean = false;
      this.confirmationService.confirm({
        key: 'general',
        message: 'Do not change the renewal to "Direct Debit" if you have entered the details to the online DD form. ' +
          'This will be updated for you later. Do you still wish to proceed?',
        header: 'Caution!',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        rejectVisible: true,
        accept: () => {
          acceptClicked = true;
          this.changeRenewalType();
        },
        reject: () => {
          if (!acceptClicked) {
            const renewalInfoGroup: FormGroup = this.orderForm.controls['renewalInformation'] as FormGroup;
            renewalInfoGroup.controls['renewalType'].setValue(this.orderFormOld.renewalInformation.renewalType);
          }
        }
      });
    } else if ((this.orderForm.value.renewalInformation.renewalType == 'recurringBilling') &&
        (this.getTagIndex('Opted out of auto-enrolment') > -1)) {
      this.confirmationService.confirm({
        key:'general',
        message: 'You can not set this order to "Recurring Billing" whilst there is an "Opted out auto-enrolment" tag on it',
        header: 'Caution!',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'OK',
        rejectVisible: false,
        acceptVisible: true,
        accept: () => {
          const renewalInfoGroup: FormGroup = this.orderForm.controls['renewalInformation'] as FormGroup;
          renewalInfoGroup.controls['renewalType'].setValue(this.orderFormOld.renewalInformation.renewalType);
        },
      });
    } else {
      this.changeRenewalType();
    }
  }

  enabled() {
    this.enable = !this.enable;
  }

  private createAddForms(): void {
    this.addHardwareForm = this.fb.group({
      'addHardware': this.fb.array([])
    });
    // TODO add for hardware version
    /*
    this.addHardwareSetForm = this.fb.group({
      'addHardwareSet': this.fb.array([])
    });
    */
    this.noteForm = this.fb.group({
      content: [''],
      categories: [this.defaultNoteCategories],
      userId: [''],
      date: [''],
      updatedAt: [moment.tz('Europe/London').toDate()],
    });

    this.vimForm = this.fb.group({
      content: [''],
      userId: [''],
      date: [''],
    });

    this.tagForm = this.fb.group({
      selectedTag: [''],
      doNotExpire: [false],
      expiryDate: [''],
    });

    this.actionForm = this.fb.group({
      outstanding: [''],
    });

    this.discountForm = this.fb.group({
      discount: ['', [Validators.required]],
      discountExpiry: [''],
      doNotExpire: [false],
      reason: ['', [Validators.required]],
      otherReasonText: [''],
    }, { 'validators': reasonRequiredValidator('discountReasonRequired', 'reason', 'otherReasonText') });

    this.addServiceUserForm = this.fb.group({
      'serviceName': ['Friends and Family'],
      'srvUserFirstName': ['', [Validators.required]],
      'srvUserLastName': ['', [Validators.required]],
      'srvUserEmail': ['', [Validators.required, emailValidator]],
      'invitationCode': [''],
    });
  }

  private initOrderForm() {
    this.orderForm = this.fb.group({
      '_id': [''],
      'website': [''],
      'orderId': [''],
      'OrderGUID': [''],
      'goCardlessId': [''],
      'goCardlessSubId': ['', [Validators.pattern(/^SB[A-Z0-9]+$/)]],
      'alarmUserDetails': this.fb.group({
        'tdCode': [''],
        'firstName': ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
        'lastName': ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
        'otherName': [''],
        'preferredName': [''],
        'email': ['', [getExternalEmailValidator(true)]],
        'emailTwo': ['', [getExternalEmailValidator(false)]],
        'telephone': ['', [phoneNumberValidator]],
        'mobile': ['', [mobileNumberValidator]],
        'primaryContact': [false],
        'doNotCall': [false],
        'userAddress': this.fb.group({
          // Allow single character before white space as could be single digit house number
          'addressOne': ['', [Validators.required, Validators.pattern(/^\S.*$/)]],
          'addressTwo': [''],
          'city': ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
          'county': [''],
          'postcode': ['', [Validators.required, Validators.pattern(/^\S{2,}\s+\S{2,}$/)]],
          'dob': [''],
          'unknown': [false],
          'validated': [false],
        }),
        'correspondenceDetails': this.fb.group({
          'firstName': [''],
          'lastName': [''],
          'email': ['', [getExternalEmailValidator(true)]],
          'telephone': ['', [phoneNumberValidator]],
          'mobile': ['', [mobileNumberValidator]],
          // Allow single character before white space as could be single digit house number
          'addressOne': ['', [Validators.required, Validators.pattern(/^\S.*$/)]],
          'addressTwo': [''],
          'city': ['', [Validators.required, Validators.pattern(/^\S{2}.*$/)]],
          'county': [''],
          'postcode': ['', [Validators.required, Validators.pattern(/^\S{2,}\s+\S{2,}$/)]],
          'validated': [false],
          'emailMarketing': [''],
          'emailMarketingUpdated': [],
          'mobileMarketing': [''],
          'mobileMarketingUpdated': [],
        }),
        'users': this.fb.array([]),
        'emailMarketing': [''],
        'emailMarketingUpdated': [],
        'emailTwoMarketing': [''],
        'emailTwoMarketingUpdated': [],
        'mobileMarketing': [''],
        'mobileMarketingUpdated': [],
      }, {'validators': contactDetailsRequiredValidator('Alarm User', 'telephone', 'mobile')}),
      'jontekCodes': [''],
      'oldAlarmCodes': [''],
      'accountContacts': this.fb.array([]),
      'accountDetails': this.fb.group({
        // TODO remove for hardware version
        'plan': ['', [Validators.required, Validators.pattern(/^\S+$/)]],
        'vat': [''],
        'monitoring': [''],
        'planType': ['', [Validators.required]],
        'stripeCustomerId': ['', [Validators.pattern(/^cus_[a-zA-Z\d]+$/)]],
        'stripeSubscriptionId': ['', [Validators.pattern(/^sub_[a-zA-Z\d]+$/)]],
      }),

      'renewalInformation': this.fb.group({
        'renewalDate': [''],
        // TODO remove for hardware version
        'renewalPrice': ['', [Validators.required]],
        // TODO add for hardware version
        // frozenPrice: ['', Validators.required],
        'renewalType': [''],
        'paymentDueDate': [''],
        'freemonths': [''],
      }),
      'renewalDiscounts': this.fb.array([]),
      'vim': this.fb.array([]),
      // TODO start remove for hardware version
      'plans': this.fb.array([]),
      'additionalEquipment': this.fb.array([]),
      'replacementEquipment': this.fb.array([]),
      // TODO end remove for hardware version
      // TODO add for hardware version
      /*
      hardware: this.fb.array([]),
      hardwareSets: this.fb.array([]),
      */
      'keySafes': this.fb.array([]),
      'services': this.fb.array([]),
      'status': this.fb.group({
        'status': [''],
        'setby': [''],
        'backgroundAutoTestCall': [''],
        'date': [],
        'lastBoxActivation': [''],
        'pendantOneLastActivation': [''],
        'pendantTwoLastActivation': [''],
        'lastMainsFail':[''],
        'mainsFailCount':[''],
        'lastSmokeAlarmActivation':[''],
        'lastCODetectorActivation':[''],
        'lastHighUseDate': [''],
        'highUseCount': [''],
        'lastGpsActivation': [''],
        'lastRadioTestMissing': [''],
        'lastEpilepsyAlert': [''],
      }),
      'outstandingActions': this.fb.array([]),
      'notes': this.fb.array([]),
      'tags': this.fb.array([]),
      'contactAttempts': this.fb.array([]),
      'created': [''],
      'referencenumber': ['', [Validators.pattern(/^[A-Z\d]{6,18}$/)]],
      'pslid': '',
      'legalCompany': '',
    });
  }

  getWebSiteProducts(websiteId: string) {
    this.hardwareService
      .getHardwareForSite(websiteId)
      .subscribe((response: MultiRecordResponse<Hardware>) => {
        this.allHardware = response.data;
        this.allHardware.forEach((hardware: Hardware) => {
          let hardwareSelects: SelectItem<Hardware>[] = this.hardwareByCategory[hardware.category];
          if (!hardwareSelects) {
            hardwareSelects = [];
            this.hardwareByCategory[hardware.category] = hardwareSelects;
            const addForCategory: FormGroup = this.fb.group({
              category: [hardware.category],
              itemToAdd: '',
            });
            this.addHardwareForms.push(addForCategory);
          }
          if (hardware.planSymbol) {
            const brandOrTypeSelects: BrandOrTypeSelectItem[] = getBrandsOrTypesForPlanCode(hardware.planSymbol);
            if (brandOrTypeSelects.length > 1) {
              brandOrTypeSelects.forEach((brandOrTypeSelect: BrandOrTypeSelectItem) => {
                if (!brandOrTypeSelect.disabled) {
                  hardwareSelects.push({
                    'label': brandOrTypeSelect.label,
                    'value': hardware,
                  });
                }
              });
              return;
            }
          }
          hardwareSelects.push({
            'label': hardware.title,
            'value': hardware,
          });
        });
      });
    this.hardwareService
      .getHardwareSetsForSite(websiteId)
      .subscribe((response: MultiRecordResponse<HardwareSet>) => {
        this.allHardwareSets = response.data;
        // TODO add for hardware version
        // this.allHardwareSets.forEach((hardwareSet: HardwareSet) => {
        //   let hardwareSetArray: HardwareSet[] = this.hardwareSetsByCategory[hardwareSet.category];
        //   if (!hardwareSetArray) {
        //     hardwareSetArray = [];
        //     this.hardwareSetsByCategory[hardwareSet.category] = hardwareSetArray;

        //     const addForCategory: FormGroup = this.fb.group({
        //       category: [hardwareSet.category],
        //       itemToAdd: '',
        //       includeHardware: true,
        //     });
        //     this.addHardwareSetForms.push(addForCategory);
        //   }
        //   hardwareSetArray.push(hardwareSet);
        // });
      });
    this.productsService
      .getKeySafesForRecordsPage(websiteId)
      .subscribe((response: MultiRecordResponse<PopulatedProduct>) => {
        this.keySafes = response.data;
      });
    this.hardwareService.getServicesForSite(websiteId)
      .subscribe((response: MultiRecordResponse<AccountService>) => {
        this.allServices = response.data;
      })
  }

  get addHardwareForms(): FormArray {
    return this.addHardwareForm.controls['addHardware'] as FormArray;
  }

  // TODO add for hardware version
  /*
  get addHardwareSetForms(): FormArray {
    return this.addHardwareSetForm.controls['addHardwareSet'] as FormArray;
  }
  */

  toLetterCode(name: string): string {
    let cd: string = '';
    switch (name) {
      case 'directDebit':
        cd = 'DD';
        break;
      case 'recurringBilling':
        cd = 'RB';
        break;
      case 'goCardless':
        cd = 'GC';
        break;
    }
    return cd
  }

  sameContactsAsuser(i: number) {
    this.accountContactsForms.at(i).patchValue({
      'accFirstName': this.orderForm.value.alarmUserDetails.firstName,
      'accLastName': this.orderForm.value.alarmUserDetails.lastName,
      'accOtherName': this.orderForm.value.alarmUserDetails.otherName,
      'accEmail': this.orderForm.value.alarmUserDetails.email,
      'accTelephone': this.orderForm.value.alarmUserDetails.telephone,
      'accMobile': this.orderForm.value.alarmUserDetails.mobile,
      'accRelationship': 'Alarm User',
      'emailMarketing': this.orderForm.value.alarmUserDetails.emailMarketing,
      'emailMarketingUpdated': this.orderForm.value.alarmUserDetails.emailMarketingUpdated,
      'mobileMarketing': this.orderForm.value.alarmUserDetails.mobileMarketing,
      'mobileMarketingUpdated': this.orderForm.value.alarmUserDetails.mobileMarketingUpdated,
    });
    this.autosave('');
  }

  getStatuses(): string[] {
    let statuses: string[] = [];
    // TODO start remove for hardware version
    statuses = this.orderForm.value.plans.map(plan => {
      return plan.status
    });
    statuses = [...statuses, ...this.orderForm.value.plans.map(plan => {
      return plan.serialStatus
    })];
    statuses = [...statuses, ...this.orderForm.value.additionalEquipment.map(additionalEquipment => {
      return additionalEquipment.status
    })];
    statuses = [...statuses, ...this.orderForm.value.additionalEquipment.map(additionalEquipment => {
      return additionalEquipment.statusPendant
    })];
    statuses = [...statuses, ...this.orderForm.value.replacementEquipment.map(replacementEquipment => {
      return replacementEquipment.status
    })];
    statuses = [...statuses, ...this.orderForm.value.replacementEquipment.map(replacementEquipment => {
      return replacementEquipment.statusPendant
    })];
    // TODO end remove for hardware version
    // TODO add for hardware version
    /*
    this.order.hardwareSets.forEach((hardwareSet: OrderHardwareSet) => {
      statuses = statuses.concat(hardwareSet.hardwareInSet.map((hardware: OrderHardware) => {
        return hardware.status
      }));
    });
    statuses = statuses.concat(this.order.hardware.map((hardware: OrderHardware) => {
      return hardware.status
    }));
    */
    statuses = statuses.concat(this.postOrderService.order.keySafes.map((keySafe: KeySafe) => {
      return keySafe.status
    }));
    return statuses;
  }

  hasReturningOrLostStatus(): boolean {
    const statuses: string[] = this.getStatuses();
    return statuses.some((value: string) => (value && value.startsWith('Returning'))) || statuses.includes('Lost - Chasing');
  }

  hasMissingStatus(): boolean {
    const statuses: string[] = this.getStatuses();
    return statuses.includes('Missing');
  }

  // TODO add for hardware version
  /*
  statusChangeOnSetHardware(newValue: string, valueToAddEquipment: string, setIndex: number, hardwareIndex: number): void {
    if (!this.hardwareStatusConfig[newValue].active) {
      const hardwareSet: FormArray = this.getHardwareForSet(this.hardwareSetsForms.at(setIndex));
      const controlToMove: AbstractControl = hardwareSet.at(hardwareIndex);
      hardwareSet.removeAt(hardwareIndex);
      this.hardwareForms.push(controlToMove);
    }
    this.onReturningChange(newValue, valueToAddEquipment);
  }
  */

  statusChangeOnService(newStatus: string, serviceName: string, serviceIndex: number) {
    switch (serviceName) {
      case 'Friends and Family':
        const fAndFUpdate: FriendsAndFamilyStatusUpdateRequest = {
          'updates': [],
          'deleteUsers': false,
        };
        this.serviceUsers.forEach((serviceUser: ServiceUser) => {
          fAndFUpdate.updates.push({
            'orderId': this.orderDbId,
            'serviceUserId': serviceUser._id,
            'emailAddress': serviceUser.srvUserEmail,
          });
        });
        if (newStatus == 'Active') {
          this.postOrderService.incPendingUpdates();
          this.orderService.activateFriendsAndFamily(fAndFUpdate).subscribe((response: SimpleResponse) => {
            this.postOrderService.decPendingUpdates();
            if (!response.success) {
              this.showErrorPopUp('Error Queuing Friends And Family Activation',
                'There was an error queuing the request to activate the Friends And Family app. The service status has been reset. ' +
                `Please try again. Error: ${response.error.message}`);
              this.serviceForms.at(serviceIndex).patchValue(this.postOrderService.order.services[serviceIndex].status);
            } else {
              this.autosave('');
            }
          }, (error: any) => {
            this.showErrorPopUp('Error Queuing Friends And Family Activation',
                'There was an error queuing the request to activate the Friends And Family app. The service status has been reset. ' +
                `Error: ${error.message}`);
            this.serviceForms.at(serviceIndex).patchValue(this.postOrderService.order.services[serviceIndex].status);
            this.postOrderService.decPendingUpdates();
          });
        } else {
          this.postOrderService.incPendingUpdates();
          this.orderService.deactivateFriendsAndFamily(fAndFUpdate).subscribe((response: SimpleResponse) => {
            this.postOrderService.decPendingUpdates();
            if (!response.success) {
              this.showErrorPopUp('Error Queuing Friends And Family Deactivation',
                'There was an error queuing the request to deactivate the Friends And Family app. The service status has been reset. ' +
                `Please try again. Error: ${response.error.message}`);
              this.serviceForms.at(serviceIndex).patchValue(this.postOrderService.order.services[serviceIndex].status);
            } else {
              this.autosave('');
            }
          }, (error: any) => {
            this.showErrorPopUp('Error Queuing Friends And Family Deactivation',
                'There was an error queuing the request to deactivate the Friends And Family app. The service status has been reset. ' +
                `Error: ${error.message}`);
            this.serviceForms.at(serviceIndex).patchValue(this.postOrderService.order.services[serviceIndex].status);
            this.postOrderService.decPendingUpdates();
          });
        }
        break;
      default:
        this.autosave('');
        break;
    }
  }

  onReturningChange(newValue: string, valueToAddEquipment: string, serial: string): void {
    const isStatusNeedToAddToEquipment: boolean = (newValue.startsWith('Returning') || (newValue == 'Lost - Chasing'));
    const returningEquipIndex: number = this.getActionIndex('Returning Equipment');
    const missingRoyalMailIndex: number = this.getActionIndex('Missing Equipment Royal Mail');
    const openCaseIndex: number = this.getActionIndex('Open Case');
    const hasReturningOrLostStatus: boolean = this.hasReturningOrLostStatus();
    const hasMissingStatus: boolean = this.hasMissingStatus();
    // Simplified check for returning to entry just starting 'Returning' as check was missing the Returning options that had 'Faulty' at the end
    if (isStatusNeedToAddToEquipment && ['no ncf', 'active'].includes(this.orderForm.controls['status'].value.status)) {
      if (returningEquipIndex == -1) {
        const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Returning Equipment');
        actionFormGroup.patchValue({
          returnedEquip: valueToAddEquipment,
        });
        this.outstandingActionsForms.push(actionFormGroup);
        if (!valueToAddEquipment) {
          this.confirmationService.confirm({
            key: 'general',
            message: 'Equipment name missing, please add details to the Returning Equipment action manually.',
            header: 'Equipment can not be added',
            icon: 'pi pi-info-circle',
            acceptLabel: 'OK',
            rejectLabel: null,
            rejectVisible: false,
          });
        }
      } else if (returningEquipIndex > -1) {
        if (!valueToAddEquipment) {
          this.confirmationService.confirm({
            key: 'general',
            message: 'Equipment name missing, please add details to the Returning Equipment action manually.',
            header: 'Equipment can not be added',
            icon: 'pi pi-info-circle',
            acceptLabel: 'OK',
            rejectLabel: null,
            rejectVisible: false,
          });
        } else {
          const returningEquipAction = this.outstandingActionsForms.at(returningEquipIndex).value;
          if (!returningEquipAction.returnedEquip) {
            this.outstandingActionsForms.at(returningEquipIndex).patchValue({
              returnedEquip: valueToAddEquipment
            })
          } else if (!returningEquipAction.returnedEquipTwo) {
            this.outstandingActionsForms.at(returningEquipIndex).patchValue({
              returnedEquipTwo: valueToAddEquipment
            })
          } else if (!returningEquipAction.returnedEquipThree) {
            this.outstandingActionsForms.at(returningEquipIndex).patchValue({
              returnedEquipThree: valueToAddEquipment
            })
          } else {
            this.confirmationService.confirm({
              key: 'general',
              message: 'No space to add equipment to Returning Equipment action',
              header: 'Equipment can not be added',
              icon: 'pi pi-info-circle',
              acceptLabel: 'OK',
              rejectLabel: null,
              rejectVisible: false,
            });
          }
        }
      }
    }
    /*
      Only add the action if a status has just changed to missing, rather than that one of the statuses that didn't change was missing.
      The code previously would cause the action to get added again after it was dealt with because the status was still missing
    */
    if ((newValue === 'Missing') && hasMissingStatus && missingRoyalMailIndex == -1) {
      const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Missing Equipment Royal Mail');
      this.outstandingActionsForms.push(actionFormGroup);
    }

    if (!hasReturningOrLostStatus && (returningEquipIndex > -1)) {
      this.outstandingActionsForms.removeAt(returningEquipIndex);
    }

    const damagedEquipIndex: number = this.getActionIndex('Damaged Equipment');
    const consideringWriteOffIndex: number = this.getActionIndex('Considering Write Off');
    if (['Damaged - Chargeable'].includes(newValue)) {
      if (damagedEquipIndex == -1) {
        const damagedEquipAction: FormGroup = this.getBaseActionFormGroup('Damaged Equipment');
        this.outstandingActionsForms.push(damagedEquipAction);
      }
      if (this.promptForDamagedEquipmentEmail) {
        this.showConfirmationPopup('Send Damaged Equipment Email', 'Do you wish to send the customer a damaged equipment email?',
          () => {
            this.manualSendFieldInitialisation = getEquipmentPriceAndDescription(serial, this.orderForm.value.website.title,
              this.orderForm.value.accountDetails.plan.toLocaleUpperCase().includes('V'));
            this.manualSendEmailMessageTypes = [{
              'label': 'Damaged Equipment',
              'value': 'Damaged Equipment'
            }];
          }
        );
      }
    } else if (['Damaged - Recommending Write Off', 'Recommending Write Off'].includes(newValue) && (consideringWriteOffIndex == -1)) {
      const writeOffAction: FormGroup = this.getBaseActionFormGroup('Considering Write Off');
      this.outstandingActionsForms.push(writeOffAction);
    } else if (['Lost - Chasing'].includes(newValue) && (openCaseIndex == -1) && (this.orderForm.value.accountDetails.planType == 'lifetime')) {
      const openCaseAction: FormGroup = this.getBaseActionFormGroup('Open Case');
      openCaseAction.patchValue({
        'reason': 'Other',
        'reasonOther': 'Lifetime customer has lost equipment needing chasing.'
      });
      this.outstandingActionsForms.push(openCaseAction);
    }
    /* TODO disabling for now at dispatch's request
    else if (newValue.startsWith('Returned')) {
      this.addNoteContent = `${valueToAddEquipment} with serial ${serial} ${newValue} on ${moment.tz('Europe/London').format('DD/MM/YYYY')}`;
    }*/

    this.autosave('');
  }

  /*******************Start outstandingActions************/
  get outstandingActionsForms(): FormArray {
    return this.orderForm.get('outstandingActions') as FormArray;
  }

  get retentionActionDate(): string {
    const retentionActionIdx: number = this.getActionIndex('Retention');
    if (retentionActionIdx != -1) {
      return this.outstandingActionsForms.value[retentionActionIdx].actionInitiatedDate;
    }
    return '';
  }

  addOutstandingAction() {
    if (this.actionForm.value.outstanding != null) {
      const actionName: string = this.actionForm.value.outstanding;
      const actionFormGroup: FormGroup = this.getBaseActionFormGroup(actionName);
      // TODO remove for hardware version
      if ((actionName == 'Overdue Payment') &&
          /^\d+\.?\d*$/.test(this.orderForm.value.renewalInformation.renewalPrice)) {
        actionFormGroup.patchValue({
          owedPayment: Number(this.orderForm.value.renewalInformation.renewalPrice)
        });
      }
      // TODO add for hardware version
      /*
      if ((actionName == 'Overdue Payment') &&
          /^\d+\.?\d*$/.test(this.order.planCodeAndPrice.renewalPrice)) {
        actionFormGroup.patchValue({
          owedPayment: Number(this.order.planCodeAndPrice.renewalPrice)
        });
      }
      */

      switch (actionName) {
        case 'No DD or RB':
          this.attention = true;
          break;
        case 'Returning Equipment':
          this.attention = true;
          break;
        case 'Renewal Payment Failure':
          this.attention = true;
          break;
        case 'Chase Cancellation':
          this.attention = true;
          break;
        case 'On Hold':
          this.attention = true;
          const nbMonth = this.orderForm.value.renewalInformation.freemonths;
          if (nbMonth) {
            actionFormGroup.patchValue({
              holdUntil: this.futureDateAtMidnight(30 * nbMonth),
            });
          }
          this.showWarnUpdate('Account On Hold', 'Please remember to update the renewal date accordingly');
          this.sendOnHoldEmail();
          break;
      }
      if ('Retention' == actionName) {
        this.showConfirmationPopup('Retention Call', 'Is a retention call required today?', () => {
          const currentMoment: moment.Moment = moment().tz('Europe/London');
          currentMoment.set('second', 0);
          currentMoment.set('millisecond', 0);
          const currentTime: string = currentMoment.format('HH:mm');
          if (currentTime < '11:55') {
            currentMoment.set('hour', 0);
            currentMoment.set('minute', 0);
          } else if (currentTime < '16:55') {
            currentMoment.set('hour', 12);
            currentMoment.set('minute', 0);
          } else  {
            currentMoment.set('hour', 17);
            currentMoment.set('minute', 0);
          }
          actionFormGroup.patchValue({
            renewalDateTaken: currentMoment.toISOString(),
          });
          this.addActionIfNeededAndSaveOrder(actionName, actionFormGroup);
        }, () => {
          this.addActionIfNeededAndSaveOrder(actionName, actionFormGroup);
        }, true);
      } else {
        this.addActionIfNeededAndSaveOrder(actionName, actionFormGroup);
      }
      
    }
  }

  addActionIfNeededAndSaveOrder(actionName: string, actionFormGroup: FormGroup): void {
    if (this.getActionIndex(actionName) == -1) {
      if ((this.getActionIndex('Chase Cancellation') != -1) && (actionName == 'Returning Equipment')) {
        this.showInfoPopUp('Adding actions is not allowed', 'Adding "Returning Equipment" action is not allowed!.\n' +
          'You need to remove "Chase Cancellation" action before then.');
      } else {
        this.outstandingActionsForms.push(actionFormGroup);
        this.autosave('');
      }
      this.outstandingActionsErr = false;
    } else {
      this.outstandingActionsErr = true;
    }
  }

  addOutstandingActionsDB(action: OutstandingAction) {
    let holdUntil: string = null;
    let renewalDateTaken: string = '';
    let actionDate: string = '';
    if (action.outstandingName == 'No DD or RB' || action.outstandingName == 'Due Payment' ||
        action.outstandingName == 'Overdue Payment' || action.outstandingName == 'Returning Equipment' ||
        action.outstandingName == 'Renewal Payment Failure') {
      this.attention = true;
    }
    // Change this to keep time part due to credit-control changes where actions will start
    // being assigned to shifts to work on
    if (action.renewalDateTaken != null) {
      renewalDateTaken = this.fromJsonDateTime(action.renewalDateTaken);
    }
    if (action.date != null) {
      actionDate = this.fromJsonDateTime(action.date);
    }
    if (action.holdUntil != null) {
      holdUntil = this.postOrderService.fromJsonDate(action.holdUntil);
    }

    let owedPayment: number = action.owedPayment;
    // TODO remove for hardware version
    if (!owedPayment && (action.outstandingName == 'Overdue Payment') &&
        /^\d+\.?\d*$/.test(this.orderForm.value.renewalInformation.renewalPrice)) {
      owedPayment = Number(this.orderForm.value.renewalInformation.renewalPrice);
    }
    // TODO add for hardware version
    /*
     if (!owedPayment && (action.outstandingName == 'Overdue Payment') &&
        /^\d+\.?\d*$/.test(this.order.planCodeAndPrice.renewalPrice)) {
      owedPayment = Number(this.order.planCodeAndPrice.renewalPrice);
    }
    */

    const actionFormGroup: FormGroup = this.fb.group({
      _id: action._id,
      outstandingName: [action.outstandingName],
      outstandingColor: [action.outstandingColor],
      status: [action.status],
      renewalDateTaken: [renewalDateTaken],
      holdUntil: [holdUntil],
      date: [actionDate],
      userName: [action.userName],
      actionInitiatedDate: [action.actionInitiatedDate],
      personReturning: [action.personReturning],
      cancellationEmail: [action.cancellationEmail],
      contactNumber: [action.contactNumber, [phoneNumberValidator]],
      responsiblePersonName: [action.responsiblePersonName],
      emailAddress: [action.emailAddress, [getExternalEmailValidator(false)]],
      returnedEquip: [action.returnedEquip],
      returnedEquipTwo: [action.returnedEquipTwo],
      returnedEquipThree: [action.returnedEquipThree],
      owedPayment: owedPayment,
      returnLabelNumbers: action.returnLabelNumbers,
      note: [action.note],
      count: [action.count],
      reason: [action.reason],
      reasonOther: [action.reasonOther],
    });
    this.outstandingActionsForms.push(actionFormGroup);
  }

  getBaseActionFormGroup(actionName: string): FormGroup {
    const actionConfig: ActionConfig = this.actions[actionName];
    if (!actionConfig) {
      return null;
    }
    let renewalDateTaken: string = null;
    if (actionConfig.defaultDueInDays) {
      renewalDateTaken = this.futureDateAtMidnight(actionConfig.defaultDueInDays);
    }
    const actionFormGroup: FormGroup = this.fb.group({
      outstandingName: [actionName],
      outstandingColor: [actionConfig.background],
      status: [''],
      renewalDateTaken: [renewalDateTaken],
      holdUntil: [null],
      date: [null],
      userName: [this.userName],
      actionInitiatedDate: [moment.tz('Europe/London').toDate()],
      personReturning: [''],
      cancellationEmail: [''],
      contactNumber: ['', [phoneNumberValidator]],
      responsiblePersonName: [''],
      emailAddress: ['', [getExternalEmailValidator(false)]],
      returnedEquip: [''],
      returnedEquipTwo: [''],
      returnedEquipThree: [''],
      owedPayment: [''],
      returnLabelNumbers: [''],
      note: [''],
      count: [''],
      reason: [''],
      reasonOther: [''],
    });
    return actionFormGroup;
  }

  removeActionFromSection(actionName: string) {
    const actionIndex: number = this.getActionIndex(actionName);
    if (actionIndex > -1) {
      this.deleteOutstandingAction(actionIndex);
    }
  }

  deleteOutstandingAction(i: number) {
    const hasReturningOrLostStatus: boolean = this.hasReturningOrLostStatus();

    if (['Due Payment', 'Overdue Payment'].includes(this.outstandingActionsForms.value[i].outstandingName) &&
        ['no ncf', 'active'].includes(this.orderForm.controls['status'].value.status) &&
        !!this.orderForm.value.renewalInformation.renewalDate) {
      const renewalMoment: moment.Moment = moment(this.orderForm.value.renewalInformation.renewalDate);
      const currentMoment: moment.Moment = moment();
      if (renewalMoment.isValid()) {
        if (renewalMoment.isBefore(currentMoment)) {
          if (this.outstandingActionsForms.value[i].outstandingName == 'Overdue Payment') {
            this.showErrorPopUp('Delete Overdue Payment Action is not Allowed!',
              'The action not allowed to be deleted unless the renewal date is in the future.'
            );
            return;
          }
        } else {
          // If order is overdue then it'll fall into the if and due payment can be removed, which is correct
          if ((this.outstandingActionsForms.value[i].outstandingName == 'Due Payment')  &&
              this.orderForm.value.renewalInformation.paymentDueDate) {
            if (currentMoment.format('YYYY-MM-DD') >= this.orderForm.value.renewalInformation.paymentDueDate) {
              this.showErrorPopUp(
                'Delete Due Payment Action is not Allowed!',
                'The action not allowed to be deleted unless the payment due date is in the future.'
              );
              return;
            }
          }
        }
      }
    }
    if ((this.outstandingActionsForms.value[i].outstandingName == 'Returning Equipment') && hasReturningOrLostStatus &&
        ['no ncf', 'active'].includes(this.orderForm.controls['status'].value.status)) {
      this.showErrorPopUp('Delete Returning Equipment Action is not Allowed!.',
        'Status should be different to any starting "Returning" and Lost - Chasing)'
      );
      return;
    }
    if (this.outstandingActionsForms.value[i].outstandingName == 'No DD or RB' ||
        this.outstandingActionsForms.value[i].outstandingName == 'Overdue Payment' ||
        this.outstandingActionsForms.value[i].outstandingName == 'Renewal Payment Failure') {
      this.attention = false;
    }
    this.outstandingActionsForms.removeAt(i);
    this.autosave('');
  }
  /*******************End outstandingActions************/

  /*******************Start Tags************/
  get tagsForms(): FormArray {
    return this.orderForm.get('tags') as FormArray;
  }

  isOkToAddTag(): boolean {
    if (!this.tagForm.value.selectedTag.tagName)  {
      return false;
    }
    if (this.tagForm.value.selectedTag.allowExpiry) {
      return !!this.tagForm.value.doNotExpire || !!this.tagForm.value.expiryDate;
    }
    return true;
  }

  addtag() {
    const tagNames: string[] = this.tagsForms.value.map((line: OrderFormTag) => {
      return line.content;
    });
    if (this.tagForm.value.selectedTag.tagName == 'Opted out of auto-enrolment') {
      if (this.orderForm.value.renewalInformation.renewalType == 'recurringBilling') {
        this.showPopUp('Caution!', 'You can not add an "Opted out of auto-enrolment" tag to this order whilst it is set to "Recurring Billing".',
          'pi pi-exclamation-triangle');
        return;
      }
      if (tagNames.includes('Automatically Renew My Plan Selected')) {
        this.showPopUp('Caution!', 'You can not add an "Opted out of auto-enrolment" tag to this order whilst it has an "Automatically Renew My Plan Selected" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
      if (tagNames.includes('Eligible for Auto-enrolment')) {
        this.showPopUp('Caution!', 'You can not add an "Opted out of auto-enrolment" tag to this order whilst it has an "Eligible for auto-enrolment" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
      if (tagNames.includes('Has been Auto-Enrolled')) {
        this.showPopUp('Caution!', 'You can not add an "Opted out of auto-enrolment" tag to this order whilst it has an "Has been Auto-Enrolled" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
    } else if (this.tagForm.value.selectedTag.tagName == 'Block Alarm Correspondence') {
      this.showPopUp('Caution!', 'Adding the "Block Alarm Correspondence" tag will stop the customer receiving low battery and mains failure notifications ' +
          'and should only be used if they are away from home and not using their alarm.', 'pi pi-exclamation-triangle');
    }
    if (tagNames.includes('Opted out of auto-enrolment')) {
      if (this.tagForm.value.selectedTag.tagName == 'Eligible for Auto-enrolment') {
        this.showPopUp('Caution!', 'You can not add an "Eligible for Auto-enrolment" tag to this order whilst it has an "Opted out of auto-enrolment" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
      if (this.tagForm.value.selectedTag.tagName == 'Automatically Renew My Plan Selected') {
        this.showPopUp('Caution!', 'You can not add an "Automatically Renew My Plan Selected" tag to this order whilst it has an "Opted out of auto-enrolment" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
      if (this.tagForm.value.selectedTag.tagName == 'Has been Auto-Enrolled') {
        this.showPopUp('Caution!', 'You can not add an "Has been Auto-Enrolled" tag to this order whilst it has an "Opted out of auto-enrolment" tag.',
          'pi pi-exclamation-triangle');
        return;
      }
    }

    const tagType = this.fb.group({
      tagID: this.tagForm.value.selectedTag._id,
      content: this.tagForm.value.selectedTag.tagName,
      color: this.tagForm.value.selectedTag.color,
      userName: this.userName,
      expiryDate: (this.tagForm.value.selectedTag.allowExpiry && !this.tagForm.value.doNotExpire) ? this.tagForm.value.expiryDate : null,
      date: moment.tz('Europe/London').toDate()
    });

    if (tagNames.includes(this.tagForm.value.selectedTag.tagName)) {
      this.tagsErr = true;
    } else {
      this.tagsForms.push(tagType);
      this.addOrRemoveMissingDatesActionIfNecessary();
      this.autosave('');
      this.tagsErr = false;
      // TODO add for hardware version
      /*
      if (this.tagForm.value.selectedTag.tagName == 'Price Frozen') {
        this.updateFrozenPriceRequirement();
        this.showInfoPopUp('Price Frozen', 'Please make sure you check/set the price that the order is Frozen at.');
      }
      */
    }
  }

  addTagDB(tag: OrderTag) {
    try {
      const tagId: Tag = tag.tagID;
      const tagType: FormGroup = this.fb.group({
        _id: [tag._id],
        tagID: [tagId._id],
        content: [tagId.tagName],
        color: [tagId.color],
        userName: [tag.userName],
        expiryDate: [tag.expiryDate],
        date: [tag.date]
      });
      if (tagId._id) {
        this.tagsForms.push(tagType);
        // TODO add for hardware version
        /*
        if (tag.tagID.tagName == 'Price Frozen') {
          this.updateFrozenPriceRequirement();
        }
        */
      }
    } catch (error) {
      // Note that 'error' could be almost anything: http error, parsing error, type error in getPosts(), handling error in above code
      console.error('date problem: ', error)
    }

  }

  deleteTag(tagIndex: number): void {
    // TODO add for hardware version
    /*
    const tagForm = this.tagsForms.at(tagIndex);
    const priceFrozenTag: boolean = (tagForm.value.content == 'Price Frozen');
    */
    this.tagsForms.removeAt(tagIndex);
    this.autosave('');
    // TODO add for hardware version
    /*
    if (priceFrozenTag) {
      this.updateFrozenPriceRequirement();
    }
    */
  }
  /*******************End Tags************/

  addReplacementEquipmentNeedsAction(): void {
    const existingActionIndex: number = this.getActionIndex('Replacement Equip Needs Testing');

    if (existingActionIndex === -1) {
      const equipNeedsTestingAction: FormGroup = this.getBaseActionFormGroup('Replacement Equip Needs Testing');
      this.outstandingActionsForms.push(equipNeedsTestingAction);
    }
  }

  checkIfEquipmentTestEmailShouldBeSent(): void {
    if (['no ncf', 'cancelled'].includes(this.orderForm.controls['status'].value.status)) {
      return;
    }
    this.addProductSidebar = false;
    this.confirmationService.confirm({
      key: 'general',
      message: 'Would you like to send the new equipment test email?',
      header: 'Send new equipment test email',
      rejectLabel: 'No',
      acceptLabel: 'Yes',
      accept: () => {
        this.manualSendEmailMessageTypes = [{
          'label': 'New Equipment Test (User)',
          'value': 'New Equipment Test (User)'
        }, {
          'label': 'New Equipment Test (Contact)',
          'value': 'New Equipment Test (Contact)'
        }];
      },
      reject: () => {
      }
    });
  }

  /*******************Start contacts************/
  get accountContactsForms(): FormArray {
    return this.orderForm.get('accountContacts') as FormArray;
  }

  get contactAttemptForms(): FormArray {
    return this.orderForm.get('contactAttempts') as FormArray;
  }

  addAccountContacts() {
    const accountContactForm: FormGroup = this.fb.group({
      'accFirstName': '',
      'accLastName': '',
      'accOtherName': '',
      'accEmail': ['',[getExternalEmailValidator(true)]],
      'accTelephone': ['',[phoneNumberValidator]],
      'accMobile': ['',[mobileNumberValidator]],
      'accRelationship': '',
      'primaryContact': false,
      'doNotCall': false,
      'emailMarketing': '',
      'emailMarketingUpdated': null,
      'mobileMarketing': '',
      'mobileMarketingUpdated': null,
    });
    this.accountContactsForms.push(accountContactForm);
  }

  addAccountContactsDB(contact: AccountContact) {
    const accountContactForm: FormGroup = this.fb.group({
      '_id': contact._id,
      'accFirstName': [contact.accFirstName],
      'accLastName': [contact.accLastName],
      'accOtherName': [contact.accOtherName],
      'accEmail': [contact.accEmail,[getExternalEmailValidator(true)]],
      'accTelephone': [contact.accTelephone,[phoneNumberValidator]],
      'accMobile': [contact.accMobile,[mobileNumberValidator]],
      'accRelationship': [contact.accRelationship],
      'primaryContact': [contact.primaryContact],
      'doNotCall': [contact.doNotCall],
      'emailMarketing': [contact.emailMarketing],
      'emailMarketingUpdated': [contact.emailMarketingUpdated],
      'mobileMarketing': [contact.mobileMarketing],
      'mobileMarketingUpdated': [contact.mobileMarketingUpdated],
    });
    this.accountContactsForms.push(accountContactForm);
  }

  addContactAttemptDB(contactAttempt: ContactAttempt) {
    const contactAttemptForm: FormGroup = this.fb.group({
      '_id': contactAttempt._id,
      'phoneNumber': contactAttempt.phoneNumber,
      'successfulPayments': contactAttempt.successfulPayments,
      'thumbsUp': contactAttempt.thumbsUp,
      'thumbsDown': contactAttempt.thumbsDown,
    });
    this.contactAttemptForms.push(contactAttemptForm);
  }

  deleteAccountContact(i: number) {
    this.accountContactsForms.removeAt(i);
    this.autosave('');
  }

  /*******************End contacts************/

  /*******************Satrt add users************/
  get usersForms(): FormArray {
    return this.orderForm.get('alarmUserDetails').get('users') as FormArray;
  }

  addusers() {
    const userType: FormGroup = this.fb.group({
      'firstName': '',
      'lastName': '',
      'otherName': '',
      'preferredName': '',
      'mobile': ['',[mobileNumberValidator]],
      'email': ['',[getExternalEmailValidator(false)]],
      'relationship': '',
      'primaryContact': false,
      'doNotCall': false,
      'emailMarketing': '',
      'emailMarketingUpdated': null,
      'mobileMarketing': '',
      'mobileMarketingUpdated': null,
    });
    this.usersForms.push(userType);
  }

  adduserDB(user: AdditionalUser) {
    const userType: FormGroup = this.fb.group({
      '_id': [user._id],
      'firstName': [user.firstName],
      'lastName': [user.lastName],
      'otherName': [user.otherName],
      'preferredName': [user.preferredName],
      'mobile': [user.mobile,[mobileNumberValidator]],
      'email': [user.email,[getExternalEmailValidator(false)]],
      'relationship': [user.relationship],
      'primaryContact': [user.primaryContact],
      'doNotCall': [user.doNotCall],
      'emailMarketing': [user.emailMarketing],
      'emailMarketingUpdated': [user.emailMarketingUpdated],
      'mobileMarketing': [user.mobileMarketing],
      'mobileMarketingUpdated': [user.emailMarketingUpdated],
    });
    this.usersForms.push(userType);
  }

  deleteUser(i: number) {
    this.usersForms.removeAt(i);
    this.autosave('');
    this.contactDetailChanges.push(
      `Additional user ${i+1} removed`
    );
  }

  /*******************End add users************/

  // TODO start remove for hardware version
  /*******************Start add plan************/
  get plansForms() {
    return this.orderForm.get('plans') as FormArray
  }

  addPlanDB(plan: Plan) {
    const planForm: FormGroup = this.fb.group({
      _id: [plan._id],
      productId: [plan.productId],
      equipment: [plan.equipment],
      serial: [plan.serial],
      baseUnitYearMade: [plan.baseUnitYearMade, [Validators.required, Validators.min(1990), Validators.max(this.currentYear)]],
      serialPendant: [plan.serialPendant],
      pendantYearMade: [plan.pendantYearMade, [Validators.required, Validators.min(1990), Validators.max(this.currentYear)]],
      typePendant: [plan.typePendant],
      serialStatus: [plan.serialStatus],
      unit: [plan.unit],
      symbol: [plan.symbol],
      status: [plan.status],
      added: [plan.added? plan.added: ''],
    });
    this.plansForms.push(planForm);
  }

  deletePlan(i: number) {
    this.plansForms.removeAt(i)
    this.autosave('');
  }
  /*******************End add plan************/

  get additionalEquipmentForms() {
    return this.orderForm.get('additionalEquipment') as FormArray
  }

  addAdditionalEquipmentFromHardware(hardwareSelect: SelectItem<Hardware>) {
    const additionalEquipmentFormGroup: FormGroup = this.fb.group({
      equipment: [hardwareSelect.label],
      serial: [''],
      equipmentYearMade: [undefined, [Validators.required, Validators.min(1990), Validators.max(this.currentYear)]],
      symbol: hardwareSelect.value.planSymbol,
      status: ['Live'],
      added: [moment.tz('Europe/London').toDate()],
      freeOfCharge: false,
    });
    this.additionalEquipmentForms.push(additionalEquipmentFormGroup);
    this.equipmentAdded = true;
    this.addReplacementEquipmentNeedsAction();
    if (hardwareSelect.label.toLocaleLowerCase().includes('smartlife')) {
      this.addTagByTagName('Ethernet Cable Sent');
    }
    this.autosave('');
  }

  addAdditionalEquipmentDB(fullEquipment: AdditionalOrReplacementEquipment) {
    const additionalEquipmentType = this.fb.group({
      _id: [fullEquipment._id],
      productId: [fullEquipment.productId],
      equipment: [fullEquipment.equipment],
      serial: [fullEquipment.serial],
      equipmentYearMade: [fullEquipment.equipmentYearMade, [Validators.required, Validators.min(1990), Validators.max(this.currentYear)]],
      symbol: [fullEquipment.symbol],
      unit: [fullEquipment.unit],
      typePendant: [fullEquipment.typePendant],
      serialPendant: [fullEquipment.serialPendant],
      statusPendant: [fullEquipment.statusPendant],
      status: [fullEquipment.status],
      added: [fullEquipment.added? fullEquipment.added: ''],
      freeOfCharge: [fullEquipment.freeOfCharge],
    });
    this.additionalEquipmentForms.push(additionalEquipmentType);
  }

  deleteAdditionalEquipment(i: number) {
    
    this.autosave('');
  }

  
  deleteEquipment(replacement: boolean, i: number) {
    if (replacement) {
      this.replacementEquipmentForms.removeAt(i);
    } else {
      this.additionalEquipmentForms.removeAt(i);
    }
    this.autosave('');
  }

  get replacementEquipmentForms() {
    return this.orderForm.get('replacementEquipment') as FormArray
  }

  addreplacementEquipmentDB(fullEquipment: AdditionalOrReplacementEquipment) {
    const replacementEquipmentType = this.fb.group({
      _id: [fullEquipment._id],
      equipment: [fullEquipment.equipment],
      serial: [fullEquipment.serial],
      equipmentYearMade: [fullEquipment.equipmentYearMade, [Validators.required, Validators.min(1990), Validators.max(this.currentYear)]],
      symbol: [fullEquipment.symbol],
      unit: [fullEquipment.unit],
      typePendant: [fullEquipment.typePendant],
      serialPendant: [fullEquipment.serialPendant],
      statusPendant: [fullEquipment.statusPendant],
      status: [fullEquipment.status],
      added: [fullEquipment.added? fullEquipment.added: ''],
      freeOfCharge: [fullEquipment.freeOfCharge],
    });
    this.replacementEquipmentForms.push(replacementEquipmentType);
  }

  // TODO end remove for hardware version

  /*******************Start add VIM************/
  get vimForms(): FormArray {
    return this.orderForm.get('vim') as FormArray;
  }

  addVim() {
    // stop the popup displaying when message added, if it hadn't already displayed
    this.vimPopupClosed = true;
    const note: string = this.vimForm.value.content;
    if (note != null && note.replace(/ /g, '') != '' && note != ' ' && note != undefined) {
      const vimType: FormGroup = this.fb.group({
        content: this.vimForm.value.content,
        userName: this.userName,
        date: moment.tz('Europe/London').toDate()
      });
      this.vimForm.reset();
      this.vimForms.push(vimType);
      this.autosave('');
    }
  }

  addvimDB(vim: Vim) {
    const vimType: FormGroup = this.fb.group({
      _id: [vim._id],
      content: [vim.content],
      userName: [vim.userName],
      date: [vim.date]
    });
    this.vimForms.push(vimType);
  }

  deletevim(vimIndex: number) {
    this.vimForms.removeAt(vimIndex)
    this.autosave('');
  }

  /*******************End add VIM************/
  get notesForms(): FormArray {
    return this.orderForm.get('notes') as FormArray;
  }

  addNoteIfNeeded(event: KeyboardEvent): void {
    if (wasCtrlPlusEnterPressed(event)) {
      event.preventDefault();
      this.addNote();
    }
  }

  addNote() {
    const note: string = this.noteForm.value.content;
    const categories: string[] = this.noteForm.value.categories;
    if (note != null && note.replace(/ /g, '') != '' && note != ' ' && note != undefined) {
      if (categories && categories.length >= 1) {
        const notesType: FormGroup = this.fb.group({
          content: note,
          previousContent: null,
          updatedAt: null,
          userName: this.userName,
          date: moment.tz('Europe/London').toDate(),
          categories:[categories],
        });
        this.noteForm.reset();
        this.noteForm.patchValue({
          'categories': this.defaultNoteCategories,
        });
        this.noteTemplateDropdown.clear(undefined);
        this.notesForms.push(notesType);
        this.addComplaintTagIfNeeded(categories);
        this.autosave('');
      } else {
        const selectNoteCategoryInput: () => void = () => {
          // Use set timeout to delay enough for other UI events which otherwise trigger the hide
          setTimeout(() => {
            this.noteCategoriesSelect.containerViewChild.nativeElement.click();
          });
        }
        this.showConfirmationPopup('All notes must have a category', 'Please select at least one category',
          selectNoteCategoryInput, selectNoteCategoryInput, false);
      }
    }
  }

  focusOnNoteCategories(): void {
    if (this.inNoteCategoryOnHideEvent) {
      return;
    }
    this.inNoteCategoryOnHideEvent = true;
    this.noteCategoriesSelect.containerViewChild.nativeElement.click();
    this.noteCategoriesSelect.hide();
    this.inNoteCategoryOnHideEvent = false;
  }

  addTagByTagName(tagName: string): void {
    const tagSelectItem: SelectItem<Tag> = this.tags.find((currentTag: SelectItem<Tag>) =>
      currentTag.label == tagName
    );
    if (tagSelectItem) {
      if (this.getTagIndex(tagName) == -1) {
        const tagType = this.fb.group({
          tagID: tagSelectItem.value._id,
          content: tagSelectItem.label,
          color: tagSelectItem.value.color,
          userName: this.userName,
          expiryDate: null,
          date: moment.tz('Europe/London').toDate()
        });
        this.tagsForms.push(tagType);
      }
    }
  }

  addComplaintTagIfNeeded(categories: string[]) {
    if (categories.includes('Complaints')) {
      this.addTagByTagName('Previous Complaint');
    }
  }

  addNotesDB(note: OrderNote) {
    const notesType: FormGroup = this.fb.group({
      _id: [note._id],
      content: [note.content],
      previousContent: [note.previousContent],
      userName: [note.userName],
      date: [note.date],
      categories: [note.categories],
      updatedAt: [note.updatedAt],
    });
    this.notesForms.push(notesType);
  }

  deleteNote(noteIndex: number) {
    this.notesForms.removeAt(noteIndex)
    this.autosave('');
  }

  /*******************Start add hardware************/
  // TODO add for hardware version
  /*
  get hardwareForms(): FormArray {
    return this.orderForm.get('hardware') as FormArray;
  }

  addHardwareDB(hardware: OrderHardware) {
    const hardwareFormGroup: FormGroup = this.fb.group({
      _id: [hardware._id],
      hardwareId: [hardware.hardwareId._id],
      hardwareName: [hardware.hardwareId.title],
      category: [hardware.hardwareId.category],
      serial: [hardware.serial],
      status: [hardware.status],
      added: [hardware.added],
    }, {'validators': hardwareSetStatusValidator});
    this.hardwareForms.push(hardwareFormGroup);
  }

  deleteHardware(hardwareIndex: number) {
    this.hardwareForms.removeAt(hardwareIndex);
    this.autosave('');
  }

  addHardware(hardware: Hardware) {
    const hardwareFormGroup: FormGroup = this.fb.group({
      hardwareId: [hardware._id],
      hardwareName: [hardware.title],
      category: [hardware.category],
      serial: [''],
      status: ['Pending'],
      added: [moment.tz('Europe/London').toDate()],
    }, {'validators': hardwareSetStatusValidator});
    this.hardwareForms.push(hardwareFormGroup);
    this.addReplacementEquipmentNeedsAction();
    this.autosave('');
  }
  */
  /*******************End add hardware************/

  /*******************Start add hardware set************/
  // TODO add for hardware version
  /*
  get hardwareSetsForms(): FormArray {
    return this.orderForm.get('hardwareSets') as FormArray;
  }

  addHardwareSetDB(hardwareSet: OrderHardwareSet) {
    const hardwareSetFormGroup: FormGroup = this.fb.group({
      _id: [hardwareSet._id],
      hardwareSetId: [hardwareSet.hardwareSetId._id],
      hardwareSetName: [hardwareSet.hardwareSetId.title],
      hardwareInSet: this.fb.array([]),
      // Set to disabled to stop it sending to the server
      requiredHardware: [{value: hardwareSet.hardwareSetId.containedHardware, disabled: true}],
    }, {'validators': hardwareSetValidator});
    const setHardware: FormArray = (hardwareSetFormGroup.controls.hardwareInSet as FormArray);
    hardwareSet.hardwareInSet
      .forEach((hardware: OrderHardware) => {
        const hardwareFormGroup: FormGroup = this.fb.group({
          _id: [hardware._id],
          hardwareId: [hardware.hardwareId._id],
          hardwareName: [hardware.hardwareId.title],
          category: [hardware.hardwareId.category],
          serial: [hardware.serial],
          status: [hardware.status],
          added: [hardware.added],
        }, {'validators': hardwareSetStatusValidator});
      setHardware.push(hardwareFormGroup);
    });
    this.hardwareSetsForms.push(hardwareSetFormGroup);
  }

  addHardwareSet(hardwareSet: HardwareSet, includeHardware: boolean) {
    const hardwareSetFormGroup: FormGroup = this.fb.group({
      hardwareSetId: [hardwareSet._id],
      hardwareSetName: [hardwareSet.title],
      hardwareInSet: this.fb.array([]),
      // Set to disabled to stop it sending to the server
      requiredHardware: [{value: hardwareSet.containedHardware.map((hardwareId: HardwareId) => hardwareId._id), disabled: true}],
    }, {'validators': hardwareSetValidator});
    if (includeHardware) {
      const setHardware: FormArray = (hardwareSetFormGroup.controls.hardwareInSet as FormArray);
      hardwareSet.containedHardware.forEach((hardwareId: HardwareId) => {
        const hardwareFormGroup: FormGroup = this.fb.group({
          hardwareId: [hardwareId._id],
          hardwareName: [hardwareId.title],
          category: [hardwareId.category],
          serial: [''],
          status: ['Pending'],
          added: [moment.tz('Europe/London').toDate()],
        }, {'validators': hardwareSetStatusValidator});
        setHardware.push(hardwareFormGroup);
      });
      this.addReplacementEquipmentNeedsAction();
    }
    this.hardwareSetsForms.push(hardwareSetFormGroup);
    this.autosave('');
  }

  deleteHardwareSet(setIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Do you wish to delete all contained hardware too? Answering No will move the hardware out of the set',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.hardwareSetsForms.removeAt(setIndex);
        this.autosave('');
      },
      reject: () => {
        this.getHardwareForSet(this.hardwareSetsForms.at(setIndex)).controls.forEach((hardwareFormGroup: FormGroup) => {
          this.hardwareForms.push(hardwareFormGroup);
        });
        this.hardwareSetsForms.removeAt(setIndex);
        this.autosave('');
      }
    });
  }

  deleteHardwareFromSet(setIndex: number, hardwareIndex: number) {
    this.getHardwareForSet(this.hardwareSetsForms.at(setIndex)).removeAt(hardwareIndex);
    this.autosave('');
  }

  getHardwareForSet(hardwareSetFormGroup: AbstractControl): FormArray {
    return ((hardwareSetFormGroup as FormGroup).controls.hardwareInSet as FormArray);
  }
  */
  /*******************End add hardware set************/

  /*******************Hardware dragging start************/
  // TODO add for hardware version
  /*
  hardwareAllowedPredicate(itemDragged: CdkDrag<{'hardware': AbstractControl, 'index': number}>,
      listToDropOn: CdkDropList<FormArray>): boolean {
    const draggedHardware = itemDragged.data.hardware.value;
    if (draggedHardware.category == 'Monitoring Only') {
      return false;
    }
    if (!this.hardwareStatusConfig[draggedHardware.status].validInSet) {
      return false;
    }

    let allowedAccessories: AccessoryTypesByPlan = {};
    const requiredHardwareIds: string[] = [];
    (listToDropOn.data.parent.get('containedHardware').value as ContainedHardwareId[])
      .forEach((reqHardware: ContainedHardwareId) => {
        requiredHardwareIds.push(reqHardware._id);
        if ('Base Unit' == reqHardware.category) {
          reqHardware.linkableAccessories.forEach((accessory: LinkableAccessory) => {
            allowedAccessories[accessory.planSymbol] = accessory.accessoryTypes
          });
        }
      });

    if (!requiredHardwareIds.includes(draggedHardware.hardwareId)) {
      return false;
    }
    if (Object.keys(allowedAccessories).length > 0) {
      // We have some restrictions to check
      const allowedBrandsOrTypes: string[] = allowedAccessories[draggedHardware.planSymbol];
      if (allowedBrandsOrTypes && (allowedBrandsOrTypes.length > 0)) {
        return allowedBrandsOrTypes.includes(draggedHardware.brandOrType);
      }
    }
    return true;
  }

  dropHardware(event: CdkDragDrop<FormArray>): void {
    // Disallowing sorting, so no need to handle dropping back in original container
    if (event.previousContainer !== event.container) {
      const controlToMove: AbstractControl = event.previousContainer.data.at(event.item.data.index);
      event.previousContainer.data.removeAt(event.item.data.index);
      event.container.data.insert(event.currentIndex, controlToMove);
      this.autosave('');
    }
  }
  */

  /*******************Hardware dragging end************/

  /*******************Start services************/
  get serviceForms(): FormArray {
    return this.orderForm.get('services') as FormArray;
  }

  addServiceDB(service: Service) {
    const serviceFormGroup: FormGroup = this.fb.group({
      _id: [service._id],
      serviceId: [service.serviceId._id],
      serviceName: [service.serviceId.title],
      status: [service.status],
      added: [service.added? service.added: ''],
    });
    this.serviceForms.push(serviceFormGroup);
  }

  deleteService(serviceIndex: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Are you sure you want to delete this service?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        const serviceControl: AbstractControl = this.serviceForms.at(serviceIndex);
        // don't need any special processing for inactive services
        if (serviceControl.value.status == 'Inactive') {
          this.serviceForms.removeAt(serviceIndex);
          this.autosave('');
        } else {
          switch (serviceControl.value.serviceName) {
            case 'Friends and Family':
              this.postOrderService.incPendingUpdates();
              const fAndFUpdate: FriendsAndFamilyStatusUpdateRequest = {
                'updates': [],
                'deleteUsers': true,
              };
              this.serviceUsers.forEach((serviceUser: ServiceUser) => {
                fAndFUpdate.updates.push({
                  'orderId': this.orderDbId,
                  'serviceUserId': serviceUser._id,
                  'emailAddress': serviceUser.srvUserEmail,
                });
              });
              this.orderService.deactivateFriendsAndFamily(fAndFUpdate).subscribe((response: SimpleResponse) => {
                this.postOrderService.decPendingUpdates();
                if (!response.success) {
                  this.showErrorPopUp('Error Queuing Friends And Family Deactivation',
                    'There was an error queuing the request to deactivate the Friends And Family app, so unable to delete service. ' +
                    `Please try again. Error: ${response.error.message}`);
                } else {
                  this.serviceForms.removeAt(serviceIndex);
                  this.autosave('');
                }
              }, (error: any) => {
                this.showErrorPopUp('Error Queuing Friends And Family Deactivation',
                    'There was an error queuing the request to deactivate the Friends And Family app, so unable to delete service. ' +
                    `Error: ${error.message}`);
                this.postOrderService.decPendingUpdates();
              });
            break;
            default:
              this.serviceForms.removeAt(serviceIndex);
              this.autosave('');
              break;
          }
        }
      },
      reject: () => {}
    });
  }

  orderHasService(serviceName: string): boolean {
    let hasService: boolean = false;
    this.serviceForms.controls.forEach((serviceForm: AbstractControl) => {
      if (serviceForm.value.serviceName == serviceName) {
        hasService = true;
      }
    });
    return hasService;
  }

  serviceIsActive(serviceName: string): boolean {
    let serviceIsActive: boolean = false;
    this.serviceForms.controls.forEach((serviceForm: AbstractControl) => {
      if (serviceForm.value.serviceName == serviceName) {
        serviceIsActive = (serviceForm.value.status == 'Active');
      }
    });
    return serviceIsActive;
  }

  addService() {
    if (!this.selectedService) {
      return;
    }
    if (this.orderHasService(this.selectedService.title)) {
      this.showErrorPopUp('Order already has service', 'You cannot add the same service to an order more than once.');
      return;
    }
    const serviceFormGroup: FormGroup = this.fb.group({
      serviceId: [this.selectedService._id],
      serviceName: [this.selectedService.title],
      status: ['Active'],
      added: moment.tz('Europe/London').toDate()
    });
    switch (this.selectedService.title) {
      case 'Friends and Family':
        /* There can't be existing service users on the account if the service has just been added
        this.postOrderService.incPendingUpdates();
        const fAndFUpdate: FriendsAndFamilyStatusUpdateRequest = {
          'updates': [],
          'deleteUsers': false,
        };
        this.serviceUsers.forEach((serviceUser: ServiceUser) => {
          fAndFUpdate.updates.push({
            'orderId': this.orderDbId,
            'serviceUserId': serviceUser._id,
            'emailAddress': serviceUser.srvUserEmail,
          });
        });
        this.orderService.activateFriendsAndFamily(fAndFUpdate).subscribe((response: SimpleResponse) => {
          this.postOrderService.decPendingUpdates();
          if (!response.success) {
            this.showErrorPopUp('Error Queuing Friends And Family Activation',
              'There was an error queuing the request to activate the Friends And Family app, so unable to add the service. ' +
              `Please try again. Error: ${response.error.message}`);
          } else {
            this.serviceForms.push(serviceFormGroup);
            this.autosave('');
          }
        }, (error: any) => {
          this.showErrorPopUp('Error Queuing Friends And Family Activation',
              'There was an error queuing the request to activate the Friends And Family app, so unable to add the service. ' +
              `Error: ${error.message}`);
          this.postOrderService.decPendingUpdates();
        });
      break;
      */
      default:
        this.serviceForms.push(serviceFormGroup);
        this.autosave('');
        break;
    }
  }
  /*******************End Services************/

  /*******************Start Key Safes************/
  get keySafesForms(): FormArray {
    return this.orderForm.get('keySafes') as FormArray;
  }

  addkeySafesDB(keySafe: KeySafe) {
    const keySafesType: FormGroup = this.fb.group({
      _id: [keySafe._id],
      productId: [keySafe.productId],
      equipment: [keySafe.equipment],
      status: [keySafe.status],
      added: [keySafe.added? keySafe.added: ''],
    });
    this.keySafesForms.push(keySafesType);
  }

  deletekeySafes(keySafeIndex: number) {
    this.keySafesForms.removeAt(keySafeIndex)
    this.autosave('');
  }

  addKeySafe() {
    if (!this.selectedKeySafe) {
      return;
    }
    const keySafesType: FormGroup = this.fb.group({
      productId: [this.selectedKeySafe._id],
      equipment: [this.selectedKeySafe.crmTitle],
      status: ['Bought'],
      added: moment.tz('Europe/London').toDate()
    });
    this.keySafesForms.push(keySafesType);
    this.autosave('');
  }
  /*******************End Key Safes************/

  /*******************Start Discounts************/
  get discountForms(): FormArray {
    return this.orderForm.get('renewalDiscounts') as FormArray;
  }

  addRenewalDiscountDB(renewalDiscount: RenewalDiscount) {
    const discountFormGroup: FormGroup = this.fb.group({
      '_id': [renewalDiscount._id],
      'discount': [renewalDiscount.discount],
      'discountExpiry': [renewalDiscount.discountExpiry],
      'reason': [renewalDiscount.reason],
      'otherReasonText': [renewalDiscount.otherReasonText],
      'addedBy': [renewalDiscount.addedBy],
    });
    this.discountForms.push(discountFormGroup);
  }

  deleteRenewalDiscount(discountIndex: number) {
    this.discountForms.removeAt(discountIndex);
    this.autosave('');
  }

  isOkToAddDiscount(): boolean {
    if (!this.discountForm.valid) {
      return false;
    }
    return !!this.discountForm.value.doNotExpire || !!this.discountForm.value.discountExpiry;
  }

  addRenewalDiscount() {
    if (this.discountForm.value.reason == 'Annual Direct Debit/Recurring Billing') {
      if ((this.orderForm.value.accountDetails.planType != 'annual') ||
          !['recurringBilling', 'directDebit'].includes(this.orderForm.value.renewalInformation.renewalType)) {
        this.showErrorPopUp('Conditions not met', 'To add the "Annual Direct Debit/Recurring Billing" discount the ' +
          'Plan Type must be Annual and the Renewal Type must be either "Direct Debit" or "Recurring Billing"'
        );
        return;
      }
    }
    const discountFormGroup: FormGroup = this.fb.group({
      'discount': this.discountForm.value.discount,
      'discountExpiry': !this.discountForm.value.doNotExpire? this.discountForm.value.discountExpiry: undefined,
      'reason': this.discountForm.value.reason,
      'otherReasonText': this.discountForm.value.otherReasonText,
      'addedBy': this.userName,
    });
    this.discountForms.push(discountFormGroup);
    this.autosave('');
    this.discountForm.reset();
  }

  clearDiscountDoNotExpireIfNeeded() {
    if (!!this.discountForm.controls['discountExpiry'].value) {
      this.discountForm.controls['doNotExpire'].setValue(false);
    }
  }

  clearDiscountExpiryIfNeeded() {
    if (this.discountForm.controls['doNotExpire'].value) {
      this.discountForm.controls['discountExpiry'].setValue('');
    }
  }

  /*******************End Discounts************/

  ngOnDestroy() {
    this.locksSocket.removeAllListeners();
    this.closing = true;
    // Shouldn't unlock if this wasn't the one that had the order locked
    if (this.blockedDocument == false) {
      this.blockedDocument = true;
      this.locksSocket.emit('unlocking', {'orderId': this.orderDbId, 'user': this.userName});
    }
    this.widthChangeSubscription.unsubscribe;
  }

  loadHistories() {
    this.historiesLoading = true;
    this.orderService.getOrderHistories(this.orderDbId)
      .subscribe((response: MultiRecordResponse<DiffHistory>) => {
        if (response.success) {
          if (response.data.length === 0) {
            this.showWarnUpdate('Change Log Loaded', 'No change log records for this order');
          }
          this.histories = response.data;
          this.historiesTree = getTree(this.histories);
          this.historiesShown = true;
        }
        this.historiesLoading = false;
      });
  }

  getUpdate() {
    // If the user has chosen to display the history refresh them
    if (this.historiesShown) {
      this.loadHistories();
    }
    this.orderService
      .getOrder(this.orderDbId)
      .subscribe((orderResponse: OrderResponse) => {
        if ((orderResponse.success) && (orderResponse.order)) {
          this.preparePageWithOrder(orderResponse.order, false);
        } else {
          this.handleError(orderResponse.error);
          this.showErrorPopUp('Error Refreshing Order',
              'Something went wrong when trying to refresh the order after the update. Please refresh the page.');
        }
      }, (err: Error) => {
        this.handleError(err);
        this.showErrorPopUp('Error Refreshing Order',
            `Something went wrong when trying to refresh the order after the update. Please refresh the page. Error: ${err.message}`);
      });
  }

  async preparePageWithOrder(order: Order, initialPageLoad: boolean): Promise<void> {
    // Don't allow two threads to be rebuilding the form as this is likely cause of duplication
    while (this.processing) {
      await this.sleep(100);
    }
    this.processing = true;
    try {
      // Initialised in ngOnInit for initial page load
      if (!initialPageLoad) {
        this.initOrderForm();
      }
      this.orderDataToExport = convertOrderToData(order);
      this.loading = false;
      this.postOrderService.prepareComponentsWithOrder(order);
      if (initialPageLoad) {
        this.contactDetailChanges = [];
        if (this.postOrderService.order.status.status == 'no ncf') {
          // This will stop the email being sent if the order was no ncf when page opened
          this.allowContactChangesEmail = false;
        }
      }
      this.filterStatus();
      const website: Website = order.website;
      const brand: string = website.title;
      const tdCode: string = order.alarmUserDetails.tdCode;
      const custName: string = `${order.alarmUserDetails.firstName} ${order.alarmUserDetails.lastName}`;

      this.pageTitle.setTitle('' + tdCode + ' | ' + custName + ' | ' + brand);
      this.ncfLink = NCF_FOLDER_ROOT;

      if (TDCODE_REGEX.test(tdCode)) {
        const matches: RegExpMatchArray|null = tdCode.match(TDCODE_REGEX);
        if (matches && (matches.length == 2)) {
          const tdCodeNumber: number = parseInt(matches[1]);
          const folderFirstNumber: number = tdCodeNumber - (tdCodeNumber % BATCH_SIZE);
          const folderSecondNumber: number = folderFirstNumber + BATCH_SIZE - 1;
          this.ncfLink = `${NCF_FOLDER_ROOT}${folderFirstNumber}-${folderSecondNumber}/${tdCode}%20-%20${custName.replace(/\s/g, '%20')}`;
        }
      }
      if (initialPageLoad) {
        this.domain = website.domain;
        this.emailTemplates = getManualSendEmailTypesForWebsite(website._id);
        this.letterTemplates = getManualSendLetterTypesForWebsite(website._id);
        this.smsTemplates = getManualSendSmsTypesForWebsite(website._id);
        this.hasReadyToTestEmail = this.emailTemplates.some((template: SelectItem<string>) =>
          template.value == 'Ready To Test'
        );
        this.getWebSiteProducts(website._id);
        this.promptForEquipmentTestEmail = this.emailTemplates.some((template: SelectItem<string>) =>
          ['New Equipment Test (User)', 'New Equipment Test (Contact)'].includes(template.value)
        );
        this.promptForDamagedEquipmentEmail = this.emailTemplates.some((template: SelectItem<string>) =>
          ['Damaged Equipment'].includes(template.value)
        );
      }
      try {
        // TODO start remove for hardware version
        this.postOrderService.order.plans = this.postOrderService.order.plans.sort((a, b) => {
          if (a.status >= b.status) {
            return 1;
          } else {
            return -1;
          }
        });

        this.postOrderService.order.additionalEquipment = this.postOrderService.order.additionalEquipment.sort((a, b) => {
          if (a.status >= b.status) {
            return 1;
          } else {
            return -1;
          }
        });
        // TODO end remove for hardware version

        if ((this.postOrderService.order.alarmUserDetails.emailTwo !== undefined) && (this.postOrderService.order.alarmUserDetails.emailTwo !== null) &&
            (this.postOrderService.order.alarmUserDetails.emailTwo !== '')) {
          this.showEmailTwo = true;
          this.buttonName = '-';
        }
        
        const sdAccount: PaymentAccount = SMART_DEBIT_ACCOUNTS[order.legalCompany][order.website.title];
        this.correctPslid = sdAccount? sdAccount.accountId: '';
        this.correctSdAccount = sdAccount? sdAccount.accountName: '';
        this.orderForm.patchValue({
          _id: this.postOrderService.order._id,
          website: this.postOrderService.order.website,
          orderId: this.postOrderService.order.orderId,
          OrderGUID: this.postOrderService.order.OrderGUID,
          goCardlessId: this.postOrderService.order.goCardlessId,
          goCardlessSubId: this.postOrderService.order.goCardlessSubId,
          alarmUserDetails: this.postOrderService.order.alarmUserDetails,
          accountDetails: this.postOrderService.order.accountDetails,
          renewalInformation: this.postOrderService.order.renewalInformation,
          created: this.postOrderService.order.created,
          status: this.postOrderService.order.status,
          jontekCodes: this.postOrderService.order.jontekCodes,
          oldAlarmCodes: this.postOrderService.order.oldAlarmCodes,
          referencenumber: this.postOrderService.order.referencenumber,
          pslid: this.postOrderService.order.pslid? this.postOrderService.order.pslid: this.correctPslid,
          legalCompany: this.postOrderService.order.legalCompany,
        });
        this.oldAlarmCodesString = this.postOrderService.order.oldAlarmCodes.join('\n');
        const correspondenceDetails: CorrespondenceDetails = this.postOrderService.order.alarmUserDetails.correspondenceDetails;
        this.isCorrespondenceMinimized = (!correspondenceDetails.firstName && !correspondenceDetails.lastName &&
          !correspondenceDetails.email && !correspondenceDetails.mobile && !correspondenceDetails.telephone &&
          !correspondenceDetails.addressOne);

      } catch (error) {
      }
      let o: OutstandingAction[] = [];
      o = o.concat(this.postOrderService.order.outstandingActions);
      o.forEach((action: OutstandingAction) => {
        this.addOutstandingActionsDB(action);
      });
      let t: OrderTag[] = [];
      t = t.concat(this.postOrderService.order.tags);
      t.forEach((tag: OrderTag) => {
        this.addTagDB(tag);
      });
      let v: Vim[] = [];
      v = v.concat(this.postOrderService.order.vim);
      v.forEach((vim: Vim) => {
        this.addvimDB(vim);
      });
      // TODO start remove for hardware version
      let pl = [];
      pl = pl.concat(this.postOrderService.order.plans);
      pl.map(plan => {
        this.addPlanDB(plan);
      });
      let e = [];
      e = e.concat(this.postOrderService.order.additionalEquipment);
      e.map(equipment => {
        this.addAdditionalEquipmentDB(equipment);
      });
      e = [];
      e = e.concat(this.postOrderService.order.replacementEquipment);
      e.map(equipment => {
        this.addreplacementEquipmentDB(equipment);
      });
      // TODO end remove for hardware version
      // TODO add for hardware version
      /*
      let hardwareList: OrderHardware[]= [];
      hardwareList = hardwareList.concat(
        this.postOrderService.order.hardware
      );
      hardwareList.forEach((hardware: OrderHardware) => {
        this.addHardwareDB(hardware);
      });
      let hardwareSetList: OrderHardwareSet[]= [];
      hardwareSetList = hardwareSetList.concat(this.postOrderService.order.hardwareSets);
      hardwareSetList.forEach((hardwareSet: OrderHardwareSet) => {
        this.addHardwareSetDB(hardwareSet);
      });
      */
      let renewalDiscounts: RenewalDiscount[] = [];
      renewalDiscounts = renewalDiscounts.concat(
        this.postOrderService.order.renewalDiscounts
      );
      renewalDiscounts.forEach((renewalDiscount: RenewalDiscount) => {
        this.addRenewalDiscountDB(renewalDiscount);
      });

      let n: OrderNote[] = [];
      n = n.concat(this.postOrderService.order.notes);
      n.forEach((note: OrderNote) => {
        this.addNotesDB(note);
      });
      let k: KeySafe[] = [];
      k = k.concat(
        this.postOrderService.order.keySafes
      );
      k.forEach((equipment: KeySafe) => {
        this.addkeySafesDB(equipment);
      });
      let services: Service[] = [];
      services = services.concat(
        this.postOrderService.order.services
      );
      services.forEach((service: Service) => {
        this.addServiceDB(service);
      });
      let us: AdditionalUser[] = [];
      us = us.concat(this.postOrderService.order.alarmUserDetails.users);
      us.forEach((user: AdditionalUser) => {
        this.adduserDB(user);
      });
      let ac: AccountContact[] = [];
      ac = ac.concat(this.postOrderService.order.accountContacts);
      ac.forEach((contact: AccountContact) => {
        this.addAccountContactsDB(contact);
      });
      if (this.postOrderService.order.contactAttempts) {
        let contactAttempts: ContactAttempt[] = [];
        contactAttempts = contactAttempts.concat(this.postOrderService.order.contactAttempts);
        contactAttempts.map((contactAttempt: ContactAttempt) => {
          this.addContactAttemptDB(contactAttempt);
        });
      }
      if (this.postOrderService.order.jontekCodes) {
        this.jontekCodesString = this.postOrderService.order.jontekCodes.join('\n');
      } else {
        this.jontekCodesString = '';
      }

      this.loading = false;
      this.orderFormOld = this.orderForm.value;
      this.oldStatus = this.orderForm.controls['status'].value.status;
      this.closing = false;
    } finally {
      this.processing = false;
    }
  }

  alarmUserAddressSearch() {
    if (!this.alarmUserSearchPostCode) {
      return;
    }
    this.alarmUserAddressResults = [];
    this.allowAlarmUserAddressManualEntry = false;
    this.alarmUserSearchError = '';
    this.getAddrClient.find(this.alarmUserSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.alarmUserAddressResults = getLookupFromGetAddressResult(this.alarmUserSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.alarmUserSearchError = addressResult.toFailed().message;
        console.error('Alarm User Address search failed. Error:', this.alarmUserSearchError);
      } else if (this.alarmUserAddressResults.length <= 2) {
        this.alarmUserSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Alarm User Address search failed. Error:', error);
    });
  }

  setAlarmUserAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAlarmUserAddressManualEntry = true;
      const existingAddress: Address = this.userAddressFormGroup.value;
      let postcodeToUse: string = existingAddress.postcode;
      if (!existingAddress.addressOne && !existingAddress.postcode) {
        postcodeToUse = this.alarmUserSearchPostCode;
      }
      this.userAddressFormGroup.patchValue({
        'postcode': postcodeToUse,
        'validated': false,
      });
      return;
    }
    this.allowAlarmUserAddressManualEntry = false;
    const oldAddress: Address = this.userAddressFormGroup.value;
    if (oldAddress.addressOne != selectedAddress.addressOne) {
      this.contactDetailsChanged('Alarm User Address 1', oldAddress.addressOne, selectedAddress.addressOne);
    }
    if (oldAddress.addressTwo != selectedAddress.addressTwo) {
      this.contactDetailsChanged('Alarm User Address 2', oldAddress.addressTwo, selectedAddress.addressTwo);
    }
    if (oldAddress.city != selectedAddress.city) {
      this.contactDetailsChanged('Alarm User City', oldAddress.city, selectedAddress.city);
    }
    if (oldAddress.county != selectedAddress.county) {
      this.contactDetailsChanged('Alarm User County', oldAddress.county, selectedAddress.county);
    }
    if (oldAddress.postcode != selectedAddress.postcode) {
      this.contactDetailsChanged('Alarm User Postcode', oldAddress.postcode, selectedAddress.postcode);
    }

    // Don't want to copy Role
    this.userAddressFormGroup.patchValue({
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    });
    this.autosave('');
  }

  correspondenceAddressSearch() {
    if (!this.correspondenceSearchPostCode) {
      return;
    }
    this.correspondenceAddressResults = [];
    this.allowCorrespondenceAddressManualEntry = false;
    this.correspondenceSearchError = '';
    this.getAddrClient.find(this.correspondenceSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.correspondenceAddressResults = getLookupFromGetAddressResult(this.correspondenceSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.correspondenceSearchError = addressResult.toFailed().message;
        console.error('Correspondence Address search failed. Error:', this.correspondenceSearchError);
      } else if (this.correspondenceAddressResults.length <= 2) {
        this.correspondenceSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Correspondence Address search failed. Error:', error);
    })
  }
  
  setCorrespondenceAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowCorrespondenceAddressManualEntry = true;
      const existingAddress: Address = this.correspondenceAddressFormGroup.value;
      let postcodeToUse: string = existingAddress.postcode;
      if (!existingAddress.addressOne && !existingAddress.postcode) {
        postcodeToUse = this.correspondenceSearchPostCode;
      }
      this.correspondenceAddressFormGroup.patchValue({
        'postcode': postcodeToUse,
        'validated': false,
      });
      return;
    }
    this.allowCorrespondenceAddressManualEntry = false;
    // Don't want to copy Role
    this.correspondenceAddressFormGroup.patchValue({
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    });
    this.autosave('');
  }

  filterStatus() {
    if (this.postOrderService.order.status.status == 'cancelling' || this.postOrderService.order.status.status == 'cancelled') {
      this.statuses = statuses;
    } else {
      this.statuses = this.statuses.filter((status: string) => {
        if (status == 'cancelling') {
          return this.userCanSetOrderToCancelling();
        } else {
          return status != 'cancelled';
        }
      });
    }
  }

  userCanDeleteOrders(): boolean {
    return this.usersService.userHasPermission('Delete Order');
  }

  userCanSetOrderToCancelling(): boolean {
    return this.usersService.userHasPermission('Set Order to Cancelling');
  }

  userCanEditOldAlarmCodes(): boolean {
    return this.usersService.userHasPermission('Edit Old Alarm Codes');
  }

  ngOnInit() {
    this.retentionRecorded = false;
    this.addProductSidebar = false;
    this.blockedDocument = false;
    this.deleteSidebar= false;
    this.otherUser = {orderId: '', user: ''};
    this.filterType = 'all';
    this.allowAlarmUserAddressManualEntry = false;
    this.allowCorrespondenceAddressManualEntry = false;
    this.categories = getNoteCategories(false);
    this.noteTemplates = getNoteTemplates();
    // Don't allow users to add CRM Automation category, but needs to be able to be filtered by
    this.categoriesToAddNote = getNoteCategories(true);
    this.monitoringOptions = getMonitoringOptions();
    this.smartDebitAccounts = getSmartDebitAccounts();
    this.defaultNoteCategories = JSON.parse(localStorage.getItem('defaultNoteCategories'));
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    this.allowContactChangesEmail = true;
    this.hasDiscountMsgBeenDisplayed = false;
    this.timeOut();
    this.userName = localStorage.getItem('userName');
    this.discountReasonSelectItems = getDiscountReasons();
    this.actions = getActionConfigs();
    this.actionNames = getActionNames().filter((actionName: string) =>
      this.actions[actionName].usersCanAdd
    ).map((actionName: string) => {
      return {
        label: actionName,
        value: actionName,
      }
    });
    this.actionReasonLookups = getActionReasonLookups();
    this.createAddForms();
    this.initOrderForm();
    this.historiesCols = [
      {field: 'field', header: 'Field'},
      {field: 'before', header: 'Before'},
      {field: 'after', header: 'After'},
      {field: 'user', header: 'User'},
      {field: 'reason', header: 'Reason'},
      {field: 'date', header: 'Date'},
    ];
    this.historiesFilterFields = this.historiesCols.map((col: Column) => col.field);
    if (localStorage.getItem('orderPageLeftColumn')) {
      try {
        this.leftColumnSections = JSON.parse(localStorage.getItem('orderPageLeftColumn'));
      } catch (parseError) {
        console.error('Error parsing orderPageLeftColumn: ', parseError);
      }
    }
    if (localStorage.getItem('orderPageRightColumn')) {
      try {
        this.rightColumnSections = JSON.parse(localStorage.getItem('orderPageRightColumn'));
      } catch (parseError) {
        console.error('Error parsing orderPageRightColumn: ', parseError);
      }
    }
    // Allow for a single column to be empty (the user might have moved everything to one column)
    if ((this.leftColumnSections.length === 0) && (this.rightColumnSections.length === 0)) {
      this.leftColumnSections = defaultLeftColumn;
      this.rightColumnSections = defaultRightColumn;
    }

    this.tagsService.getActiveTags().subscribe((response: TagsResponse) => {
      this.tags = response.tags.map((tag: Tag) => {
        return {
          label: tag.tagName,
          value: tag,
        }
      });
    }, err => {
      this.handleError(err);
    });
    this.route.params.subscribe((params: Params) => {
      this.orderDbId = params.id;
      if (!isValidObjectId(this.orderDbId)) {
        return;
      }
      if (isValidObjectId(params.feedbackId)) {
        this.feedbackIdToEdit = params.feedbackId;
      }
      this.orderLink = `${environment.protocol}${environment.IPAddress}/order/${this.orderDbId}`;
      this.orderService.getOrder(this.orderDbId)
        .subscribe((orderResponse: OrderResponse) => {
          this.locksSocket.emit('locking', {'orderId': this.orderDbId, 'user': this.userName}, (res: OrderLockData) => {
            if (res.added == true) {
              this.unblockedDocuments();
              this.socketId = res.socketId;
            } else {
              this.otherUser = res;
              this.blockedDocuments();
            }
          });
          if ((orderResponse.success) && (orderResponse.order)) {
            this.preparePageWithOrder(orderResponse.order, true).then((): void => {
              this.displayVimAsPopup();
            });
          } else {
            console.error(`Error loading order ${this.orderDbId}. Error response:`, orderResponse.error);
            this.showErrorPopUp('Error Getting Order',
                'Something went wrong when trying to get the order. Please refresh the page.');
          }
        }, (err: Error) => {
          console.error(`Error loading order ${this.orderDbId}. Caught Error: `, err);
        });
      this.orderService.getServiceUsers(this.orderDbId)
        .subscribe((serviceUserResponse: MultiRecordResponse<ServiceUser>) => {
          if ((serviceUserResponse.success) && (serviceUserResponse.data)) {
            this.serviceUsers = serviceUserResponse.data;
          } else {
            console.error(`Error getting services users for order ${this.orderDbId}. Error response:`, serviceUserResponse.error);
            this.showErrorPopUp('Error Getting Service Users',
                'Something went wrong when trying to get the service users for this order. Please refresh the page.');
          }
        });
      this.orderService.getExternalIdForOrderAndNameStart(this.orderDbId, 'EVO Device ID')
        .subscribe((evoDeviceResponse: MultiRecordResponse<ExternalId>) => {
          if ((evoDeviceResponse.success) && (evoDeviceResponse.data)) {
            this.evoDeviceIds = evoDeviceResponse.data;
          } else {
            console.error(`Error getting EVO Device details for order ${this.orderDbId}. Error response:`, evoDeviceResponse.error);
            this.showErrorPopUp('Error EVO Device Details',
                'Something went wrong when trying to get the EVO Device details for this order. Please refresh the page.');
          }
        });
    });
    this.locksSocket.on('locked', (data: OrderLockData) => {
      if (data.socketId === this.socketId) {
        this.otherUser = data;
        this.blockedDocuments();
      }
    });

    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      // Stop it trying to relock the order if the page is closing
      if (this.closing) {
        return;
      }
      if (this.blockedDocument == true && data.filter(item => item.orderId === this.postOrderService.order._id).length == 0) {
        this.locksSocket.emit('locking', {'orderId': this.orderDbId, 'user': this.userName}, (res: OrderLockData) => {
          if (res.added == true) {
            this.getUpdate();
            this.unblockedDocuments();
            this.socketId = res.socketId;
          }
        });
      }
    });

    this.isSmallerThanLarge = this.breakpointObserver.isMatched('(max-width: 991px)');
    this.widthChangeSubscription = this.breakpointObserver.observe([
      '(max-width: 991px)'
    ]).subscribe(result => {
      this.isSmallerThanLarge = result.matches;
    });
  }

  toggle() {
    this.showEmailTwo = !this.showEmailTwo;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.showEmailTwo) {
      this.buttonName = '-';
    } else {
      this.buttonName = '+';
    }
  }

  unlock() {
    this.confirmationService.confirm({
      key: 'aboveBlockMessage',
      message: this.otherUser.user +
        ' is currently editing this order, do you want to have full access permission? Warning: Any unsaved changes made by ' +
        this.otherUser.user + ' will be lost.',
      header: 'Warning',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.locksSocket.emit('unlocking-F', {'orderId': this.orderDbId, 'user': this.userName}, (res: OrderLockData) => {
          if (res.added === true) {
            this.getUpdate();
            this.unblockedDocuments();
            this.socketId = res.socketId;
          }
        });

      },
      reject: () => {
      }
    });
  }
  
  futureDateAtMidnight(daysToAdd: number): string {
    return moment().tz('Europe/London').startOf('day').add(daysToAdd, 'days').toISOString();
  }

  datetimeLocalfromJson(jDate: string): string {
    return moment(jDate).tz('Europe/London').format('YYYY-MM-DDTHH:mm');
  }

  fromJsonDateTime(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString();
  }

  createUpdateLogs(updateLogs: LogWithUpload[], retryCount: number) {
    this.logsService
      .createLogs({logs: updateLogs})
      .subscribe((logres: SimpleResponse) => {
        if (!logres.success) {
          console.error('Error creating update logs', logres.message);
          if (retryCount > 0) {
            this.createUpdateLogs(updateLogs, retryCount-1);
          } else {
            this.postOrderService.decPendingUpdates();
          }
        } else {
          this.postOrderService.decPendingUpdates();
        }
      }, (err: Error) => {
        console.error('Error creating update logs', err);
        if (retryCount > 0) {
          this.createUpdateLogs(updateLogs, retryCount-1);
        } else {
          this.postOrderService.decPendingUpdates();
        }
      });
  }

  submitForm() {
    if (this.orderForm.value.alarmUserDetails.tdCode == null ||
        this.orderForm.value.alarmUserDetails.tdCode == undefined ||
        this.orderForm.value.alarmUserDetails.tdCode.trim() == '') {
      this.showErrorPopUp('Cannot Save order', 'Update failed, TD code should not be empty!');
      return;
    }
    this.postOrderService.incPendingUpdates();
    const res1 = this.filterFN(this.orderFormOld, this.orderForm.value);
    this.orderFormOld = this.orderForm.value;
    const ress = this.conveJSON(res1);
    const updateLogs: LogWithUpload[] = [];

    for (const res of ress) {
      if (res.value) {
        const log: LogWithUpload = {
          brand: this.orderForm.value.website.title,
          source: 'order',
          code: this.postOrderService.order.alarmUserDetails.tdCode,
          firstName: this.postOrderService.order.alarmUserDetails.firstName,
          lastName: this.postOrderService.order.alarmUserDetails.lastName,
          id: this.orderDbId,
          user: this.userName,
          date: moment.tz('Europe/London').toISOString(),
          key: res.key,
          action: res.value.action
        }
        if (res.value.old) {
          log['before'] = res.value.old
        }
        if (res.value.new) {
          log['after'] = res.value.new
        }
        updateLogs.push(log);
      }
    }
    if (updateLogs.length > 0) {
      this.postOrderService.incPendingUpdates();
      this.createUpdateLogs(updateLogs, 2);
    }
    const orderParams = {
      order: this.orderForm.value,
      user: this.userName,
      reason: ''
    };
    orderParams.order.alarmUserDetails.tdCode = orderParams.order.alarmUserDetails.tdCode.toUpperCase();
    const result: Observable<OrderResponse> = this.orderService.updateOrder(this.postOrderService.order._id, orderParams);
    result.subscribe((orderResponse: OrderResponse) => {
      this.postOrderService.decPendingUpdates();
      if (orderResponse.success && orderResponse.order) {
        this.showSuccess();
        // If the user has chosen to display the history refresh them
        if (this.historiesShown) {
          this.loadHistories();
        }
        this.orderDataToExport = convertOrderToData(orderResponse.order);
        this.postOrderService.updateOrderWithoutUpdatingComponents(orderResponse.order);
        this.filterStatus();
        this.oldStatus = this.orderForm.controls['status'].value.status;
      } else {
        this.showErrorPopUp('Error Updating Order',
            `Something went wrong when trying to update the order. Please try again. Error: ${orderResponse.error.message}`);
        this.handleError(orderResponse.error);
      }
    }, (err: Error) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error Updating Order',
          `Something went wrong when trying to update the order. Please try again. Error: ${err.message}`);
      this.handleError(err);
      return;
    });
  }

  private handleError(err: Error) {
    console.error(err);
  }

  setOrderToCancelling() {
    const p: number = this.getActionIndex('Chase Cancellation');
    if (p == -1) {
      const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Chase Cancellation');
      this.outstandingActionsForms.push(actionFormGroup);
    }
    const finalInvoiceIdx: number = this.getActionIndex('Final Invoice');
    const overDueIdx: number = this.getActionIndex('Overdue Payment');
    if (overDueIdx > -1) {
      const owedPayment: number|undefined = this.outstandingActionsForms.at(overDueIdx).value.owedPayment;
      if (owedPayment) {
        if (finalInvoiceIdx == -1) {
          const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Final Invoice');
          actionFormGroup.patchValue({
            owedPayment: owedPayment,
          });
          this.outstandingActionsForms.push(actionFormGroup);
        } else {
          this.outstandingActionsForms.at(finalInvoiceIdx).patchValue({
            'owedPayment': owedPayment,
          });
        }
      }
    }
    this.removeActionsByNames(this.actionToRemoveOnCancellingOrCancelled);
    this.removeActionsByNames(['Returning Equipment']);
    (this.orderForm.controls['status'] as FormGroup).patchValue({
      'setby': this.userName,
      'status': 'cancelling',
      'date':  moment.tz('Europe/London').toISOString()
    });
    this.autosave('');
  }

  closeCancellingDialog(formData: CancellingDialogData|undefined) {
    this.showCancellingDialog = false;
    if (!formData) {
      this.orderForm.get('status').get('status').setValue(this.oldStatus);
      this.orderForm.get('status').get('status').markAsPristine();
      return;
    }
    let retentionActionAdded: string = this.retentionActionDate;
    if (!['Duplicate', 'High Usage'].includes(formData.data.cancellationReason)) {
      this.postOrderService.incPendingUpdates();
      const currentDate: string = (new Date()).toISOString();
      // If no retention action assume today as the date retention started
      if (!retentionActionAdded) {
        retentionActionAdded = currentDate;
      }
      const newFeedback: CustomerFeedback = {
        'websiteId': this.order.website,
        'tdCode': this.order.alarmUserDetails.tdCode,
        'orderId': this.order._id,
        'loggedBy': this.userName,
        'dateLogged': retentionActionAdded,
        'datePending': undefined,
        'closedBy': this.userName,
        'dateClosed': currentDate,
        'feedbackType': 'Failed Retention',
        'priority': 'Medium',
        'partnership': 'None',
        'status': 'Closed',
        'resolutionDays': getDaysBetween(retentionActionAdded, currentDate),
        'pendingDays': 0,
        'whoContactedUs': '',
        'relationshipToUser': '',
        'emailAddress': '',
        'phoneNumber': '',
        'contactMethod': '',
        'mainReason': formData.data.cancellationReason,
        'specificReason': formData.data.detailedCancellationReason,
        'feedbackDetails': formData.data.otherReason,
        'resolvedAtFirstContact': false,
        'ccTo': '',
        'contactAttempts': [],
        'resolution': '',
        'respondByEmail': false,
        'freeServiceGiven': false,
        'datePaymentRestarts': undefined,
        'chaserEmailSent': false,
        'updatedAt': undefined
      };
      this.customerFeedbackService.saveNewCustomerFeedback({
        'feedback': newFeedback,
        'crmBaseUrl': `${environment.protocol}${environment.IPAddress}/`,
      }).subscribe((response: SimpleResponse) => {
        this.postOrderService.decPendingUpdates();
        if (!response.success) {
          this.showErrorPopUp('Error Logging Failed Retention', `Error Logging Failed Retention. Error: ${response.message}`);
        }
      }, (err: Error) => {
        this.postOrderService.decPendingUpdates();
        this.showErrorPopUp('Error Logging Failed Retention', `Error Logging Failed Retention. Error: ${err.message}`);
      });
    }
    this.postOrderService.clearCancellationSection(true);
    this.postOrderService.cancellationDetailsSubject.emit(formData.data);
    if (formData.note) {
      this.addAutomatedNote(formData.note, formData.noteCategories, false);
    }
    this.oldStatus = this.orderForm.controls['status'].value.status;
    this.postOrderService.sendArcCancellingEmail(formData.data.cancellationReason, this.userName).then((result: SimpleResponse) => {
      this.addAutomatedNote(result.message, ['ARC'], false);
      this.setOrderToCancelling();
      if (formData.emailRequired) {
        if (formData.reasonForNoEmail) {
          this.sendNotificationEmail('email: Admin', 'cancelling',
            'Please be advised that the following order has been set to cancelling, but no initial cancellation email was sent because:\n' +
            formData.reasonForNoEmail,
            'to notify them that the order is cancelling, but that no initial cancellation email was set because ' + formData.reasonForNoEmail);
        } else {
          const initialCancellationEmail: SelectItem<string> = this.emailTemplates.find((template: SelectItem<string>) =>
            template.label == formData.emailType
          );
          this.manualSendEmailMessageTypes = [initialCancellationEmail]
        }
      }
      if (result.success) {
        this.showSuccess('Email Sent', 'Email successfully sent to ARC');
      } else {
        this.showErrorPopUp('Error sending email', result.error);
      }
    });
  }

  closeNcfDialog(formData: NcfDialogData|undefined) {
    this.showNcfDialog = false;
    if (!formData) {
      this.orderForm.get('status').get('status').setValue(this.oldStatus);
      this.orderForm.get('status').get('status').markAsPristine();
      return;
    }
    this.postOrderService.clearCancellationSection(false);
    // Don't want to send the reactivated email if we are going to send the RIP version
    if ((this.oldStatus != 'active') && !formData.isCustomerRip) {
      this.sendOrderActivatedEmail();
    }
    this.oldStatus = this.orderForm.controls['status'].value.status;

    const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Missing NCF');
    if (this.getActionIndex('Missing NCF') == -1) {
      this.outstandingActionsForms.push(actionFormGroup);
      this.outstandingActionsErr = false;
    }
    (this.orderForm.controls['status'] as FormGroup).patchValue({
      'setby': this.userName,
      'date':  moment.tz('Europe/London').toISOString()
    });
    
    if (formData.isCustomerRip) {
      const vimType: FormGroup = this.fb.group({
        content: `Alarm user ${this.postOrderService.order.alarmUserDetails.firstName} ${this.postOrderService.order.alarmUserDetails.lastName} ` +
          'is RIP and the alarm is being transferred to a new user.',
        userName: this.userName,
        date: moment.tz('Europe/London').toDate()
      });
      this.vimForms.push(vimType);
      this.postOrderService.sendArcRipTransferEmail(this.userName).then((result: SimpleResponse) => {
        this.addNoteFromSection({
          'content': result.message,
          'categories': ['ARC'],
        });
        if (result.success) {
          this.showSuccess('Email Sent', 'Email successfully sent to ARC');
        } else {
          this.showErrorPopUp('Error sending email', result.error);
        }
      });
    }
    this.autosave('');

    if (formData.ncfMethod == 'Email') {
      const welcomeEmail: SelectItem<string> = this.emailTemplates.find((template: SelectItem<string>) =>
        template.label == 'Welcome Email'
      );
      this.manualSendEmailMessageTypes = [welcomeEmail]
    }
  }

  changeStatusSetbyAndDate() {
    const actionToRemoveOnCancelled: string[] = [
      'No DD or RB',
      'Missing NCF',
      'Chase Cancellation',
      'Missing Renewal/Payment Due Date',
    ];

    if (this.orderForm.controls['status'].value.status == 'cancelling') {
      if (this.oldStatus == 'cancelled') {
        this.setOrderToCancelling();
        this.oldStatus = this.orderForm.controls['status'].value.status;
      } else {
        this.initialCancellationEmailTemplates = this.emailTemplates.filter((template: SelectItem<string>) =>
          template.value.startsWith('Cancellation Initial')
        );
        this.noCancellingEmailTag = '';
        const bulkTagName: string = this.getBulkTagName();
        if (bulkTagName) {
          this.noCancellingEmailTag = bulkTagName;
        } else if (this.getTagIndex('NCC 6 WK Free') > -1) {
          this.noCancellingEmailTag = 'NCC 6 WK Free';
        } else if (this.getTagIndex('Norfolk CC') > -1) {
          this.noCancellingEmailTag = 'Norfolk CC';
        }
        // Use set timeout to delay enough for other UI events to see if it fixes cancellation issue
        setTimeout(() => {
          this.showCancellingDialog = true;
        });
      }
      return;
    } else if (this.orderForm.controls['status'].value.status == 'no ncf') {
      /* Need to check how to handle reassignment
      if (this.orderHasService('Friends and Family')) {
        this.showErrorPopUp('Friends and Family Service On Order',
            'The Friends and Family service is on this order, but the users will need to register once the alarm has been reassigned in EVO.' +
            'Please delete the service before setting the order to "No NCF".');
        this.orderForm.patchValue({
          'status': {
            'status': this.postOrderService.order.status.status,
          }
        });
        return;
      }*/
      setTimeout(() => {
        this.showNcfDialog = true;
      });
      return;
    } else if (this.orderForm.controls['status'].value.status == 'active') {
      if (this.oldStatus == 'no ncf') {
        if (this.hasReadyToTestEmail) {
          this.checkIfReadyToTestEmailShouldBeSent();
        }
      } else {
        this.confirmChangeStatusFromCancellingToActive();
        this.sendOrderActivatedEmail();
      }
      this.postOrderService.clearCancellationSection(false);
      this.removeActionsByNames(['Missing NCF', 'Chase Cancellation']);
      (this.orderForm.controls['status'] as FormGroup).patchValue({
        'setby': this.userName,
        'date':  moment.tz('Europe/London').toISOString()
      });
      this.autosave('');
    } else if (this.orderForm.controls['status'].value.status == 'cancelled') {
      let activeService: boolean = false;
      this.serviceForms.controls.forEach((serviceControl: AbstractControl) => {
        if (serviceControl.value.status == 'Active') {
          activeService = true;
        }
      });
      if (activeService) {
        this.showErrorPopUp('Services Still Active',
            'There are one or more services active on this order. Please delete/deactivate them before cancelling the order.');
        this.orderForm.patchValue({
          'status': {
            'status': this.postOrderService.order.status.status,
          }
        });
        return;
      }
      const renewalType: string = this.orderForm.value.renewalInformation.renewalType;
      if (renewalType) {
        this.showInfoPopUp(
          'Review Payment',
          `The customer was set to pay using ${renewalType}. Please make sure the payments are stopped.`
        );
      }
      this.orderForm.patchValue({
        renewalInformation: {
          renewalDate: null,
          paymentDueDate: '',
        },
        cancellation: {
          returnDate: moment.tz('Europe/London').format('YYYY-MM-DD'),
        }
      });
      for (let i: number = this.outstandingActionsForms.value.length - 1; i > -1; i--) {
        const action: OutstandingAction = this.outstandingActionsForms.value[i];
        if (this.actionToRemoveOnCancellingOrCancelled.includes(action.outstandingName) ||
            actionToRemoveOnCancelled.includes(action.outstandingName)) {
          this.outstandingActionsForms.removeAt(i);
        }
      }
      (this.orderForm.controls['status'] as FormGroup).patchValue({
        'setby': this.userName,
        'date':  moment.tz('Europe/London').toISOString()
      });
      this.autosave('');
      /* Code written by abdul ends */
      this.sendOrderCancelledEmail();
    }
    this.oldStatus = this.orderForm.controls['status'].value.status;
  }

  checkIfReadyToTestEmailShouldBeSent(): void {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Did you just process a paper copy NCF?',
      header: 'Send ready to test email',
      rejectLabel: 'No',
      acceptLabel: 'Yes',
      accept: () => {
        this.manualSendEmailMessageTypes = [{
          'label': 'Ready To Test',
          'value': 'Ready To Test'
        }];
      },
      reject: () => {
      }
    })
  }

  sendCustomerEmail(): void {
    this.manualSendEmailMessageTypes = this.emailTemplates;
  }

  closeSendEmailDialog(): void {
    this.manualSendFieldInitialisation = {};
    this.manualSendEmailMessageTypes = [];
  }

  sendCustomerLetter(): void {
    this.manualSendLetterMessageTypes = this.letterTemplates;
  }

  closeSendLetterDialog(): void {
    this.manualSendLetterMessageTypes = [];
  }

  sendCustomerSms(): void {
    this.manualSendSmsMessageTypes = this.smsTemplates;
  }

  closeSendSmsDialog(): void {
    this.manualSendSmsMessageTypes = [];
  }

  changeRenewalDate() {
    // Do not allow date clearing
    if (!this.orderForm.get('renewalInformation').get('renewalDate').value) {
      this.orderForm.get('renewalInformation').patchValue({
        renewalDate: this.postOrderService.order.renewalInformation.renewalDate
      });
      this.orderForm.get('renewalInformation').get('renewalDate').markAsPristine();
      return;
    }
    const startOfDay: moment.Moment = moment.tz('Europe/London').startOf('day');
    const newExpiryDate: moment.Moment = moment.tz(this.orderForm.get('renewalInformation').get('renewalDate').value, 'Europe/London');
    if (newExpiryDate.isBefore(startOfDay)) {
      let acceptClicked: boolean = false;
      this.confirmationService.confirm({
        key: 'general',
        message: 'Did you mean to set the expiry date to a date in the past?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        rejectVisible: true,
        accept: () => {
          acceptClicked = true;
          this.isRenewalInformationChangeDialogOpen = true;
        },
        reject: () => {
          if (!acceptClicked) {
            this.orderForm.get('renewalInformation').patchValue({
              renewalDate: this.postOrderService.order.renewalInformation.renewalDate
            });
            this.orderForm.get('renewalInformation').get('renewalDate').markAsPristine();
          }
        }
      });
    } else {
      this.isRenewalInformationChangeDialogOpen = true;
    }
  }

  changeFreeMonths() {
    this.isFreeMonthsChangeDialogOpen = true;
  }

  // TODO remove for hardware version
  changeRenewalPrice() {
    this.isRenewalPriceChangeDialogOpen = true;
  }

  // TODO add for hardware version
  /*
  changeFrozenPrice() {
    this.isFrozenPriceChangeDialogOpen = true;
  }
  */

  getBulkTagName(): string {
    const tag: OrderFormTag|undefined = (this.orderForm.value.tags as OrderFormTag[]).find((tag: OrderFormTag) =>
      tag.content.toLocaleLowerCase().startsWith('bulk')
    );
    return tag? tag.content: '';
  }

  addOrRemoveMissingDatesActionIfNecessary() {
    const missingDateActionIndex: number = this.getActionIndex('Missing Renewal/Payment Due Date');
    if (['', 'standing order'].includes(this.orderForm.value.renewalInformation.renewalType) &&
        !['lifetime'].includes(this.orderForm.value.accountDetails.planType) && !this.getBulkTagName() &&
        !this.postOrderService.order.alarmUserDetails.tdCode.toLocaleUpperCase().startsWith('KL') &&
        (this.postOrderService.order.status.status != 'cancelled')) {
      if (!this.orderForm.value.renewalInformation.renewalDate && (missingDateActionIndex == -1)) {
        const actionFormGroup: FormGroup = this.getBaseActionFormGroup('Missing Renewal/Payment Due Date');
        this.outstandingActionsForms.push(actionFormGroup);
      }
    } else if (missingDateActionIndex > -1) {
      this.outstandingActionsForms.removeAt(missingDateActionIndex);
    }
  }

  changeRenewalType() {
    this.addOrRemoveMissingDatesActionIfNecessary();
    if (this.orderForm.value.renewalInformation.renewalType == '') {
      if (this.getActionIndex('No DD or RB') == -1) {
        const actionFormGroup: FormGroup = this.getBaseActionFormGroup('No DD or RB');
        this.outstandingActionsForms.push(actionFormGroup);
        this.attention = true;
      }
      if ((this.orderForm.value.accountDetails.planType != 'lifetime') &&
          !this.orderForm.value.renewalInformation.renewalDate) {
        // Orders with no automatic payment should have a renewal/expiry date
        this.showInfoPopUp('Renewal/Expiry Date',
            'Orders without a renewal type need to have a renewal date set, unless they are lifetime plans, ' +
            'so that payment will be chased if the standing order is cancelled. Please set the renewal date.');
      }
    } else {
      if (this.orderForm.value.renewalInformation.renewalType != 'standing order') {
        this.orderForm.patchValue({
          renewalInformation: {
            renewalDate: null,
            paymentDueDate: '',
          }
        });
      } else if (!this.orderForm.value.renewalInformation.renewalDate) {
        // Standing orders should have a renewal/expiry date
        this.showInfoPopUp('Standing Order Renewal/Expiry Date',
            'Orders set to "Standing Order" renewal type need to have a renewal date set, ' +
            'so that payment will be chased if the standing order is cancelled. Please set the renewal date.');
      }
      this.removeActionsByNames(['No DD or RB', 'Due Payment', 'Overdue Payment']);
    }
    if (this.orderForm.value.renewalInformation.renewalType != 'recurringBilling') {
      // Remove the 'Has been Auto-Enrolled' tag if it is on the order
      const autoEnrolTagIndex: number = this.getTagIndex('Has been Auto-Enrolled');
      if (autoEnrolTagIndex > -1) {
        this.tagsForms.removeAt(autoEnrolTagIndex);
      }
    }
    this.checkAnnualDiscount();
    if (this.orderForm.value.renewalInformation.renewalType == 'directDebit') {
      const tagIndex: number = this.getTagIndex('Awaiting Direct Debit set up');
      if (tagIndex > -1) {
        this.tagsForms.removeAt(tagIndex);
      }
    }
    this.autosave('');
  }

  checkAnnualDiscount(): void {
    const annualDiscountIndex: number = this.discountForms.controls.findIndex(
      (discountControl: AbstractControl) => (discountControl.value.reason === 'Annual Direct Debit/Recurring Billing')
    );
    if ((this.orderForm.value.accountDetails.planType != 'annual') ||
        !['recurringBilling', 'directDebit'].includes(this.orderForm.value.renewalInformation.renewalType)) {
      // Remove the discount if it's there
      if (annualDiscountIndex > -1) {
        this.discountForms.removeAt(annualDiscountIndex);
      }
      return;
    }
  }

  titleCaseWord(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  close() {
    this.closing = true;
    if (this.blockedDocument == false) {
      //stop unlocking firing twice as close is called twice
      this.blockedDocument = true;
      this.locksSocket.emit('unlocking', {'orderId': this.orderDbId, 'user': this.userName});
    }
  }

  //###################Origin Add Product#######################
  addProduct() {
    this.addProductSidebar = true;
  }

  get showAddProductSidebar(): boolean {
    return this.addProductSidebar;
  }

  set showAddProductSidebar(newValue: boolean) {
    if (!newValue && this.equipmentAdded && this.promptForEquipmentTestEmail) {
      this.promptForEquipmentTestEmail = false;
      this.checkIfEquipmentTestEmailShouldBeSent();
    }
    this.equipmentAdded = false;
    this.addProductSidebar = newValue;
  }

  async delete(password: string) {
    this.usersService.login({
      email: this.email,
      password: password
    }).subscribe((response: CrmLoginResponse) => {
      if (!response.success) {
        this.data.error(response.message);
      } else {
        const orderParams = {
          order: {
            'deleted': true,
            'deletedBy': this.userName,
            'alarmUserDetails.tdCode': `deleted_${moment.tz('Europe/London')
              .format('YYYY-MM-DDTHH:mm')}_${this.postOrderService.order.alarmUserDetails.tdCode}`,
          },
          user: this.userName,
          reason: ''
        };
        this.postOrderService.incPendingUpdates();
        this.orderService.updateOrder(this.postOrderService.order._id, orderParams).subscribe((_rsp: OrderResponse) => {
          const fName = this.postOrderService.order.alarmUserDetails.firstName || '';
          const oName = this.postOrderService.order.alarmUserDetails.otherName || '';
          const lName = this.postOrderService.order.alarmUserDetails.lastName || '';
          const brand: string = this.postOrderService.order.website.title;
          this.orderService.sendAutoNotificationOnDeleteOrder({
            userName: this.userName,
            tdCode: this.postOrderService.order.alarmUserDetails.tdCode,
            alarmUserName: (`${fName} ${oName} ${lName}`).trim(),
            brandName: brand,
            deletingByUserId: localStorage.getItem('userId')
          }).subscribe((_response: SingleRecordResponse<any>) => {
            this.postOrderService.decPendingUpdates();
          }, (_err: Error) => {
            this.postOrderService.decPendingUpdates();
            this.showErrorPopUp(
              'Error sending Delete notification',
              'Unable to notify users that a CRM order has been deleted.'
            );
          });
          window.close();
        });
      }
    }, (error: any) => {
      this.data.error(error.message);
    });
  }

  async timeOut() {
    await this.delay(this.delayValue);
    this.countdown = 30;
    this.confirmTimeOut();
    while (this.countdown > 0) {
      await this.delay(1000);
      this.countdown -= 1
    }
    if (this.countdown == 0) {
      // Don't stop the idle timeout from leaving if it's just mandatory fields missing
      this.canExitOrderPage = true;
      this.router.navigate(['/']);
    }
  }

  private delay(ms: number): Promise<void> {
    // Clear any previous timeout first else we might have an old one trigger later
    if (this.timeoutVar) {
      clearTimeout(this.timeoutVar);
    }
    return new Promise((resolve): void => {
      this.timeoutVar = setTimeout(resolve, ms);
    });
  }

  private sleep(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  confirmTimeOut() {
    this.confirmationService.confirm({
      key: 'aboveBlockMessage',
      message: `You have been inactive for ${this.delayMins} mins, would you like to continue? page will close in ${this.countdown} seconds`,
      header: 'Timeout Message',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.timeOut();
        this.countdown = -1;
      },
      reject: () => {
        this.router.navigate(['/']);
      }
    });
  }


  getCategoryColor(categoryName: string): string {
    // categories.filter( cat => cat.label == categoryName)[0].color
    if (categoryName) {
      const categoryByName: NoteCategory = this.categories.find(cat => cat.label == categoryName);
      return categoryByName? categoryByName.color: 'white';
    }
    return 'white';
  }

  upperCaseField(field: AbstractControl) {
    if (field && field.value && (field.value.length > 0)) {
      field.patchValue(field.value.substring(0, 1).toUpperCase() + field.value.substring(1), {emitEvent: false});
    }
  }

  clearExpiryOptions() {
    this.tagForm.controls['expiryDate'].setValue('');
    this.tagForm.controls['doNotExpire'].setValue(false);
  }

  clearTagDoNotExpireIfNeeded() {
    if (this.tagForm.controls['expiryDate'].value) {
      this.tagForm.controls['doNotExpire'].setValue(false);
    }
  }

  clearTagExpiryIfNeeded() {
    if (this.tagForm.controls['doNotExpire'].value) {
      this.tagForm.controls['expiryDate'].setValue('');
    }
  }

  openContactAttempt(action) {
    this.outstandingActionNameForContactAttempt = action.value.outstandingName;
    this.isOpenContactAttempt = true;
  }

  closeContactAttempt() {
    this.isOpenContactAttempt = false;
    this.outstandingActionNameForContactAttempt = null;
  }

  updateOrderAfterActionsAndVotings(event: any) {
    this.isOpenContactAttempt = false;
    this.getUpdate();
    this.outstandingActionNameForContactAttempt = null;
    this.showSuccess();
    if (event.displayReplacementItemPopup) {
      this.displayReplacementItemPopup = true;
    }
  }

  closeExpiryDateChangeDialog() {
    this.orderForm.get('renewalInformation').patchValue({
      renewalDate: this.postOrderService.order.renewalInformation.renewalDate
    });
    this.orderForm.get('renewalInformation').get('renewalDate').markAsPristine();
    this.isRenewalInformationChangeDialogOpen = false;
  }

  getThumbsUp(phoneNumber: string): number {
    if (this.contactAttemptForms) {
      const contactAttempt: AbstractControl = this.contactAttemptForms.controls.find(
        (contactAttempt: AbstractControl) => (contactAttempt.value.phoneNumber === phoneNumber)
      );
      if (contactAttempt) {
        return contactAttempt.value.thumbsUp;
      }
    }
    return 0;
  }

  getThumbsDown(phoneNumber: string): number {
    if (this.contactAttemptForms) {
      const contactAttempt: AbstractControl = this.contactAttemptForms.controls.find(
        (contactAttempt: AbstractControl) => (contactAttempt.value.phoneNumber === phoneNumber)
      );
      if (contactAttempt) {
        return contactAttempt.value.thumbsDown;
      }
    }
    return 0;
  }

  getPaymentSuccesses(phoneNumber: string): number {
    if (this.contactAttemptForms) {
      const contactAttempt: AbstractControl = this.contactAttemptForms.controls.find(
        (contactAttempt: AbstractControl) => (contactAttempt.value.phoneNumber === phoneNumber)
      );
      if (contactAttempt) {
        return contactAttempt.value.successfulPayments;
      }
    }
    return 0;
  }

  updateThumbsUpThumbsDownValue(phoneNumber: string, counter: string, counterChange: number) {
    this.confirmationService.confirm({
      key: 'general',
      message: 'Did you mean to update the thumbs up/down or payment success without a contact attempt?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.isThumbsUpDownDisabled = true;
        const params = {
          'orderId': this.orderDbId,
          'number': phoneNumber,
          'counter': counter,
          'counterChange': counterChange,
          'userName': this.userName,
        };
        this.postOrderService.incPendingUpdates();
        this.orderService.updateThumbsUpOrDown(params)
          .subscribe((response: SingleRecordResponse<ContactAttempt>) => {
            this.postOrderService.decPendingUpdates();
            if (response.success) {
              const newContactAttempt: ContactAttempt = response.data;
              const existingIndex: number = this.contactAttemptForms.controls.findIndex(
                (contactAttempt: AbstractControl) => (contactAttempt.value.phoneNumber === phoneNumber)
              );
              if (existingIndex < 0) {
                this.addContactAttemptDB(newContactAttempt);
              } else {
                this.contactAttemptForms.at(existingIndex).setValue(newContactAttempt);
              }
              this.isThumbsUpDownDisabled = false;
            } else {
              this.isThumbsUpDownDisabled = false;
              this.showErrorPopUp('Error', 'Something went Wrong try again');
            }
          }, (_err: Error) => {
            this.postOrderService.decPendingUpdates();
            this.isThumbsUpDownDisabled = false;
            this.showErrorPopUp('Error', 'Something went Wrong try again');
          });
      },
      reject: () => {
      }
    });
  }

  updateOrderOnExpiryDateChange(event: OrderUpdateResponse) {
    this.showSuccess();
    this.preparePageWithOrder(event.order, false).then((): void => {
      this.isRenewalInformationChangeDialogOpen = false;
    });
    // If the user has chosen to display the history refresh them
    if (this.historiesShown) {
      this.loadHistories();
    }
  }

  closeFreeMonthChangeDialog() {
    this.isFreeMonthsChangeDialogOpen = false;
  }

  updateOrderOnFreeMonthsChange(newOrder: Order) {
    this.showSuccess();
    this.preparePageWithOrder(newOrder, false).then((): void => {
      this.isFreeMonthsChangeDialogOpen = false;
    });
    // If the user has chosen to display the history refresh them
    if (this.historiesShown) {
      this.loadHistories();
    }
  }

  // TODO start remove for hardware version
  updateOrderOnRenewalPriceChange(newOrder: Order) {
    this.showSuccess();
    this.preparePageWithOrder(newOrder, false).then((): void => {
      this.isRenewalPriceChangeDialogOpen = false;
    });
    this.orderForm.get('renewalInformation').get('renewalPrice').markAsPristine();
    // If the user has chosen to display the history refresh them
    if (this.historiesShown) {
      this.loadHistories();
    }
  }

  closeRenewalPriceChangeDialog() {
    this.isRenewalPriceChangeDialogOpen = false;
    this.orderForm.get('renewalInformation').patchValue({
      renewalPrice: this.postOrderService.order.renewalInformation.renewalPrice
    });
    this.orderForm.get('renewalInformation').get('renewalPrice').markAsPristine();
  }
  // TODO end remove for hardware version

  // TODO add for hardware version
  /*
  updateOrderOnFrozenPriceChange(newOrder: Order) {
    this.showSuccess();
    this.preparePageWithOrder(event.order, false).then((): void => {
      this.isFrozenPriceChangeDialogOpen = false;
    });
    // If the user has chosen to display the history refresh them
    if (this.historiesShown) {
      this.loadHistories();
    }
    this.orderForm.get('renewalInformation').get('frozenPrice').markAsPristine();
  }

  closeFrozenPriceChangeDialog() {
    this.isFrozenPriceChangeDialogOpen = false;
    this.orderForm.get('renewalInformation').patchValue({
      frozenPrice: this.order.renewalInformation.frozenPrice
    });
    this.orderForm.get('renewalInformation').get('frozenPrice').markAsPristine();
  }
  */

  onPlanTypeChange(): void {
    this.addOrRemoveMissingDatesActionIfNecessary();
    this.sendPlanChangeAutoNotification();
    let additionalMsg: string = '';
    const order: Order = this.orderForm.value;
    if (!doPlanCodeAndTypeMatch(order.accountDetails.plan, order.accountDetails.planType)) {
      additionalMsg = 'The plan code and plan type do not agree with each other, please correct.'
    }
    if (order.accountDetails.planType == 'lifetime') {
      const renewalType: string = this.orderForm.value.renewalInformation.renewalType;
      this.orderForm.get('renewalInformation').patchValue({
        renewalDate: null,
        paymentDueDate: '',
        renewalType: '',
        renewalPrice: '',
      });
      if (renewalType) {
        if (additionalMsg != '') {
          this.showErrorPopUp('Please Correct',
            `${additionalMsg}\nThe customer was set to pay using ${renewalType}. Please make sure the payments are stopped.`);
        } else {
          this.showInfoPopUp(
            'Review Payment',
            `The customer was set to pay using ${renewalType}. Please make sure the payments are stopped.`
          );
        }
      } else if (additionalMsg != '') {
        this.showErrorPopUp('Please Correct', additionalMsg);
      }
    } else {
      let reviewMsg: string = 'Please review expiry date and renewal price as these might need to be changed.';
      if (this.discountForms.controls.length > 0) {
        reviewMsg += '\nPlease also review the discounts and price and adjust them for the new renewal period.';
      }
      if (additionalMsg != '') {
        this.showErrorPopUp('Please Correct', `${additionalMsg}\n${reviewMsg}`);
      } else {
        this.showInfoPopUp('Please Review', reviewMsg);
      }
    }
    this.checkAnnualDiscount();
    this.autosave();
  }

  discountReview(planChanged: boolean): void {
    let additionalMsg: string = '';
    if (planChanged && !this.orderForm.get('accountDetails').get('plan').dirty) {
      // Plan hasn't actually changed (it's on a blur event, not a change event) so exit
      return;
    }
    this.orderForm.get('accountDetails').get('plan').markAsPristine();
    const order: Order = this.orderForm.value;
    if (planChanged) {
      this.orderForm.get('accountDetails').patchValue({
        'plan': this.orderForm.value.accountDetails.plan.toLocaleUpperCase(),
      });
      this.autosave();
      if (!doPlanCodeAndTypeMatch(order.accountDetails.plan, order.accountDetails.planType)) {
        additionalMsg = 'The plan code and plan type do not agree with each other, please correct.'
      }
    }
    if (!doPlanCodeAndVatStatusMatch(order.accountDetails.plan, order.accountDetails.vat)) {
      additionalMsg = 'The plan code and VAT status do not agree with each other, please correct.'
    }
    if (!this.hasDiscountMsgBeenDisplayed && (this.discountForms.controls.length > 0)) {
      if (additionalMsg != '') {
        this.showErrorPopUp('Please Correct',
          `${additionalMsg}\nPlease also review the discounts and price as they may need adjusting.`);
      } else {
        this.showInfoPopUp('Please Review', 'Please review the discounts and price as they may need adjusting.');
      }
      this.hasDiscountMsgBeenDisplayed = true;
    } else if (additionalMsg != '') {
      this.showErrorPopUp('Please Correct', additionalMsg);
    }
  }

  checkStripeCustomerId(): void {
    if (!this.orderForm.get('accountDetails').get('stripeCustomerId').valid) {
      this.showErrorPopUp('Please Correct', 'Stripe customer ids must start cus_ please enter a valid customer id.');
      this.orderForm.get('accountDetails').get('stripeCustomerId').setValue(
        this.postOrderService.order.accountDetails.stripeCustomerId
      );
    } else if (this.orderForm.get('accountDetails').get('stripeCustomerId').dirty) {
      this.autosave('');
    }
    this.orderForm.get('accountDetails').get('stripeCustomerId').markAsPristine();
  }

  checkStripeSubscriptionId(): void {
    if (!this.orderForm.get('accountDetails').get('stripeSubscriptionId').valid) {
      this.showErrorPopUp('Please Correct',
        'Stripe subscription ids must start sub_ please enter a valid subscription id.\n' +
        'Note: ids starting sub_sched_ are schedule ids, not subscription ids and should not be used');
      this.orderForm.get('accountDetails').get('stripeSubscriptionId').setValue(
        this.postOrderService.order.accountDetails.stripeSubscriptionId
      );
    } else if (this.orderForm.get('accountDetails').get('stripeSubscriptionId').dirty) {
      this.autosave('');
    }
    this.orderForm.get('accountDetails').get('stripeSubscriptionId').markAsPristine();
  }

  checkBacsReference(): void {
    if (!this.orderForm.get('referencenumber').valid) {
      this.showErrorPopUp('Please Correct',
        'SmartDebit Bacs Reference must be between 6 and 18 characters and must be upper case letters and numbers only.');
      this.orderForm.get('referencenumber').setValue(
        this.postOrderService.order.referencenumber
      );
    } else if (this.orderForm.get('referencenumber').dirty) {
      this.autosave('');
    }
    this.orderForm.get('referencenumber').markAsPristine();
  }

  checkGoCardlessSubId(): void {
    if (!this.orderForm.get('goCardlessSubId').valid) {
      this.showErrorPopUp('Please Correct',
        'GoCardless subscription ids must start SB please enter a valid subscription id.');
      this.orderForm.get('goCardlessSubId').setValue(
        this.postOrderService.order.goCardlessSubId
      );
    } else if (this.orderForm.get('goCardlessSubId').dirty) {
      this.autosave('');
    }
    this.orderForm.get('goCardlessSubId').markAsPristine();
  }

  sendPlanChangeAutoNotification() {
    const params = {
      brandName: this.orderForm.value.website?.title,
      tdCode: this.orderForm.value.alarmUserDetails?.tdCode,
      oldPlanType: this.postOrderService.order.accountDetails.planType,
      newPlanType:this.orderForm.value.accountDetails.planType,
    }
    this.orderService.sendPlanTypeChangeAutoNotification({
      changedOrders: JSON.stringify([params]),
      userName:this.userName
    }).subscribe((response: any) => {
      console.debug('Response on sending plan change auto-notification', response);
    }, (err: Error) => {
      console.error('error on sending plan change auto-notification', err);
    });
  }

  dropPageSection(event: CdkDragDrop<PageSection[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    localStorage.setItem('orderPageLeftColumn', JSON.stringify(this.leftColumnSections));
    localStorage.setItem('orderPageRightColumn', JSON.stringify(this.rightColumnSections));
  }

  minimizeSection(pageSection: PageSection) {
    pageSection.minimized = !pageSection.minimized;
    localStorage.setItem('orderPageLeftColumn', JSON.stringify(this.leftColumnSections));
    localStorage.setItem('orderPageRightColumn', JSON.stringify(this.rightColumnSections));
  }

  exportOrder() {
    if (!this.excelService.exportAllColumns(
      [this.orderDataToExport],
      orderSpreadsheetCols,
      this.userName
    )) {
      this.messageService.add({
        severity: 'error',
        life: 300000,
        summary: 'Data Truncated',
        detail: 'Some of the data exceeded the maximum excel cell length of 32,767 characters. Affected cells have been truncated and have "Truncated..." at the start',
      });
    }
  }

  changeActionDueDate($event: Event, actionIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    this.outstandingActionsForms.controls[actionIndex].patchValue({
      renewalDateTaken: newDate
    });
    this.autosave('');
  }

  changeActionDate($event: Event, actionIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    this.outstandingActionsForms.controls[actionIndex].patchValue({
      date: newDate
    });
    this.autosave('');
  }

  changePlanAddedDate($event: Event, planIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    this.plansForms.controls[planIndex].patchValue({
      added: newDate
    });
    this.autosave('');
  }

  changeEquipmentAddedDate($event: Event, replacement: boolean, equipIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    if (replacement) {
      this.replacementEquipmentForms.controls[equipIndex].patchValue({
        added: newDate
      });
    } else {
      this.additionalEquipmentForms.controls[equipIndex].patchValue({
        added: newDate
      });
    }
    this.autosave('');
  }

  changeServiceAddedDate($event: Event, serviceIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    this.serviceForms.controls[serviceIndex].patchValue({
      added: newDate
    });
    this.autosave('');
  }

  changeKeysafeAddedDate($event: Event, keysafeIndex: number) {
    // datetime-local excludes timezone, so do this to get timezone included
    const newDate: Date = moment(($event.target as HTMLInputElement).value).toDate();
    this.keySafesForms.controls[keysafeIndex].patchValue({
      added: newDate
    });
    this.autosave('');
  }

  primaryContactChange(event: Event, contactType: string, contactId: number) {
    // If a contact is turned on as primary we have to turn off all the rest
    if ((event.target as HTMLInputElement).checked) {
      if (contactType === 'AlarmUser') {
        this.accountContactsForms.controls.forEach((control: AbstractControl) => {
          control.patchValue({
            'primaryContact': false,
          });
        });
        if (contactId === -1) {
          this.usersForms.controls.forEach((control: AbstractControl) => {
            control.patchValue({
              'primaryContact': false,
            });
          });
        } else {
          this.orderForm.get('alarmUserDetails').patchValue({
            'primaryContact': false,
          });
          this.usersForms.controls.forEach((control: AbstractControl, index: number) => {
            if (index !== contactId) {
              control.patchValue({
                'primaryContact': false,
              });
            }
          });
        }
      } else {
        this.orderForm.get('alarmUserDetails').patchValue({
          'primaryContact': false,
        });
        this.usersForms.controls.forEach((control: AbstractControl) => {
          control.patchValue({
            'primaryContact': false,
          });
        });
        this.accountContactsForms.controls.forEach((control: AbstractControl, index: number) => {
          if (index !== contactId) {
            control.patchValue({
              'primaryContact': false,
            });
          }
        });
      }
    }
    this.autosave('');
  }

  saveLayout() {
    this.postOrderService.incPendingUpdates();
    this.orderService.savePageLayout({
      'email': localStorage.getItem('email'),
      'orderPageLeftColumn': this.leftColumnSections,
      'orderPageRightColumn': this.rightColumnSections,
      'userPreferences': getUserPreferences(),
    }).subscribe((response: SimpleResponse) => {
      this.postOrderService.decPendingUpdates();
      if (response.success) {
        this.showSuccess('Layout saved', 'Your layout has been saved');
      } else {
        this.showWarnUpdate('Save Failed', 'Failed to save page layout, please try again.');
      }
    }, (_err: Error) => {
      this.postOrderService.decPendingUpdates();
      this.showWarnUpdate('Save Failed', 'Failed to save page layout, please try again.');
    });
  }

  enableNoteEditing(noteUserName: string, noteIndex: number) {
    if (this.userName === noteUserName) {
      this.isNoteEditing[noteIndex] = true;
    }
  }

  saveNote(noteIndex: number) {
    const noteCurrentContent: string = this.notesForms.at(noteIndex).value.content;
    const noteOldContent: string = this.postOrderService.order.notes[noteIndex] ? this.postOrderService.order.notes[noteIndex].content: '';
    const noteCurrentCategories: string = this.notesForms.at(noteIndex).value.categories.join('\n');
    const noteOldCategories: string = this.postOrderService.order.notes[noteIndex].categories.join('\n');
    // Only mark as edited and save if the content has changed
    if ((noteCurrentContent && (noteCurrentContent.trim() !== noteOldContent.trim()))
        || (noteCurrentCategories !== noteOldCategories)) {
      this.notesForms.at(noteIndex).patchValue({
        updatedAt: moment.tz('Europe/London').toDate()
      });
      if (noteCurrentContent.trim() !== noteOldContent.trim()) {
        this.notesForms.at(noteIndex).patchValue({
          previousContent: noteOldContent
        });
      }
      this.addComplaintTagIfNeeded(this.notesForms.at(noteIndex).value.categories);
      this.autosave();
    }
    this.isNoteEditing[noteIndex] = false;
  }

  undoNote(noteIndex: number) {
    this.notesForms.at(noteIndex).patchValue({
      content: this.postOrderService.order.notes[noteIndex].content
    });
    this.isNoteEditing[noteIndex] = false;
  }

  // TODO remove for hardware version
  openAddFaultDialog(serialNum: string, equipName: string, equipStatus: string, isPendant: boolean) {
    this.equipDetailsForAddFault = {
      'serialNumber': serialNum,
      'name': equipName,
      'status': equipStatus,
      'isPendant': isPendant,
    };
    this.isAddFaultDialogOpen = true;
  }
  // TODO add for hardware version
  /*
  openAddFaultDialog(serialNum: string, equipName: string, equipStatus: string) {
    this.equipDetailsForAddFault = {
      'serialNumber': serialNum,
      'name': equipName,
      'status': equipStatus,
    };
    this.isAddFaultDialogOpen = true;
  }
  */

  closeAddFaultDialog(event) {
    if (event.status == 'success') {
      const newNote: FormGroup = this.fb.group({
        content: event.noteText,
        previousContent: null,
        updatedAt: null,
        userName: this.userName,
        date: moment.tz('Europe/London').toDate(),
        categories: [['Technical']],
      });
      this.notesForms.push(newNote);
      this.autosave('');
      this.showSuccess();
    } else if (event.status == 'failed') {
      this.showErrorPopUp('Error Adding Fault', 'Error adding fault please try again');
    }
    this.isAddFaultDialogOpen = false;
    this.equipDetailsForAddFault = null;
  }

  closeReplacementItemPopup() {
    this.displayReplacementItemPopup = false;
  }

  showConfirmationPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisible: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      'key': 'general',
      'header': header,
      'message': message,
      'rejectVisible': isRejectLabelVisible,
      'acceptLabel': isRejectLabelVisible? 'Yes': 'OK',
      'rejectLabel': 'No',
      'accept': () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      'reject': () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }

  showMessageReport() {
    this.isMessageReportOpen = true;
  }

  closeMessageReport() {
    this.isMessageReportOpen = false;
  }

  tooltipByLabel(labelName: string): string {
    return localStorage.getItem(labelName) || '';
  }

  globalChangeLogFilter($event: Event, filterType: string): void {
    this.changeLogTable.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  getEquipmentField(equipFormGroup: AbstractControl, fieldName: string): string {
    return (equipFormGroup as FormGroup).controls[fieldName].value;
  }

  // TODO add for hardware version
  /*
  updateFrozenPriceRequirement(): void {
    if (this.isPriceFrozen) {
      this.orderForm.get('renewalInformation').get('frozenPrice').setValidators(Validators.required);
    } else {
      this.orderForm.get('renewalInformation').get('frozenPrice').clearValidators();
    }
    this.orderForm.get('renewalInformation').get('frozenPrice').updateValueAndValidity();
  }
  */

  showAddPost(): void {
    this.showPostSheetAdd = true;
  }

  closeAddPostSheet(event: PostSheet|string|undefined): void {
    if (typeof event == 'string') {
      this.showErrorPopUp('Error adding Post Sheet entry.', `Error saving new post sheet entry. Error ${event}`);
    } else if ((event != null) && (typeof event == 'object')) {
      const noteContent: string = `${this.userName} created a post sheet entry for ${event.whatToSend}.`;
      this.addAutomatedNote(noteContent, ['Post/Dispatch/Return'], true);
    }
    this.showPostSheetAdd = false;
  }

  openRefundRequestDialog(): void {
    this.showRefundRequestDialog = true;
  }

  closeShowRefundRequestDialog(event: string): void {
    // Add a note, if note text returned.
    if (event) {
      this.addAutomatedNote(event, ['Credit control'], true);
    }
    this.showRefundRequestDialog = false;
  }

  addNoteFromSection(params: AddNoteParams) {
    this.postOrderService.incPendingUpdates();
    // Do this via the queue to stop to saves in close sucession that might happen out of order
    this.proposedMessageService.addEntryToNoteQueue({
      mongoOrderId: this.postOrderService.order._id,
      brand: this.postOrderService.order.website.title,
      tdCode: this.postOrderService.order.alarmUserDetails.tdCode,
      note: params.content,
      username: this.userName,
      categories: params.categories
    }).subscribe((_response: SimpleResponse) => {
      this.postOrderService.decPendingUpdates();
    }, (err: Error) => {
      console.error("Error on adding Note From Section: ", err);
      this.postOrderService.decPendingUpdates();
    });
  }

  addAutomatedNote(content: string, categories: string[], saveOrder: boolean): void {
    const newNote: FormGroup = this.fb.group({
      content: content,
      previousContent: null,
      updatedAt: null,
      userName: this.userName,
      date: moment.tz('Europe/London').toDate(),
      categories: [categories],
    });
    this.notesForms.push(newNote);
    this.addComplaintTagIfNeeded(categories);
    if (saveOrder) {
      this.autosave('');
    }
  }

  contactDetailsChanged(fieldname: string, oldValue: string, newValue: string): void {
    this.contactDetailChanges.push(
      `${fieldname} changed from "${oldValue}" to "${newValue}"`
    );
  }

  sendContactDetailsChangedEmail(): void {
    if (!this.allowContactChangesEmail || (this.contactDetailChanges.length == 0)) {
      return;
    }
    this.postOrderService.incPendingUpdates();
    const emailType: string = 'Name or Contact Details Changed';
    const emailParams: SendEmailRequest =
      this.notificationService.getArcContactChangesEmailParams(emailType, this.postOrderService.order, this.userName, this.contactDetailChanges);
    this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
      this.postOrderService.decPendingUpdates();
      let noteContent: string;
      if (response.success) {
        if (response.data.length == 0) {
          this.showSuccess('Email Sent', 'Email successfully sent to ARC');
          noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          this.contactDetailChanges = [];
        } else {
          const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
            !response.data.includes(recipient)
          );
          // Sent successfully to some
          if (recipients.length > 0) {
            this.showErrorPopUp('Error sending email',
              `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
              `to notify them of the changes because ${response.message}. Please send the email yourself`);
            noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
              `but the email failed to send to ${response.data.join(';')}.`;
          } else {
            this.showErrorPopUp('Error sending email',
              `Email could not be sent to ${response.data.join(';')} to notify them of the changes. Please send the email yourself`);
            noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
          }
        }
      } else {
        this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the changes. ` +
          `Error: ${response.message} Please send the email yourself`);
        noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      }
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    }, (error: any) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the changes. ` +
          `Error: ${error.message} Please send the email yourself`);
      const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    });
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.noteForm.patchValue({
        content: event.value.value,
        categories: event.value.noteCategories,
      });
    }
  }

  sendOrderCancelledEmail(): void {
    this.postOrderService.incPendingUpdates();
    const emailType: string = 'Order Cancelled';
    const emailParams: SendEmailRequest = this.notificationService.getArcCancellationEmailParams(emailType, this.postOrderService.order, this.userName);
    this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
      this.postOrderService.decPendingUpdates();
      let noteContent: string;
      if (response.success) {
        if (response.data.length == 0) {
          this.showSuccess('Email Sent', 'Email successfully sent to ARC');
          noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
        } else {
          const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
            !response.data.includes(recipient)
          );
          // Sent successfully to some
          if (recipients.length > 0) {
            this.showErrorPopUp('Error sending email',
              `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
              `to notify them of the cancellation because ${response.message}. Please send the email yourself`);
            noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
              `but the email failed to send to ${response.data.join(';')}.`;
          } else {
            this.showErrorPopUp('Error sending email',
              `Email could not be sent to ${response.data.join(';')} to notify them of the cancellation. Please send the email yourself`);
            noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
          }
        }
      } else {
        this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
          `Error: ${response.message} Please send the email yourself`);
        noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      }
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    }, (error: any) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
          `Error: ${error.message} Please send the email yourself`);
      const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    });
  }

  sendOrderActivatedEmail(): void {
    this.postOrderService.incPendingUpdates();
    const emailType: string = 'Order Reactivated';
    const emailParams: SendEmailRequest = this.notificationService.getArcCancellationEmailParams(emailType, this.postOrderService.order, this.userName);
    this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
      this.postOrderService.decPendingUpdates();
      let noteContent: string;
      if (response.success) {
        if (response.data.length == 0) {
          this.showSuccess('Email Sent', 'Email successfully sent to ARC');
          noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
        } else {
          const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
            !response.data.includes(recipient)
          );
          // Sent successfully to some
          if (recipients.length > 0) {
            this.showErrorPopUp('Error sending email',
              `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
              `to notify them of the order reactivation because ${response.message}. Please send the email yourself`);
            noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
              `but the email failed to send to ${response.data.join(';')}.`;
          } else {
            this.showErrorPopUp('Error sending email',
              `Email could not be sent to ${response.data.join(';')} to notify them of the order reactivation. Please send the email yourself`);
            noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
          }
        }
      } else {
        this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the order reactivation. ` +
          `Error: ${response.message} Please send the email yourself`);
        noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      }
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    }, (error: any) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} to notify them of the order reactivation. ` +
          `Error: ${error.message} Please send the email yourself`);
      const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
      this.addNoteFromSection({
        content: noteContent,
        categories: ['ARC']
      });
    });
  }

  changeActionReason(event: DropDownChangeEvent<string>, actionIndex: number): void {
    const reason: string = event.value;
    // If we've changed the reason, clear the other field as either it isn't relevant
    // or there shouldn't have been a value there in the first place
    this.outstandingActionsForms.at(actionIndex).patchValue({
      reason: reason,
      reasonOther: '',
    });
    this.autosave('');
  }

  getTagIndex(tagName: string): number {
    const tagIndex: number = (this.orderForm.value.tags as OrderFormTag[]).findIndex((tag: OrderFormTag) =>
      tag.content == tagName
    );
    return tagIndex;
  }

  getActionIndex(actionName: string): number {
    const actionIndex: number =  (this.outstandingActionsForms.value as OutstandingAction[]).findIndex((action: OutstandingAction) => 
      action.outstandingName == actionName
    );
    return actionIndex;
  }

  getBillingAccountName(): string {
    const tag: OrderFormTag = (this.orderForm.value.tags as OrderFormTag[]).find((tag: OrderFormTag) =>
      PAYMENT_TAG_REGEX.test(tag.content)
    );
    return tag? tag.content.replace(PAYMENT_TAG_REGEX, '$1'): '';
  }

  openPriceBook(): void {
    this.isPriceBookOpen = true;
  }

  closePriceBook(): void {
    this.isPriceBookOpen = false;
  }

  emailNote(noteIndex: number) {
    this.noteToEmailContent = this.notesForms.at(noteIndex).get('content').value;
    this.isEmailNoteOpen = true;
  }
  
  closeEmailNote(): void {
    this.isEmailNoteOpen = false;
  }

  sendOnHoldEmail(): void {
    this.sendNotificationEmail('email: Admin', 'put on hold',
      'Please be advised that the following order has had the "On Hold" action added.',
      'to notify them of the "On Hold" action being added');
  }

  sendNotificationEmail(emailGroup: string, subjectText: string, notificationText: string, errorText: string): void {
    this.postOrderService.incPendingUpdates();
    if (!localStorage.getItem(emailGroup)) {
      return;
    }
    const customerName: string = `${this.postOrderService.order.alarmUserDetails.firstName} ${this.postOrderService.order.alarmUserDetails.lastName}`;
    const tdCode: string = this.postOrderService.order.alarmUserDetails.tdCode;
    const emailParams: SendEmailRequest = {
      'recipients': JSON.parse(localStorage.getItem(emailGroup)),
      'subject': `Account ${tdCode} ${customerName} ${subjectText}`,
      'plainTextMsg':
        `Hi Team,\n${notificationText}\n` +
        `Customer Name: ${customerName}\nCustomer Order (TD): ${tdCode} ${this.orderLink}\n` +
        `Thanks,\n${this.userName}`,
      'htmlMsg':
        `<p>Hi Team,</p><p>${notificationText.replace(/\n/g, '<br/>')}</p>` +
        `<p>Customer Name: ${customerName}</p><p>Customer Order (TD): <a href="${this.orderLink}" target="_blank">${tdCode}</a></p>` +
        `<p>Thanks,<br/>${this.userName}</p>`,
    };
    this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
      this.postOrderService.decPendingUpdates();
      if (response.success) {
        if (response.data.length == 0) {
          this.showSuccess('Email Sent', `Email successfully sent to group ${emailGroup}`);
        } else {
          const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
            !response.data.includes(recipient)
          );
          // Sent successfully to some
          if (recipients.length > 0) {
            this.showErrorPopUp('Error sending email',
              `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
              `${errorText} because ${response.message}. Please send the email yourself`);
          } else {
            this.showErrorPopUp('Error sending email',
              `Email could not be sent to ${response.data.join(';')} ${errorText}. Please send the email yourself`);
          }
        }
      } else {
        this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} ${errorText}. ` +
          `Error: ${response.message} Please send the email yourself`);
      }
    }, (error: any) => {
      this.postOrderService.decPendingUpdates();
      this.showErrorPopUp('Error sending email',
          `Error sending email to ${emailParams.recipients.join(';')} ${errorText}. ` +
          `Error: ${error.message} Please send the email yourself`);
    });
  }

  openRetentionDialog(): void {
    this.isRetentionDialogOpen = true;
  }

  closeRetentionDialog(event: RetentionDialogReturn|undefined): void{
    this.isRetentionDialogOpen = false;
    if (event) {
      this.removeActionsByNames(['Retention']);
      if (event.reason == 'Keeping until expiry') {
        this.addTagByTagName('Keeping until expiry');
      }
      this.addTagByTagName('Retained Customer');
      this.addAutomatedNote(event.noteText, ['Retention'], true);
      this.retentionRecorded = true;
    }
  }

  removeActionsByNames(actionNames: string[]): void {
    for (let i: number = this.outstandingActionsForms.value.length - 1; i >= 0; i--) {
      if (actionNames.includes(this.outstandingActionsForms.value[i].outstandingName)) {
        this.outstandingActionsForms.removeAt(i);
      }
    }
  }

  openManual(serial: string) {
    const manualFilename: string = getEquipmentManualFilename(serial);
    window.open(`/crm-manuals/${manualFilename}`,'_blank');
  }
  
  openPaymentDataDialog(): void {
    this.showPaymentDataDialog = true;
  }

  closePaymentDataDialog(): void {
    this.showPaymentDataDialog = false;
  }

  copyToClipboard(textToCopy: string): void {
    this.clipboard.copy(textToCopy);
  }

  copyName(): void {
    this.copyToClipboard(`${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`);
  }

  copyTdCodeAndName(): void {
    this.copyToClipboard(`${this.order.alarmUserDetails.tdCode} - ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`);
  }

  copyAlarmCodeAndName(): void {
    this.copyToClipboard(`${this.alarmCode} - ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`);
  }

  

  validateBestAddressAndCopyToClipboard(): void {
    const bestAddress: UserWithAddress = getBestAddress(this.alarmUserDetails.value);
    if (bestAddress.userAddress.role == 'Correspondence') {
      this.allowCorrespondenceAddressManualEntry = false;
    } else {
      this.allowAlarmUserAddressManualEntry = false;
    }
    this.clipboard.copy(getUserAddress(bestAddress));
    if (bestAddress.userAddress.validated) {
      return;
    }
    validateAddress(this.getAddrClient, bestAddress.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          if (bestAddress.userAddress.role == 'Correspondence') {
            this.correspondenceAddressFormGroup.patchValue({
              'validated': true,
            });
          } else {
            this.userAddressFormGroup.patchValue({
              'validated': true,
            });
          }
          this.autosave('');
          return;
        }
        if (bestAddress.userAddress.role == 'Correspondence') {
          this.correspondenceSearchPostCode = bestAddress.userAddress? bestAddress.userAddress.postcode: '';
          this.correspondenceSearchError = addressValResponse.message;
        } else {
          this.alarmUserSearchPostCode = bestAddress.userAddress? bestAddress.userAddress.postcode: '';
          this.alarmUserSearchError = addressValResponse.message;        
        }
        if (!addressValResponse.data) {
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Error Validating ${bestAddress.userAddress.role} Address`,
            'message': `Error trying to validate the existing ${bestAddress.userAddress.role} address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-exclamation-triangle',
          });
        } else {
          if (bestAddress.userAddress.role == 'Correspondence') {
            this.correspondenceAddressResults = addressValResponse.data;
          } else {
            this.alarmUserAddressResults = addressValResponse.data;
          }
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Possible Invalid ${bestAddress.userAddress.role} Address`,
            'message': `The existing ${bestAddress.userAddress.role} address could not be validated please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-info-circle',
          });
        }
      }
    );
  }

  validateUserAddressAndCopyToClipboard(): void {
    const userWithAddress: UserWithAddress = this.alarmUserDetails.value;
    this.allowAlarmUserAddressManualEntry = false;
    this.clipboard.copy(getUserAddress(userWithAddress));
    if (userWithAddress.userAddress.validated) {
      return;
    }
    validateAddress(this.getAddrClient, userWithAddress.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.userAddressFormGroup.patchValue({
            'validated': true,
          });
          this.autosave('');
          return;
        }
        this.alarmUserSearchPostCode = userWithAddress.userAddress? userWithAddress.userAddress.postcode: '';
        this.alarmUserSearchError = addressValResponse.message;        
        if (!addressValResponse.data) {
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Error Validating Alarm User Address`,
            'message': `Error trying to validate the existing Alarm User address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-exclamation-triangle',
          });
        } else {
          this.alarmUserAddressResults = addressValResponse.data;
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Possible Invalid Alarm User Address`,
            'message': `The existing Alarm User address could not be validated please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-info-circle',
          });
        }
      }
    );
  }

  validateCorrespondenceAddressAndCopyToClipboard(): void {
    const userWithAddress: UserWithAddress = getCorrespondenceAsUserWithAddress(this.alarmUserDetails.value);
    this.allowCorrespondenceAddressManualEntry = false;
    this.clipboard.copy(getUserAddress(userWithAddress));
    if (userWithAddress.userAddress.validated) {
      return;
    }
    validateAddress(this.getAddrClient, userWithAddress.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.correspondenceAddressFormGroup.patchValue({
            'validated': true,
          });
          this.autosave('');
          return;
        }
        this.correspondenceSearchPostCode = userWithAddress.userAddress? userWithAddress.userAddress.postcode: '';
        this.correspondenceSearchError = addressValResponse.message;        
        if (!addressValResponse.data) {
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Error Validating Correspondence Address`,
            'message': `Error trying to validate the existing Correspondence address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-exclamation-triangle',
          });
        } else {
          this.correspondenceAddressResults = addressValResponse.data;
          this.confirmationService.confirm({
            'key': 'general',
            'header': `Possible Invalid Correspondence Address`,
            'message': `The existing Correspondence address could not be validated please check it to make sure it is correct. Reason: ${addressValResponse.message}`,
            'rejectVisible': false,
            'acceptLabel': 'OK',
            'icon': 'pi pi-info-circle',
          });
        }
      }
    );
  }

  updateAlarmUserMarketingUpdated(fieldName: string): void {
    this.alarmUserDetails.get(fieldName).setValue(new Date());
    this.autosave('');   
  }

  updateCorrespondenceMarketingUpdated(fieldName: string): void {
    this.correspondenceAddressFormGroup.get(fieldName).setValue(new Date());
    this.autosave('');
  }

  updateUserMarketingUpdated(userIdx: number, fieldName: string): void {
    this.usersForms.at(userIdx).get(fieldName).setValue(new Date());
    this.autosave('');
  }
  
  updateContactMarketingUpdated(contactIdx: number, fieldName: string): void {
    this.accountContactsForms.at(contactIdx).get(fieldName).setValue(new Date());
    this.autosave('');
  }

  openAddNoteDialog(noteContent: string) {
    this.addNoteContent = noteContent;
  }
  
  closeAddNoteDialog(addNoteParams: AddNoteParams): void {
    if (addNoteParams != null) {
      this.addAutomatedNote(addNoteParams.content, addNoteParams.categories, true);
    }
    this.addNoteContent = '';
  }
 
  smartDebitServiceUserChange(event: Event) {
    if ((event.target as HTMLSelectElement).selectedOptions.length > 0) {
      if ((event.target as HTMLSelectElement).selectedOptions[0].innerText.trim() != this.correctSdAccount) {
        this.showErrorPopUp('Incorrect SmartDebit Service User',
          `The SmartDebit Service User should be "${this.correctSdAccount}" if the DD has been set up against the wrong one, please correct it, if able.`);
      }
    }
  }

  get alarmCode(): string {
    if (!this.postOrderService.order || !this.postOrderService.order.jontekCodes ||
      (this.postOrderService.order.jontekCodes.length == 0)) {
        return '';
    }
    return this.postOrderService.order.jontekCodes[this.postOrderService.order.jontekCodes.length - 1];
  }
}