<p-dialog (onHide)="closeDialog()" header="Request Refund" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100" 
    (onShow)="removeScrollBlock()">
  <div id="refundDialogBody">
    <form [formGroup]="refundForm">
      <div class="row">
        <div *ngIf="resultMessage" class="col-12 form-group">
          <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
            {{resultMessage}}
          </div>
        </div>
        <div class="col-9 form-group">
          <label for="refundReason">Refund Reason:</label>
          <input type="text" id="refundReason" name="refundReason" formControlName="refundReason" [required]="true">
        </div>
        <div class="col-3 form-group">
          <label for="refundAmount">Refund Amount:</label>
          <input type="number" id="refundAmount" name="refundAmount" formControlName="refundAmount"
            step="0.01" (keypress)="numberOnly($event, true)" [required]="true">
        </div>
        <div class="col-9 form-group">
          <label for="refundMethod">Refund Method:</label>
          <p-dropdown [options]="refundMethods" name="refundMethod" id="refundMethod" formControlName="refundMethod" 
            placeholder="Select how the money should be refunded" [required]="true" appendTo="body" [baseZIndex]="3200"
            (onChange)="changeRefundMethod($event.value)">
          </p-dropdown>
        </div>
        <div class="col-3 form-group">
          <label>Refund Due:</label>
          <div class="form-check form-check-inline" [ngClass]="refundDue.errors?.required? 'valueRequired': 'valueProvided'">
            <input class="form-check-input" type="radio" id="refundDueYes" [value]="true" formControlName="refundDue">
            <label class="form-check-label" for="refundDueYes">
              Yes
            </label>
            <input class="form-check-input" type="radio" id="refundDueNo" [value]="false" formControlName="refundDue">
            <label class="form-check-label" for="refundDueNo">
              No
            </label>
          </div>
        </div>
        <ng-container *ngIf="showAccountInputs">
          <div class="col-9 form-group">
            <label for="accountName">Account Name:</label>
            <input type="text" id="accountName" name="accountName" formControlName="accountName" class="validated">
          </div>
          <div class="col-9 form-group">
            <label for="sortCode">Sort Code:</label>
            <input type="text" id="sortCode" name="sortCode" formControlName="sortCode" class="validated">
          </div>
          <div class="col-9 form-group">
            <label for="accountNo">Account Number:</label>
            <input type="text" id="accountNo" name="accountNo" formControlName="accountNo" class="validated">
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-12 form-group text-right">
          <button type="button" class="btn btn-primary" (click)="sendRefundRequest()" [disabled]="!isOkToSend()">
            Send Request
          </button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>