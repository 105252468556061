import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from 'rxjs';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {SpreadsheetImportRequest} from '../models/requests/spreadsheetImportRequest.model';

@Injectable({
  providedIn: 'root'
})

export class SpreadsheetImportService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) { }

  importSpreadsheet(importParams: SpreadsheetImportRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/spreadsheetImport`, importParams);
  }
}
