import {Component, Host, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {renewalTypeDisplayLookup} from '../../../lookups/renewalTypes';
import {getStatusBackgroundColour, getStatusColour} from '../../../lookups/statuses';
import {Order} from '../../../models/order.model';
import {ProposedMessage} from '../../../models/proposedMessage.model';

@Component({
  selector: 'app-renewal-comms[message][order][isSmallerThanLarge]',
  templateUrl: './renewal-comms.component.html',
  styleUrls: ['./renewal-comms.component.scss']
})
export class RenewalCommsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    @Host() private parentFormDirective: FormGroupDirective,
  ) { }
  @Input() message: ProposedMessage;
  @Input() order: Order;
  @Input() isSmallerThanLarge: boolean;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  renewalTypeDisplayLookup: {[lookupName: string]: string} = renewalTypeDisplayLookup;

  ngOnInit() {
    // Need to escape the . in the url (and just to be safe anything else with special meaning in regex)
    const escapedDomain: string = this.order.website.domain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const startsWithDomain: RegExp = new RegExp('^' + escapedDomain + '.*$');
    this.messageForm.addControl('renewal',
      this.formBuilder.group({
        'renewalLink': [this.message.renewalData.renewalLink, [Validators.pattern(startsWithDomain)]],
        'showDdOffer': [this.message.renewalData.showDdOffer],
      })
    );
  }

  get messageForm(): FormGroup {
    return this.parentFormDirective.form;
  }
}
