import {Component, Host, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {getStatusBackgroundColour, getStatusColour} from '../../../lookups/statuses';
import {Order} from '../../../models/order.model';
import {ProposedMessage} from '../../../models/proposedMessage.model';

@Component({
  selector: 'app-cancellation-comms[message][order][isSmallerThanLarge]',
  templateUrl: './cancellation-comms.component.html',
  styleUrls: ['./cancellation-comms.component.scss']
})
export class CancellationCommsComponent implements OnInit {

  constructor(
    @Host() private parentFormDirective: FormGroupDirective,
  ) { }
  @Input() message: ProposedMessage;
  @Input() order: Order;
  @Input() isSmallerThanLarge: boolean;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;

  ngOnInit() {
    // Need to escape the . in the url (and just to be safe anything else with special meaning in regex)
    const escapedDomain: string = this.order.website.domain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const startsWithDomain: RegExp = new RegExp('^' + escapedDomain + '.*$');
    this.messageForm.addControl('link',
      new FormControl(this.message.cancellationInfo.link, [Validators.pattern(startsWithDomain)]));
  }

  get messageForm(): FormGroup {
    return this.parentFormDirective.form;
  }
}
