import * as moment from 'moment-timezone';
import {Injectable, OnDestroy, EventEmitter} from '@angular/core';
import {AccountContact, AccountDetails, AdditionalOrReplacementEquipment, AlarmUserDetails, CancellationDetails, ContactAttempt, 
  InitialOrderDetails, InitialOrderDispatchInformation, KeySafe, Order, OrderItem, OrderNote, OrderRoot, OrderStatus, OrderTag,
  Plan, RenewalInformation, Vim} from '../models/order.model';
import {OutstandingAction} from '../models/outstandingAction.model';
import {RenewalDiscount} from '../models/renewalDiscount.model';
import {SendEmailRequest} from '../models/requests/sendEmailRequest.model';
import {NotificationService} from '../notifications/notification.service';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {environment} from '../../environments/environment';

@Injectable()
export class PostOrderService implements OnDestroy {
  private pendingUpdates: number;
  order: Order;
  jontekCodesStringOld: string;
  orderRootSubject: EventEmitter<OrderRoot>;
  alarmUserDetailsSubject: EventEmitter<AlarmUserDetails>;
  jontekCodesSubject: EventEmitter<string[]>;
  accountContactsSubject: EventEmitter<AccountContact[]>;
  accountDetailsSubject: EventEmitter<AccountDetails>;
  plansSubject: EventEmitter<Plan[]>;
  keySafesSubject: EventEmitter<KeySafe[]>;
  additionalEquipmentSubject: EventEmitter<AdditionalOrReplacementEquipment[]>;
  replacementEquipmentSubject: EventEmitter<AdditionalOrReplacementEquipment[]>;
  renewalInformationSubject: EventEmitter<RenewalInformation>;
  renewalDiscountsSubject: EventEmitter<RenewalDiscount[]>;
  initialOrderDetailsSubject: EventEmitter<InitialOrderDetails>;
  initialOrderDispatchInformationSubject: EventEmitter<InitialOrderDispatchInformation>;
  vimSubject: EventEmitter<Vim[]>;
  orderStatusSubject: EventEmitter<OrderStatus>;
  outstandingActionsSubject: EventEmitter<OutstandingAction[]>;
  cancellationDetailsSubject: EventEmitter<CancellationDetails>;
  notesSubject: EventEmitter<OrderNote[]>;
  tagsSubject: EventEmitter<OrderTag[]>;
  itemsSubject: EventEmitter<OrderItem[]>;
  contactAttemptsSubject: EventEmitter<ContactAttempt[]>;

  /*
  private alarmUserDetailsForm: FormGroup;
  private accountContactsForms: FormArray;
  private accountDetailsForm: FormGroup;
  private planForms: FormArray;
  private additionalEquipmentForms: FormArray;
  private replacementEquipmentForms: FormArray;
  private keySafeForms: FormArray;
  private renewalInformationForm: FormGroup;
  private renewalDiscountForms: FormArray;
  // Can't change so does it need a form?
  // private initialOrderDetailsForm: FormGroup;
  private initialOrderDispatchInformationForm: FormGroup;
  private orderStatusForm: FormGroup;
  private outstandingActionForms: FormArray;
  private cancellationForm: FormGroup;
  private noteForms: FormArray;
  private tagForms: FormArray;
  // Can't change so does it need a form?
  // private reviewForms: FormArray;
  // Can't change so does it need a form?
  // private jontekContactForms: FormArray;
  // Can't change through here so does it need a form?
  // private customerFeedbackForms: FormArray;
  */

  // NOTE ngOnInit is not called for Services, so have to put this in the constructor
  constructor(
    /* private orderService: OrderService,
    private logsService: LogsService, */
    private notificationService: NotificationService,
  ) { 
    this.jontekCodesStringOld = '';
    this.pendingUpdates = 0;
    this.orderRootSubject = new EventEmitter<OrderRoot>();
    this.alarmUserDetailsSubject = new EventEmitter<AlarmUserDetails>();
    this.jontekCodesSubject = new EventEmitter<string[]>();
    this.accountContactsSubject = new EventEmitter<AccountContact[]>();
    this.accountDetailsSubject = new EventEmitter<AccountDetails>();
    this.plansSubject = new EventEmitter<Plan[]>();
    this.keySafesSubject = new EventEmitter<KeySafe[]>();
    this.additionalEquipmentSubject = new EventEmitter<AdditionalOrReplacementEquipment[]>();
    this.replacementEquipmentSubject = new EventEmitter<AdditionalOrReplacementEquipment[]>();
    this.renewalInformationSubject = new EventEmitter<RenewalInformation>();
    this.renewalDiscountsSubject = new EventEmitter<RenewalDiscount[]>();
    this.initialOrderDetailsSubject = new EventEmitter<InitialOrderDetails>();
    this.initialOrderDispatchInformationSubject = new EventEmitter<InitialOrderDispatchInformation>();
    this.vimSubject = new EventEmitter<Vim[]>();
    this.orderStatusSubject = new EventEmitter<OrderStatus>();
    this.outstandingActionsSubject = new EventEmitter<OutstandingAction[]>();
    this.cancellationDetailsSubject = new EventEmitter<CancellationDetails>();
    this.notesSubject = new EventEmitter<OrderNote[]>();
    this.tagsSubject = new EventEmitter<OrderTag[]>();
    this.itemsSubject = new EventEmitter<OrderItem[]>();
    this.contactAttemptsSubject = new EventEmitter<ContactAttempt[]>();
  }

  ngOnDestroy(): void {
    this.orderRootSubject.complete();
    this.alarmUserDetailsSubject.complete();
    this.jontekCodesSubject.complete();
    this.accountContactsSubject.complete();
    this.accountDetailsSubject.complete();
    this.plansSubject.complete();
    this.keySafesSubject.complete();
    this.additionalEquipmentSubject.complete();
    this.replacementEquipmentSubject.complete();
    this.renewalInformationSubject.complete();
    this.renewalDiscountsSubject.complete();
    this.initialOrderDetailsSubject.complete();
    this.initialOrderDispatchInformationSubject.complete();
    this.vimSubject.complete();
    this.orderStatusSubject.complete();
    this.outstandingActionsSubject.complete();
    this.cancellationDetailsSubject.complete();
    this.notesSubject.complete();
    this.tagsSubject.complete();
    this.itemsSubject.complete();
    this.contactAttemptsSubject.complete();
  }

  incPendingUpdates(): void {
    this.pendingUpdates++;
  }

  decPendingUpdates(): void {
    if (this.pendingUpdates > 0) {
      this.pendingUpdates--;
    }
  }

  get pendingUpdateCount(): number {
    return this.pendingUpdates;
  }

  checkForJontekCodesUpdate(): void {
    // This section is a simple check to see if anything has changed and can save calls if not
    const jontekCodesString: string = this.order.jontekCodes.join('\n').trim();
    if (this.order.jontekCodes && (this.jontekCodesStringOld != jontekCodesString)) {
      this.jontekCodesStringOld = jontekCodesString;
      this.jontekCodesSubject.emit(this.order.jontekCodes);
    }
  }

  prepareComponentsWithOrder(updatedOrder: Order): void {
    this.order = updatedOrder;
    this.prepareDates();
    this.orderRootSubject.emit(this.order);
    this.alarmUserDetailsSubject.emit(this.order.alarmUserDetails);
    this.checkForJontekCodesUpdate();
    this.accountContactsSubject.emit(this.order.accountContacts);
    this.accountDetailsSubject.emit(this.order.accountDetails);
    this.plansSubject.emit(this.order.plans);
    this.keySafesSubject.emit(this.order.keySafes);
    this.additionalEquipmentSubject.emit(this.order.additionalEquipment);
    this.replacementEquipmentSubject.emit(this.order.replacementEquipment);
    this.renewalInformationSubject.emit(this.order.renewalInformation);
    this.renewalDiscountsSubject.emit(this.order.renewalDiscounts);
    this.initialOrderDetailsSubject.emit(this.order.initialOrderDetails);
    this.initialOrderDispatchInformationSubject.emit(this.order.initialOrderDispatchInformation);
    this.vimSubject.emit(this.order.vim);
    this.orderStatusSubject.emit(this.order.status);
    this.outstandingActionsSubject.emit(this.order.outstandingActions);
    this.cancellationDetailsSubject.emit(this.order.cancellation);
    this.notesSubject.emit(this.order.notes);
    this.tagsSubject.emit(this.order.tags);
    this.itemsSubject.emit(this.order.items);
    this.contactAttemptsSubject.emit(this.order.contactAttempts);
  }

  updateOrderWithoutUpdatingComponents(updatedOrder: Order): void {
    this.order = updatedOrder;
    this.prepareDates();
    this.checkForJontekCodesUpdate();
  }
  
  fromJsonDate(jDate: string): string {
    try {
      let result: moment.Moment;
      if ((typeof jDate == 'string') && (jDate.length == 10)) {
        // It has no time component, so tell parser it is a London time
        result = moment.tz(jDate, 'Europe/London');
      } else if ((typeof jDate == 'string') && (jDate.length > 0)) {
        // Else parse it then convert to London time.
        result = moment(jDate).tz('Europe/London');
      }
      return (result && result.isValid())? result.format('YYYY-MM-DD'): '';
    } catch (error) {
      return '';
    }
  }

  prepareDates() {
    if (this.order.initialOrderDispatchInformation) {
      this.order.initialOrderDispatchInformation.dispatchDate = this.fromJsonDate(this.order.initialOrderDispatchInformation.dispatchDate);
    }
    if (this.order.renewalInformation) {
      this.order.renewalInformation.renewalDate = this.fromJsonDate(this.order.renewalInformation.renewalDate);
      this.order.renewalInformation.paymentDueDate = this.fromJsonDate(this.order.renewalInformation.paymentDueDate);
    }
    if (this.order.cancellation) {
      this.order.cancellation.cancellationDate = this.fromJsonDate(this.order.cancellation.cancellationDate);
      this.order.cancellation.returnDate = this.fromJsonDate(this.order.cancellation.returnDate);
    }
    this.order.created = this.fromJsonDate(this.order.created);
  }

  clearCancellationSection(setCancellationDate: boolean): void {
    this.cancellationDetailsSubject.emit({
      'cancellationDate': setCancellationDate ? moment.tz('Europe/London').format('YYYY-MM-DD') : null,
      'returnDate': '',
      'cancellationReason': '',
      'detailedCancellationReason': '',
      'otherReason': '',
      'personReturning': '',
      'cancellationEmail': '',
      'telephoneNumber': '',
      'setToCancellingBy': ''
    });
  }

  sendArcCancellingEmail(cancellationReason: string, username: string): Promise<SimpleResponse> {
    this.incPendingUpdates();
    return new Promise<SimpleResponse>((resolve) => {
      const response: SimpleResponse = {
        'success': false,
        'message': 'Error sending cancellation email',
      }
      let emailType: string;
      if ('rip' == cancellationReason.toLocaleLowerCase()) {
        emailType = 'Customer Cancellation RIP';
      } else if ('care' == cancellationReason.toLocaleLowerCase()) {
        emailType = 'Care Home Customer Cancellation';
      } else {
        emailType = `Customer Cancellation Reason: ${cancellationReason}`;
      }
      const emailParams: SendEmailRequest =
        this.notificationService.getArcCancellationEmailParams(emailType, this.order, username);
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        this.decPendingUpdates();
        if (response.success) {
          if (response.data.length == 0) {
            response.success = true;
            response.message = `${username} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              response.success = false;
              response.error = `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                `to notify them of the cancellation because ${response.message}. Please send the email yourself`;
              response.message = `${username} sent a ${emailType} email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              response.success = false;
              response.error =
                `Email could not be sent to ${response.data.join(';')} to notify them of the cancellation. Please send the email yourself`;
              response.message = `${username} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          response.success = false;
          response.error = `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
            `Error: ${response.message} Please send the email yourself`;
          response.message = `${username} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        resolve(response);
      }, (error: any) => {
        this.decPendingUpdates();
        response.success = false;
        response.error = `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
            `Error: ${error.message} Please send the email yourself`;
        response.message = `${username} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        resolve(response);
      });
    });
  }

  sendArcRipTransferEmail(username: string): Promise<SimpleResponse> {
    this.incPendingUpdates();
    return new Promise<SimpleResponse>((resolve) => {
      const response: SimpleResponse = {
        'success': false,
        'message': 'Error sending ARC RIP Transfer email',
      }
      const arcRecipients: string[] = JSON.parse(localStorage.getItem('email: ARC Alarm Centre'));
      const customerName: string = `${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}`;
      const tdCode: string = this.order.alarmUserDetails.tdCode;
      const orderLink: string = `${environment.protocol}${environment.IPAddress}/order/${this.order._id}`;

      const emailParams: SendEmailRequest = {
        'recipients': arcRecipients,
        'subject': `${this.order.website.title} RIP Alarm Transfer - ${tdCode} ${customerName}`,
        'plainTextMsg':
          `Hi Team,\nPlease be advised that the following customer has sadly passed away.\n` + 
          `Customer Name: ${customerName}\nCustomer Order (TD): ${tdCode} ${orderLink}\n` +
          `Their alarm is going to be transferred to a new user.\n` +
          `Thanks,\n${username}`,
        'htmlMsg':
          `<p>Hi Team,</p><p>Please be advised that the following customer has sadly passed away.</p>` + 
          `<p>Customer Name: ${customerName}</p><p>Customer Order (TD): <a href="${orderLink}" target="_blank">${tdCode}</a></p>` +
          `<p>Their alarm is going to be transferred to a new user.</p>` +
          `<p>Thanks,<br/>${username}</p>`,
      };
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        this.decPendingUpdates();
        if (response.success) {
          if (response.data.length == 0) {
            response.success = true;
            response.message = `${username} sent a RIP Alarm Transfer email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) =>
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              response.success = false;
              response.error = `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                `to notify them of the transfer because ${response.message}. Please send the email yourself`;
              response.message = `${username} sent a RIP Alarm Transfer email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              response.success = false;
              response.error =
                `Email could not be sent to ${response.data.join(';')} to notify them of the transfer. Please send the email yourself`;
              response.message = `${username} tried to send a RIP Alarm Transfer email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          response.success = false;
          response.error = `Error sending email to ${emailParams.recipients.join(';')} to notify them of the transfer. ` +
            `Error: ${response.message} Please send the email yourself`;
          response.message = `${username} tried to send a RIP Alarm Transfer email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        resolve(response);
      }, (error: any) => {
        this.decPendingUpdates();
        response.success = false;
        response.error = `Error sending email to ${emailParams.recipients.join(';')} to notify them of the transfer. ` +
            `Error: ${error.message} Please send the email yourself`;
        response.message = `${username} tried to send a RIP Alarm Transfer email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        resolve(response);
      });
    });
  }
}
