import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {OrderLink} from '../models/orderLink.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';

@Injectable({
  providedIn: 'root'
})
export class OrderLinkService {

  constructor(
    private http: HttpClient,
  ) { }
  
  private ORDER_LINK_URL: string = environment.protocol + environment.IPAddress + '/api/order-links';

  addOrderLink(params: OrderLink): Observable<SingleRecordResponse<OrderLink>> {
    return this.http.post<SingleRecordResponse<OrderLink>>(`${this.ORDER_LINK_URL}`, params);
  }

  getLinksForOrder(orderId: string): Observable<MultiRecordResponse<OrderLink>> {
    return this.http.get<MultiRecordResponse<OrderLink>>(`${this.ORDER_LINK_URL}/forOrder/${orderId}`);
  }

  deleteLinkedOrder(linkId: string): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(`${this.ORDER_LINK_URL}/${linkId}`);
  }
}
