import {MessageService} from 'primeng/api';
import {FormGroup} from '@angular/forms';
import {ActionService} from './../action.service';
import {AbstractControl} from '@angular/forms';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Validators} from '@angular/forms';
import {FormBuilder} from '@angular/forms';
import {ActionConfig} from '../../../lookups/actions';
import {numberOnly} from '../../../helpers/keyboardHelpers';
import {SimpleResponse} from '../../../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss'],
  providers:[MessageService],
})
export class AddActionComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private actionService: ActionService,
  ) { }

  @Output() closeAddAction = new EventEmitter<{ name: string }>();
  @Input() actionDetail: Partial<ActionConfig> = null;
  @Input() openMode='add';
  displayModal: boolean = true;
  addActionForm: FormGroup;
  duplicateActionError = null;
  numberOnly = numberOnly;
                
  ngOnInit(): void {
    this.initializeForm();
  }
  
  initializeForm() {
    this.addActionForm = this.formBuilder.group({
      actionName: [this.actionDetail?.actionName, [Validators.required]],
      background: [!this.actionDetail.background ? '#FFFFFF' : this.actionDetail?.background, [Validators.required]],
      color: [!this.actionDetail.color ? '#000000' : this.actionDetail?.color, [Validators.required]],
      usersCanAdd: [{ value: (this.actionDetail?.usersCanAdd == null) ? true : this.actionDetail.usersCanAdd, disabled: (this.openMode == 'view') }],
      defaultDueInDays: [this.actionDetail?.defaultDueInDays],
      attention: [{ value: this.actionDetail?.attention, disabled: (this.openMode == 'view') }],
      owedPaymentLabel: [this.actionDetail?.owedPaymentLabel],
      returnedEquipLabel: [this.actionDetail?.returnedEquipLabel],
      returnedEquipTwoLabel: [this.actionDetail?.returnedEquipTwoLabel],
      returnedEquipThreeLabel: [this.actionDetail?.returnedEquipThreeLabel],
      personReturningLabel: [this.actionDetail?.personReturningLabel],
      returnLabelNumbersLabel: [this.actionDetail?.returnLabelNumbersLabel],
      cancellationEmailLabel: [this.actionDetail?.cancellationEmailLabel],
      holdUntilLabel: [this.actionDetail?.holdUntilLabel],
      statusLabel: [this.actionDetail?.statusLabel],
      dateLabel: [this.actionDetail?.dateLabel],
      noteLabel: [this.actionDetail?.noteLabel],
      countLabel: [this.actionDetail?.countLabel],
      responsiblePersonNameLabel: [this.actionDetail?.responsiblePersonNameLabel],
      contactNumberLabel: [this.actionDetail?.contactNumberLabel],
      emailAddressLabel: [this.actionDetail?.emailAddressLabel],
      reasonLabel: [this.actionDetail?.reasonLabel],
      board: [this.actionDetail?.board? this.actionDetail?.board: 'Miscellaneous'],
      boardSortOrder: [this.actionDetail?.boardSortOrder? this.actionDetail?.boardSortOrder: 10000],
      allowDueDateFilterOnCC: [{ value: (this.actionDetail?.allowDueDateFilterOnCC == null) ? false : this.actionDetail.allowDueDateFilterOnCC, disabled: (this.openMode == 'view') }],
    });
  }

  addAction() {
    console.log("FORM SUBMITTED ::", this.addActionForm.value);
    const params = {
      ...this.addActionForm.value
    };
    this.actionService.addAction(params).subscribe((response: SingleRecordResponse<ActionConfig>) => {
      console.log("Response :: ", response);
      if (response.success) {
        this.displayModal = false;
        this.closeAddAction.emit({ name: 'success' });
      } else if (!response.success && response.errorCode == 11000) {
        this.duplicateActionError = 'An action already exist with the given name, Try again with the different name';
        // setTimeout(() => {
        //   this.duplicateActionError = null
        // }, 1500);
      }
    }, (err: Error) => {
      console.log("Error :: ", err);
      this.displayModal = false;
      this.closeAddAction.emit({ name: 'error' });
    })
  }

  updateAction() {
    console.log("Update action called ::");
    const action = { ...this.addActionForm.value };
    delete action.createdAt;
    delete action.updatedAt;
    action._id = this.actionDetail._id;
    this.actionService.updateAction(action).subscribe((response: SimpleResponse) => {
      console.log("Response on update action :: ", response);
      if (response.success) {
        this.displayModal = false;
        this.closeAddAction.emit({ name: 'success' });
      } else {
        this.duplicateActionError = 'An action already exist with the given name, Try again with the different name'
      }
    }, (err: Error) => {
      console.log("Error on update action :: ", err);
      this.displayModal = false;
      this.closeAddAction.emit({ name: 'error' });
    })
  }

  get usersCanAdd(): AbstractControl {
    return this.addActionForm.get('usersCanAdd');
  }

  get attention(): AbstractControl {
    return this.addActionForm.get('attention');
  }

  get background(): AbstractControl {
    return this.addActionForm.get('background');
  }

  get color(): AbstractControl {
    return this.addActionForm.get('color');
  }

  onActionNameChange() {
    this.duplicateActionError = null;
  }

  closeDialog() {
    this.closeAddAction.emit({ name: 'close' });
    this.displayModal = false;
  }

}
