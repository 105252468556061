<ng-container [formGroup]="orderForm" *ngIf="orderForm.value.status.status == 'cancelling' || orderForm.value.status.status == 'cancelled'">
  <div class="formbar row no-gutters">
    <div class="col-12">
      Cancellation
      <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
        <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
        <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
      </button>
    </div>
  </div>
  <div *ngIf="formLoaded" class="formsect" formGroupName="cancellation">
    <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
      <div class="form-row">
        <div class="form-group col-6">
          <label class="canc">Cancellation Date</label>
          <input class="canc" type="date" name="cancellationDate" min="2014-01-01"
              [ngStyle]="{'color': !safeGetFormField('cancellationDate') ? '#ffffff' : 'black' }"
              formControlName="cancellationDate"/>
        </div>
        <div class="form-group col-6">
          <label class="canc">Cancellation Date</label>
          <input class="canc" type="text" name="setToCancellingBy" formControlName="setToCancellingBy" readonly/>
        </div>
        <div class="form-group col-6">
          <label class="canc">Cancellation Main Reason</label>
          <p-dropdown class="canc noAutoSave" id='canReason' [showClear]="true" [options]="cancellationCategoriesSelectItems" appendTo="body"
            [filter]="true" [resetFilterOnHide]="true" placeholder="Select Main Reason for Cancellation"
            [baseZIndex]="3200" [required]="true" formControlName="cancellationReason" (onChange)="changeCancellationCategory()">
          </p-dropdown>
        </div>
        <div class="form-group col-6">
          <ng-container *ngIf="cancellationReasonsSelectItems.length > 0">
            <label class="canc">Cancellation Detailed Reason</label>
            <p-dropdown class="canc noAutoSave" id='canDetailedReason' [showClear]="true" [options]="cancellationReasonsSelectItems" appendTo="body"
              [filter]="true" [resetFilterOnHide]="true" placeholder="Select Detailed Reason for Cancellation"
              [baseZIndex]="3200" [required]="true" formControlName="detailedCancellationReason" (onChange)="changeCancellationReason(true)">
            </p-dropdown>
          </ng-container>
        </div>
        <div class="form-group col-md-12" *ngIf="otherReasonRequired">
          <label class="canc">Other Reason</label>
          <input class="canc" type="text" name="otherReason" formControlName="otherReason" required/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-6">
          <label class="canc">Return Date</label>
          <input class="canc" type="date" name="returnDate" min="2014-01-01"
              [ngStyle]="{'color': !safeGetFormField('returnDate') ? '#ffffff' : 'black' }"
              formControlName="returnDate"/>
        </div>
        <div class="form-group col-6">
          <label class="canc">Person Returning</label>
          <input class="canc" type="text" name="personReturning" formControlName="personReturning"/>
        </div>
        <div class="form-group col-6">
          <label class="canc">Telephone Number</label>
          <input class="canc validated" type="text" name="telephoneNumber" formControlName="telephoneNumber" (keypress)="numberOnly($event, false)"/>
        </div>
        <div class="form-group col-6">
          <label class="canc">Cancellation Email</label>
          <input class="canc validated" type="text" name="cancellationEmail" formControlName="cancellationEmail"/>
        </div>
      </div>
    </div>
  </div>
</ng-container>