<div class="message-list">
  <p-table #dt [value]="allMessages" [columns]="cols" [globalFilterFields]="searchableFields"
    [paginator]="true" [rows]="15" sortField="daysToGo" [sortOrder]="1" sortMode="single" (onFilter)="onFilter($event)">
    <ng-template pTemplate="caption">
      <div class="container">
        <div class="d-flex justify-content-center m-1">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input type="search" pInputText size="30" placeholder="Global Filter" (input)="globalFilter($event, 'contains')" style="width:auto">
          <p-multiSelect class="ml-2" [options]="messageTypes" placeholder="Messages to Load"
              [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]=2
              selectedItemsLabel="{0} selected" [style]="{'display':'flex'}"
              [(ngModel)]="selectedMessageTypes">
          </p-multiSelect>
          <p-multiSelect class="ml-2" [options]="statusType" placeholder="Statuses to Load"
              [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]=2
              selectedItemsLabel="{0} selected" [style]="{'display':'flex'}"
              [(ngModel)]="selectedStatuses">
          </p-multiSelect>
          <div class="ml-2" *ngIf="!loading else loadingBlock">
            <button type="button" class="btn btn-primary" pButton (click)="loadMessages()" label="Load Messages"></button>
          </div>
          <ng-template #loadingBlock>
            <div class="ml-2 spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-template>
          <div *ngIf="!loading && (allMessages.length > 0)" ngbDropdown class="d-inline-flex">
            <button type="button" id="exportMessages" ngbDropdownToggle class="ml-2 btn btn-primary" aria-controls="exportMessageControl">
              Export
            </button>
            <div ngbDropdownMenu aria-labelledby="exportFeedback" id="exportFeedbackOptions">
              <button ngbDropdownItem type="button" (click)="exportMessages(ExportOptions.ALL)">
                All Loaded Records
              </button>
              <button ngbDropdownItem type="button" (click)="exportMessages(ExportOptions.FILTERED)">
                Filtered Records
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'action'">
            {{col.header}}
          </div>
          <div *ngSwitchDefault [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" 
                ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </div>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'brand'">
            <p-multiSelect 
              [options]="brands" 
              placeholder="Brand" 
              styleClass="brandcss"
              [panelStyle]="{minWidth:'12em'}" 
              scrollHeight="600px" 
              [maxSelectedLabels]=0
              selectedItemsLabel="{0} selected" 
              (onChange)="dt.filter($event.value,col.field,'in')" 
              [style]="{'display':'flex'}"
            >
              <ng-template let-Pbrand let-i="index" pTemplate="item">
                <span class="brandBar" [ngStyle]="{'background-color': getBrandBackgroundColour(Pbrand.label), 'color': getBrandColour(Pbrand.label)}">
                  &nbsp;{{Pbrand.label}}
                </span>
              </ng-template>
            </p-multiSelect>
          </div>
          <div *ngSwitchCase="'messageType'">
            <p-multiSelect
              [options]="messageTypes"
              placeholder="Message"
              [panelStyle]="{minWidth:'12em'}" 
              scrollHeight="600px" 
              [maxSelectedLabels]=0
              selectedItemsLabel="{0} selected"
              [style]="{'display':'flex'}"
              (onChange)="dt.filter($event.value,col.field,'in')"
            >
            </p-multiSelect>
          </div>
          <div *ngSwitchCase="'tdCode'" class="p-d-flex p-jc-between p-ai-center">
            <p-columnFilter type="text" [matchModeOptions]="tdCodeMatchModeOptions" [matchMode]="'in-delimited-list'" field="tdCode" display="menu" class="p-ml-auto">
            </p-columnFilter>
          </div>
          <div *ngSwitchCase="'renewalType'">
            <p-multiSelect 
              [options]="renewalTypes" 
              placeholder="Renewal Type" 
              [panelStyle]="{minWidth:'12em'}" 
              scrollHeigh="600px" 
              [maxSelectedLabels]=0
              selectedItemsLabel="{0} selected" 
              (onChange)="dt.filter($event.value,col.field,'in')" 
              [style]="{'display':'flex'}"
            >
            </p-multiSelect>
          </div>
          <div *ngSwitchCase="'status'">
            <p-multiSelect 
              [options]="statusType" 
              placeholder="Status" 
              [panelStyle]="{minWidth:'12em'}" 
              scrollHeigh="600px" 
              [maxSelectedLabels]=0
              selectedItemsLabel="{0} selected" 
              (onChange)="dt.filter($event.value,col.field,'in')" 
              [style]="{'display':'flex'}"
            >
            </p-multiSelect>
          </div>
          <div *ngSwitchCase="'methods'">
            <p-toggleButton [onLabel]="'Show Invalid'" [offLabel]="'Show All'" [(ngModel)]="showInvalidOnly" (click)="changeMethodFilter()">
            </p-toggleButton>
          </div>
          <div *ngSwitchDefault>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'brand'" >
            <div class="brandBar" [ngStyle]="{'background-color': getBrandBackgroundColour(rowData.brand), 'color': getBrandColour(rowData.brand)}">
              {{rowData.brand}}
            </div>
          </div>
          <div *ngSwitchCase="'methods'">
            <span *ngFor="let method of rowData[col.field]; let i=index;" [ngSwitch]="method" class="methods">
              <span *ngSwitchCase="'email'" [ngStyle]="{'color': isEmailFieldValid(rowData.contactDetails.email)? 'black': 'red'}">
                {{method}}
              </span>
              <span *ngSwitchCase="'text'" [ngStyle]="{'color': isValidUkMobile(rowData.contactDetails.mobile)? 'black': 'red'}">
                {{method}}
              </span>
              <span *ngSwitchCase="'letter'" [ngStyle]="{'color': isValidAddressArray(rowData.contactDetails.address)? 'black': 'red'}">
                {{method}}
              </span>
              <span *ngSwitchDefault>{{method}}</span>
              <span *ngIf="!(i==rowData.methods.length-1)" >,</span>
            </span>
          </div>
          <div *ngSwitchCase="'tdCode'">
            <a routerLink="/order/{{rowData.orderId}}" target="_blank">
              {{rowData[col.field]}}
              <div *ngIf="isOrderLocked(rowData.orderId)">
                {{ orderLockedBy(rowData.orderId) }}
              </div>
            </a>
          </div>
          <div *ngSwitchCase="'status'">
            {{rowData.status}}
            <div *ngIf="!hasValidContactForOneOrMoreMethods(rowData)">
              Invalid Comms Details
            </div>
          </div>
          <div *ngSwitchCase="'action'">
            <span (click)="openMessageDetailComponent(rowData._id)">
              <button type="button" class="btn btn-primary">Review</button>
              <div *ngIf="isMessageLocked(rowData._id)" >
                {{messageLockedBy(rowData._id)}}
              </div>
            </span>
          </div>
          <div *ngSwitchCase="'generated'">
            {{rowData[col.field] | date:'dd/MM/y'}}
          </div>
          <div *ngSwitchCase="'renewalType'">
            {{renewalTypeDisplayLookup[rowData[col.field]]}}
          </div>
          <div *ngSwitchDefault>
            {{rowData[col.field]}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-message-detail *ngIf="isMessageDetailViewId" (closeMessageDetailView)="closeMessageDetailView($event)" 
    [messageId]="isMessageDetailViewId" [messageTypes]="messageTypes">
</app-message-detail>
<p-toast></p-toast>
