<div class="row no-gutters">
    <div class="col-md-6">
        <button type="button" class="btn btn-primary m-2" (click)="addRoleModal = true">Add Role</button>
    </div>
</div>
<div class="maintain-roles">
    <p-table #dt [value]="roles" [columns]="cols" [paginator]="true" [rows]="10" class="maintain-table-block">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" class="px-2">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr>
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchCase="'action'">
                        <button type="button" *ngIf="rowData.users == 0" class="btn btn-danger" (click)="deleteRole(rowData._id)">
                          Delete
                        </button>
                        <button type="button" class="btn btn-primary ml-2" (click)="updateRole(rowData)">
                          Update
                        </button>
                    </div>
                    <div *ngSwitchCase="'permissions'">
                        <p-multiSelect id="permissions" name="selectedRolePermissions" [options]="rolePermissions"
                            [(ngModel)]="rowData['permissions']" [maxSelectedLabels]=3 selectedItemsLabel="{0} permissions selected"
                            [panelStyle]="{minWidth:'12em'}" [scrollHeight]="'400px'"
                            placeholder="Select Permissions" (onChange)="confirmPermissionChange($event, rowData)">
                        </p-multiSelect>
                    </div>
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-toast></p-toast>
<p-confirmDialog>
</p-confirmDialog>
<app-add-role *ngIf="addRoleModal" (closeAddRoleModal)="closeAddRoleModal($event)"></app-add-role>