<p-dialog (onHide)="closeDialog()" header="Add Note" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <form [formGroup]="noteForm">
    <div class="row">
      <div class="col-12 form-group">
        <label for="noteContent">Note Text</label>
        <textarea name="noteContent" class="form-control" rows="10" formControlName="content" required="true">
        </textarea>
      </div>
      <div class="col-12 form-group">
        <label for="noteCategories">Categories</label>
        <p-multiSelect #noteCategoriesSelect [options]="categoriesToAddNote" formControlName="categories"
          [panelStyle]="{minWidth:'12em'}" placeholder="Choose Categories" [required]="true"
          [appendTo]="'body'" [baseZIndex]="3200">
        </p-multiSelect>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <label>Note Template</label>
      <p-dropdown [options]="noteTemplates" name="noteTemplates" [showClear]="true" placeholder="Select a template" id="noteTemplates"
        (onChange)="changeNoteTemplate($event)" [filter]=true [resetFilterOnHide]="true" [optionLabel]="'label'"
        [appendTo]="'body'" [baseZIndex]="3200">
      </p-dropdown>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToSave()" (click)="save()">
      Add Note
    </button>
  </ng-template>
</p-dialog>