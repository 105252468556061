import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UpdateRoleNotificationRequest} from '../../models/requests/updateRoleNotificationRequest.model';
import {UpdateRoleRequest} from '../../models/requests/updateRoleRequest.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {Role} from '../../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  API_URL = environment.protocol + environment.IPAddress+'/api';
  constructor(
    private httpClient: HttpClient,
  ) { }

  getRoles(): Observable<MultiRecordResponse<Role>> {
    return this.httpClient.get<MultiRecordResponse<Role>>(`${this.API_URL}/roles`);
  }

  addRole(params: Role): Observable<SingleRecordResponse<Role>> {
    return this.httpClient.post<SingleRecordResponse<Role>>(`${this.API_URL}/roles`, params);
  }

  deleteRole(roleId: string): Observable<SingleRecordResponse<Role>> {
    return this.httpClient.delete<SingleRecordResponse<Role>>(`${this.API_URL}/roles/${roleId}`);
  }

  updateRole(params: UpdateRoleRequest): Observable<SingleRecordResponse<Role>> {
    return this.httpClient.patch<SingleRecordResponse<Role>>(`${this.API_URL}/roles`, params);
  }

  changeRoleRightsAutoNotification(params: UpdateRoleNotificationRequest) {
    return this.httpClient.post(`${this.API_URL}/notifications/change-roles/auto-notification`, params);
  }
}
