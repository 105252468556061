<p-dialog  [(visible)]="display" [modal]="true" [style]="{width: '850px', height: '800px' , minWidth: '600px'}" [minY]="700"
           [maximizable]="true" [baseZIndex]="10000">
  <div class="container" *ngIf="progress>-1" >
    <h3>Update in progress...</h3>
    <p-progressBar [value]="progressVal"></p-progressBar>
  </div>
  <p-footer  *ngIf="progress>-1">
    <button type="button" pButton icon="pi pi-check" (click)="loadProducts()" label="Refresh"></button>
  </p-footer>
</p-dialog>

<div class="container">
  <div class="d-flex justify-content-center m-1">
    <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px">
      <ng-template let-Pbrand let-i="index" pTemplate="item">
        <span class="brandDropDown" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
          &nbsp;{{Pbrand.value}}
        </span>
      </ng-template>
    </p-dropdown>
    <div class="ml-2">
      <button type="button" class="btn btn-primary" pButton (click)="loadProducts()" label="Load Products" [disabled]="!selectedBrand"></button>
    </div>
  </div>
</div>

<div class="tableFixHead">
  <table border="1">
    <thead>
      <tr>
        <th></th>
        <th>SKU</th>
        <th>Product title</th>
        <th>CRM title</th>
        <th>Brand</th>
        <th>Pull in<br/>orders</th>
        <th>Display on<br/>records</th>
        <th>Display on<br/>CSEs page</th>
        <th>Product Type</th>
        <th>Hardware / Hardware Sets<br/>/ Services</th>
        <th>Product<br/>Image</th>
        <th>VAT</th>
        <th>Renewal<br/>Price</th>
        <th>Plan<br/>Symbols</th>
        <th>Renewal After Months</th>
        <th>Regular Price</th>
        <th>
          Status <br/>
          <p-multiSelect [options]="statuses" [(ngModel)]="selectedStatuses" placeholder="Statuses" filterPlaceHolder="status"
            [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]="0" selectedItemsLabel="{0} selected">
          </p-multiSelect>
        </th>
      </tr>
    </thead>
    <ng-container *ngFor="let product of products; let i = index">
      <tbody *ngIf="(selectedStatuses.length == 0) || (selectedStatuses.includes(product.status))"
          [ngStyle]="{'background-color': brandConfigs[product.websiteId.title].background + '80'}">
        <tr>
          <td [attr.data-target]="'#' +product.sku" >{{i+1}}</td>
          <td> {{product.sku}} </td>
          <td>
            <a href="{{product.url}}">
              <span *ngIf="!!product.isSub"> --&gt; </span> {{product.title}} 
            </a>
            <div *ngIf="product.subProducts.length > 0">(COMPOSITE)</div>
            <div *ngIf="product.isSub">(SUBPRODUCT)</div>
          </td>
          <td><input [(ngModel)]="product.crmTitle" type="text"></td>
          <td>
            <a href="/websites/{{product.websiteId._id}}">{{product.websiteId.title}}</a>
          </td>
          <td>
            <select [(ngModel)]="product.pullinOrders" class="yesNo">
              <option selected value></option>
              <option value="yes">YES</option>
              <option value="no">No</option>
            </select>
          </td>
          <td>
            <select [(ngModel)]="product.displayOnRecords" class="yesNo">
              <option selected value></option>
              <option value="yes">YES</option>
              <option value="no">No</option>
            </select>
          </td>
          <td>
            <select [(ngModel)]="product.displayOnCSES" class="yesNo">
              <option selected value></option>
              <option value="yes">YES</option>
              <option value="no">No</option>
            </select>
          </td>
          <td>
            <select [(ngModel)]="product.category" class="category" (change)="setCategory(product)">
              <option selected value></option>
              <option value="additionalEquipment">Additional Equipment</option>
              <option value="annualPlan">Annual Plan</option>
              <option value="installations">Installations</option>
              <option value="keySafe">Key Safe</option>
              <option value="lifetimePlan">Lifetime Plan</option>
              <option value="quarterlyPlan">Quarterly Plan</option>
              <option value="cables">Cables</option>
              <option value="discontinued">Discontinued</option>
              <option value="services">Services</option>
            </select>

          </td>
          <td>
            <ng-container *ngIf="(product.variations.length == 0) && (!['keySafe', 'services'].includes(product.category))">
              <label>Hardware Sets:</label>
              <ng-container *ngFor="let hardwareSet of product.hardwareSets; let i = index">
                <div>
                  {{hardwareSet.title}}
                  <button type="button" class="btn icon-btn" (click)="deleteHardwareSet(product, i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <hr />
              </ng-container>
              <div>
                <p-dropdown styleClass="addHardware" [options]="hardwareSetList" placeholder="Select hardware set" [(ngModel)]="product.newHardwareSet">
                </p-dropdown>
                <button type="button" class="btn icon-btn" (click)="addHardwareSet(product)">
                  <i class="fas fa-plus-square"></i>
                </button>
              </div>
              <hr/>
              <label>Hardware:</label>
              <ng-container *ngFor="let hardware of product.hardware; let i = index">
                <div>
                  {{hardware.title}}
                  <button type="button" class="btn icon-btn" (click)="deleteHardware(product, i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <hr />
              </ng-container>
              <div>
                <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select hardware" [(ngModel)]="product.newHardware">
                </p-dropdown>
                <button type="button" class="btn icon-btn" (click)="addHardware(product)">
                  <i class="fas fa-plus-square"></i>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="(product.category == 'services')">
              <label>Service:</label>
              <div>
                <p-dropdown styleClass="addHardware" [options]="accountServicesList" placeholder="Select Service" [(ngModel)]="product.serviceId">
                </p-dropdown>
              </div>
            </ng-container>
          </td>
          <td><img *ngIf="product.productImage!='false'" src="{{product.productImage}}">
            <p *ngIf="product.productImage=='false'">No Image</p>
          </td>
          <td>
            <select *ngIf="(product.subProducts.length == 0) && (product.variations.length == 0) && (product.category != 'keySafe')"
                [(ngModel)]="product.vat" class="vat">
              <option disabled selected value></option>
              <option *ngFor="let vatStatus of vatStatuses" [value]="vatStatus.value">
                {{vatStatus.label}}
              </option>
            </select>
            <input *ngIf="product.category == 'keySafe'" [(ngModel)]="product.vat" type="text" readonly class="vat"/>
          </td>
          <td>
            <input *ngIf="(product.subProducts.length == 0) && (product.variations.length == 0) && (product.category != 'keySafe')"
                type="number" step="0.01" [(ngModel)]="product.renewalPrice">
          </td>
          <td>
            <input *ngIf="(product.subProducts.length == 0) && (product.variations.length == 0) && (product.category != 'keySafe')" 
                type="text" [(ngModel)]="product.planOrEquipment" class="plan">
          </td>
          <td>
            <select *ngIf="(product.category != 'keySafe') && (!product.isSub)" [(ngModel)]="product.renewalAfterDate">
              <option value=''></option>
              <option value=3>3</option>
              <option value=12>12</option>
            </select>
          </td>
          <td>
            <input *ngIf="(product.subProducts.length == 0) && (product.variations.length == 0)" type="number" step="0.01"
                [(ngModel)]="product.regularPrice">
          </td>
          <td>
            <select [(ngModel)]="product.status" class="status">
              <option selected value></option>
              <option value='not configured'>Not Configured</option>
              <option value='configured'>Configured</option>
              <option value='archived'>Archived</option>
            </select>
          </td>
        </tr>
        <tr class="variations" *ngFor="let variation of product.variations; let j = index">
          <td>{{i+1}} : {{j+1}}</td>
          <td>{{variation.sku}}</td>
          <td colspan="7">
            <div *ngFor="let attribute of variation.attributes; let k = index" >
              {{attribute.name}}: {{attribute.option}};
              - Sku: <input type="text"  [(ngModel)]="variation.attributes[k].sku">
            </div>
          </td>
          <td>
            <ng-container *ngIf="(product.category != 'keySafe')">
              <label>Hardware Sets:</label>
              <ng-container *ngFor="let hardwareSet of variation.hardwareSets; let i = index">
                <div>
                  {{hardwareSet.title}}
                  <button type="button" class="btn icon-btn" (click)="deleteHardwareSet(variation, i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <hr />
              </ng-container>
              <div>
                <p-dropdown styleClass="addHardware" [options]="hardwareSetList" placeholder="Select hardware set" [(ngModel)]="variation.newHardwareSet">
                </p-dropdown>
                <button type="button" class="btn icon-btn" (click)="addHardwareSet(variation)">
                  <i class="fas fa-plus-square"></i>
                </button>
              </div>
              <hr/>
              <label>Hardware:</label>
              <ng-container *ngFor="let hardware of variation.hardware; let i = index">
                <div>
                  {{hardware.title}}
                  <button type="button" class="btn icon-btn" (click)="deleteHardware(variation, i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <hr />
              </ng-container>
              <div>
                <p-dropdown styleClass="addHardware" [options]="hardwareList" placeholder="Select hardware" [(ngModel)]="variation.newHardware">
                </p-dropdown>
                <button type="button" class="btn icon-btn" (click)="addHardware(variation)">
                  <i class="fas fa-plus-square"></i>
                </button>
              </div>
            </ng-container>
          </td>
          <td></td>
          <td>
            <input *ngIf="(product.category == 'keySafe')" [(ngModel)]="variation.vat" type="text" class="vat" readonly />
            <select *ngIf="(product.category != 'keySafe')" [(ngModel)]="variation.vat" class="vat">
              <option disabled selected value></option>
              <option *ngFor="let vatStatus of vatStatuses" [value]="vatStatus.value">
                {{vatStatus.label}}
              </option>
            </select>
          </td>
          <td>
            <input *ngIf="(product.category != 'keySafe')" type="number" step="0.01" [(ngModel)]="variation.renewalPrice">
          </td>
          <td>
            <input *ngIf="(product.category != 'keySafe')" type="text" [(ngModel)]="variation.symbol" class="plan">
          </td>
          <td>
            <select [(ngModel)]="variation.renewalAfterDate">
              <option value=''></option>
              <option value=3>3</option>
              <option value=12>12</option>
            </select>
          </td>
          <td><input type="number" [(ngModel)]="variation.regular_price"></td>
          <td></td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>
<div *ngIf="!!selectedBrand" >
  <button type="button" class="btn-success"
      style="position: fixed; right: 17px; bottom: 38px; color: #fff; background-color: #17a2b8; border-color: #000000; padding: 8px 73px;"
      (click)="allUpdate()">UPDATE ALL</button>
</div>
<p-toast></p-toast>