import {SingleRecordResponse} from './../../models/responses/singleRecordResponse.model';
import {CancellationLink} from './../../models/cancellation-links.model';
import {MultiRecordResponse} from './../../models/responses/multiRecordResponse.model';
import {HttpClient} from '@angular/common/http';
import {environment} from './../../../environments/environment';
import {Injectable} from '@angular/core';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancellationLinkService {
  API_URL: string = environment.protocol + environment.IPAddress + '/api/cancellation-links';

  constructor(
    private http: HttpClient,
  ) { }

  getCancellationLinks(brand: string) {
    return this.http.get<MultiRecordResponse<CancellationLink>>(`${this.API_URL}/${brand}`);
  }

  addCancellationLink(params: CancellationLink) {
    return this.http.post<SingleRecordResponse<CancellationLink>>(`${this.API_URL}`, params);
  }

  updateCancellationLink(params: CancellationLink): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(this.API_URL, params);
  }

  deleteCancellationLink(linkId: string) {
    return this.http.delete<SimpleResponse>(`${this.API_URL}/${linkId}`);
  }

}
