<div class="feedback-list">
  <p-table #custFeedback [value]="feedbackRecords" [columns]="cols" [paginator]="true" [rows]="20" 
      sortField="dateLogged" [sortOrder]="1" sortMode="single" (onFilter)="onFilter($event)"
      [showCurrentPageReport]="true" currentPageReportTemplate="Page {currentPage} of {totalPages} - Feedback {first} to {last} of {totalRecords}">
    <ng-template pTemplate="caption">
      <div class="text-right">
        <div class="d-inline-flex load-feedback">
          <div *ngIf='customerFeedbackLoading' class="btn">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="d-inline-flex">
          <button type="button" class="btn btn-primary" (click)="addFeedback()">
            Add
          </button>
        </div>
        <div *ngIf="userCanExportFeedback" ngbDropdown class="d-inline-flex">
          <button type="button" id="exportFeedback" ngbDropdownToggle class="ml-2 btn btn-primary" aria-controls="exportFeedbackControl">
            Export
          </button>
          <div ngbDropdownMenu aria-labelledby="exportFeedback" id="exportFeedbackOptions">
            <button ngbDropdownItem type="button" (click)="exportCustomerFeedback(ExportOptions.ALL)">
              All Records
            </button>
            <button ngbDropdownItem type="button" (click)="exportCustomerFeedback(ExportOptions.FILTERED)">
              Filtered Records
            </button>
            <button ngbDropdownItem type="button" (click)="exportCustomerFeedback(ExportOptions.THIS_MONTH)">
              This Month's
            </button>
            <button ngbDropdownItem type="button" (click)="exportCustomerFeedback(ExportOptions.LAST_MONTH)">
              Last Month's
            </button>
            <button ngbDropdownItem type="button" (click)="exportCustomerFeedback(ExportOptions.THIS_MONTH_AND_LAST_MONTH)">
              This Month's &amp; Last Month's
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'actions'">
              {{col.header}}
            </div>
            <div *ngSwitchCase="'websiteId'" [pSortableColumn]="'websiteId.title'">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
            </div>
            <div *ngSwitchDefault [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
            </div>
          </ng-container>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'websiteId'">
              <p-multiSelect [options]="brands" scrollHeight="600px" [style]="{'display':'flex'}" 
                  filterPlaceHolder="brand" [maxSelectedLabels]=1 selectedItemsLabel="{0} sel"
                  (onChange)="custFeedback.filter($event.value, col.field, 'brand-website')"
                  placeholder="{{col.header}}">
                <ng-template let-brand let-i="index" pTemplate="item">
                  <div class="brandBar" 
                      [ngStyle]="{'background-color': getBrandBackgroundColour(brand.label), 'color': getBrandColour(brand.label)}">
                    &nbsp;{{brand.label}}
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'feedbackType'">
              <p-multiSelect [options]="feedbackTypes" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'priority'">
              <p-dropdown [options]="feedbackPriorities" name="priority" id="priority" placeholder="Priority" appendTo="body">
            </p-dropdown>
            </ng-container>
            <ng-container *ngSwitchCase="'partnership'">
              <p-multiSelect [options]="feedbackPartnerships" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <p-multiSelect [options]="feedbackStatuses" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')" [(ngModel)]="selectedStatuses"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'mainReason'">
              <p-multiSelect [options]="feedbackMainReasons" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'specificReason'">
              <p-multiSelect [options]="feedbackSpecificReasons" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'contactMethod'">
              <p-multiSelect [options]="feedbackContactMethods" placeholder="{{col.header}}" appendTo="body" [filter]=true
                  (onChange)="custFeedback.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'tdCode'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'equals')" />
            </ng-container>
            <ng-container *ngSwitchCase="'loggedBy'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
            </ng-container>
            <ng-container *ngSwitchCase="'closedBy'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
            </ng-container>
            <ng-container *ngSwitchCase="'respondByEmail'">
              <p-dropdown [options]="respondByEmailFilter" appendTo="body"
                (onChange)="custFeedback.filter($event.value, col.field, 'equals')" >
              </p-dropdown>
            </ng-container>
            <ng-container *ngSwitchCase="'dateLogged'">
              <p-calendar [(ngModel)]="dateLoggedFilters" appendTo="body" selectionMode="range"
                [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="custFeedback.filter($event, col.field, col.field)"
                [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=true
                [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
                [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
                (onClearClick)="clearDateLoggedFilters()">
              </p-calendar>
            </ng-container>
            <ng-container *ngSwitchCase="'dateClosed'">
              <p-calendar [(ngModel)]="dateClosedFilters" appendTo="body" selectionMode="range"
                [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="custFeedback.filter($event, col.field, col.field)"
                [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=true
                [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
                [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
                (onClearClick)="clearDateClosedFilters()">
              </p-calendar>
            </ng-container>
            <div *ngSwitchDefault>
            </div>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr class="{{rowData.rowClass}}">
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'websiteId'">
            <span class="brandBar" [ngStyle]="{'background-color':rowData[col.field].background, 'color':rowData[col.field].color}">
              {{rowData[col.field].title}}
            </span>
          </div>
          <div *ngSwitchCase="'tdCode'">
            <a *ngIf="rowData.orderId; else noOrderLink" routerLink="/order/{{rowData.orderId._id}}" target="_blank">
              {{rowData.tdCode}}
              <div *ngIf="isOrderLocked(rowData.orderId)">
                {{orderLockedBy(rowData.orderId)}}
              </div>
            </a>
            <ng-template #noOrderLink>
              {{rowData.tdCode}}
            </ng-template>
          </div>
          <div *ngSwitchCase="'dateLogged'">
            {{rowData.dateLogged | date:'dd/MM/y'}}
          </div>
          <div *ngSwitchCase="'dateClosed'">
            {{rowData.dateClosed | date:'dd/MM/y'}}
          </div>
          <div *ngSwitchCase="'actions'">
            <button type="button" class="btn btn-primary" (click)="editFeedback(rowData._id)">
              Edit
            </button>
            <button *ngIf="userCanDeleteFeedback && !isCustFeedbackLocked(rowData._id)"
                type="button" class="btn btn-danger ml-2" (click)="deleteFeedback(rowData._id)">
              Delete
            </button>
            <div *ngIf="isCustFeedbackLocked(rowData._id)" >
              {{custFeedbackLockedBy(rowData._id)}}
            </div>
          </div>
          <div *ngSwitchCase="'respondByEmail'">
            {{rowData.respondByEmail? 'Yes': 'No'}}
          </div>
          <div *ngSwitchDefault>
            {{rowData[col.field]}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-add-customer-feedback *ngIf="showAddFeedbackDialog" [order]="undefined" [tags]="[]" (closeModal)="closeAddFeedback($event)">
</app-add-customer-feedback>
<app-edit-customer-feedback *ngIf="feedbackIdToEdit" [feedbackId]="feedbackIdToEdit" [fromOrder]="false" (closeModal)="closeEditFeedback($event)">
</app-edit-customer-feedback>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>