
<!-- Button to enable add-lead pop-up -->
<div class="row ml-2">
  <button type="button" class="btn btn-primary m-2" (click)="leadOpenMode = 'add'">Add New Lead</button>
  <button type="button" class="btn btn-dark m-2" (click)="displayImportDialog = true">Import Leads</button>
  <button type="button" class="btn btn-dark m-2" (click)="exportLeads()" [disabled]="selectedLeads.length == 0"
      data-toggle="tooltip" title="Please select leads to export" data-placement="top">
    Export Leads
  </button>
  <button type="button" class="btn btn-danger m-2" (click)="deleteRecords()" [disabled]="selectedLeads.length == 0">Delete Selected</button>
  <div class="btn">
    <label for="matchedLeads" class='px-2'>Leads Match Filter:</label>
    <p-dropdown class="btn" [options]="matchedFilter" (onChange)="dt.filter($event.value,'matchedOrders','arrayHasValues')">
    </p-dropdown>
  </div>
  <ng-container class="load-logs" *ngIf="!inactiveLeadsLoading else leadsLoadingBlock">
    <button type="button" class="btn btn-primary m-2" (click)="loadInactiveLeads()">
      Load Inactive Leads
    </button>
  </ng-container>
  <ng-template #leadsLoadingBlock>
    <div class="btn">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    </div>
  </ng-template>
</div>

<!-- Listing of leads(Leads Table) -->
<div class="row no-gutters">
  <div class="lead-list" style="width: 100%">
    <p-table #dt [value]="leads" [(selection)]="selectedLeads" [columns]="columns" [paginator]="true" [rows]="15" 
        [sortField]="'callDate'" [sortOrder]="1" sortMode="single" (sortFunction)="subfieldSort($event)" [customSort]="true"
        [showCurrentPageReport]="true" currentPageReportTemplate="Page {currentPage} of {totalPages} - Leads {first} to {last} of {totalRecords}">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">
              <div *ngSwitchCase="'actions'">
                {{col.header}}
              </div>
              <div *ngSwitchCase="'websiteId'" [pSortableColumn]="'websiteId.title'">
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
                </p-sortIcon><br/>
              </div>
              <div *ngSwitchDefault [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
                </p-sortIcon><br/>
              </div>
            </ng-container>
            </th>
        </tr>
        <tr>
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <!-- TODO add in sort -->
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'name'">
            <input pInputText type="search" (input)="applyFilter($event, 'alarmUserDetails', 'name-contains')" style="width: 100%;">
          </div>
            <div *ngSwitchCase="'brand'">
              <p-multiSelect [options]="brands" placeholder="Brand" styleClass="brandcss" [panelStyle]="{minWidth:'12em'}"
                scrollHeight="600px" [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                (onChange)="dt.filter($event.value,col.field,'in')" [style]="{'display':'flex'}">
                <ng-template let-Pbrand let-i="index" pTemplate="item">
                  <span class="brandBar"
                    [ngStyle]="{'background-color': getBrandBackgroundColour(Pbrand.label), 'color': getBrandColour(Pbrand.label)}">
                    &nbsp;{{Pbrand.label}}
                  </span>
                </ng-template>
              </p-multiSelect>
            </div>
            <div *ngSwitchCase="'tdCode'">
              <input  pInputText type="search" (input)="applyFilter($event, 'tdCode', 'equals')" style="width: 100%;">
            </div>
            <div *ngSwitchCase="'paymentRef'">
              <input  pInputText type="search" (input)="applyFilter($event, 'paymentRef', 'equals')" style="width: 100%;">
            </div>
            <div *ngSwitchCase="'leadType'">
              <p-multiSelect [options]="leadTypes" placeholder="Lead Type"
                [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                (onChange)="dt.filter($event.value,col.field,'in')" [style]="{'display':'flex'}">
              </p-multiSelect>
            </div>
            <div *ngSwitchCase="'alarmUserDetails.telephone'">
              <input pInputText type="search" (input)="applySubFieldFilter($event, 'alarmUserDetails', 'subfield-startsWith', ['telephone'])" style="width: 100%;">
            </div>
            <div *ngSwitchCase="'alarmUserDetails.email'">
              <input pInputText type="search" (input)="applySubFieldFilter($event, 'alarmUserDetails', 'subfield-startsWith', ['email'])" style="width: 100%;">
            </div>
            <div *ngSwitchCase="'alarmUserDetails.userAddress.postcode'">
              <input pInputText type="search" (input)="applySubFieldFilter($event, 'alarmUserDetails', 'subfield-startsWith', ['userAddress', 'postcode'])" style="width: 100%;">
            </div>
            <div *ngSwitchCase="'ownerName'">
              <p-dropdown [options]="ownerNames" placeholder="Owner" [showClear]="true" [filter]="true"
              [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" selectedItemsLabel="{0} selected"
              (onChange)="dt.filter($event.value,col.field,'equals')" [style]="{'display':'flex'}">
              </p-dropdown>
            </div>
            <div *ngSwitchCase="'status'">
              <p-multiSelect [options]="statusSelects" [(ngModel)]="selectedStatus" placeholder="Status"
                [panelStyle]="{minWidth:'12em'}" scrollHeight="600px" [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                (onChange)="dt.filter($event.value,col.field,'in')" [style]="{'display':'flex'}">
              </p-multiSelect>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" [class]="getRowClass(rowData)">
          <td>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" [ngSwitch]="col['field']">
            <div *ngSwitchCase="'name'">
              {{rowData['alarmUserDetails']['firstName'] + ' ' + rowData['alarmUserDetails']['lastName']}}
            </div>
            <div *ngSwitchCase="'brand'">
              <div class="brandBar"
                [ngStyle]="{'background-color': getBrandBackgroundColour(rowData.brand), 'color': getBrandColour(rowData.brand)}">
                {{rowData.brand}}
              </div>
            </div>
            <div *ngSwitchCase="'tdCode'">
              <a routerLink="/order/{{rowData.orderId}}" target="_blank">
                {{rowData.tdCode}}
                <div *ngIf="isOrderLocked(rowData.orderId)">
                  {{orderLockedBy(rowData.orderId)}}
                </div>
              </a>
            </div>
            <div *ngSwitchCase="'ownerName'">
              {{rowData['ownerName']}}
            </div>
            <div *ngSwitchCase="'alarmUserDetails.telephone'">
              {{rowData['alarmUserDetails']['telephone']}}
            </div>
            <div *ngSwitchCase="'alarmUserDetails.email'">
              {{rowData['alarmUserDetails']['email']}}
            </div>
            <div *ngSwitchCase="'alarmUserDetails.userAddress.postcode'">
              {{rowData['alarmUserDetails']['userAddress']['postcode']}}
            </div>
            <div *ngSwitchCase="'referralDate'">
              {{rowData['referralDate']?moment.tz(rowData['referralDate'],'Europe/London').format('DD/MM/YYYY'):'Date missing'}}
            </div>
            <div *ngSwitchCase="'callDate'">
              {{rowData['callDate']?moment.tz(rowData['callDate'],'Europe/London').format('DD/MM/YYYY @ HH:mm:ss'):'Date missing'}}
            </div>
            <div *ngSwitchCase="'actions'">
              <button type="button" class="btn btn-primary" (click)="leadOpenMode = 'edit'; editViewLeadId = rowData._id;">
                Edit
              </button>
              <button type="button" class="btn btn-info ml-2" (click)="leadOpenMode = 'view'; editViewLeadId = rowData._id">
                View
              </button>
              <button type="button" class="btn btn-primary ml-2" (click)="callLeadId = rowData._id">
                Call
              </button>
              <button type="button" class="btn btn-danger ml-2" (click)="deleteRecord(rowData._id)" *ngIf="deleteButtonsDisabled == false">
                Delete
              </button>
              <div *ngIf="leadLockedBy(rowData._id)">{{leadLockedBy(rowData._id)}}</div>
            </div>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Update lead-component, basically a popup which will be enabled on click of call button -->
<app-update-lead *ngIf="callLeadId" [leadId]="callLeadId" (closeModal)="closeModal($event)">
</app-update-lead>

<!-- Add, Edit and View lead component, basically a popup which will be enabled on click either of
   add new lead, edit or view button -->
<app-add-lead *ngIf="leadOpenMode" [openMode]="leadOpenMode" [leadId]="editViewLeadId" (closeModal)="closeEditViewLead($event)">
</app-add-lead>
<p-toast></p-toast>

<div *ngIf="displayImportDialog" class="import-leads">
  <p-dialog header="Import Leads" [closable]="false" [(visible)]="displayImportDialog" [style]="{width: '75vw'}">
  <div *ngIf="leadImportStatus == 'success'">
    <div class="col-12 alert alert-success" role="alert">
      Lead import success!
    </div>
  </div>
  <div *ngIf="leadImportStatus == 'warning'">
    <div class="col-12 alert alert-warning" role="alert">
    Lead import success, but with warnings.
    {{importError}}
    </div>
  </div>
  <div *ngIf="leadImportStatus == 'failed'">
    <div class="col-12 alert alert-danger" role="alert">
    {{importError}}
    </div>
  </div>
  <div *ngIf="!displayDuplicates else duplicateRecords" class="row">
    <div class="col-12 mb-2">
    <input type="file" (change)="incomingFile($event)" style="display: inline-block;">
    </div>
  </div>
  <ng-template #duplicateRecords>
    <div class="col-12">
    Possible duplicate leads have been found. The brand, name and any non-blank values in telephone, mobile, address line 1 and postcode match
    an existing lead. Please select any you wish to import anyway and then click "Upload including selected duplicates".
    </div>
    <div class="col-12">
    <p-table [value]="possibleDuplicateLeads" [columns]="duplicatesCols"  [(selection)]="selectedDuplicateLeads">
      <ng-template pTemplate="header" let-columns>
      <tr>
        <th>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns">
        {{col.header}}
        </th>
      </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <div *ngSwitchCase="'name'">
          {{rowData['alarmUserDetails']['firstName'] + ' ' + rowData['alarmUserDetails']['lastName']}}
        </div>
        <div *ngSwitchCase="'telephone'">
          {{rowData['alarmUserDetails']['telephone']}}
        </div>
        <div *ngSwitchCase="'mobile'">
          {{rowData['alarmUserDetails']['mobile']}}
        </div>
        <div *ngSwitchCase="'addressOne'">
          {{rowData['alarmUserDetails']['userAddress']['addressOne']}}
        </div>
        <div *ngSwitchCase="'postcode'">
          {{rowData['alarmUserDetails']['userAddress']['postcode']}}
        </div>
        <div *ngSwitchCase="'brand'">
          <div class="brandBar"
            [ngStyle]="{'background-color': getBrandBackgroundColour(rowData.brand), 'color': getBrandColour(rowData.brand)}">
          {{rowData.brand}}
          </div>
        </div>
        <div *ngSwitchDefault>
          {{rowData[col.field]}}
        </div>
        </td>
      </tr>
      </ng-template>
    </p-table>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeImport()">Close</button>
    <ng-container *ngIf="!displayDuplicates else processDuplicatesBtn">
    <button type="button" class="btn btn-primary" [disabled]="!isOkToUpload()" (click)="uploadLeadsSheet()" style="margin-right: 10px;">
      Upload
    </button>
    </ng-container>
    <ng-template #processDuplicatesBtn>
    <button type="button" class="btn btn-primary" [disabled]="disableProcessDuplicates" (click)="processDuplicates()" style="margin-right: 10px;">
      Upload including selected duplicates
    </button>
    </ng-template>
  </ng-template>
  </p-dialog>
</div>
<p-confirmDialog key='general'></p-confirmDialog>
