import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {RenewalLink} from '../../models/renewalLink.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RenewalLinkService {
  API_URL = environment.protocol + environment.IPAddress + '/api/renewal-links';
  constructor(
    private http:HttpClient
  ) { }

  getRenewalLinks(brand:string) {
    return this.http.get<MultiRecordResponse<RenewalLink>>(`${this.API_URL}/${brand}`);
  }

  addRenewalLink(params:RenewalLink) {
    return this.http.post<SingleRecordResponse<RenewalLink>>(this.API_URL, params);
  }

  updateRenewalLink(params:RenewalLink): Observable<SimpleResponse> {
    return this.http.patch<SimpleResponse>(this.API_URL, params);
  }

  deleteRenewalLink(renewalLinkId: string) {
    return this.http.delete<SimpleResponse>(`${this.API_URL}/${renewalLinkId}`);
  }
}
