<p-dialog (onHide)="closeDialog()" header="Add Customer Feedback" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    [appendTo]="'body'" [styleClass]="'addFeedbackDialog'">
  <!-- append to body is to stop the contents of this dialog being part of the post-order component form -->
  <form [formGroup]="customerFeedbackForm">
    <div class="row">
      <div *ngIf="resultMessage" class="col-12 form-group">
        <div class="alert alert-danger">
          {{resultMessage}}
        </div>
      </div>
      <ng-container *ngIf="order; else noOrder">
        <div class="col-4 col-lg-2 form-group">
          <label for="brand">Brand:</label>
          <input type="text" class="form-control" name="brand" id="brand" value="{{order.website.title}}" readonly="true"
          [ngStyle]="{'background-color': getBrandBackgroundColour(order.website.title), 'color': getBrandColour(order.website.title)}"/>
        </div>
        <div class="col-4 col-lg-2">
          <label for="brand">TD Code:</label>
          <a routerLink="/order/{{order._id}}" target="_blank">
            {{order.alarmUserDetails.tdCode}}
            <span *ngIf="isOrderLocked(order._id)">
              {{orderLockedBy(order._id)}}
            </span>
          </a>
        </div>
        <div class="col-4 col-lg-8"></div>
      </ng-container>
      <ng-template #noOrder>
        <div class="col-4 col-lg-2 form-group">
          <label for="brand">Brand:</label>
          <p-dropdown [options]="websites" name="brand" formControlName="websiteId" appendTo="body" 
              placeholder="Select a Brand" [required]="true" [baseZIndex]="3200">
            <ng-template let-selectedBrand pTemplate="selectedItem">
              <div *ngIf="selectedBrand.label">
                <div>{{selectedBrand.label}}</div>
              </div>
            </ng-template>
            <ng-template let-brand pTemplate="item">
              <span class="brandBar"
                  [ngStyle]="{'background-color': getBrandBackgroundColour(brand.label), 'color': getBrandColour(brand.label)}">
                {{brand.label}}
              </span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-8 col-lg-10"></div>
      </ng-template>
      <div class="col-4 col-lg-2 form-group">
        <label for="feedbackType">Feedback Type:</label>
        <p-dropdown [options]="feedbackTypes" name="feedbackType" id="feedbackType" formControlName="feedbackType"
          placeholder="Feedback Type" [required]="true" appendTo="body" [baseZIndex]="3200"
          (onChange)="changeFeedbackType($event.value)">
        </p-dropdown>
      </div>
      <div class="col-4 col-lg-2 form-group">
        <label for="priority">Priority:</label>
        <p-dropdown [options]="feedbackPriorities" name="priority" id="priority" formControlName="priority"
          placeholder="Priority" [required]="true" appendTo="body" [baseZIndex]="3200">
        </p-dropdown>
      </div>
      <div class="col-4 col-lg-2 form-group">
        <label for="partnership">Partnership:</label>
        <p-dropdown [options]="feedbackPartnerships" name="partnership" id="partnership" formControlName="partnership"
          placeholder="Partnership" [required]="true" appendTo="body" [baseZIndex]="3200">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="whoContactedUs">Who Contacted Us:</label>
        <input type="text" class="form-control" name="whoContactedUs" id="whoContactedUs" formControlName="whoContactedUs" required="true"/>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="relationshipToUser">Relationship To User:</label>
        <input type="text" class="form-control" name="relationshipToUser" id="relationshipToUser" formControlName="relationshipToUser" required="true"/>
      </div>
    </div>
    <div class="row" [ngClass]="customerFeedbackForm.errors?.contactDetailsRequired? 'valueRequired': 'valueProvided'">
      <div class="col-6 form-group">
        <label for="emailAddress">Email Address:</label>
        <input type="text" class="form-control validated" name="emailAddress" id="emailAddress" formControlName="emailAddress" [required]="emailRequired" />
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="phoneNumber">Phone Number:</label>
        <input type="text" class="form-control validated" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber"
          (keypress)="numberOnly($event, false)"/>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="contactMethod">Contact Method:</label>
        <p-dropdown [options]="feedbackContactMethods" name="contactMethod" id="contactMethod" formControlName="contactMethod"
          placeholder="Contact Method" [required]="true" appendTo="body" [baseZIndex]="3200" (onChange)="updateEmailAddressRequired()">
        </p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-lg-3 form-group">
        <label for="mainReason">Main Reason:</label>
        <p-dropdown [options]="feedbackMainReasons" name="mainReason" id="mainReason" formControlName="mainReason"
          placeholder="Main Reason" [required]="true" appendTo="body" [baseZIndex]="3200"
          (onChange)="changeMainReason($event.value)">
        </p-dropdown>
      </div>
      <div class="col-6 col-lg-3 form-group">
        <label for="specificReason">Specifically:</label>
        <p-dropdown [options]="feedbackSpecificReasons" name="specificReason" id="specificReason" formControlName="specificReason"
          placeholder="Specific Reason" [required]="true" appendTo="body" [baseZIndex]="3200">
        </p-dropdown>
      </div>
      <div class="col-6 form-group">
        <label for="feedbackDetails">Feedback Details:</label>
        <textarea name="feedbackDetails" class="form-control" rows="3" formControlName="feedbackDetails" required="true">
        </textarea>
      </div>
      <div class="col-6 form-group">
        <label for="resolution">Resolution:</label>
        <textarea name="resolution" class="form-control" rows="3" formControlName="resolution" [required]="isResolutionRequired" >
        </textarea>
      </div>
      <div class="col-6 form-group">
        <label for="respondByEmail">Respond To Customer By Email:</label>
        <input type="checkbox" name="respondByEmail" id="respondByEmail" formControlName="respondByEmail"
          (change)="updateEmailAddressRequired()"/>
      </div>
      <div class="col-6 form-group">
        <label for="resolvedAtFirstContact">Resolved at 1st Contact:</label>
        <input type="checkbox" name="resolvedAtFirstContact" id="resolvedAtFirstContact" formControlName="resolvedAtFirstContact" 
          (change)="changeResolvedAtFirstContact($event)"/>
      </div>
      <div class="col-12 col-xl-6 form-group">
        <label for="ccTo">CC To:</label>
        <textarea name="ccTo" class="form-control validated" rows="3" formControlName="ccTo">
        </textarea>
        <span class="hint">semi colon, space or newline separated</span>
      </div>
      <div class="col-12 col-xl-4 form-group">
        <div class="row">
          <div class="col-6">
            <label for="freeServiceGiven">Free Service Given:</label>
            <input type="checkbox" name="freeServiceGiven" id="freeServiceGiven" formControlName="freeServiceGiven"
              (change)="freeServiceChanged()" />
          </div>
          <div class="col-6" *ngIf="isFreeServiceGiven">
            <label for="datePaymentRestarts">Payment Restarts:</label>
            <input type="date" name="datePaymentRestarts" id="datePaymentRestarts" formControlName="datePaymentRestarts"
              required min="{{minDateStr}}"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-2 form-group">
        <label for="status">Status:</label>
        <p-dropdown [options]="feedbackStatuses" name="status" id="status" formControlName="status"
          placeholder="Feedback Status" [required]="true" appendTo="body" [baseZIndex]="3200"
          (onChange)="changeFeedbackStatus($event.value)" [readonly]="isStatusReadOnly"
          [styleClass]="isStatusReadOnly? 'readonly': ''">
        </p-dropdown>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToAdd" (click)="addFeedback()">
      Submit
    </button>
  </ng-template>
</p-dialog>
<app-email-send *ngIf="showSendEmailDialog" [order]="order" [tags]="tags" [emailTypes]="reviewTemplates"
  [fieldInitialisation]="{}" (closeModal)="closeSendEmailDialog()">
</app-email-send>
<p-confirmDialog key="general" [baseZIndex]="5000"></p-confirmDialog>
<p-confirmDialog key="error" [baseZIndex]="6000"></p-confirmDialog>