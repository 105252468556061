import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {GravityFormResponse} from '../models/responses/gravityFormResponse.model';

@Injectable({
  providedIn: 'root'
})
export class GravityformsService {
  private API_URL: string = environment.protocol + environment.IPAddress + '/api';

  constructor(private http: HttpClient) {
  }
  createForm (id: string, form: {[field: string]: string}): Observable<SingleRecordResponse<GravityFormResponse>> {
    return this.http.post<SingleRecordResponse<GravityFormResponse>>(`${this.API_URL}/gravityforms/${id}`, form);
  }
}
