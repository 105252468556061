import {Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SendEmailRequest} from '../../models/requests/sendEmailRequest.model';
import {emailValidator} from '../../validators/email.validator';
import {DomSanitizer} from '@angular/platform-browser';
import {SecurityContext} from '@angular/core';
import {NotificationService} from '../../notifications/notification.service';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {environment} from '../../../environments/environment';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-email-note[orderId][tdCode][alarmCode][custName][noteContent][closeModal]',
  templateUrl: './email-note.component.html',
  styleUrls: ['./email-note.component.scss']
})
export class EmailNoteComponent implements OnInit {
  @Input() orderId: string;
  @Input() tdCode: string;
  @Input() alarmCode: string;
  @Input() custName: string;
  @Input() noteContent: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  dialogVisible: boolean;
  resultMessage: string;
  resultSuccess: boolean;
  emailForm: FormGroup;
  sendDisabled: boolean;
  emailAddresses: SelectItem<string>[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      'emailAddress': ['', emailValidator],
      'emailSubject': [`TD Code: ${this.tdCode} Alarm Code: ${this.alarmCode}`, Validators.required],
      'emailBody': [this.noteContent, Validators.required],
    });
    if (localStorage.getItem('email: Dropdown')) {
      this.emailAddresses = JSON.parse(localStorage.getItem('email: Dropdown'));
    }
    this.sendDisabled = false;
    this.dialogVisible = true;
  }

  sendEmail() {
    const username: string = localStorage.getItem('userName');
    this.resultMessage = '';
    this.sendDisabled = true;
    const params: SendEmailRequest = {
      'recipients': [this.emailForm.get('emailAddress').value],
      'subject': this.emailForm.get('emailSubject').value,
      'plainTextMsg': this.emailForm.get('emailBody').value +
        `\nTD Code: ${this.tdCode}\nCustomer: ${this.custName}` +
        `\nOrder: ${environment.protocol}${environment.IPAddress}/order/${this.orderId}\nSent By: ${username}`,
      'htmlMsg': `<p>${this.sanitizer.sanitize(SecurityContext.HTML, this.emailForm.get('emailBody').value)}</p>` +
          `<p>TD Code: ${this.tdCode}<br/>Customer: ${this.custName}<br/>` +
          `Order: <a href="${environment.protocol}${environment.IPAddress}/order/${this.orderId}" target="_blank">` +
          `${environment.protocol}${environment.IPAddress}/order/${this.orderId}</a></p><p>Sent By: ${username}</p>`,
    };
    this.notificationService.sendEmail(params).subscribe((response: MultiRecordResponse<string>) => {
      this.sendDisabled = false;
      if ((response.success) && (response.data.length == 0)) {
        this.resultMessage = 'Email sent successfully';
        this.resultSuccess = true;
      } else {
        this.resultMessage = `Error sending email. Error: ${response.message}`;
        this.resultSuccess = false;
      }
      
    }, (err: Error) => {
      this.sendDisabled = false;
      this.resultMessage = `Error sending email. Error: ${err.message}`;
      this.resultSuccess = false;
    });
  }

  isOkToSend(): boolean {
    return this.emailForm.valid && !this.sendDisabled;
  }

  closeDialog(): void {
    this.resultMessage = '';
    this.closeModal.emit();
  }
}
