<ng-template #accountMenu>
  <div class="navbar-nav">
    <div ngbDropdown #acDropdown="ngbDropdown">
      <a class="nav-link dropdown-toggle" ngbDropdownToggle id="acDropdown"
          [ngStyle]="outstandingNotificationCount>0?{'background-color':'yellow','color':'black'}:{}">
        <i class="fa fa-user mr-1"></i>
        <span *ngIf="userName">{{ userName}}</span>
        <span class="badge badge-dark ml-1">{{outstandingNotificationCount}}</span>
      </a>
      <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="acDropdown" [ngStyle]="{ 'background-color': navBarColour }">
        <a (click)="closeDropdown(acDropdown);" routerLink="/profile" class="dropdown-item">
          <i class="fa fa-user-circle" aria-hidden="true"></i> Profile
        </a>
        <a (click)="closeDropdown(acDropdown); openDisplayNotificationDialog()" class="dropdown-item">
          <i class="fas fa-bell"></i> Display Notifications
        </a>
        <a (click)="closeDropdown(acDropdown);openCreateNotificationDialog()" class="dropdown-item">
          <i class="fas fa-bell"></i> Create Notification
        </a>
        <a (click)="closeDropdown(acDropdown);logout()" class="dropdown-item">
          <i class="fa fa-key" aria-hidden="true"></i> Logout
        </a>
        <a (click)="closeDropdown(acDropdown)" class="dropdown-item version" href="assets/release-notes/{{crmClientVersion}}.pdf" target="_blank">
          CRM Client version: {{crmClientVersion}}
        </a>
      </div>
    </div>
  </div>
</ng-template>

<nav class="navbar navbar-expand-lg navbar-dark" [ngStyle]="{ 'background-color': navBarColour }">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand">
      {{crmName}}
    </a>
    <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>
    <ng-container *ngIf="isSmallerThanLarge">
      <ng-container *ngTemplateOutlet="accountMenu"></ng-container>
    </ng-container>
    <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse flex-column" id="navbarNav">
      <div class="w-100">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
              Home
            </a>
          </li>
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/cses" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
              Order
            </a>
          </li>
          <li ngbDropdown #logsDropdown="ngbDropdown" routerLinkActive="active">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle id="boardsDropdown">
              Boards
            </a>
            <div ngbDropdownMenu aria-labelledby="boardsDropdown" class="dropdown-menu" [ngStyle]="{ 'background-color': navBarColour }">
              <a *ngFor="let boardName of boards"
                (click)="collapse()" routerLink="/boards/{{boardName}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                {{boardName}}
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/messages" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
              Messages Review
            </a>
          </li>
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/leads" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Leads</a>
          </li>
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/customer-feedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
              Customer Feedback
            </a>
          </li>
          <li *ngIf="true" class="nav-item">
            <a (click)="collapse()" routerLink="/faulty-equipments" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Faulty Equipment</a>
          </li>
          <li *ngIf="false" ngbDropdown #dispatchDropdown="ngbDropdown" routerLinkActive="active">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle id="dispatchDropdown">
              Dispatch
            </a>
            <div ngbDropdownMenu aria-labelledby="dispatchDropdown" class="dropdown-menu" [ngStyle]="{ 'background-color': navBarColour }">
              <a (click)="collapse()" routerLink="/faulty-equipments" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Faulty Equipment
              </a>
              <a (click)="collapse()" routerLink="/post-sheets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Post Sheets
              </a>
              <a (click)="collapse()" routerLink="/post-sheets-item-report" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Post Sheets Item Report
              </a>
              <a (click)="collapse()" routerLink="/post-sheets-infopack-report" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Post Sheets Info Pack Report
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a (click)="collapse()" routerLink="/feedback" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
              Bug Report
            </a>
          </li>
          <li ngbDropdown #logsDropdown="ngbDropdown" routerLinkActive="active">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle id="logsDropdown">
              Logs
            </a>
            <div ngbDropdownMenu aria-labelledby="logsDropdown" class="dropdown-menu" [ngStyle]="{ 'background-color': navBarColour }">
              <a (click)="collapse()" routerLink="/as-at-order-report" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                As At Order Report
              </a>
              <a (click)="collapse()" routerLink="/logs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Change log
              </a>
              <a (click)="collapse()" routerLink="/cse-order-report" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                CSE Order Report
              </a>
              <a (click)="collapse()" routerLink="/hardware-audit" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Hardware and Sets Audit
              </a>
              <a (click)="collapse()" routerLink="/importlogs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Import log
              </a>
            </div>
          </li>
          <li ngbDropdown #setupDropdown="ngbDropdown" routerLinkActive="active">
            <a class="nav-link dropdown-toggle" ngbDropdownToggle id="setupDropdown">
              Setup
            </a>
            <div ngbDropdownMenu aria-labelledby="setupDropdown" class="dropdown-menu" [ngStyle]="{ 'background-color': navBarColour }">
              <a (click)="collapse()" routerLink="/actions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Actions
              </a>
              <a (click)="collapse()" routerLink="/announcements" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Announcements
              </a>
              <a (click)="collapse" routerLink="/business-growth-customers" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Business Growth
              </a>
              <a (click)="collapse" routerLink="/cancellation-links" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Cancellation Links
              </a>
              <a (click)="collapse()" routerLink="/coupons" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Coupons
              </a>
              <a (click)="collapse()" routerLink="/friends-and-family-content" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Friends and Family Content
              </a>
              <a (click)="collapse()" routerLink="/hardware" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Hardware
              </a>
              <a (click)="collapse()" routerLink="/hardware-sets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Hardware Sets
              </a>
              <a (click)="collapse()" routerLink="/manuals" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Manuals
              </a>
              <a (click)="collapse()" routerLink="/products" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Products
              </a>
              <a (click)="collapse" routerLink="/renewal-links" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Renewal Links
              </a>
              <a (click)="collapse" routerLink="/roles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Roles
              </a>
              <a (click)="collapse()" routerLink="/account-services" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Services
              </a>
              <a (click)="collapse()" routerLink="/tags" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Tags
              </a>
              <a (click)="collapse()" routerLink="/tooltips" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Tooltips
              </a>
              <a (click)="collapse()" routerLink="/users" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">
                Users
              </a>
              <a (click)="collapse()" routerLink="/websites" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" class="dropdown-item">
                Websites / API
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <ng-container *ngIf="!isSmallerThanLarge">
      <ng-container *ngTemplateOutlet="accountMenu"></ng-container>
    </ng-container>
  </div>

</nav>
<nav *ngIf="crmName == 'CRM BACKUP'" class="navbar navbar-expand-md navbar-dark" style="background-color:black">
  <div class="container-fluid" style="color: orange">
    THIS IS THE BACKUP SERVER
  </div>
</nav>
<div *ngIf="announcementMessage" class="scroll-left-container">
  <div class="scroll-left">
    <p class="annoucement">{{announcementMessage}}</p>
    <p class="annoucement2">{{announcementMessage}}</p>
  </div>
</div>

<app-create-notification *ngIf="isCreateNotificationDialogOpen"
    (closeCreateNotificationDialog)="closeCreateNotificationDialog()">
</app-create-notification>

<app-display-notifications *ngIf="unreadNotificationCount || isDisplayNotificationDialogOpen"
    (closeDisplayNotificationDialog)="closeDisplayNotificationDialog()"
    [isOutstandingNotifications]="unreadNotificationCount">
</app-display-notifications>