import {ConfirmationService} from 'primeng/api';
import {LogsService} from '../../../reporting/logs/logs.service';
import {ExcelExportService} from './../../excel-export.service';
import {FilterMetadata, MessageService, SelectItem, SortEvent, FilterService} from 'primeng/api';
import {FaultyEquipmentService} from './../faulty-equipment.service';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment-timezone';
import {equipmentCategories, getAllFaultTypes, getNotFixedReasons, shelfLocations} from '../../../lookups/faultyEquipment';
import {FaultyEquipment} from '../../../models/faultyEquipment.model';
import {MultiRecordResponse} from '../../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Table} from 'primeng/table';
import {dateRangeFilters} from '../../../helpers/tableDateRangeFilters';
import {LocksSocketService} from '../../../sockets/locks-socket.service';
import {Title} from '@angular/platform-browser';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Column} from '../../../models/column.model';
import {escapeForRegExp, getBase64EncodedFileContents} from '../../../helpers/helperFunctions';
import {SpreadsheetImportService} from '../../../my-orders/spreadsheet-import.service';
import {SimpleResponse} from '../../../models/responses/simpleResponse.model';
import {LogWithUpload} from '../../../models/log.model';
import {OrderLockData} from '../../../models/socket-io/orderLockData.model';

@Component({
  selector: 'app-display-faulty-equipments',
  templateUrl: './display-faulty-equipments.component.html',
  styleUrls: ['./display-faulty-equipments.component.scss'],
  providers:[MessageService,ConfirmationService]
})
export class DisplayFaultyEquipmentsComponent implements OnInit, OnDestroy {
  @ViewChild('dt', {static: true}) private table: Table;
  @ViewChild('returnsFileUpload', {static: false}) private returnsFileUpload: ElementRef;
  faultyEquipments: FaultyEquipment[] = [];
  loading: boolean = true;
  cols: { field: string, header: string }[] = [];
  faultyEquipmentDetailId: string = null;
  isFaultyEquipmentDetailReadOnly: string;
  isFaultyEquipmentVisible: boolean = false;
  searchableFields: string[] = [];
  troubleshootingCorrectCount: number = 0;
  troubleshootingIncorrectCount: number = 0;
  notYetTestedCount: number = 0;
  selectedRows: FaultyEquipment[] = [];
  orderLockList: OrderLockData[] = [];
  orderExportOptions: string[] = [
    '1 sheet, 1 tab total',
    '1 sheet, 1 tab/category',
    '1 sheet/category',
    // 'Flag as sent to supplier',
    // 'Flag as received from supplier'
  ];
  equipmentCategories: SelectItem<string>[] = equipmentCategories;
  allFaults: SelectItem<string>[];
  troubleshootingOptions: SelectItem<string>[] = [
    {'label': 'Yes', 'value': 'Yes'},
    {'label': 'No', 'value': 'No'},
    {'label': 'N/A', 'value': 'N/A'},
  ];
  shelfLocations: SelectItem<number>[] = shelfLocations;
  dateTestedFilters: Date[];
  dateReportedFilters: Date[];
  rowsProcessFlag: string;
  trackingIdToUpdate: string;
  dispatchDateToUpdate: string;
  receivedFromSupplierDate: string;
  serial: string;
  today: string = moment.tz('Europe/London').startOf('day').format('YYYY-MM-DD');
  showReturnDialog: boolean = false;
  returnFormsGroup: FormGroup;
  selectedReturnsForms: FormGroup[];
  returnsColumns: Column[] = [
    { field: 'serial', header: 'Serial' },
    { field: 'warranty', header: 'Warranty?' },
    { field: 'fixed', header: 'Fixed?' },
    { field: 'reason', header: 'Reason Not Fixed' },
    { field: 'returnDate', header: 'Return Date' },
  ];
  yesNoOptions: SelectItem<string>[];
  notFixedReasons: SelectItem<string>[];
  notFixedReasonsForBulkUpdate: SelectItem<string>[];
  selectedWarrantyOption: string;
  selectedFixedOption: string;
  selectedReasonOption: string;
  maxToProcess: number;
  progress: number;
  file: File;
  filterYearRange: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private faultyEquipmentService: FaultyEquipmentService,
    private messageService: MessageService,
    private excelService: ExcelExportService,
    private logService: LogsService,
    private filterService: FilterService,
    private confirmationService: ConfirmationService,
    private locksSocket: LocksSocketService,
    private title: Title,
    private fb: FormBuilder,
    private spreadsheetImportService: SpreadsheetImportService,
  ) { }

  ngOnInit(): void {
    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.orderLockList = data;
    });
    this.locksSocket.emit('getLocked');
    this.initializeVariables();
    this.allFaults = getAllFaultTypes()
    this.getAllFaultyEquipment();
    this.notFixedReasons = getNotFixedReasons();
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      const passedSerial: string = params.get('serial');
      if (passedSerial) {
        this.table.filter(passedSerial, 'serial', 'contains');
      }
    });
    this.title.setTitle('CRM Faulty Equipment');
    this.returnFormsGroup = this.fb.group({
      'returnForms': this.fb.array([]),
    });
    this.notFixedReasonsForBulkUpdate = this.notFixedReasons.concat([{
        'label': 'Do not update', 'value': ''
      }
    ]);
    this.yesNoOptions = [{
        'label': 'Yes', 'value': 'yes'
      },{
        'label': 'No', 'value': 'no'
      },{
        'label': 'Do not update', 'value': ''
      }];    
  }

  ngOnDestroy() {
    this.locksSocket.removeAllListeners();
  }

  exportSelectedRows(option: string) {
    let excelResponse = null;
    const dataToExport = {};
    this.selectedRows.forEach(element => {
      const newElement = {
        'Td Code': element.orderId?.alarmUserDetails.tdCode,
        'Equipment Type': element.equipmentType,
        'Equipment': element.equipmentBrand,
        'Reported Fault': element.reportedFault,
        'Additional Note':element.additionalNote,
        'Reporting User': element.reportingUser,
        'Reported Date': moment(element.reportedAt).format('DD/MM/YYYY').replace('Invalid date', ''),
        'Serial Number': element.serial,
        'Faulty Product': element.faultyProduct ? element.faultyProduct : '',        
        'Out Come Of Test': element.outcomeOfTest,
        'Date Tested': moment(element.dateTested).format('DD/MM/YYYY').replace('Invalid date', ''),
        'Tester': element.tester,
        'Date Sent To Supplier': moment(element.dateSentToSupplier).format('DD/MM/YYYY').replace('Invalid date', ''),
        'Date Returned From Supplier': moment(element.dateReturnedFromSupplier).format('DD/MM/YYYY').replace('Invalid date', ''),
        'Fixed': (element.fixed == null)? '': !!element.fixed? 'Yes' : 'No',
        'Troubleshooting Correct': element.troubleShootingCorrect,
      };
      let equipmentType = element.equipmentType;
      if (equipmentType == 'Pendant/Accessories')
        equipmentType = 'Pendant_Accessories'
      
      if (dataToExport[equipmentType]) {
        dataToExport[equipmentType].push(newElement);
      } else {
        dataToExport[equipmentType] = [newElement];
      }
    });
    if (this.selectedRows.length > 0) {
      if (option == '1 sheet, 1 tab total') {
        const data: any[] = [];
        for (let key in dataToExport) {
          data.push(...dataToExport[key]);
        }
        excelResponse = this.excelService.exportAsExcelFile({ data: data },'Faulty Equipment');
      } else if (option == '1 sheet, 1 tab/category') {
        excelResponse = this.excelService.exportAsExcelFile(dataToExport, 'Faulty Equipment');
      } else if (option == '1 sheet/category') {
        for (let key in dataToExport) {
          excelResponse = this.excelService.exportAsExcelFile({ [key]: dataToExport[key] }, key.replace('\ / ? * [ ]','_'));
        }
      }
      let equipmentType = this.selectedRows[0].equipmentType;
      if (equipmentType == 'Pendant/Accessories') {
        equipmentType = 'Pendant_Accessories';
      }
      const updateLog: LogWithUpload = {
        fileName: excelResponse.fileName,
        source: 'FaultyEquipment',
        nbRecorders: this.selectedRows.length,
        fields: Object.keys(dataToExport[equipmentType][0]),
        user: localStorage.getItem('userName'),
        base64Contents: excelResponse.contents,
      }
      this.logService.createLog({ log: updateLog }).subscribe((_response: SimpleResponse) => {
      });
    }
  }

  showFaultyEquipmentDetail(id: string, isReadOnly: string) {
    this.faultyEquipmentDetailId = id;
    this.isFaultyEquipmentDetailReadOnly = isReadOnly;
    this.isFaultyEquipmentVisible = true;
  }

  closeFaultyEquipmentDetailDialog(event: SingleRecordResponse<FaultyEquipment>) {
    this.faultyEquipmentDetailId = null;
    this.isFaultyEquipmentVisible = false;
    if (event.success) {
      const tmpFaultyEquipments: FaultyEquipment[] = [];
      this.faultyEquipments.forEach((fault: FaultyEquipment) => {
        if (fault._id === event.data._id) {
          if (event.data.deleted || (fault.troubleShootingCorrect != event.data.troubleShootingCorrect)) {
            switch (fault.troubleShootingCorrect) {
              case 'Yes':
                this.troubleshootingCorrectCount--;
                break;
              case 'No':
                this.troubleshootingIncorrectCount--;
            }
            if (!event.data.deleted) {
              switch (event.data.troubleShootingCorrect) {
                case 'Yes':
                  this.troubleshootingCorrectCount++;
                  break;
                case 'No':
                  this.troubleshootingIncorrectCount++;
              }
            }
          }
          if (event.data.deleted || (fault.dateTested != event.data.dateTested)) {
            if (fault.dateTested == null) {
              this.notYetTestedCount--;
            } else if (!event.data.deleted && (event.data.dateTested == null)) {
              this.notYetTestedCount++;
            }
          }
          if (!event.data.deleted) {
            tmpFaultyEquipments.push(event.data);
          }
        } else {
          tmpFaultyEquipments.push(fault);
        }
      });
      this.faultyEquipments = tmpFaultyEquipments;
      this.showSuccess();
    } else if (event.message != 'closed') {
      this.showError();
    }
  }

  getAllFaultyEquipment() {
    this.faultyEquipmentService.getAllFaultyEquipment().subscribe(
      (response: MultiRecordResponse<FaultyEquipment>) => {
        this.loading = false;
        if (response.success) {
          this.faultyEquipments = response.data;
          this.troubleshootingCorrectCount = this.faultyEquipments.filter(e => e.troubleShootingCorrect == 'Yes').length;
          this.troubleshootingIncorrectCount = this.faultyEquipments.filter(e => e.troubleShootingCorrect == 'No').length;
          this.notYetTestedCount = this.faultyEquipments.filter(e => e.dateTested == null).length;
        }
      }, (err: Error) => {
        console.error("ERR on getAllFaultyEquipment :: ", err);
      }
    );
  }

  initializeVariables() {
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    this.cols = [
      { field: 'orderId.alarmUserDetails.tdCode', header: 'Td Code' },
      { field: 'serial', header: 'Serial' },
      { field: 'equipmentType', header: 'Equipment Type' },
      { field: 'reportedFault', header: 'Reported Fault' },
      { field: 'outcomeOfTest', header: 'Outcome Of Test' },
      { field: 'troubleShootingCorrect', header: 'Troubleshooting Correct' },
      { field: 'reportedAt', header: 'Date Reported' },
      { field: 'dateTested', header: 'Date Tested' },
      { field: 'shelfLocation', header: 'Shelf Location' },
      { field: 'trackingId', header: 'Tracking Id' },
      { field: 'actions', header: 'Actions' },
    ];
    this.searchableFields = this.cols.map(e => e.field);
    let self = this;
    this.filterService.register('dateTested', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dateTestedFilters);
    });
    this.filterService.register('reportedAt', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dateReportedFilters);
    });
    this.dispatchDateToUpdate = this.today;
    this.receivedFromSupplierDate = this.today;
  }

  customSort(event: SortEvent) {
    event.data.sort((row1: any, row2: any): number => {
      let result: number = 0;
      let value1 = row1[event.field];
      let value2 = row2[event.field];
      if (event.field === 'orderId.alarmUserDetails.tdCode') {
        value1 = row1.orderId?.alarmUserDetails?.tdCode;
        value2 = row2.orderId?.alarmUserDetails?.tdCode;
      }
      if (event.field === 'dateTested') {
        // Want untested first when sorted descending by test date
        if (value1 == null && value2 != null) {
          result = 1;
        } else if (value1 != null && value2 == null) {
          result = -1;
        } else if (value1 == null && value2 == null) {
          result = (row1.reportedAt < row2.reportedAt)? -1: (row1.reportedAt > row2.reportedAt)? 1: 0;
        } else if (value1 < value2) {
          result = -1;
        } else if (value1 > value2) {
          result = 1;
        } else {
          result = (row1.reportedAt < row2.reportedAt)? -1: (row1.reportedAt > row2.reportedAt)? 1: 0;
        }
      } else {
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
      }
      return result * event.order;
    });
  }
  
  customReportDateSort(event: SortEvent) {
    event.data.sort((row1: any, row2: any): number => {
      let result: number = 0;
      let value1 = row1[event.field];
      let value2 = row2[event.field];
      if (event.field === 'orderId.alarmUserDetails.tdCode') {
        value1 = row1.orderId?.alarmUserDetails?.tdCode;
        value2 = row2.orderId?.alarmUserDetails?.tdCode;
      }
      if (event.field === 'reportedAt') {
        // Want sorted descending by reported date
        if (value1 == null && value2 != null) {
          result = 1;
        } else if (value1 != null && value2 == null) {
          result = -1;
        } else if (value1 == null && value2 == null) {
          result = (row1.reportedAt < row2.reportedAt)? -1: (row1.reportedAt > row2.reportedAt)? 1: 0;
        } else if (value1 < value2) {
          result = -1;
        } else if (value1 > value2) {
          result = 1;
        } else {
          result = (row1.reportedAt < row2.reportedAt)? -1: (row1.reportedAt > row2.reportedAt)? 1: 0;
        }
      } else {
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
      }
      return result * event.order;
    });
  }

  clearTestDateFilters() {
    delete this.table.filters['dateTested'];
    let filters: any = { ...this.table.filters };
    this.table.reset();
    for (let key in filters) {
      this.table.filter(filters[key].value,key,filters[key].matchMode)
    }
  }
  clearReportedDateFilters() {
    delete this.table.filters['reportedAt'];
    let filters: any = { ...this.table.filters };
    this.table.reset();
    for (let key in filters) {
      this.table.filter(filters[key].value,key,filters[key].matchMode)
    }
  }

  processRows(flag: string) {
    if ((this.selectedRows.length == 0) && (!flag.includes('serial'))) {
      this.showPopUp(
        'Process selected rows',
        'Please check records from table and then proceed'
      );
      return;
    }
    this.rowsProcessFlag = flag;
  }

  getSerialRegex(serial: string): RegExp {
    return new RegExp(`^([^:-]*[:-]\\s*)?${escapeForRegExp(serial.trim())}\\s*$`);
  }

  updateSelectedRows() {
    let params = {};
    const unmatchedSerials: string[] = [];
    if (this.rowsProcessFlag.startsWith('Flag as sent to supplier')) {
      params['sentToSupplier'] = {
        trackingId: this.trackingIdToUpdate,
        dateSentToSupplier:this.dispatchDateToUpdate?this.dispatchDateToUpdate:moment().format('YYYY/MM/DD')
      }
    } else if (this.rowsProcessFlag == 'Flag as received from supplier') {
      params['dateReturnedFromSupplier'] = this.receivedFromSupplierDate ? this.receivedFromSupplierDate : moment().format('YYYY/MM/DD');
    }
    if (this.rowsProcessFlag.includes('serial')) {
      const equipmentInDescDateOrder: FaultyEquipment[] = this.faultyEquipments.filter((equip: FaultyEquipment) => 
        equip.reportedAt
      ).sort((equipA: FaultyEquipment, equipB: FaultyEquipment) => 
        equipB.reportedAt.localeCompare(equipA.reportedAt)
      );
      params['faultyEquipmentIds'] = [];
      this.serial.split('\n').forEach((serial: string) => {
        const serialTestRegex: RegExp = this.getSerialRegex(serial);
        const matchedEquip: FaultyEquipment = equipmentInDescDateOrder.find((equip: FaultyEquipment) =>
          serialTestRegex.test(equip.serial)
        );
        if (!matchedEquip) {
          unmatchedSerials.push(serial.trim());
        } else {
          params['faultyEquipmentIds'].push(matchedEquip._id);
        }
      });
    } else {
      params['faultyEquipmentIds'] = this.selectedRows.map(row => row._id);
    }
    this.faultyEquipmentService.updateTrackingIdAndDates(params).subscribe((response: SimpleResponse) => {
      console.debug("Response ::", response);
      if (response.success) {
        if (unmatchedSerials.length > 0) {
          this.serial = unmatchedSerials.join('\n');
          this.showPopUp('Some serials unmatched', 
            'Some of the serials could not be matched to a record. These have been left on the popup.' +
            'The other records have been updated'
          );
        } else {
          this.showSuccess();
          this.dispatchDateToUpdate = this.today;
          this.receivedFromSupplierDate = this.today;
          this.trackingIdToUpdate = null;
          this.rowsProcessFlag = null;
          this.getAllFaultyEquipment();
        }
      } else {
        this.showError();
      }
    }, (err: Error) => {
      console.error("Error updating faulty equipment tracking Ids and Dates. Error:",err)
      this.showError();
    });
  }

  closeSelectedRowsProcess() {
    this.rowsProcessFlag = null;
    this.dispatchDateToUpdate = this.today;
    this.receivedFromSupplierDate = this.today;
    this.trackingIdToUpdate = null;
  }

   showPopUp(header: string, message: string, acceptLabel: string = 'OK', callback: () => void = () => {}) {
    this.confirmationService.confirm({
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      rejectVisible: false,
      acceptLabel: acceptLabel,
      accept: () => {
        if (callback) {
          callback();
        }
      },
    });
  }

  showSuccess() {
    this.messageService.add({
      key: 'faultyEquipmentList',
      severity: 'success',
      summary: 'Success',
      detail: 'Changes successfuly applied',
      life: 1000
    })
  }

  showError() {
    this.messageService.add({
      key: 'faultyEquipmentList',
      severity: 'error',
      summary: 'Error',
      detail: 'Something went wrong, Changes not applied, Try again',
      life: 300000,
    })
  }

  isOrderLocked(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  
  globalFilter($event: Event, filterType: string): void {
    this.table.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    if (this.table.filters[field] instanceof Array) {
      const filterMetadataArray: FilterMetadata[] = (this.table.filters[field] as FilterMetadata[]);
      if (filterMetadataArray.length > 0){
        return filterMetadataArray[0].value;
      }
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }

  okToSendToSupplier(): boolean {
    return this.trackingIdToUpdate && ((this.rowsProcessFlag == 'Flag as sent to supplier') || !!this.serial);
  }

  closeReturnsPage() {
    this.selectedReturnsForms = [];
    this.returnFormsArray.clear();
    this.showReturnDialog = false;
  }

  get returnFormsArray(): FormArray {
    return this.returnFormsGroup.get('returnForms') as FormArray;
  }

  generateReturnPage() {
    this.rowsProcessFlag = null;
    this.serial.split('\n').forEach((serial: string) => {
      serial = serial.trim();
      if (!serial) {
        return;
      }
      const returnForm: FormGroup = this.fb.group({
        'serial': [serial],
        'warranty': [false],
        'fixed': [false],
        'reason': [''],
        'returnDate': [this.receivedFromSupplierDate],
        'failureReason': [''],
      });
      this.returnFormsArray.push(returnForm);
    });
    this.serial = '';
    this.showReturnDialog = true;
  }

  applyChangesToSelected() {
    this.selectedReturnsForms.forEach((returnsForm: FormGroup) => {
      if (this.selectedWarrantyOption) {
        returnsForm.controls['warranty'].setValue(this.selectedWarrantyOption == 'yes')
        
      };
      if (this.selectedFixedOption) {
        returnsForm.controls['fixed'].setValue(this.selectedFixedOption == 'yes')
       
      };
      if (this.selectedReasonOption) {
        returnsForm.controls['reason'].setValue(this.selectedReasonOption)
      };
    });
  }

  async updateRecords() {
    const equipmentInDescDateOrder: FaultyEquipment[] = this.faultyEquipments.filter((equip: FaultyEquipment) => 
        equip.reportedAt
      ).sort((equipA: FaultyEquipment, equipB: FaultyEquipment) => 
        equipB.reportedAt.localeCompare(equipA.reportedAt)
      );
    this.selectedReturnsForms = [];
    for (let index: number = this.returnFormsArray.controls.length-1; index >= 0; index--) {
      const returnsForm: FormGroup = (this.returnFormsArray.at(index) as FormGroup);
      returnsForm.controls['failureReason'].setValue('');

      const serialTestRegex: RegExp = this.getSerialRegex(returnsForm.controls['serial'].value);
      const matchedEquip: FaultyEquipment = equipmentInDescDateOrder.find((equip: FaultyEquipment) =>
        serialTestRegex.test(equip.serial)
      );
      if (matchedEquip) {
        if (!returnsForm.controls['fixed'].value && !returnsForm.controls['reason'].value) {
          returnsForm.controls['failureReason'].setValue('You must specify a reason the item was not fixed.');
          continue;
        }
        const response: SingleRecordResponse<FaultyEquipment> = 
          await this.faultyEquipmentService.updateFaultyEquipment(matchedEquip._id, {
            'dateReturnedFromSupplier': returnsForm.controls['returnDate'].value,
            'fixed': returnsForm.controls['fixed'].value,
            'warranty': returnsForm.controls['warranty'].value,
            'reasonNotFixed': returnsForm.controls['reason'].value,
          }).toPromise();
        if (response.success) {
          this.returnFormsArray.removeAt(index);
        } else {
          returnsForm.controls['failureReason'].setValue(response.message);
        }
      } else {
        returnsForm.controls['failureReason'].setValue('Serial number not found');
      }
    }
    if (this.returnFormsArray.length == 0) {
      this.showPopUp('Updates applied successfully', 
        'All of the equipment has successfully been marked as returned.', 'OK', 
        () => {this.showReturnDialog = false;});  
    } else {
      this.showPopUp('Some updates failed', 
        'Some of the serials could not be matched to a record, or updates failed. Details are shown. Any serial that has been ' +
        'removed was successfully updated.'
      );
    }
  }
  async importSupplierReturnValues(fileContentsBase64: string) {

    this.spreadsheetImportService.importSpreadsheet({
      'appName': 'import-supplier-returns',
      'filename': this.file.name,
      'fileContents': fileContentsBase64,
      'username': localStorage.getItem('userName'),
      'email': localStorage.getItem('email'),
    }).subscribe((importResult: SimpleResponse) => {
      if (!importResult.success) {
        this.showPopUp('Error Importing Spreadsheet', importResult.message);
      } else {
        this.file = undefined;
        this.returnsFileUpload.nativeElement.value = '';
        this.showSuccess();
      }
    });
  } 

  async upload() {
    const fileContents: string = await getBase64EncodedFileContents(this.file);
    this.importSupplierReturnValues(fileContents);
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  closeUpload() {
    this.progress = -1;
  }
}
