import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OrderService} from '../post-order/order.service';
import {ColumnFilterFormElement, Table} from 'primeng/table';
import {ConfirmationService, FilterMetadata, MessageService, SelectItem, SortEvent} from "primeng/api";
import {Location} from '@angular/common';
import {ExcelExportService} from '../post-order/excel-export.service';
import * as moment from 'moment-timezone';
import {Router} from "@angular/router";
import * as XLSX from 'xlsx';
import {TagsService} from '../setup/tags/tags.service';
import {WebsiteService} from '../setup/websites/website.service';
import {LogsService} from "../reporting/logs/logs.service";
import {PageCountResponse} from '../models/responses/pageCountResponse.model';
import {ActionCfg, getActionNameLookup, getActionConfigs, getActionSelectItems, getActionColour, getActionBackgroundColour} from '../lookups/actions';
import {BrandCfg, getBrandConfigs, getBrandSelectItems, getBrandBackgroundColour, getBrandColour} from '../lookups/brands';
import {StatusCfg, statusConfigs, getStatusColour, getStatusBackgroundColour, statusSelectItems, equipStatusSelectItems} from '../lookups/statuses';
import {TagsResponse} from '../models/responses/tagsResponse.model';
import {Tag} from '../models/tag.model';
import {importTypes} from '../lookups/importTypes';
import {ImportType} from '../models/importType.model';
import {SpreadsheetImportService} from './spreadsheet-import.service';
import {SpreadsheetImportRequest} from '../models/requests/spreadsheetImportRequest.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {convertOrderToData} from '../helpers/convertOrderToData';
import {FilterService} from 'primeng/api';
import {OrderPageResponse} from '../models/responses/orderPageResponse.model';
import {OrderUpdate} from '../models/socket-io/orderUpdate.model';
import {FindOrderResponse} from '../models/responses/findOrderResponse.model';
import {UsersService} from '../setup/users/users.service';
import {renewalTypeDBLookup, renewalTypeSelectItems} from '../lookups/renewalTypes';
import {OrderResponse} from '../models/responses/orderResponse.model';
import {Order, OrderNote, OrderTag, Service} from '../models/order.model';
import {Observable} from 'rxjs';
import {Column} from '../models/column.model';
import {WebsitesResponse} from '../models/responses/websitesResponse.model';
import {Website} from '../models/website.model';
import {orderSpreadsheetCols} from '../lookups/spreadsheetColumns';
import {CancellationCategorySelectItem, getCancellationReasonCategories} from '../lookups/cancellationReasons';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {OrderUpdatesSocketService} from '../sockets/order-updates-socket.service';
import {Title} from '@angular/platform-browser';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {JontekCodeMatches} from '../models/jontekCodeMatches.model';
import {tdCodeMatchModeOptions} from '../lookups/filterOptions';
import {NotificationService} from '../notifications/notification.service';
import {SendEmailRequest} from '../models/requests/sendEmailRequest.model';
import {LogWithUpload} from '../models/log.model';
import {OrderLockData} from '../models/socket-io/orderLockData.model';
import {RenewalDiscount} from '../models/renewalDiscount.model';
import {ReviewsService} from '../services/reviews.service';
import {ReviewsImportRequest} from '../models/requests/reviewsImportRequest.model';
import {ReviewImportMessage} from '../models/responses/reviewImportMessage.model';
import {getMonitoringDBLookup, getMonitoringOptions} from '../lookups/monitoring';
import {VALID_ALARM_CODE, doPlanCodeAndTypeMatch, doPlanCodeAndVatStatusMatch, getBase64EncodedFileContents} from '../helpers/helperFunctions';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;
ColumnFilterFormElement.prototype.onModelChange = function (value) {
  this.filterConstraint.value = value;
  if (this.type || value === '') {
    this.dt._filter();
  }
}

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class MyOrdersComponent implements OnInit, OnDestroy {
  constructor(
    private orderService: OrderService,
    private location: Location,
    private tagsService: TagsService,
    private websiteService: WebsiteService,
    private locksSocket: LocksSocketService,
    private orderUpdatesSocket: OrderUpdatesSocketService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelExportService,
    private router: Router,
    private messageService: MessageService,
    private logsService: LogsService,
    private spreadsheetImportService: SpreadsheetImportService,
    private filterService: FilterService,
    private userService: UsersService,
    private title: Title,
    private notificationService: NotificationService,
    private reviewsService: ReviewsService,
  ) {
  }

  cols: Column[];
  bigSearch: string[];
  locked: OrderLockData[] = [];
  data: HomepageFormatOrder[] = [];
  websites: Website[];
  selectedOrders: any[];
  renewaltypes: SelectItem<string>[] = renewalTypeSelectItems;
  readonly statusType: SelectItem<string>[] = statusSelectItems;
  freeMonthsType: SelectItem<string>[];
  monitoringOptions: SelectItem<string>[];
  pStatus: SelectItem<string>[];
  cancellationReasons: SelectItem<string>[];
  selectedstatus: string[];
  selectedMonths: string[];
  selectedmonitoring: string[];
  userName: string;
  selectedColumns: Column[];
  defaultColumns: Column[];
  selectedBrand: string[];
  selectedrenewalType: string[];
  savedColumnSets: SelectItem<Column[]>[];
  logs: {'msg': string; 'status': string; 'tdCode'?: string}[];
  progressVal: number;
  progress: number;
  maxToProcess: number;
  paginationLoadingProg: number;
  totalRecords: number;
  tagsColor: {[tagName: string]: string} = {};
  findField: string;
  dateFilters: Date[];
  renewalDateFilters: Date[];
  actionDateFilters: Date[];
  actionsInitiatedDateFilters: Date[];
  notesDateDateFilters: Date[];
  returnDateDateFilters: Date[];
  cancellationDateFilters: Date[];
  vimsForDialog: any;
  titleDialog: string;
  sliceOptions: {[option: string]: number};
  mark: number;
  tags: Tag[];
  tagSelectItems: SelectItem<string>[] = [];
  vimModalDisplay: boolean = false;
  showImportDialog: boolean = false;
  curDate: Date = new Date();
  minDAte: Date = new Date("1980");
  All: number;
  selectedAction: string;
  file: File;
  actions: ActionCfg;
  brands: SelectItem<string>[];
  actionSelectItems: SelectItem<string>[];
  actionNameLookup: {[lookupName: string]: string};
  brandConfigs: BrandCfg;
  readonly statusConfigs: StatusCfg = statusConfigs;
  getActionColour = getActionColour;
  getActionBackgroundColour = getActionBackgroundColour;
  getStatusColour = getStatusColour;
  getStatusBackgroundColour = getStatusBackgroundColour;
  getBrandColour = getBrandColour;
  getBrandBackgroundColour = getBrandBackgroundColour;
  importTypes: ImportType[];
  selectedImportType: ImportType;
  allowOrderCreation: boolean = false;
  orderExportOptions: string[] = [
    '1 sheet, 1 tab total',
    '1 sheet, 1 tab/brand',
    '1 sheet/brand'
  ];
  pagesProcessing: number;
  closing: boolean;
  first: number;
  updateReceived: boolean;
  myMoment = moment;
  isSaveColumnDisabled: boolean = false;
  selectedBrandForReview: string = null;
  tdCodeMatchModeOptions: SelectItem<string>[] = tdCodeMatchModeOptions;
  columnSetName: string;
  showColumSetDialog: boolean = false;
  updatesWhileLoading: {[orderId: string]: Order} = {};
  filterYearRange: string;
  
  showPageCountError(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: 'Error getting page count' + errorMessage,
    });
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  getDateXls(xsls, fieldName, tdcode): string {
    const xslsDate: moment.Moment = moment.utc(xsls, "DD/MM/YYYY", true);
    if (xslsDate.isValid() != true && Number(xsls) !== xsls) {
      this.logs.push({
        msg: fieldName + ' "' + xsls + '" is not valid  DD/MM/YYYY  ',
        status: 'alert-warning',
        tdCode: tdcode
      });
      return null;
    } else if (Number(xsls) === xsls) {
      return (new Date((xsls - (25567 + 2)) * 86400 * 1000)).toISOString();
    } else {
      return xslsDate.toISOString();
    }
  }

  getTagFromName(tagName: string): Tag|undefined {
    if (tagName.trim() === '') {
      return undefined;
    }
    const matchingTag: Tag = this.tags.find((tag: Tag) => {
      try {
        return (tag.tagName.trim() == tagName.trim());
      } catch (e) {
        return false;
      }
    })
    return matchingTag;
  }

  getBrandId(brandName: string): string {
    let id = '';
    this.websites.map((website: Website) => {
      if (website.title == brandName) {
        id = website._id;
        //////console.log(id)
      }
    })
    return id;
  }

  exist(element) {
    return element != undefined && element != '' && element != null;
  }

  async makeOrder(row, newOrder: Order): Promise<void> {
    let statusChangedToCancellingOrReasonChanged: boolean = false;
    let contactDetailChanges: string[] = [];
    let originalStatus: string = newOrder.status.status;
    if (row['Order Date'] != undefined && row['Order Date'] != '' && row['Order Date'] != null) {
      newOrder['created'] = this.getDateXls(row['Order Date'], 'Order Date', row['Td Code']);
      newOrder['initialOrderDetails']['orderDate'] = moment(newOrder['created']).format('DD/MM/YYYY').replace("Invalid date", '');
    }

    if (this.exist(row['Customer Name'])) {
      let firstName: string = (row['Customer Name'] + '').split("|")[0].trim().split(" ").slice(0, -1).join(' ').trim().replace("undefined", '');
      if (firstName != '') {
        if (newOrder['alarmUserDetails']['firstName'] != firstName) {
          contactDetailChanges.push(
            `Alarm User First Name changed from "${newOrder['alarmUserDetails']['firstName']}" to "${firstName}"`
          );
        }
        newOrder['alarmUserDetails']['firstName'] = firstName;
      }
      let lastName: string = (row['Customer Name'] + '').split("|")[0].trim().split(" ").slice(-1).join(' ').trim().replace("undefined", '');
      if (lastName != '') {
        if (newOrder['alarmUserDetails']['lastName'] != lastName) {
          contactDetailChanges.push(
            `Alarm User Last Name changed from "${newOrder['alarmUserDetails']['lastName']}" to "${lastName}"`
          );
        }
        newOrder['alarmUserDetails']['lastName'] = lastName;
      }
      let preferredName: string = (row['Preferred Name'] + '').split("|")[0].trim().replace("undefined", '');
      if (preferredName != '') {
        if (newOrder['alarmUserDetails']['preferredName'] != preferredName) {
          contactDetailChanges.push(
            `Alarm User Preferred Name changed from "${newOrder['alarmUserDetails']['preferredName']}" to "${preferredName}"`
          );
        }
        newOrder['alarmUserDetails']['preferredName'] = preferredName;
      }
    }
    let otherName: string = (row['Other Name'] + '').split("|")[0].trim().replace("undefined", '');
    if (otherName != '') {
      if (newOrder['alarmUserDetails']['otherName'] != otherName) {
        contactDetailChanges.push(
          `Alarm User Other Name changed from "${newOrder['alarmUserDetails']['otherName']}" to "${otherName}"`
        );
      }
      newOrder['alarmUserDetails']['otherName'] = otherName;
    }

    let email: string = (row['Email'] + '').split("|")[0].trim().replace("undefined", '');
    if (email != '') {
      newOrder['alarmUserDetails']['email'] = email;
    }

    (row['Customer Name'] + '').split("|").slice(1).map((e, i) => {
      //////console.log('Customer Name', e);
      if (e != undefined && i > newOrder['alarmUserDetails']['users'].length - 1) {
        newOrder['alarmUserDetails']['users'].push({
          'primaryContact': false,
          'doNotCall': false,
          'emailMarketing': '',
          'emailMarketingUpdated': null,
          'mobileMarketing': '',
          'mobileMarketingUpdated': null,
        });
      }
      if (e.trim() != '') {
        let firstname: string = e.trim().split(" ").slice(0, -1).join(' ').replace("undefined", '');
        let lastname: string = e.trim().split(" ").slice(-1).join(' ').replace("undefined", '');
        if (newOrder['alarmUserDetails']['users'][i]['firstName'] != firstname) {
          contactDetailChanges.push(
            `Additional User ${i+1} First Name changed from "${newOrder['alarmUserDetails']['users'][i]['firstName']}" to "${firstname}"`
          );
        }
        if (newOrder['alarmUserDetails']['users'][i]['lastName'] != lastname) {
          contactDetailChanges.push(
            `Additional User ${i+1} Last Name changed from "${newOrder['alarmUserDetails']['users'][i]['lastName']}" to "${lastname}"`
          );
        }
        newOrder['alarmUserDetails']['users'][i]["firstName"] = firstname;
        newOrder['alarmUserDetails']['users'][i]["lastName"] = lastname;
      }
    });
    (row['Preferred Name'] + '').split("|").slice(1).map((e, i) => {
      if (e != undefined && i > newOrder['alarmUserDetails']['users'].length - 1) {
        newOrder['alarmUserDetails']['users'].push({
          'primaryContact': false,
          'doNotCall': false,
          'emailMarketing': '',
          'emailMarketingUpdated': null,
          'mobileMarketing': '',
          'mobileMarketingUpdated': null,
        });
      }
      if (e.trim() != '') {
        let preferredName: string = e.trim().replace("undefined", '');
        if (newOrder['alarmUserDetails']['users'][i]['preferredName'] != preferredName) {
          contactDetailChanges.push(
            `Additional User ${i+1} Preferred Name changed from "${newOrder['alarmUserDetails']['users'][i]['preferredName']}" to "${preferredName}"`
          );
        }
        newOrder['alarmUserDetails']['users'][i]["preferredName"] = preferredName;
      }
    });

    (row['Other Name'] + '').split("|").slice(1).map((e, i) => {
      if (e != undefined && i > newOrder['alarmUserDetails']['users'].length - 1) {
        newOrder['alarmUserDetails']['users'].push({
          'primaryContact': false,
          'doNotCall': false,
          'emailMarketing': '',
          'emailMarketingUpdated': null,
          'mobileMarketing': '',
          'mobileMarketingUpdated': null,
        });
      }
      if (e.trim() != '') {
        let otherName: string = e.trim().replace("undefined", '');
        if (newOrder['alarmUserDetails']['users'][i]['otherName'] != otherName) {
          contactDetailChanges.push(
            `Additional User ${i+1} Other Name changed from "${newOrder['alarmUserDetails']['users'][i]['otherName']}" to "${otherName}"`
          );
        }
        newOrder['alarmUserDetails']['users'][i]["otherName"] = otherName;
      }
    });
    (row['Email'] + '').split("|").slice(1).map((e, i) => {
      if (e != undefined && i > newOrder['alarmUserDetails']['users'].length - 1) {
        newOrder['alarmUserDetails']['users'].push({
          'primaryContact': false,
          'doNotCall': false,
          'emailMarketing': '',
          'emailMarketingUpdated': null,
          'mobileMarketing': '',
          'mobileMarketingUpdated': null,
        });
      }
      if (e.trim() != '') {
        newOrder['alarmUserDetails']['users'][i]["email"] = e.trim().replace("undefined", '');
      }
    });

    (row['Alarm User Relationship'] + '').split("|").map((e, i) => {
      if (e != undefined && e.trim() != '' && e.trim() != "undefined") {
        if (e != undefined && i > newOrder['alarmUserDetails']['users'].length - 1) {
          newOrder['alarmUserDetails']['users'].push({
            'primaryContact': false,
            'doNotCall': false,
            'emailMarketing': '',
            'emailMarketingUpdated': null,
            'mobileMarketing': '',
            'mobileMarketingUpdated': null,
          });
        }
        newOrder['alarmUserDetails']['users'][i]["relationship"] = e.trim().replace("undefined", '');
      }
    });
    (row['Mobile'] + '').split("|").slice(1).map((e, i) => {
      if (e == null) {
        return;
      }
      // Remove whitespace before test to allow number to have been formatted for readability
      const mobileNo: string = e.replace(/\s/g, '').replace("undefined", '');
      if (mobileNo.replace(/[0-9]/g, '') != '') {
        this.logs.push({
          msg: 'mobile "' + e + '" is not valid ',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      } else {
        if (i > newOrder['alarmUserDetails']['users'].length - 1) {
          newOrder['alarmUserDetails']['users'].push({
            'primaryContact': false,
            'doNotCall': false,
            'emailMarketing': '',
            'mobileMarketing': ''
          });
        }
        if (newOrder['alarmUserDetails']['users'][i]['mobile'] != mobileNo) {
          contactDetailChanges.push(
            `Additional User ${i+1} Mobile changed from "${newOrder['alarmUserDetails']['users'][i]['mobile']}" to "${mobileNo}"`
          );
        }
        newOrder['alarmUserDetails']['users'][i]["mobile"] = mobileNo;
      }
    });
    // Remove whitespace before test to allow number to have been formatted for readability
    let telephone = (row['Telephone'] + '').split("|")[0].replace("undefined", '').replace(/\s/g, '');
    if (telephone != '' && newOrder['alarmUserDetails']['telephone'] != telephone) {
      if (telephone.replace(/[0-9]/g, '') != '') {
        this.logs.push({
          msg: 'telephone "' + telephone + '" is not valid ',
          status: 'alert-warning',
          tdCode: row['Td Code']
        })
      } else {
        if (newOrder['alarmUserDetails']['telephone'] != telephone) {
          contactDetailChanges.push(
            `Alarm User Telephone changed from "${newOrder['alarmUserDetails']['telephone']}" to "${telephone}"`
          );
        }
        newOrder['alarmUserDetails']['telephone'] = telephone;
      }
    }
    // Remove whitespace before test to allow number to have been formatted for readability
    let mobile = (row['Mobile'] + '').split("|")[0].replace("undefined", '').replace(/\s/g, '');
    if (mobile != '' && newOrder['alarmUserDetails']['mobile'] != mobile) {
      if (mobile.replace(/[0-9]/g, '') != '') {
        this.logs.push({
          msg: 'mobile "' + mobile + '" is not valid ',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      } else {
        if (newOrder['alarmUserDetails']['mobile'] != mobile) {
          contactDetailChanges.push(
            `Alarm User Mobile changed from "${newOrder['alarmUserDetails']['mobile']}" to "${mobile}"`
          );
        }
        newOrder['alarmUserDetails']['mobile'] = mobile;
      }
    }
    if (row['Address Line 1'] != undefined && row['Address Line 1'] != '' && row['Address Line 1'] != null) {
      newOrder['alarmUserDetails']['userAddress']['addressOne'] = row['Address Line 1'];
    }
    if (row['Address Line 2'] != undefined && row['Address Line 2'] != '' && row['Address Line 2'] != null) {
      newOrder['alarmUserDetails']['userAddress']['addressTwo'] = row['Address Line 2'];
    }
    if (row['City'] != undefined && row['City'] != '' && row['City'] != null) {
      newOrder['alarmUserDetails']['userAddress']['city'] = row['City'];
    }
    if (row['County'] != undefined && row['County'] != '' && row['County'] != null) {
      newOrder['alarmUserDetails']['userAddress']['county'] = row['County'];
    }
    if (row['Postcode'] != undefined && row['Postcode'] != '' && row['Postcode'] != null) {
      newOrder['alarmUserDetails']['userAddress']['postcode'] = row['Postcode'];
    }
    if (row['Date Of Birth'] != undefined && row['Date Of Birth'] != '' && row['Date Of Birth'] != null) {
      newOrder['alarmUserDetails']['userAddress']['dob'] = moment(this.getDateXls(row['Date Of Birth'], 'Date Of Birth', row['Td Code'])).format('YYYY-MM-DD');
    }

    // TODO remove for hardware version
    if (row['Plan'] != undefined && row['Plan'] != '' && newOrder['accountDetails']['plan'] != row['Plan'] && row['Plan'] != null) {
      newOrder['accountDetails']['plan'] = row['Plan'].toLocaleUpperCase();
    }
    // TODO remove for hardware version
    if (this.exist(row['Renewal Price'])) {
      newOrder['renewalInformation']['renewalPrice'] = (parseFloat(row['Renewal Price'])).toFixed(2);
    }

    if (this.exist(row['VAT'])) {
      newOrder['accountDetails']['vat'] = row['VAT'];
    }
    /*Sensitive case plan type: this fields will accept only pre-existing */
    if (this.exist(row['Plan Type'])) {
      let plantype = row['Plan Type'].toLocaleLowerCase().split(' ').join('');
      let plantypeList = ['monthly', 'quarterly', 'annual', 'lifetime'];
      if (plantypeList.includes(plantype)) {
        newOrder['accountDetails']['planType'] = plantype;
      } else {
        this.logs.push({
          msg: 'Plan Type "' + row['Plan Type'] + '" is not valid',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      }
    }
    /*END: Sensitive case plan type: this fields will accept only pre-existing */
    if (this.exist(row['Monitoring'])) {
      let monitoring: string = getMonitoringDBLookup()[row['Monitoring'].toLocaleLowerCase().split(" ").join('')];
      if (monitoring != null) {
        newOrder['accountDetails']['monitoring'] = monitoring;
      } else {
        this.logs.push({
          msg: 'Monitoring "' + row['Monitoring'] + '" is not valid',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      }
    }
    /*END: Sensitive case Monitoring: this fields will accept only pre-existing */
    if (this.exist(row['Background Auto Test Call'])) {
      newOrder['status']['backgroundAutoTestCall'] =
        this.getDateXls(row['Background Auto Test Call'], 'Background Auto Test Call', row['Td Code']);
    }
    if (this.exist(row['Status'])) {
      let status = row['Status'].toLocaleLowerCase().split(' ').join('');
      let statusList = ['noncf', 'active', 'cancelled', 'cancelling'];
      if (statusList.includes(status)) {
        if (status == 'noncf') {
          status = 'no ncf'
        }
        if (originalStatus != status) {
          let allowStatusUpdate: boolean = true;
          if (status == 'cancelling') {
            if (this.userCanSetOrderToCancelling()) {
              statusChangedToCancellingOrReasonChanged = true;
            } else {
              this.logs.push({
                msg: 'You do not have permission to set the order status to cancelling, status not changed.',
                status: 'alert-warning',
                tdCode: row['Td Code']
              });
              allowStatusUpdate = false;
            }
          } else if (status == 'cancelled') {
            if (newOrder.services && (newOrder.services.length > 0)) {
              newOrder.services.forEach((service: Service) => {
                if (service.status == 'Active') {
                  allowStatusUpdate = false;
                }
              });
            }
            if (!allowStatusUpdate) {
              this.logs.push({
                msg: 'There are one or more services active on the order. Please deactivate them before cancelling the order.',
                status: 'alert-warning',
                tdCode: row['Td Code']
              });
            }
          }
          if (allowStatusUpdate) {
            newOrder['status']['status'] = status;
            newOrder['status']['setby'] = this.userName;
            newOrder['status']['date'] = moment(new Date(), "DD/MM/YYYY", true).toISOString();
          }
        }
      } else {
        this.logs.push({
          msg: 'Status "' + row['Status'] + '" is not valid',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      }
    }
    if (this.exist(row['Cancellation Date'])) {
      newOrder['cancellation']['cancellationDate'] =
        this.getDateXls(row['Cancellation Date'], 'Cancellation Date', row['Td Code']);
    }
    if (this.exist(row['Free Months'])) {
      newOrder['renewalInformation']['freemonths'] = row['Free Months'];
    }
    if (this.exist(row['Renewal Type'])) {
      const renewalType: string = renewalTypeDBLookup[row['Renewal Type'].toLocaleLowerCase().replace(/\s/g, '')];
      if (renewalType != null) {
        newOrder['renewalInformation']['renewalType'] = renewalType;
      } else {
        this.logs.push({
          msg: 'Renewal Type "' + row['renewalType'] + '" is not valid',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      }
    }
    if ((row['Renewal Date'] != undefined) && (row['Renewal Date'] != '') && (row['Renewal Date'] != null)) {
      if ((row['Renewal Date'].trim) && (row['Renewal Date'].trim() == '')) {
        newOrder['renewalInformation']['renewalDate'] = null;
        newOrder['renewalInformation']['paymentDueDate'] = '';
      } else {
        newOrder['renewalInformation']['renewalDate'] = this.getDateXls(row['Renewal Date'], 'Renewal Date', row['Td Code']);
        if (!!newOrder['renewalInformation']['renewalDate']) {
          let date = moment(newOrder['renewalInformation']['renewalDate']);
          date = date.subtract(1, 'months').add(1, 'days');
          newOrder['renewalInformation']['paymentDueDate'] = date.toISOString();
        }
      }
    }
    let actionDates = [];
    if (row['Action Date'] != undefined && row['Action Date'] != '' && row['Action Date'] != null) {
      actionDates = (row['Action Date'] + '').split("|\r");
    }
    let outstandingName = [];
    if (row['Action Title'] != undefined && row['Action Title'] != '' && row['Action Title'] != null) {
      outstandingName = row['Action Title'].split("|\r");
    }
    let k = 0;
    while (k < outstandingName.length) {
      actionDates[k] = (actionDates[k] + '').trim();
      //////console.log("-ActionDates :"+ActionDates[k]);
      if (actionDates[k].trim() == "NO DATE") {
        actionDates[k] = null;
      } else {
        if (actionDates[k].length == 10) {
          actionDates[k] = moment.utc(actionDates[k], "DD/MM/YYYY").toDate();
        } else {
          actionDates[k] = new Date((actionDates[k] - (25567 + 2)) * 86400 * 1000);
        }
      }
      let actionName: string = this.actionNameLookup[outstandingName[k].toLocaleLowerCase().replace(/\s/g, '')];

      if (k < newOrder['outstandingActions'].length) {
        delete newOrder['outstandingActions'][k]["_id"];
        delete newOrder['outstandingActions'][k]["date"];
        if (actionName !== undefined) {
          newOrder['outstandingActions'][k]["outstandingName"] = actionName;
          newOrder['outstandingActions'][k]["outstandingColor"] = getActionBackgroundColour(actionName);
          newOrder['outstandingActions'][k]["renewalDateTaken"] = actionDates[k];
        } else {
          this.logs.push({
            msg: 'status * "' + outstandingName[k] + '" is not valid',
            status: 'alert-warning',
            tdCode: row['Td Code']
          });
        }
      } else {
        if (actionDates[k] != '') {
          if (actionName !== undefined) {
            if ((actionDates[k] + '').trim() == 'NO DATE') {
              actionDates[k] = null;
            }
            let newAction = {
              outstandingName: actionName,
              outstandingColor: getActionBackgroundColour(actionName),
              status: '',
              renewalDateTaken: actionDates[k],
              userName: this.userName,
              actionInitiatedDate: (new Date()).toISOString()
            };
            if (outstandingName[k].trim() == "No DD or RB" && !actionDates[k]) {
              newAction["renewalDateTaken"] = moment().add(30, 'days').toISOString();
            }
            newOrder['outstandingActions'].push(newAction);

          } else {
            this.logs.push({
              msg: 'status "' + outstandingName[k].trim() + '" is not valid',
              status: 'alert-warning',
              tdCode: row['Td Code']
            });
          }
        }
      }
      k++;
    }

    /* TODO remove for hardware version
    Import Plan
    */
    let plansMap = {
      "equipment": "Plan Title",
      "serial": "Plan Serial",
      "status": "Plan Status",
      "typePendant": "Pendant Type",
      "serialPendant": "Pendant Serial",
      "serialStatus": "Pendant Status",
      "added":"Plan Added Date"
    };
    for (let key in plansMap) {
      // //console.log(key + ":" + plansMap[key]);
      let fName = plansMap[key];
      if (row[fName] != undefined && row[fName] != '' && row[fName] != null) {
        let PlanStatus = (row[fName] + '').split("|");
        let k = 0;
        while (k < PlanStatus.length) {
          let value: any = '';
          if (key == 'added') {
            value = moment.tz(PlanStatus[k],'DD/MM/YYYY','Europe/London').toDate();
          } else {
            value = PlanStatus[k].trim();
          }
          if (value != '') {
            if (k < newOrder['plans'].length) {
              newOrder['plans'][k][key] = value;
            } else {
              const newPlan = {
                added: moment.tz('Europe/London').toISOString(),
              };
              newPlan[key] = value;
              newOrder['plans'].push(newPlan);
            }
          }
          k++;
        }
      }
    }

    /* TODO remove for hardware version
    Import additionalEquipment
    */
    let additionalEquipmentMap = {
      "equipment": "Additional Equipment Title",
      "serial": "Additional Equipment Serial",
      "status": "Additional Equipment Status",
      "added":"Additional Equipment Added Date"
    };
    for (let key in additionalEquipmentMap) {
      // //console.log(key + ":" + additionalEquipmentMap[key]);
      let fName = additionalEquipmentMap[key];
      if (row[fName] != undefined && row[fName] != '' && row[fName] != null) {
        let PlanStatus = (row[fName] + '').split("|");
        let k = 0;
        while (k < PlanStatus.length) {
          let value: any = '';
          if (key == 'added') {
            value = moment.tz(PlanStatus[k], 'DD/MM/YYYY', 'Europe/London').toDate();
          } else {
            value = PlanStatus[k].trim();
          }
          if (value != '') {
            if (k < newOrder['additionalEquipment'].length) {
              newOrder['additionalEquipment'][k][key] = value;
            } else {
              const newEquip = {
                added: moment.tz('Europe/London').toISOString(),
              };
              newEquip[key] = value;
              newOrder['additionalEquipment'].push(newEquip);
            }
          }
          k++;
        }
      }
    }

    /*
    Import Contacts
     */
    let ContactsMap = {
      'accFirstName': 'Account Contact First Name',
      'accLastName': 'Account Contact Last Name',
      'accOtherName': 'Account Contact Other Name',
      'accEmail': 'Account Contact Email',
      'accTelephone': 'Account Contact Telephone',
      'accMobile': 'Account Contact Mobile',
      'accRelationship': 'Account Contact Relationship',
    };
    for (let key in ContactsMap) {
      // //console.log(key + ":" + plansMap[key]);
      let fName = ContactsMap[key];
      if (row[fName] != undefined && row[fName] != '' && row[fName] != null) {
        let contact = (row[fName] + '').split("|");
        let k = 0;
        while (k < contact.length) {
          let value = contact[k].trim();
          if (value != '') {
            // take out spaces in numbers which might have them for readability
            if ((key == 'accTelephone') || (key == 'accMobile')) {
              value = value.replace(/\s/g, '');
            }
            if (k < newOrder['accountContacts'].length) {
              newOrder['accountContacts'][k][key] = value;
            } else {
              const newContact = {
                'primaryContact': false,
                'doNotCall': false,
                'emailMarketing': '',
                'emailMarketingUpdated': null,
                'mobileMarketing': '',
                'mobileMarketingUpdated': null,
              };
              newContact[key] = value;
              newOrder['accountContacts'].push(newContact);
            }
          }
          k++;
        }
      }
    }
    let VIMs = [];
    if (row['VIM'] != undefined && row['VIM'] != '' && row['VIM'] != null) {
      VIMs = ('' + row['VIM']).split("|");
    }
    let p = 0;
    while (p < VIMs.length) {
      if (p < newOrder['vim'].length) {
        newOrder['vim'][p]["content"] = VIMs[p];
      } else {
        if (VIMs[p] != '') {
          let newVim = {
            content: VIMs[p],
            userName: this.userName,
            date: (new Date()).toISOString(),
          };
          newOrder['vim'].push(newVim);
        }
      }
      p++;
    }
    let NotesCategories: string[] = [];
    if (row['Notes Categories'] != undefined && row['Notes Categories'] != '' && row['Notes Categories'] != null) {
      // //console.log(row['Notes']);
      NotesCategories = (row['Notes Categories'] + '').split("|");
    }
    let Notes: string[] = [];
    if (row['Notes'] != undefined && row['Notes'] != '' && row['Notes'] != null) {
      // //console.log(row['Notes']);
      Notes = (row['Notes'] + '').split("|");
    }
    p = 0;
    while (p < Notes.length) {
      if (NotesCategories.length <= p) {
        NotesCategories.push("General");
      }
      if (Notes[p] != '' && !newOrder['notes'].some((note: OrderNote) => Notes[p].trim() == note["content"].trim())) {
        const categories: string[] = NotesCategories[p]? NotesCategories[p].split(';').filter(category => (category.trim().length > 0)): ["General"];
        if (categories.length === 0) {
          categories.push("General");
        }
        let newNote = {
          content: Notes[p].trim(),
          categories: categories,
          userName: this.userName,
          date: Date(),
        };
        newOrder['notes'].push(newNote);
      }
      p++;
    }
    let tagNames: string[] = [];

    if (row['Tags'] != undefined && row['Tags'] != '' && row['Tags'] != null) {
      tagNames = row['Tags'].split("|");
      newOrder['tags'] = [];
    }

    let tagExpiryDatesRaw: string[] = [];
    if (row['Tag Expiry Dates'] != undefined && row['Tag Expiry Dates'] != '' && row['Tag Expiry Dates'] != null) {
      tagExpiryDatesRaw = row['Tag Expiry Dates'].split("|");
    }
    /* Written by abdul rauf to remove awaiting direct debit set up tag */
    p = 0;
    while (p < tagNames.length) {
      const tagFromName: Tag|undefined = this.getTagFromName(tagNames[p]);
      if (!tagFromName) {
        this.logs.push({
          msg: 'Tag "' + tagNames[p] + '" doesn\'t exist   ',
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      } else if (newOrder['tags'].map<string>((tag: OrderTag) => {
        // newOrder tags cleared, so tagID = tag's ID rather than tagID being the Tag record
        // return tag["tagID"]["_id"]
        return tag.tagID._id
      }).includes(tagFromName._id)) {
        // tag already on the order, so don't add again
      } else {
        let expiryDate: string = null;
        if (p < tagExpiryDatesRaw.length) {
          try {
            const rawDate: string = tagExpiryDatesRaw[p].trim();
            if ((rawDate !== 'NO EXPIRY') && (rawDate !== 'NO DATE')) {
              expiryDate = this.getDateXls(rawDate, 'Tag Expiry Date', row['Td Code']);
            }
          } catch(e) {}
        }
        // Tag not on the order
        let newTag: OrderTag = {
          tagID: tagFromName,
          userName: this.userName,
          date: Date(),
          expiryDate: expiryDate,
        };
        newOrder['tags'].push(newTag);
      }
      p++;
    }
    if (row['Cancellation Reason'] != undefined && row['Cancellation Reason'] != '' && row['Cancellation Reason'] != null) {
      if ((row['Cancellation Reason'] != newOrder['cancellation']['cancellationReason'])) {
        statusChangedToCancellingOrReasonChanged = true;
      }
      newOrder['cancellation']['cancellationReason'] = (row['Cancellation Reason'] + '').replace("undefined", '');
    }
    if (row['Detailed Cancellation Reason'] != undefined && row['Detailed Cancellation Reason'] != '' && row['Detailed Cancellation Reason'] != null) {
      if ((row['Detailed Cancellation Reason'] != newOrder['cancellation']['detailedCancellationReason'])) {
        statusChangedToCancellingOrReasonChanged = true;
      }
      newOrder['cancellation']['detailedCancellationReason'] = (row['Detailed Cancellation Reason'] + '').replace("undefined", '');
    }
    if (row['Cancellation Other Reason'] != undefined && row['Cancellation Other Reason'] != '' && row['Cancellation Other Reason'] != null) {
      newOrder['cancellation']['otherReason'] = (row['Cancellation Other Reason'] + '').replace("undefined", '');
    }

    if (row['Initial Order Processor'] != undefined && row['Initial Order Processor'] != '' && row['Initial Order Processor'] != null) {
      newOrder['initialOrderDetails']['processor'] = row['Initial Order Processor'];
    }
    if (row['Initial Order Payment'] != undefined && row['Initial Order Payment'] != '' && row['Initial Order Payment'] != null) {
      newOrder['initialOrderDetails']['payment'] = row['Initial Order Payment'];
    }
    if (row['initial Date'] != undefined && row['initial Date'] != '' && row['initial Date'] != null) {
      newOrder['initialOrderDetails']['orderDate'] = row['initial Date'];
    }
    if (row['initial Order Type'] != undefined && row['initial Order Type'] != '' && row['initial Order Type'] != null) {
      newOrder['initialOrderDetails']['orderType'] = row['initial Order Type'];
    }
    if (row['initial order Renewal'] != undefined && row['initial order Renewal'] != '' && row['initial order Renewal'] != null) {
      newOrder['initialOrderDetails']['renewal'] = row['initial order Renewal'];
    }
    if (row['Initial Order First Name'] != undefined && row['Initial Order First Name'] != '' && row['Initial Order First Name'] != null) {
      newOrder['initialOrderDetails']['firstName'] = row['Initial Order First Name'];
    }
    if (row['Initial Order Telephone'] != undefined && row['Initial Order Telephone'] != '' && row['Initial Order Telephone'] != null) {
      newOrder['initialOrderDetails']['telephone'] = row['Initial Order Telephone'];
    }
    if (row['Initial Order Alarm User'] != undefined && row['Initial Order Alarm User'] != '' && row['Initial Order Alarm User'] != null) {
      newOrder['initialOrderDetails']['alarmUser'] = row['Initial Order Alarm User'];
    }
    if (row['Initial Order Last Name'] != undefined && row['Initial Order Last Name'] != '' && row['Initial Order Last Name'] != null) {
      newOrder['initialOrderDetails']['lastName'] = row['Initial Order Last Name'];
    }
    if (row['Initial Order SF Order ID'] != undefined && row['Initial Order SF Order ID'] != '' && row['Initial Order SF Order ID'] != null) {
      newOrder['initialOrderDetails']['sfOrderId'] = row['Initial Order SF Order ID'];
    }
    if (row['Initial Order Transaction ID'] != undefined && row['Initial Order Transaction ID'] != '' && row['Initial Order Transaction ID'] != null) {
      newOrder['initialOrderDetails']['transactionId'] = row['Initial Order Transaction ID'];
    }
    if (row['Initial Order Email'] != undefined && row['Initial Order Email'] != '' && row['Initial Order Email'] != null) {
      newOrder['initialOrderDetails']['email'] = row['Initial Order Email'];
    }
    if (row['Initial Order Billing Address'] != undefined && row['Initial Order Billing Address'] != '' && row['Initial Order Billing Address'] != null) {
      newOrder['initialOrderDetails']['billing'] = row['Initial Order Billing Address'];
    }
    if (row['Initial Order Shipping Address'] != undefined && row['Initial Order Shipping Address'] != '' && row['Initial Order Shipping Address'] != null) {
      newOrder['initialOrderDetails']['shipping'] = row['Initial Order Shipping Address'];
    }
    if (row['Initial Order Installation Address'] != undefined && row['Initial Order Installation Address'] != '' && row['Initial Order Installation Address'] != null) {
      newOrder.initialOrderDetails.shipping = row['Initial Order Installation Address'];
    }
    
    if (row['Initial Order VAT Total'] != undefined && row['Initial Order VAT Total'] != '' && row['Initial Order VAT Total'] != null) {
      newOrder['initialOrderDetails']['vatTotal'] = row['Initial Order VAT Total'];
    }
    if (row['Initial Order Subtotal'] != undefined && row['Initial Order Subtotal'] != '' && row['Initial Order Subtotal'] != null) {
      newOrder['initialOrderDetails']['total'] = row['Initial Order Subtotal'];
    }
    if (row['Initial Order Notes'] != undefined && row['Initial Order Notes'] != '' && row['Initial Order Notes'] != null) {
      newOrder['initialOrderDetails']['notes'] = row['Initial Order Notes'];
    }
    if (row['Payment Method'] != undefined && row['Payment Method'] != '' && row['Payment Method'] != null) {
      newOrder['initialOrderDetails']['payment'] = row['Payment Method'];
    }
    if (row['Customer Called From'] != undefined && row['Customer Called From'] != '' && row['Customer Called From'] != null) {
      newOrder['initialOrderDetails']['customerCalledFrom'] = row['Customer Called From'];
    }
    if (row['Order ID'] != undefined && row['Order ID'] != '' && row['Order ID'] != null) {
      newOrder['orderId'] = row['Order ID'];
    }
    if (row['Tracking Number'] != undefined && row['Tracking Number'] != '' && row['Tracking Number'] != null) {
      newOrder['initialOrderDispatchInformation']['trackingNo'] = row['Tracking Number'];
    }
    if (row['Click And Drop Order Id'] != undefined && row['Click And Drop Order Id'] != '' && row['Click And Drop Order Id'] != null) {
      newOrder['initialOrderDispatchInformation']['cadOrderId'] = row['Click And Drop Order Id'];
    }
    if (row['Return Date'] != undefined && row['Return Date'] != '' && row['Return Date'] != null) {
      newOrder['cancellation']['returnDate'] = this.getDateXls(row['Return Date'], 'Return Date', row['Td Code']);
    }
    if (this.exist(row['Box - Last Activation'])) {
      newOrder['status']['lastBoxActivation'] = this.getDateXls(row['Box - Last Activation'], 'Box - Last Activation',row['Td Code'] );
    }
    if (this.exist(row['Pendant 1 - Last Activation'])) {
      newOrder['status']['pendantOneLastActivation'] = this.getDateXls(row['Pendant 1 - Last Activation'], 'Pendant 1 - Last Activation',row['Td Code'] );
    }
    if (this.exist(row['Pendant 2 - Last Activation'])) {
      newOrder['status']['pendantTwoLastActivation'] = this.getDateXls(row['Pendant 2 - Last Activation'], 'Pendant 2 - Last Activation',row['Td Code'])
    }
    if (this.exist(row['Last Mains Fail'])) {
      newOrder['status']['lastMainsFail'] = this.getDateXls(row['Last Mains Fail'],'Last Mains Fail',row['Td Code']);
    }
    if (this.exist(row['Mains Fail Count'])) {
      newOrder['status']['mainsFailCount'] = row['Mains Fail Count'];
    }
    if (this.exist(row['Last Smoke Alarm Activation'])) {
      newOrder['status']['lastSmokeAlarmActivation'] = this.getDateXls(row['Last Smoke Alarm Activation'],'Last Smoke Alarm Activation',row['Td Code']);
    }
    if (this.exist(row['Last CO Detector Activation'])) {
      newOrder['status']['lastCODetectorActivation'] = this.getDateXls(row['Last CO Detector Activation'],'Last CO Detector Activation',row['Td Code']);
    }
    if (this.exist(row['Last Appeared On High Use Report'])) {
      newOrder['status']['lastHighUseDate'] =
        this.getDateXls(row['Last Appeared On High Use Report'],'Last Appeared On High Use Report',row['Td Code']);
    }
    if (this.exist(row['Consecutive Months on High Use Report'])) {
      newOrder['status']['highUseCount'] = row['Consecutive Months on High Use Report'],'Consecutive Months on High Use Report',row['Td Code'];
    }
    if (this.exist(row['Last GPS Activation'])) {
      newOrder['status']['lastGpsActivation'] = this.getDateXls(row['Last GPS Activation'], 'Last GPS Activation',row['Td Code'] );
    }
    if (this.exist(row['Last Radio Test Missing'])) {
      newOrder['status']['lastRadioTestMissing'] = this.getDateXls(row['Last Radio Test Missing'], 'Last Radio Test Missing',row['Td Code'] );
    }
    if (this.exist(row['Last Epilepsy Alert'])) {
      newOrder['status']['lastEpilepsyAlert'] = this.getDateXls(row['Last Epilepsy Alert'], 'Last Epilepsy Alert',row['Td Code'] );
    }

    // These updates should happen at the end, else you might undo the changes processing later columns
    if (!['', 'standing order'].includes(newOrder['renewalInformation']['renewalType'])) {
      newOrder['renewalInformation']['renewalDate'] = null;
      newOrder['renewalInformation']['paymentDueDate'] = '';
      for (let i = newOrder['outstandingActions'].length - 1; i >= 0; i--) {
        if (['No DD or RB', 'Due Payment', 'Overdue Payment', 'Missing Renewal/Payment Due Date']
            .includes(newOrder['outstandingActions'][i].outstandingName)) {
          newOrder['outstandingActions'].splice(i, 1);
        }
      }
    }
    const hasBulkTag: boolean = newOrder.tags.some((tag: OrderTag) =>
      tag.tagID.tagName.toLocaleLowerCase().startsWith('bulk')
    );
    if ((newOrder['renewalInformation']['renewalDate'] && newOrder['renewalInformation']['paymentDueDate']) ||
        ['lifetime'].includes(newOrder.accountDetails.planType) || hasBulkTag ||
        row['Td Code'].toLocaleUpperCase().startsWith('KL') || (newOrder.status.status == 'cancelled')) {
      for (let i = newOrder['outstandingActions'].length - 1; i >= 0; i--) {
        if (['Missing Renewal/Payment Due Date'].includes(newOrder['outstandingActions'][i].outstandingName)) {
          newOrder['outstandingActions'].splice(i, 1);
        }
      }
    }
    if (newOrder['renewalInformation']['renewalType'] == 'directDebit') {
      /* Removing Awaiting Direct debit set up tag if renewal type is directdebit */
      newOrder['tags'] = newOrder['tags'].filter((tag: OrderTag) => 
        tag.tagID.tagName != 'Awaiting Direct Debit set up'
      );
    }

    if (this.exist(row['Plan Type']) && row['Plan Type'] == 'lifetime') {
      newOrder.renewalInformation.renewalDate = null;
      newOrder.renewalInformation.paymentDueDate = '';
      newOrder.renewalInformation.renewalType = '';
      newOrder.renewalInformation.renewalPrice = '';
      if (this.exist(row['Renewal Type'])) {
        let renewalType = row['Renewal Type'];
        row['Renewal Type'] = '';
        this.logs.push({
          msg: `Make sure you stop the ${renewalType}`,
          status: 'alert-warning',
          tdCode: row['Td Code']
        });
      }
    }

    if (!doPlanCodeAndTypeMatch(newOrder.accountDetails.plan, newOrder.accountDetails.planType)) {
      this.logs.push({
        msg: 'The "Plan" code and "Plan Type" do not agree with each other, please correct',
        status: 'alert-warning',
        tdCode: row['Td Code']
      });
    }

    if (!doPlanCodeAndVatStatusMatch(newOrder.accountDetails.plan, newOrder.accountDetails.vat)) {
      this.logs.push({
        msg: 'The "Plan" code and "VAT" status do not agree with each other, please correct',
        status: 'alert-warning',
        tdCode: row['Td Code']
      });
    }

    if (statusChangedToCancellingOrReasonChanged && (newOrder['status']['status'] == 'cancelling')) {
      await this.sendArcCancellationEmail(newOrder);
    }

    if ((originalStatus != 'cancelled') && (newOrder['status']['status'] == 'cancelled')) {
      await this.sendArcCancelledEmail(newOrder);
    }

    if (['cancelling', 'cancelled'].includes(originalStatus) &&
      ['active', 'no ncf'].includes(newOrder['status']['status'])) {
      await this.sendArcActivationEmail(newOrder);
    }

    if ((contactDetailChanges.length > 0) && (originalStatus != 'no ncf')) {
      await this.sendArcContactEmail(newOrder, contactDetailChanges);
    }
    tagNames = newOrder['tags'].map((tag: OrderTag) => 
      tag.tagID.tagName
    );
    if(tagNames.includes('Opted out of auto-enrolment') &&
        (tagNames.includes('Eligible for Auto-enrolment') || tagNames.includes('Automatically Renew My Plan Selected') || tagNames.includes('Has been Auto-Enrolled'))) {
      this.logs.push({
        msg: `Cannot have 'Eligible for Auto-enrolment', 'Automatically Renew My Plan Selected' or 'Has been Auto-Enrolled' on an order with 'Opted out of auto-enrolment'`,
        status: 'alert-warning',
        tdCode: row['Td Code']
      });
      newOrder['tags'] = newOrder['tags'].filter((tag: OrderTag) => 
         !['Eligible for auto-enrolment', 'Automatically Renew My Plan Selected', 'Has been Auto-Enrolled'].includes(tag.tagID.tagName)
      );
    }

    const annualDiscountIndex: number = newOrder.renewalDiscounts.findIndex(
      (renewalDiscount: RenewalDiscount) => (renewalDiscount.reason === 'Annual Direct Debit/Recurring Billing')
    );
    if ((newOrder.accountDetails.planType != 'annual') ||
        !['recurringBilling', 'directDebit'].includes(newOrder.renewalInformation.renewalType)) {
      // Remove the discount if it's there
      if (annualDiscountIndex > -1) {
        newOrder.renewalDiscounts.splice(annualDiscountIndex, 1);
      }
    }
  }

  addNoteToOrder(order: Order, content: string, category: string): void {
    const newNote: OrderNote = {
      content: content,
      categories: [category],
      userName: this.userName,
      date: Date(),
    };
    order.notes.push(newNote);
  }

  sendArcCancellationEmail(order: Order): Promise<void> {
    return new Promise<void>((resolve): void => {
      const tdCode: string = order.alarmUserDetails.tdCode;
      const cancellationReason: string = order.cancellation.cancellationReason;
      let emailType: string;
      if ('rip' == cancellationReason.toLocaleLowerCase()) {
        emailType = 'Customer Cancellation RIP';
      } else if ('care' == cancellationReason.toLocaleLowerCase()) {
        emailType = 'Care Home Customer Cancellation';
      } else {
        emailType = `Customer Cancellation Reason: ${cancellationReason}`;
      }
      const emailParams: SendEmailRequest = this.notificationService.getArcCancellationEmailParams(emailType, order, this.userName);
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        let noteContent: string;
        if (response.success) {
          if (response.data.length == 0) {
            noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) => 
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              this.logs.push({
                msg: `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                  `to notify them of the cancellation because ${response.message}. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              this.logs.push({
                msg: `Email could not be sent to ${response.data.join(';')} to notify them of the cancellation. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          this.logs.push({
            msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
                `Error: ${response.message} Please send the email yourself`,
            status: 'alert-warning',
            tdCode: tdCode
          });
          noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      }, (error: any) => {
        this.logs.push({
          msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
          `Error: ${error.message} Please send the email yourself`,
          status: 'alert-warning',
          tdCode: tdCode
        });
        const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      });
    });
  }

  sendArcCancelledEmail(order: Order): Promise<void> {
    return new Promise<void>((resolve): void => {
      const tdCode: string = order.alarmUserDetails.tdCode;
      const emailType: string = 'Order Cancelled';
      const emailParams: SendEmailRequest = this.notificationService.getArcCancellationEmailParams(emailType, order, this.userName);
      let noteContent: string;
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        if (response.success) {
          if (response.data.length == 0) {
            noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) => 
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              this.logs.push({
                msg: `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                    `to notify them of the cancellation because ${response.message}. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              this.logs.push({
                msg: `Email could not be sent to ${response.data.join(';')} to notify them of the cancellation. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          this.logs.push({
            msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
              `Error: ${response.message} Please send the email yourself`,
            status: 'alert-warning',
            tdCode: tdCode
          });
          noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        this.addNoteToOrder(order, noteContent, 'ARC');
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      }, (error: any) => {
        this.logs.push({
          msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the cancellation. ` +
          `Error: ${error.message} Please send the email yourself`,
          status: 'alert-warning',
          tdCode: tdCode
        });
        const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      });
    });
  }

  sendArcActivationEmail(order: Order): Promise<void> {
    return new Promise<void>((resolve): void => {
      const tdCode: string = order.alarmUserDetails.tdCode;
      const emailType: string = 'Order Reactivated';
      const emailParams: SendEmailRequest = this.notificationService.getArcCancellationEmailParams(emailType, order, this.userName);
      let noteContent: string;
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        if (response.success) {
          if (response.data.length == 0) {
            noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) => 
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              this.logs.push({
                msg: `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                    `to notify them of the order reactivation because ${response.message}. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              this.logs.push({
                msg: `Email could not be sent to ${response.data.join(';')} to notify them of the order reactivation. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          this.logs.push({
            msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the order reactivation. ` +
              `Error: ${response.message} Please send the email yourself`,
            status: 'alert-warning',
            tdCode: tdCode
          });
          noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        this.addNoteToOrder(order, noteContent, 'ARC');
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      }, (error: any) => {
        this.logs.push({
          msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the order reactivation. ` +
          `Error: ${error.message} Please send the email yourself`,
          status: 'alert-warning',
          tdCode: tdCode
        });
        const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      });
    });
  }

  sendArcContactEmail(order: Order, changes: string[]): Promise<void> {
    return new Promise<void>((resolve): void => {
      const tdCode: string = order.alarmUserDetails.tdCode;
      const emailType: string = 'Name or Contact Details Changed';
      const emailParams: SendEmailRequest = this.notificationService.getArcContactChangesEmailParams(emailType, order, this.userName, changes);
      let noteContent: string;
      this.notificationService.sendEmail(emailParams).subscribe((response: MultiRecordResponse<string>) => {
        if (response.success) {
          if (response.data.length == 0) {
            noteContent = `${this.userName} sent a ${emailType} email to ${emailParams.recipients.join(';')}.`
          } else {
            const recipients: string[] = emailParams.recipients.filter((recipient: string) => 
              !response.data.includes(recipient)
            );
            // Sent successfully to some
            if (recipients.length > 0) {
              this.logs.push({
                msg: `Email sent to ${recipients.join(';')}, but could not be sent to ${response.data.join(';')}` +
                    `to notify them of the changes because ${response.message}. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} sent a ${emailType} email to ${recipients.join(';')}, ` +
                `but the email failed to send to ${response.data.join(';')}.`;
            } else {
              this.logs.push({
                msg: `Email could not be sent to ${response.data.join(';')} to notify them of the changes. Please send the email yourself`,
                status: 'alert-warning',
                tdCode: tdCode
              });
              noteContent = `${this.userName} tried to send a ${emailType} email to ${response.data.join(';')}, but it failed to send.`;
            }
          }
        } else {
          this.logs.push({
            msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the changes. ` +
              `Error: ${response.message} Please send the email yourself`,
            status: 'alert-warning',
            tdCode: tdCode
          });
          noteContent = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        }
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      }, (error: any) => {
        this.logs.push({
          msg: `Error sending email to ${emailParams.recipients.join(';')} to notify them of the changes. ` +
          `Error: ${error.message} Please send the email yourself`,
          status: 'alert-warning',
          tdCode: tdCode
        });
        const noteContent: string = `${this.userName} tried to send a ${emailType} email to ${emailParams.recipients.join(';')}, but it failed to send.`;
        this.addNoteToOrder(order, noteContent, 'ARC');
        resolve();
      });
    });
  }

  verfication(row, line: number) {
    let websiteName = row['Brand'].trim().toUpperCase();

    let validate = true;
    if (this.brandConfigs[websiteName] === undefined) {
      validate = false;
      this.logs.push({
        msg: 'Brand missing or not valid At line ' + (line + 2),
        status: 'alert-danger',
        tdCode: row['tdCode']
      });
    }
    if ((row['Renewal Type'] == "directDebit" || row['Renewal Type'] == "recurringBilling") && row['Action Title'] && row['Action Title'].includes('No DD or RB')) {
      validate = false;
      this.logs.push({
        msg: "TdCode : " + row['Td Code'] + " -> Order Import Failed: The \"No DD or RB\" and \"RENEWAL TYPE\" " + row['Renewal Type'] + " fields shouldn't exist together",
        status: 'alert-danger',
        tdCode: row['Td Code']
      });
    }
    return validate;
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve): void => {
      setTimeout(resolve, ms);
    });
  }

  async crmProcessUpload(fileContentsBase64: string) {
    const planTypeChangedOrders = [];
    this.progress = 0;
    const orderNotFoundMessage: RegExp = /is not found on the CRM/i;
    const workbook = XLSX.read(fileContentsBase64, {type: 'base64'});
    const first_sheet_name = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[first_sheet_name];
    const rows = (XLSX.utils.sheet_to_json(worksheet, {raw: true}));
    this.maxToProcess = rows.length;
    this.logs = [];
    if (this.maxToProcess > 5000) {
      this.progress = -2;
      this.logs.push({
        msg: 'Spreadsheet exceeds limit of 5000 rows (excluding the header). Please split it and re-upload.',
        status: 'alert-danger',
        tdCode: 'N/A'
      });
      return;
    }

    const counts = {};
    rows.map(row =>
      row['Brand']
    ).forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });

    const updateLog: LogWithUpload = {
      fileName: this.file.name,
      source: 'upload',
      nbRecorders: this.maxToProcess,
      fields: Object.keys(rows[0]),
      recorderPerBrand: counts,
      user: this.userName,
      base64Contents: fileContentsBase64
    };

    function createUploadResultsLog(logsService: LogsService, uploadLog: LogWithUpload, retryCount: number) {
      logsService.createLog({log: uploadLog})
        .subscribe((logres: SimpleResponse) => {
          if (!logres.success) {
            console.error('Error creating upload log', logres.message);
            if (retryCount > 0) {
              createUploadResultsLog(logsService, uploadLog, retryCount-1);
            }
          }
        }, err => {
          console.error('Error creating upload log', err);
          if (retryCount > 0) {
            createUploadResultsLog(logsService, uploadLog, retryCount-1);
          }
        });
    }

    createUploadResultsLog(this.logsService, updateLog, 2);

    // Track a new record type for original imports to track down who is still using them
    createUploadResultsLog(this.logsService, {
      'source': 'original import',
      'user': updateLog.user,
      'fields': updateLog.fields,
    }, 2);

    Object.keys(rows[0])
    let tdcodekey = 'Td Code';
    for (let key of Object.keys(rows[0])) {
      if (key.toLocaleLowerCase().split(" ").join('') == 'tdcode') {
        tdcodekey = key;
      }
    }

    let numProcessing: number = 0;
    function updateProgress(myOrdersComp: MyOrdersComponent) {
      numProcessing--;
      myOrdersComp.progress += 1;
      myOrdersComp.progressVal = Math.round(Math.floor((myOrdersComp.progress / myOrdersComp.maxToProcess) * 100));
    }

    for (let index: number = 0; index < this.maxToProcess; index++) {
      // Limit the max number of orders being updated in parallel to stop resource error causing it to try to create orders
      while (numProcessing >= MAX_PARALLEL) {
        await this.delay(250);
      }
      numProcessing++;
      const row: any = rows[index];
      row['Td Code'] = row[tdcodekey];
      const websiteName = row['Brand'].trim().toUpperCase();
      if (this.verfication(row, index)) {
        let websiteOrderIsFor: Website;
        for (let website of this.websites) {
          if (website.title == websiteName) {
            websiteOrderIsFor = website;
          }
        }
        if (!websiteOrderIsFor) {
          this.logs.push({
            msg: `Brand code ${websiteName} not found.`,
            status: 'alert-danger',
            tdCode: row['Td Code']? row['Td Code']: `Row Number: ${index+1}`,
          });
          updateProgress(this);
          continue;
        }
        if (!row['Td Code']) {
          this.logs.push(
            {msg: 'Td Code missing from Import spreadsheet', status: 'alert-danger', tdCode: `Row Number: ${index+1}`}
          );
          updateProgress(this);
        } else {
          this.orderService
            .getOrderTdCode(row['Td Code'], websiteOrderIsFor._id)
            .subscribe(async (response: OrderResponse) => {
              const newOrder: Order = response.order;
              if (this.exist(row['Plan Type']) && (row['Plan Type'] != newOrder.accountDetails.planType)) {
                planTypeChangedOrders.push({
                  brandName: websiteName,
                  tdCode: newOrder.alarmUserDetails.tdCode,
                  oldPlanType: newOrder.accountDetails.planType,
                  newPlanType: row['Plan Type'],
                });
              }
              if ((index == rows.length - 1) && (planTypeChangedOrders.length > 0)) {
                this.sendPlanTypeAutoNotification(planTypeChangedOrders);
              }
              try {
                this.makeOrder(row, newOrder);
                this.logs.push({msg: 'Trying to Update order ... ', status: 'alert-info', tdCode: row['Td Code']});
                newOrder.alarmUserDetails.tdCode = (newOrder.alarmUserDetails.tdCode + '').toUpperCase();
                const result: Observable<OrderResponse> = this.orderService.updateOrder(newOrder._id, {
                  'order': newOrder, user: this.userName,
                  reason: "XLS import"
                });
                result.subscribe((res: OrderResponse) => {
                  if (res.success) {
                    this.logs.push({msg: 'order updated ', status: 'alert-success', tdCode: row['Td Code']});
                  } else {
                    this.logs.push({msg: res.message, status: 'alert-danger',tdCode: row['Td Code']});
                  }
                  updateProgress(this);
                }, err => {
                  this.logs.push({
                    msg: err['error']['message'],
                    status: 'alert-danger',
                    tdCode: row['Td Code']
                  });
                  updateProgress(this);
                });
              } catch (error) {
                this.logs.push({
                  msg: `Error creating order. Error: ${error.message}`,
                  status: 'alert-danger',
                  tdCode: row['Td Code']
                });
                updateProgress(this);        
              }
            }, err => {
              if ((err.status == 400) && err.error && err.error.message && orderNotFoundMessage.test(err.error.message)) {
                if (!this.allowOrderCreation) {
                  this.logs.push({msg: 'Order not found and order creation disabled ... ', status: 'alert-warning', tdCode: row['Td Code']});
                  updateProgress(this);
                } else {
                  this.orderService.findOrder({'alarmUserDetails.tdCode': row['Td Code']})
                    .subscribe(async (findResponse: FindOrderResponse) => {
                      if (findResponse.success && findResponse.orders && (findResponse.orders.length > 0)) {
                        this.logs.push(
                            {msg: 'Td Code exists for another brand, not creating order ... ', status: 'alert-danger', tdCode: row['Td Code']}
                        );
                      } else if (findResponse.success && findResponse.orders && (findResponse.orders.length === 0)) {
                        // Not found, rather than some other error
                        this.logs.push({
                          msg: 'Td Code not in use, but order creation not allowed with this import. Please use "Create Order" import if you want to create a new order.',
                          status: 'alert-danger', tdCode: row['Td Code']
                        });
                      } else {
                        // Don't try to create the order, if it was an error other than not found.
                        let errorMessage: string = 'unknown error';
                        if (err.error.message){
                          errorMessage = err.error.message;
                        }
                        this.logs.push(
                          {msg: `Error checking if TD code already in use. Error: ${errorMessage}`, status: 'alert-danger', tdCode: row['Td Code']}
                        );
                      }
                      updateProgress(this);
                    }, _err => { 
                        this.logs.push(
                          {msg: 'Error checking Td Code for other brands, not creating order ... ', status: 'alert-danger', tdCode: row['Td Code']}
                        );
                        updateProgress(this);
                      }
                    );
                }
              } else {
                // Don't try to create the order, if it was an error other than not found.
                let errorMessage: string = 'unknown error';
                if (err.error.message){
                  errorMessage = err.error.message;
                }
                this.logs.push({msg: `Error retrieving record from CRM. Error: ${errorMessage}`, status: 'alert-danger', tdCode: row['Td Code']});
                updateProgress(this);
              }
            });
        }
      } else {
        updateProgress(this);
      }
    }
    // console.log("ChangedPlanTypeOrders :: ", planTypeChangedOrders);
  }

  sendPlanTypeAutoNotification(planTypeChangedOrders) {
    // console.log("Plan type changed orders :: ", planTypeChangedOrders);
    this.orderService.sendPlanTypeChangeAutoNotification({
      changedOrders: JSON.stringify(planTypeChangedOrders),
      userName: this.userName,
    }).subscribe((response: any) => {
      console.log("Response on sending planTypeChangeAutoNotification", response);
    }, (err: Error) => {
      console.log("Error while sending planTypeChangeAutoNotification", err);
    })
  }

  async serverProcessUpload(fileContentsBase64: string) {
    this.logs = [];
    this.progress = -2;
    const importParams: SpreadsheetImportRequest = {
      'appName': this.selectedImportType.appName,
      'filename': this.file.name,
      'fileContents': fileContentsBase64,
      'username': this.userName,
      'email': localStorage.getItem('email'),
    };
    this.spreadsheetImportService.importSpreadsheet(importParams).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          this.logs.push({
            'msg': response.message,
            'status': 'alert-success',
            'tdCode': 'N/A'
          });
        } else {
          this.logs.push({
            'msg': response.message,
            'status': 'alert-danger',
            'tdCode': 'N/A'
          });
        }
      },
      (error: any) => {
        this.logs.push({
          'msg': 'Error processing spreadsheet. Error' + error.message,
          'status': 'alert-danger',
          'tdCode': 'N/A'
        });
      }
    );
  }

  /* Create Review process */
  processReviewImport(fileContentsBase64: string) {
    this.progress = 0;
    const workbook = XLSX.read(fileContentsBase64, { type: 'base64', dateNF: 'd/m/yy hh:mm', cellDates: true });
    const first_sheet_name = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[first_sheet_name];
    const rows = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    this.maxToProcess = rows.length;
    this.logs = [];
    if (this.maxToProcess >5000) {
      this.progress = -2;
      this.logs.push({
        msg: 'Spredsheet exceeds limit of 5000 rows (excluding the header). Please split it and re-upload.',
        status: 'alert-danger',
        tdCode: 'N/A'
      });
      return;
    }

    const updateLog: LogWithUpload = {
      fileName: this.file.name,
      source: 'upload',
      nbRecorders: this.maxToProcess,
      fields: Object.keys(rows[0]),
      recorderPerBrand: { [this.selectedBrandForReview]: this.maxToProcess },
      user: this.userName,
      base64Contents: fileContentsBase64,
    }
    
    const params: ReviewsImportRequest = {
      reviews: JSON.stringify(rows),
      brandId: this.brandConfigs[this.selectedBrandForReview]._id
    };
    this.logs.push({
      msg: 'Trying to import reviews',
      status: 'alert-info',
      tdCode: 'N/A'
    });
    this.reviewsService.importReviews(params).subscribe((response: MultiRecordResponse<ReviewImportMessage>) => {
      console.log("Response on importing reviews :: ", response);
      if (response.success) {
        this.logs.push({
          msg: 'Reviews Imported',
          status: 'alert-success',
          tdCode: 'N/A'
        });
        response.data.forEach(element => {
          this.logs.push({
            msg: element.message,
            status: 'alert-warning',
            tdCode: element.order_id
          });
        });
      } else {
        this.logs.push({
          msg: 'Something went wrong try again',
          status: 'alert-danger',
          tdCode: 'N/A'
        });
      }
      this.createUploadLog(updateLog, 2);
    }, (err: Error) => {
      console.log("Error on importing reviews :: ", err);
      this.logs.push({
        msg: `Error on importing reviews. Error: ${err.message}`,
        status: 'alert-danger',
      });
    })
  }

  createUploadLog(uploadLog: LogWithUpload, retryCount: number) {
    this.logsService
      .createLog({log: uploadLog})
      .subscribe((logres: SimpleResponse) => {
        if (!logres.success) {
          console.error('Error creating upload log', logres.message);
          if (retryCount > 0) {
            this.createUploadLog(uploadLog, retryCount-1);
          }
        }
        this.progress = this.maxToProcess;
        this.progressVal = 100;
      }, err => {
        console.error('Error creating upload log', err);
        if (retryCount > 0) {
          this.createUploadLog(uploadLog, retryCount-1);
        }
      });
  }

  /*Import function*/
  async upload() {
    const fileContents: string = await getBase64EncodedFileContents(this.file);
    if (this.selectedImportType.appName === 'crm') {
      this.crmProcessUpload(fileContents);
    } else if (this.selectedImportType.appName == 'create review') {
      this.processReviewImport(fileContents);
    } else {
      this.serverProcessUpload(fileContents);
    }
  }

  closeUpload() {
    this.progress = -1;
    this.showImportDialog = false;
  }

  /*Import function*/
  /*dateTimeout: any;*/
  @ViewChild('dt', {static: true})
  private table: Table;

  onClick() {
    // this.table.clear();
    this.table.filteredValue = null;
    this.table.first = 0;
    this.findField = '';
    this.selectedstatus = [];
    this.selectedBrand = [];

    // grab a copy of the p-columnFilter values as if these are removed completly it upsets the controls.
    const tempPColumnFilters: { [s: string]: FilterMetadata | FilterMetadata[]; } = {};
    for (const filterName of Object.keys(this.table.filters)) {
      const colDef: Column|undefined = this.cols.find((column: Column) => column.field == filterName);
      if (colDef && colDef.usePColumnFilter) {
        tempPColumnFilters[filterName] = {
          value: null,
          matchMode: colDef.filterMatchMode? colDef.filterMatchMode: 'startsWith',
        };
      }
    }
    this.table.filters = tempPColumnFilters;
    // clear has removed the filters, so back to original data length
    this.table.totalRecords = this.data.length;
    this.actionDateFilters = null;
    this.actionsInitiatedDateFilters = null;
    this.selectedAction = '';
    this.dateFilters = null;
    this.selectedrenewalType = [];
    this.selectedOrders = [];
    this.selectedMonths = [];
    this.selectedmonitoring = [];
    this.renewalDateFilters = null;
    this.notesDateDateFilters = null;
    this.returnDateDateFilters = null;
    this.cancellationDateFilters = null;
    this.selectedrenewalType = [];
    this.table.sortField = 'created';
    this.table.sortOrder = -1;
    this.table.sortSingle();
  }

  sortByDate(e) {
    if (this.mark == 0) {
      this.mark = 1;
    }
    this.mark *= -1;
    this.table.filteredValue = [
      ...this.data.filter(row => row.outstandingActionsRenewalDateTaken.length != 0).sort((a, b) => 
        this.mark * (a.outstandingActionsRenewalDateTaken[0].getTime() - b.outstandingActionsRenewalDateTaken[0].getTime())
      ),
      ...this.data.filter(row => row.outstandingActionsRenewalDateTaken.length == 0)
    ];
  }

  export(orderExportOption) {
    if (!this.excelService.exportAllColumns(
      this.selectedOrders,
      this.cols,
      this.userName,
      orderExportOption
    )) {
      this.messageService.add({
        severity: 'error',
        life: 300000,
        summary: 'Data Truncated',
        detail: 'Some of the data exceeded the maximum excel cell length of 32,767 characters. Affected cells have been truncated and have "Truncated..." at the start',
      });
    }
  }

  customExport(orderExportOption) {
    this.selectedColumns.sort((a, b) => a.order - b.order);
    if (!this.excelService.exportAllColumns(
      this.selectedOrders,
      this.selectedColumns,
      this.userName,
      orderExportOption
    )) {
      this.messageService.add({
        severity: 'error',
        life: 300000,
        summary: 'Data Truncated',
        detail: 'Some of the data exceeded the maximum excel cell length of 32,767 characters. Affected cells have been truncated and have "Truncated..." at the start',
      });
    }
  }

  userCanExportOrders(): boolean {
    return this.userService.userHasPermission('Export Orders');
  }

  userHasImportPermission(): boolean {
    return this.userService.userHasImportPermission();
  }

  userCanSetOrderToCancelling(): boolean {
    return this.userService.userHasPermission('Set Order to Cancelling');
  }
 
  async ngOnInit() {
    this.title.setTitle('CRM Home');
    this.first = 0;
    this.updateReceived = false;
    this.updatesWhileLoading = {};
    this.closing = false;
    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.locked = data;
    });
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    this.selectedOrders = [];
    this.orderUpdatesSocket.on('updateOrders', this.processUpdateFromSocketIo);
    this.tagsColor = {};
    this.selectedAction = '';
    this.userName = localStorage.getItem('userName');
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
    this.selectedBrandForReview = this.brands[0].value;
    this.actions = getActionConfigs();
    this.actionSelectItems = getActionSelectItems();
    this.actionNameLookup = getActionNameLookup();
    this.importTypes = importTypes.filter(
      (importType: ImportType) => this.userService.userHasPermission(importType.label)
    );
    this.selectedImportType = (this.importTypes.length > 0)? this.importTypes[0]: undefined;

    // Update lock list if page is redisplayed.
    this.locksSocket.emit('getLocked');

    this.progress = -1;
    /*MOMENT JS*/
    this.tagsService.getActiveTags().subscribe((tagsResponse: TagsResponse) => {
      this.tags = tagsResponse["tags"];
      // console.log(this.tags);
      for (let tag of this.tags) {
        this.tagsColor[tag["tagName"]] = tag["color"];
        this.tagSelectItems.push({
          'label': tag.tagName,
          'value': tag.tagName,
        });
      }
    }, err => {
      ////console.log(err);
    });
    this.mark = 0;
    /*End MOMENT JS*/
    this.websiteService.getWebsites()
      .subscribe((websites: WebsitesResponse) => {
        this.websites = websites.websites;
      });
    this.orderService.getOrderPageCount().subscribe(
      async (pageCountResponse: PageCountResponse) => {
        if (!pageCountResponse.success) {
          this.showPageCountError(pageCountResponse.message);
        } else {
          this.paginationLoadingProg = pageCountResponse.pageCount!;
          this.pagesProcessing = 0;
          for (let page: number = pageCountResponse.pageCount!; page > 0; page--) {
            // Limit the max number of pages being updated in parallel which allows other work to complete
            // whilst pages are still loading and allows abort if page navigated away from
            while (this.pagesProcessing >= MAX_PARALLEL) {
              await this.delay(250);
            }
            // No need to keep loading pages
            if (this.closing) {
              break;
            }
            this.pagesProcessing++;
            this.loadPage(page, PAGE_RETRIES);
          }
        }
      });
    this.sliceOptions = {
      start: 0,
      end: 10,
      default: 10
    };
    this.cancellationReasons = [{
      value: '*blanks', label: ' '
    }];
    // this strips the disabled flag and other properties off the select items
    getCancellationReasonCategories(false).forEach((selectItem: CancellationCategorySelectItem) => {
      this.cancellationReasons.push({
        'label': selectItem.label,
        'value': selectItem.value
      });
    });
    this.pStatus = equipStatusSelectItems;
    this.freeMonthsType = [
      {label: '1 month', value: '1'},
      {label: '2 months', value: '2'},
      {label: '3 months', value: '3'},
      {label: '4 months', value: '4'},
      {label: '5 months', value: '5'},
      {label: '6 months', value: '6'},
      {label: '7 months', value: '7'},
      {label: '8 months', value: '8'},
      {label: '9 months', value: '9'},
      {label: '10 months', value: '10'},
      {label: '11 months', value: '11'},
      {label: '12 months', value: '12'},
    ];
    this.monitoringOptions = getMonitoringOptions();
    
    this.cols = orderSpreadsheetCols;
    this.bigSearch = this.cols.filter((col: Column) => 
        !col.excludeFromGlobalSearch
      ).map ((col: Column) => 
        col.field
      );
    // Add field name of notes array to allow filter to handle
    this.bigSearch.push('notesContent');
    this.defaultColumns = JSON.parse(localStorage.getItem('defaultColumns'));
    if (!this.selectedColumns) {
      // this.selectedColumns = this.cols.filter(col => col.hide != true);
      this.selectedColumns = this.defaultColumns;
    }
    this.savedColumnSets = JSON.parse(localStorage.getItem('savedColumnSets'));

    let _self = this;
    /*Filter column on load by default*/
    this.selectedstatus = [];
    this.selectedBrand = [];
    this.filterService.register('NoDate', (value: any[], filter: any): boolean => {
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());
        // console.log(vDate);
        if (vDate == 0) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollection', (value: any[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      // if(filter === undefined || filter === null || )
      if ((filter === undefined) || (filter === null) || (_self.actionDateFilters == null) ||
          (_self.actionDateFilters[0] === undefined) || (_self.actionDateFilters[0] === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.actionDateFilters[0].getTime();
      let e;
      if (_self.actionDateFilters[1]) {
        e = _self.actionDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());

        if (vDate >= s && vDate <= e) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollectionActionsInitiatedDateFilter', (value: any[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.actionsInitiatedDateFilters === null) ||
          (_self.actionsInitiatedDateFilters[0] === undefined) || (_self.actionsInitiatedDateFilters[0] === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.actionsInitiatedDateFilters[0].getTime();
      let e;
      if (_self.actionsInitiatedDateFilters[1]) {
        e = _self.actionsInitiatedDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      for (let i = 0; i < value.length; i++) {

        let vDate = (new Date(value[i]).getTime());

        if (vDate >= s && vDate <= e) {
          return true;
        }
      }

      return false;
    });
    this.filterService.register('inCollectionReturnDate', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.returnDateDateFilters === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.returnDateDateFilters[0].getTime();
      let e;
      if (_self.returnDateDateFilters[1]) {
        e = _self.returnDateDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inCollectionCancellationDate', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if (filter === undefined || filter === null || _self.cancellationDateFilters === null || _self.cancellationDateFilters.length === 0 || _self.cancellationDateFilters[0] === undefined || _self.cancellationDateFilters[0] === null) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      let s = _self.cancellationDateFilters[0].getTime();
      let e;
      if (_self.cancellationDateFilters[1]) {
        e = _self.cancellationDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inCollectionNoteDate', (noteTimes: number[], filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter == null) || (_self.notesDateDateFilters == null)) {
        return true;
      }
      if ((noteTimes == null) || (noteTimes.length == 0)) {
        return false;
      }
      const startTime: number = _self.notesDateDateFilters[0].getTime();
      let endTime: number;
      if (_self.notesDateDateFilters[1]) {
        endTime = _self.notesDateDateFilters[1].getTime() + 86400000 - 1;
      } else {
        endTime = startTime + 86400000 - 1;
      }
      for (let i = 0; i < noteTimes.length; i++) {
        if (!noteTimes[i]) {
          continue;
        }
        if ((noteTimes[i] >= startTime) && (noteTimes[i] <= endTime)) {
          return true;
        }
      }
      return false;
    });
    this.filterService.register('inCreatedCollection', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.dateFilters === null) || (!_self.dateFilters[0])) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.dateFilters[0].getTime();
      let e;
      if (_self.dateFilters[1]) {
        e = _self.dateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      let vDate = (new Date(value).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
    this.filterService.register('inrenewalDateFilters', (value: any, filter: any): boolean => {
      // value = array of data from the current row
      // filter = value from the filter that will be searched in the value-array
      ////console.log("------------inCollection--------------")
      if ((filter === undefined) || (filter === null) || (_self.renewalDateFilters === null)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length === 0)) {
        return false;
      }
      let s = _self.renewalDateFilters[0].getTime();
      let e;
      if (_self.renewalDateFilters[1]) {
        e = _self.renewalDateFilters[1].getTime() + 86400000 - 1;
      } else {
        e = s + 86400000 - 1;
      }
      // let vDate = (new Date(value).getTime());
      let vDate = (new Date(_self.myMoment(value,'DD/MM/YYYY').toDate()).getTime());

      if (vDate >= s && vDate <= e) {
        return true;
      }
      return false;
    });
  }

  loadColumnSet(columns: Column[]) {
    this.selectedColumns = columns;
  }

  processUpdateFromSocketIo: (orderUpdateData: OrderUpdate) => void = (newData: OrderUpdate) => {
    // Hold off applying updates whilst the data is loading else end up with duplicates
    if (this.paginationLoadingProg > 0) {
      this.updatesWhileLoading[newData.order._id] = newData.order;
    } else {
      this.updateReceived = true;
      this.first = this.table.first;   
      const neworder: HomepageFormatOrder = convertOrderToData(newData.order);
      let tmpData = this.data;
      this.data = [];
      if (newData.order.deleted == false) {
        this.data = [...[neworder], ...tmpData.filter(item => item._id != neworder["_id"])];
      } else {
        this.data = [...tmpData.filter(item => item._id != neworder["_id"])];
      }
    }
  }

  loadPage(page: number, retryCount: number) {
    // pageCount gives number of pages, but get by page uses zero offset
    this.orderService
      .getOrdersByPage(page - 1)
      .subscribe((orderResponse: OrderPageResponse) => {
        if ((!orderResponse.success) || (!orderResponse.orders)) {
          console.log(`Error loading page ${page}. Error: ${orderResponse.message}`);
          if (retryCount > 0) {
            this.loadPage(page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.pagesProcessing--;
          return;
        }
        this.pagesProcessing--;
        this.data = [...this.data, ...orderResponse.orders.map((order: Order) => {
          return convertOrderToData(order);
        })];
        this.All = this.data.length;
        this.decrementPagesRemaining();
      }, 
      (err: any) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadPage(page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.pagesProcessing--;
      });
  }

  decrementPagesRemaining(): void {
    this.paginationLoadingProg--;
    if (this.paginationLoadingProg == 0) {
      // Now apply updates received whilst pages loading
      this.updateReceived = true;
      this.first = this.table.first;
      let tmpData = this.data;
      this.data = [];
      tmpData.forEach(item => {
        if (this.updatesWhileLoading[item._id]) {
          const order: Order = this.updatesWhileLoading[item._id];
          // Don't included orders now deleted
          if (!order.deleted) {
            // Remove the order off so updates so we are left with just new records
            delete this.updatesWhileLoading[item._id];
            this.data.push(convertOrderToData(order));
          }
        } else {
          // The order hasn't changed
          this.data.push(item);
        }
      });
      Object.keys(this.updatesWhileLoading).forEach((orderId: string) => {
        this.data.push(convertOrderToData(this.updatesWhileLoading[orderId]));
        delete this.updatesWhileLoading[orderId];
      });

    }
  }

  /*Filter column on load by default*/
  goBack() {
    this.location.back();
  }

  onColumnChange(event, _dt) {
    const isTitleExist: boolean = event.value.some((col: Column) => col.field == 'title');
    const isTdCodeExist: boolean = event.value.some((col: Column) => col.field == 'tdCode');
    const isCustomerNameExist: boolean = event.value.some((col: Column) => col.field == 'customerName');
    // Have to push the current column definition, else it doesn't work correctly
    if (!isTitleExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'title'));
    }
    if (!isTdCodeExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'tdCode'));
    }
    if (!isCustomerNameExist) {
      this.selectedColumns.push(orderSpreadsheetCols.find((defaultCfg: Column) => defaultCfg.field == 'customerName'));
    }
    this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  isColumnVisible(column: string): boolean {
    return this.selectedColumns? this.selectedColumns.some((col: Column) => (col.field === column)): false;
  }

  unlock(_id) {
    ////console.log("unlock");
    this.confirmationService.confirm({
      message: this.orderLockedBy(_id) +
        ' is currently editing this order, do you want to have full access permission? Warning: Any unsaved changes made by ' +
        this.orderLockedBy(_id) + ' will be lost.',
      header: 'Warning',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit("unlocking", {'orderId': _id, 'user': this.userName});
        this.router.navigate(["/order/" + _id]);
      },
      reject: () => {
      }
    });
  }

  customSort(event: SortEvent) {
    let arrayToSort: any[] = this.table.value;
    if (this.table.filteredValue != null) {
      arrayToSort = this.table.filteredValue;
    }
    arrayToSort = arrayToSort.map(order => {
      for (let k = 0; k < order.outstandingActionsRenewalDateTaken.length; k++) {
        for (let i = 0; i < order.outstandingActionsRenewalDateTaken.length - 1; i++) {
          if (event.order == -1 && (order.outstandingActionsRenewalDateTaken[i] < order.outstandingActionsRenewalDateTaken[i + 1])) {
            let x = order.outstandingActionsRenewalDateTaken[i];
            order.outstandingActionsRenewalDateTaken[i] = order.outstandingActionsRenewalDateTaken[i + 1];
            order.outstandingActionsRenewalDateTaken[i + 1] = x;
            x = order.outstandingActionsName[i];
            order.outstandingActionsName[i] = order.outstandingActionsName[i + 1];
            order.outstandingActionsName[i + 1] = x;
            x = order.outstandingActionsInitiatedDate[i];
            order.outstandingActionsInitiatedDate[i] = order.outstandingActionsInitiatedDate[i + 1];
            order.outstandingActionsInitiatedDate[i + 1] = x;
          }
          if (event.order == 1 && (order.outstandingActionsRenewalDateTaken[i] > order.outstandingActionsRenewalDateTaken[i + 1] || new Date(order.outstandingActionsRenewalDateTaken[i]).getTime() == 0)) {
            let x = order.outstandingActionsRenewalDateTaken[i];
            ////console.log(order.outstandingActionsRenewalDateTaken[i])
            order.outstandingActionsRenewalDateTaken[i] = order.outstandingActionsRenewalDateTaken[i + 1];
            order.outstandingActionsRenewalDateTaken[i + 1] = x;
            x = order.outstandingActionsName[i];
            order.outstandingActionsName[i] = order.outstandingActionsName[i + 1];
            order.outstandingActionsName[i + 1] = x;
            x = order.outstandingActionsInitiatedDate[i];
            order.outstandingActionsInitiatedDate[i] = order.outstandingActionsInitiatedDate[i + 1];
            order.outstandingActionsInitiatedDate[i + 1] = x;
          }
        }
      }
      return order;
    });

    event.data.sort((data1, data2) => {
      const datesFields: string[] = ['renewalDate', 'dob', 'paymentDueDate', 'dispatchDate'];
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      if (event.field == "outstandingActionsRenewalDateTaken") {
        let p1 = 0;
        let p2 = 0;
        if (this.selectedAction != '') {
          p1 = data1["outstandingActionsName"].indexOf(this.selectedAction);
          p2 = data2["outstandingActionsName"].indexOf(this.selectedAction);

        }
        value1 = moment(data1[event.field][p1]).format('YYYY-MM-DD');
        value2 = moment(data2[event.field][p2]).format('YYYY-MM-DD');

        if (event.order == -1) {
          if (value1 == "Invalid date" || p1 == -1)
            value1 = '1999-01-01';
          if (value2 == "Invalid date" || p2 == -1)
            value2 = '1999-01-01';
        }
        if (event.order == 1) {
          if (value1 == "Invalid date" || p1 == -1)
            value1 = '2999-12-30';
          if (value2 == "Invalid date" || p2 == -1)
            value2 = '2999-12-30';
        }
      } else if (datesFields.includes(event.field)) {
        value1 = value1 ? moment(value1, 'DD/MM/YYYY').toDate() : value1;
        value2 = value2 ? moment(value2, 'DD/MM/YYYY').toDate() : value2;
      } else if (event.field == 'notesContent') {
        value1 = data1.notesContent[0]? data1.notesContent[0]: '';
        value2 = data2.notesContent[0]? data2.notesContent[0]: '';
      } else if (event.field == 'noteCategory') {
        value1 = data1.noteCategory[0]? data1.noteCategory[0]: '';
        value2 = data2.noteCategory[0]? data2.noteCategory[0]: '';
      } else if (event.field == 'notesUserName') {
        value1 = data1.notesUserName[0]? data1.notesUserName[0]: '';
        value2 = data2.notesUserName[0]? data2.notesUserName[0]: '';
      } else if (event.field == 'notesWithDate') {
        // notesWithDate starts with the note username then has note date then categories
        value1 = data1.notesUserName[0]? `${data1.notesUserName[0]} ${data1.noteTime[0]} ${data1.noteCategory[0]}`: '';
        value2 = data2.notesUserName[0]? `${data2.notesUserName[0]} ${data2.noteTime[0]} ${data2.noteCategory[0]}`: '';
      } else if (event.field == 'notesDate') {
        value1 = data1.noteTime[0]? data1.noteTime[0]: '';
        value2 = data2.noteTime[0]? data2.noteTime[0]: '';
      }
      // ////console.log(value1+"|"+value2);
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);

    });

  }

  customFilter(event) {
    if (this.table.filteredValue == null) {
      this.table.filteredValue = this.table.value;
    }
    if (this.selectedAction && this.actionDateFilters && this.actionDateFilters.length != 0) {
      this.table.filteredValue = this.table.filteredValue.filter(e => {
        const selectedActionIndex: number = e.outstandingActionsName.indexOf(this.selectedAction);
        let date1: any = new Date();
        const date2 = moment(this.actionDateFilters[0]).format('YYYY/MM/DD');
        if (selectedActionIndex == -1) {
          return false;
        } else {
          date1 = moment(e.outstandingActionsRenewalDateTaken[selectedActionIndex]).format('YYYY/MM/DD');
        }
        if (this.actionDateFilters[0] && this.actionDateFilters[1]) {
          const date3 = moment(this.actionDateFilters[1]).format('YYYY/MM/DD');
          return Date.parse(date2) <= Date.parse(date1) && Date.parse(date1) <= Date.parse(date3);
        } else {
          return Date.parse(date1) == Date.parse(date2);
        }
      });
      this.table.totalRecords = this.table.filteredValue.length;
    } 

    if (this.selectedAction && this.actionsInitiatedDateFilters && this.actionsInitiatedDateFilters.length != 0) {
      this.table.filteredValue = this.table.filteredValue.filter(e => {
        const selectedActionIndex: number = e.outstandingActionsName.indexOf(this.selectedAction);
        let date1: any = new Date();
        const date2 = moment(this.actionsInitiatedDateFilters[0]).format('YYYY/MM/DD');
        if (selectedActionIndex == -1)
          return false;
        else
          date1 = moment(e.outstandingActionsInitiatedDate[selectedActionIndex]).format('YYYY/MM/DD');
        if (this.actionsInitiatedDateFilters[0] && this.actionsInitiatedDateFilters[1]) {
          const date3 = moment(this.actionsInitiatedDateFilters[1]).format('YYYY/MM/DD');
          return Date.parse(date2) <= Date.parse(date1) && Date.parse(date1) <= Date.parse(date3);
        } else {
          return Date.parse(date1) == Date.parse(date2);
        }
      });
      this.table.totalRecords = this.table.filteredValue.length;
    }
    // If the re-run of the filter is triggered by an update want to go back to the page we were on
    if (this.updateReceived) {
      this.table.first = Math.min(this.first, this.table.totalRecords);
      // reset the flag
      this.updateReceived = false;
    }
  }

  showDialog(data) {
    ////console.log("showDialog");
    this.vimModalDisplay = true;
    this.vimsForDialog = data.vim;
    this.titleDialog = data.tdCode + ", " + data.customerName;
  }

  refresh() {
    location.reload();
  }

  isOrderLocked(_id: string) {
    return this.locked.filter((lock: OrderLockData) => lock.orderId == _id).length > 0
  }

  orderLockedBy(_id: string) {
    return this.locked.filter((lock: OrderLockData) => lock.orderId == _id)[0].user
  }

  actionDateCustomFilter($event: MouseEvent, number: number) {

    // //console.log(this.actionDateFilters);
    this.actionDateFilters = [];
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.actionDateFilters.push(start);
    // console.log("Action date filter 1 : ", this.actionDateFilters);
    if (number > 0) {

      this.actionDateFilters.push(moment(start).add(number, 'days').toDate());
    } else {
      this.actionDateFilters.unshift(moment(start).add(number, 'days').toDate())
    }
// console.log(this.table.filters)
    // console.log("Action date filter 2 : ", this.actionDateFilters)

  }

  createdDateCustomFilter($event: MouseEvent, number: number) {

    // //console.log(this.actionDateFilters);
    this.dateFilters = [];
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    this.dateFilters.push(start);
    // console.log("Action date filter 1 : ", this.actionDateFilters);
    if (number > 0) {

      this.dateFilters.push(moment(start).add(number, 'days').toDate());
    } else {
      this.dateFilters.unshift(moment(start).add(number, 'days').toDate())
    }
// console.log(this.table.filters)
    // console.log("Action date filter 2 : ", this.actionDateFilters)

  }

  lastCalenderMonth($event: MouseEvent, startMonth: number, endMonth: number) {

    // //console.log(this.actionDateFilters);
    this.dateFilters = [];
    let prevMonthFirstDay = moment().subtract(startMonth, 'months').startOf('month').toDate();
    // The filter adds 1 ms short of a day to the end date, so need to be at the start of the end date, else go into the following day
    let nextMonthLastDay = moment().subtract(endMonth, 'months').endOf('month').startOf('day').toDate();
    this.dateFilters.push(prevMonthFirstDay, nextMonthLastDay);
  }

  clearDateFilters(filterName: string) {
    const filterDefinition: FilterMetadata = this.table.filters[filterName] as FilterMetadata;
    if (filterDefinition) {
      this.table.filter(null, filterName, filterDefinition.matchMode);
    }
  }

  ngOnDestroy() {
    this.closing = true;
    this.orderUpdatesSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
  }

  globalFilter($event: Event, filterType: string): void {
    this.table.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    if (this.table.filters[field] instanceof Array) {
      const filterMetadataArray: FilterMetadata[] = (this.table.filters[field] as FilterMetadata[]);
      if (filterMetadataArray.length > 0){
        return filterMetadataArray[0].value;
      }
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }

  promptForSetName() {
    this.showColumSetDialog = true;
  }

  closeColumnSetDialog() {
    const colSet: SelectItem<Column[]> = this.savedColumnSets.find((colSet: SelectItem<Column[]>) => 
      colSet.label == this.columnSetName
    );
    if (colSet) {
      this.showErrorPrompt('Name must be unique', 'You must specify a name you have not already used');
    } else {
      this.saveColumns(this.columnSetName, true);
      this.showColumSetDialog = false;
    }
  }

  saveColumns(setName: string, newName: boolean) {
    this.isSaveColumnDisabled = true;
    // Update local storage with their new choices
    if (setName == 'Default') {
      localStorage.setItem('defaultColumns', JSON.stringify(this.selectedColumns));
      this.defaultColumns = this.selectedColumns;
    } else {
      if (newName) {
        this.savedColumnSets.push({
          label: setName,
          value: this.selectedColumns
        });
        this.savedColumnSets.sort((setA: SelectItem<Column[]>, setB: SelectItem<Column[]>) => 
          setA.label.localeCompare(setB.label)
        );
      } else {
        const colSet: SelectItem<Column[]> = this.savedColumnSets.find((colSet: SelectItem<Column[]>) => 
          colSet.label == setName
        );
        if (colSet) {
          colSet.value = this.selectedColumns;
        }
      }
      localStorage.setItem('savedColumnSets', JSON.stringify(this.savedColumnSets));
    }

    this.userService.saveColumns({
      userId: localStorage.getItem('userId'),
      defaultColumns: this.defaultColumns,
      savedColumnSets: this.savedColumnSets.map<SelectItem<string[]>>((colSet: SelectItem<Column[]>) => {
        return {
          label: colSet.label,
          value: colSet.value.map((col: Column) => col.field)
        }
      }),
    }).subscribe((response: SimpleResponse) => {
      this.isSaveColumnDisabled = false;
      if (response.success) {
        this.showSuccess();
      } else {
        this.showErrorPrompt('Error saving column sets', `Error saving column sets. ${response.message}`);
        console.log('Error on saving columns sets. Error:', response.message);
      }
    }, (err: Error) => {
      this.isSaveColumnDisabled = false;
      this.showErrorPrompt('Error saving column sets', `Error saving column sets. Error: ${err.message}`);
      console.log('Error on saving columns sets. Error: ', err);
    });
  }
  
  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: "Changes Successfuly Applied"
    });
  }

  showErrorPrompt(header: string, message: string) {
    this.confirmationService.confirm({
      header: header,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'OK',
      rejectVisible: false,
      accept: () => {},
    });
  }
}
