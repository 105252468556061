<p-dialog (onHide)="closeDialog()" header="Customer Retention" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    (onShow)="removeScrollBlock()">
  <div id="retentionDialogBody">
    <form [formGroup]="retentionForm">
      <div class="row">
        <div *ngIf="resultMessage" class="col-12 form-group">
          <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
            {{resultMessage}}
          </div>
        </div>
        <div class="form-group col-4">
          <label for='canReason'>Main reason they wanted to cancel</label>
          <p-dropdown id='canReason' [showClear]="true" [options]="cancellationCategoriesSelectItems" appendTo="body"
            [filter]="true" [resetFilterOnHide]="true" placeholder="Select Main Reason"
            [baseZIndex]="3200" [required]="true" formControlName="cancellationReason" (onChange)="changeCancellationCategory($event.value)">
          </p-dropdown>
        </div>
        <div class="form-group col-4" *ngIf="cancellationReasonsSelectItems.length > 0">
          <label for='canDetailedReason'>Detailed reason they wanted to cancel</label>
          <p-dropdown id='canDetailedReason' [showClear]="true" [options]="cancellationReasonsSelectItems" appendTo="body"
            [filter]="true" [resetFilterOnHide]="true" placeholder="Select Detailed Reason"
            [baseZIndex]="3200" [required]="true" formControlName="detailedCancellationReason" (onChange)="changeCancellationReason($event.value)">
          </p-dropdown>
        </div>
        <div class="form-group col-4" *ngIf="retentionForm.get('otherReason').enabled">
          <label fpr='canOther'>Additional Information</label>
          <input id='canOther' type="text" name="otherReason" formControlName="otherReason" required pInputText/>
        </div>
        <div class="col-12 form-group">
          <label for="retentionComment">Comment:</label>
          <textarea name="retentionComment" class="form-control" rows="4" formControlName="retentionComment" required
            placeholder="What was done to retain the customer?">
          </textarea>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12 form-group text-right">
        <button type="button" class="btn btn-primary" (click)="saveRetentionRecord()" [disabled]="!isOkToSave()">
          Save
        </button>
        <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
      </div>
    </div>
  </div>
</p-dialog>