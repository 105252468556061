import {Component, OnInit} from '@angular/core';
import {FilterService, SelectItem} from 'primeng/api';
import * as moment from 'moment-timezone';
import {getBrandSelectItems} from './lookups/brands';
import {Website} from './models/website.model';
import {HardwareSet} from './models/hardwareSet.model';
import {Hardware} from './models/hardware.model';
import {ObjectWithTwoNameFields} from './models/function-params/objectWithTwoNameFields.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  brands: SelectItem<string>[];

  constructor(
    private filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    this.brands = getBrandSelectItems();

    this.filterService.register('in-delimited-list', (value: string, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      return filter.split(/[\s,;]+/).includes(value);
    });

    this.filterService.register('name-contains', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return fullName.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    });

    this.filterService.register('name-starts-with', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return fullName.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase());
    });

    this.filterService.register('name-not-contains', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return !fullName.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    });

    this.filterService.register('name-ends-with', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return fullName.toLocaleLowerCase().trim().endsWith(filter.toLocaleLowerCase());
    });

    this.filterService.register('name-equals', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return fullName.toLocaleLowerCase().trim() == filter.toLocaleLowerCase().trim();
    });

    this.filterService.register('name-not-equals', (value: ObjectWithTwoNameFields, filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const fullName: string = `${value.firstName} ${value.lastName}`;
      return fullName.toLocaleLowerCase().trim() != filter.toLocaleLowerCase().trim();
    });

    this.filterService.register('date-range', (value: string, filter: Date[]): boolean => {
      if (!filter || !filter[0]) {
        return true;
      }
      const startFilterMoment: moment.Moment = moment(filter[0]).startOf('day');
      const valueMoment: moment.Moment = moment(value);
      let endFilterMoment: moment.Moment = moment(filter[0]).endOf('day');
      if (filter[1]) {
        endFilterMoment = moment(filter[1]).endOf('day');
      }
      return (valueMoment.isSameOrAfter(startFilterMoment) && valueMoment.isSameOrBefore(endFilterMoment));
    });

    // This is for the log pages where the is a cell that contains numbers per brand, not the normal brand filter
    this.filterService.register('brand-count', (value: {[brand: string]: number}, filter: string[]): boolean => {
      if (filter.length === this.brands.length) {
        return true;
      }
      if (!value) {
        return false;
      }
      const rowBrands: string[] = Object.keys(value);
      let include: boolean = false;
      rowBrands.forEach((brand: string): void => {
        if (filter.includes(brand)) {
          include = true;
        }
      });
      return include;
    });

    // This is where the value is a website object/injected id
    this.filterService.register('brand-website', (value: Website, filter: string[]): boolean => {
      if (filter.length === this.brands.length) {
        return true;
      }
      if (!value) {
        return false;
      }
      return filter.includes(value.title);
    });

    // This is where the value is a hardware or hardware set object/injected id
    this.filterService.register('hardware-or-set-title', (value: Hardware|HardwareSet, filter: string): boolean => {
      if (!filter || (filter.trim() === '')) {
        return true;
      }
      if (!value || !value.title) {
        return false;
      }
      return value.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    });

    // This is to allow filtering to blank values as by default primeng treats blank as no filter applied
    this.filterService.register('blank-or-equals', (value: string, filter: string): boolean => {
      if (!filter || (filter.trim() === '')) {
        return true;
      }
      if (filter == '*blanks') {
        return !value;
      }
      // We aren't after blanks and the record has no value
      if (!value) {
        return false;
      }
      return value.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    });

    this.filterService.register('label-equals', (value: SelectItem, filter: SelectItem): boolean => {
      if (filter === undefined || filter === null) {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      return value.label == filter.label;
    });

    this.filterService.register('array-contains-array', (value: string[], filter: string[]): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.length == 0)) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length == 0)) {
        return false;
      }
      let include: boolean = true;
      filter.forEach((filterValue: string) => {
        if (!value.includes(filterValue)) {
          include = false;
        }
      });
      return include;
    });

    this.filterService.register('array-contains-value', (value: string[], filter: string): boolean => {
      if (!filter) {
        return true;
      }
      if ((value === undefined) || (value === null) || (value.length == 0)) {
        return false;
      }
      return value.includes(filter);
    });
    
    // filter contains the value to filter with and the subfields to use
    this.filterService.register('subfield-startsWith', (value: any, filter: string[]): boolean => {
      if ((filter === undefined) || (filter === null) || (filter[0].trim() === '') || (filter.length < 2)) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return false;
      }
      const filterToApply: string = filter[0].toLocaleLowerCase();
      filter.slice(1).forEach((subfield: string) => {
        if (value) {
          value = value[subfield];
        } else {
          value = '';
        }
        
      });
      return value && value.toLocaleLowerCase().startsWith(filterToApply);
    });
    
    // filter contains the value to filter with and the subfields to use
    this.filterService.register('array-subfield-contains', (values: any[], filter: string[]): boolean => {
      if ((filter === undefined) || (filter === null) || (filter[0].trim() === '') || (filter.length < 2)) {
        return true;
      }
      if ((values === undefined) || (values === null) || (values.length == 0)) {
        return false;
      }
      const filterToApply: string = filter[0].toLocaleLowerCase();
      const subfields: string[] = filter.slice(1);
      const include: boolean = values.some((value: any) => {
        subfields.forEach((subfield: string) => {
          if (value) {
            value = value[subfield];
          } else {
            value = '';
          }
        });
        return (value as string).toLocaleLowerCase().includes(filterToApply);
      });
      
      return include;
    });

    this.filterService.register('arrayHasValues', (value: any[], filter: string): boolean => {
      if ((filter === undefined) || (filter === null) || (filter.trim() === '') || (filter == 'all')) {
        return true;
      }
      if ((value === undefined) || (value === null)) {
        return (filter == 'doesNotHaveValues');
      }
      if (filter == 'doesNotHaveValues') {
        return (value.length == 0);
      }
      if (filter == 'hasValues') {
        return (value.length > 0);
      }
      return false;
    });
  }

  get token(): boolean {
    return !!localStorage.getItem('token');
  }

  get boardsLoaded(): boolean {
    return !!localStorage.getItem('actionConfigs');
  }

}
