import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {FaultyEquipment} from '../../models/faultyEquipment.model';
import {AddFaultyEquipmentRequest} from '../../models/requests/addFaultyEquipmentRequest.model';
import {DeleteFaultyEquipmentRequest} from '../../models/requests/deleteFaultyEquipmentRequest.model';
import {UpdateFaultyEquipmentRequest} from '../../models/requests/updateFaultyEquipmentRequest.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
@Injectable({
  providedIn: 'root'
})
export class FaultyEquipmentService {
  private API_URL: string = environment.protocol + environment.IPAddress + '/api/faultyEquipment';
  
  constructor(
    private http: HttpClient
  ) { }

  addFaultToEquipment(params: AddFaultyEquipmentRequest): Observable<SingleRecordResponse<FaultyEquipment>> {
    return this.http.post<SingleRecordResponse<FaultyEquipment>>(`${this.API_URL}/add`, params);
  }

  getAllFaultyEquipment(): Observable<MultiRecordResponse<FaultyEquipment>> {
    return this.http.get<MultiRecordResponse<FaultyEquipment>>(`${this.API_URL}/all`);
  }

  getFaultyEquipment(faultId: string): Observable<SingleRecordResponse<FaultyEquipment>> {
    return this.http.get<SingleRecordResponse<FaultyEquipment>>(`${this.API_URL}/${faultId}`);
  }

  updateFaultyEquipment(faultId: string, params: UpdateFaultyEquipmentRequest|DeleteFaultyEquipmentRequest): 
      Observable<SingleRecordResponse<FaultyEquipment>> {
    return this.http.put<SingleRecordResponse<FaultyEquipment>>(`${this.API_URL}/${faultId}`, params);
  }

  updateTrackingIdAndDates(params):  Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}/update/trackingId-dates`, params);
  }
}
