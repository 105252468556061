
<div class="container">
  <hr>
  <p class="h1">List of Websites</p>
  <hr>
  <app-message></app-message>

  <div class="row">
    <div class="col-1" *ngFor="let website of websites; let index = index;">
      <a routerLink="/websites/{{website._id}}">{{website.title}}</a>
    </div>
  </div>

  <hr>

  <div>
    <form [formGroup]="websiteForm" novalidate>
      <p class="h1">{{formHeading}}</p>
      <hr>
      <div>
        <div class="form">
          <div class="form-group">
            <label>Website's Domain</label>
            <input type="text" name="newWebsite" class="form-control" formControlName="domain" >
            <label>Website's Title</label>
            <input type="text" name="newTitle" class="form-control" placeholder="The title is very important for the rest of the CRM functionality"
              formControlName="title" [readonly]="!allowBrandCodeChange">
            <label>Background</label>
            <input type="color" style="height: 33px;"  name="background" class="form-control" formControlName="background" >
            <label>Color</label>
            <input type="color" style="height: 33px;" name="color" class="form-control" formControlName="color" >
            <label>Title Bar Color</label>
            <input type="color" style="height: 33px;" name="titleBarColor" class="form-control" formControlName="titleBarColor">
            <label>Board Sort Order</label>
            <input type="number" style="height: 33px;" name="order" class="form-control" formControlName="order">
            <label>Accounting Code</label>
            <input type="text" style="height: 33px;" name="accountingCode" class="form-control" formControlName="accountingCode">

            <div class="formsect" formGroupName="api">
              <label>Website's URL</label>
              <input type="text" name="url" class="form-control" formControlName="url" >
              <label>Secret Code</label>
              <input type="text" name="secret" class="form-control" formControlName="secret" >
              <label>Api Key</label>
              <input type="text" name="key" class="form-control" formControlName="key" >
              <h2>Gravity Forms API Settings</h2>
              <label>Public API Key</label>
              <input type="text" name="apiKeyGF" class="form-control" formControlName="apiKeyGF" >
              <label>Private API Key</label>
              <input type="text" name="privateKeyGF" class="form-control" formControlName="privateKeyGF" >
            </div>
          </div>
          <button class="btn btn-primary" type="submit" name="action" (click)="submitForm()">
            {{action}}
          </button>
        </div>
      </div>
    </form>

    <ng-container *ngIf="!!id">
      <hr>
      <h2>Import Products</h2>
      <div>
        <label for="skus">SKUS to Import (one SKU per line)</label>
        <textarea [(ngModel)]="skus" rows="5" id="skus"  class="form-control">
        </textarea>
        <div class="mt-1">
          <button type="button" class="btn btn-primary" (click)="importSpecificProductsForWebsite()" [disabled]="skus.trim() == ''">
            Import Specific SKUs
          </button>
        </div>
      </div>
      <hr>
      <div>
        Please note that Import New Products should only be used in exceptional circumstances. 
        It will import all products for the site that do not already exist in the CRM. 
        Items in the "Renewal" category or are zero priced items with no variations will be
        excluded. Depending on what is on the alarm site this could bring in a lot of unncessary products.
      </div>
      <div class="mt-1">
        <button type="button" class="btn btn-primary" (click)="importNewProducts()">
          Import New Products
        </button>
      </div>
    </ng-container>
  </div>
</div>
<p-toast></p-toast>