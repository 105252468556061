<div *ngIf="loading && !documentBlocked" class="loading-indicator d-flex align-items-center justify-content-center"
  style="height: 200px;">
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</div>
<p-blockUI *ngIf="!loading && documentBlocked" [blocked]="documentBlocked" >
  <div style="width: 100%; height: 200px;background-color: #ff3f4e; position: absolute;top:0; bottom: 0; left: 0; right: 0; margin: auto;">
    <div class="row">
      <div class="col text-center">
        <h1 style="color: #fff; padding: 20px;">
          This message is currently being reviewed by {{messageEditingBy.user}}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-lock" [ngClass]="isSmallerThanLarge? 'fa-3x': 'fa-5x'" (click)="unlock()">
          Unlock
        </i>
        &nbsp;
        <i class="fa fa-ban" [ngClass]="isSmallerThanLarge? 'fa-3x': 'fa-5x'" (click)="onHideDialog()">
          Cancel
        </i>
      </div>
    </div>
  </div>
</p-blockUI>
<p-dialog *ngIf="!loading && !documentBlocked" (onHide)="onHideDialog()" header="Message Detail" [(visible)]="display"
    [modal]="true" [style]="{width:'96vw',height:'auto'}" [maximizable]="true"
    [minY]="70" [baseZIndex]="1000">
  <ng-template #failedToLoadBlock>
    <div class="row mx-0 my-2 messageDetails">
      Error loading message, please try again
    </div>
  </ng-template>
  <ng-container *ngIf="messageDetail else failedToLoadBlock">
    <div class="row mx-0 my-2 messageDetails">
      <div class="col-2 col-lg-1">
        <div [ngStyle]="{'background-color': getBrandBackgroundColour(messageDetail.brand), 'color': getBrandColour(messageDetail.brand)}">
          &nbsp;{{messageDetail.brand}}
        </div>
      </div>
      <div class="col-4 col-lg-2">
        {{messageDetail.messageType}}
      </div>
      <div class="col-6 col-lg-3">
        Generated: {{messageDetail.generated | date:'dd/MM/y'}}
      </div>
      <div class="col-6 col-lg-2">
        Send By:
        <span *ngFor="let method of messageDetail.methods; let i=index" [ngSwitch]="method" class="methods">
          <span *ngSwitchCase="'email'" [ngStyle]="{'color': email.valid? 'black': 'red'}">
            {{method}}
          </span>
          <span *ngSwitchCase="'text'" [ngStyle]="{'color': mobile.valid? 'black': 'red'}">
            {{method}}
          </span>
          <span *ngSwitchCase="'letter'" [ngStyle]="{'color': address.valid? 'black': 'red'}">
            {{method}}
          </span>
          <span *ngSwitchDefault>{{method}}</span>
          <span *ngIf="!(i==messageDetail.methods.length-1)">,</span>
        </span>
      </div>
      <div class="col-3 col-lg-2">
        TD Code:
        <a routerLink="/order/{{messageDetail.orderId}}" target="_blank">
          {{messageDetail.tdCode}}
        </a>
        <span *ngIf="isOrderLocked(messageDetail.orderId)">
          {{orderLockedBy(messageDetail.orderId)}}
        </span>
      </div>
      <div class="col-3 col-lg-2">
        Status: {{messageDetail.status}}
        <div *ngIf="!hasValidContactForOneOrMoreMethods">
          Invalid Comms Details
        </div>
      </div>
    </div>
    <form [formGroup]="messageForm">
      <ng-container [ngSwitch]="messageDetail.messageType">
        <ng-container *ngSwitchCase="'Auto-enrolment'">
          <app-auto-enrolment [order]="order" [message]="messageDetail" [isSmallerThanLarge]="isSmallerThanLarge">
          </app-auto-enrolment>
        </ng-container>
        <ng-container *ngSwitchCase="messageDetail.messageType.startsWith('Renewal')? messageDetail.messageType: ''">
          <app-renewal-comms [order]="order" [message]="messageDetail" [isSmallerThanLarge]="isSmallerThanLarge">
          </app-renewal-comms>
        </ng-container>
        <ng-container *ngSwitchCase="'Cancellation Phase 4'">
          <app-cancellation-invoice [order]="order" [message]="messageDetail" [isSmallerThanLarge]="isSmallerThanLarge">
          </app-cancellation-invoice>
        </ng-container>
        <ng-container *ngSwitchCase="messageDetail.messageType.startsWith('Cancellation Phase') &&
            (messageDetail.messageType != 'Cancellation Phase 4')? messageDetail.messageType: ''">
          <app-cancellation-comms [order]="order" [message]="messageDetail" [isSmallerThanLarge]="isSmallerThanLarge">
          </app-cancellation-comms>
        </ng-container>
        <ng-container *ngSwitchCase="messageDetail.messageType.startsWith('High User')? messageDetail.messageType: ''">
          <app-high-user [order]="order" [message]="messageDetail" [isSmallerThanLarge]="isSmallerThanLarge" [messageTypes]="messageTypes">
          </app-high-user>
        </ng-container>
      </ng-container>
      <div class="row mx-0 my-2 messageDetails">
        <div class="col-2">Tags:</div>
        <div class="col-2" *ngFor="let tag of order.tags;">
          <div [ngStyle]="{'background-color':tag.tagID.color}" class="tagfield">
            {{tag.tagID.tagName}}
            <ng-container *ngIf="tag.expiryDate">
              <br/>Expires at the start of: {{tag.expiryDate | date:'dd/MM/yyyy' }}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mx-0 my-2 messageDetails">
        <div class="col-2">Actions:</div>
        <div class="col-2" *ngFor="let action of order.outstandingActions;">
          <div [ngStyle]="{'background-color':action.outstandingColor}" class="tagfield">
            {{action.outstandingName}}
            <ng-container *ngIf="action.renewalDateTaken">
              <br/>Due Date: {{action.renewalDateTaken | date:'dd/MM/yyyy' }}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mx-0 my-2">
        <div class="col-12">
          <h4>
            Contact Details 
            <ng-container *ngIf="!!(messageDetail.contactDetails.source)">(from {{messageDetail.contactDetails.source}})</ng-container>
            :
          </h4>
        </div>
      </div>
      <div class="row mx-0 my-2">
        <div class="col-6 col-md-3 form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" name="firstName" id="firstName" formControlName="firstName" required/>
        </div>
        <div class="col-6 col-md-3 form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" name="lastName" id="lastName" formControlName="lastName"/>
        </div>
        <ng-container *ngIf="showRegardingInputs">
          <div class="col-6 col-md-3 form-group">
            <label for="reFirstName">Regarding First Name</label>
            <input type="text" class="form-control" name="reFirstName" id="reFirstName" formControlName="reFirstName" required/>
          </div>
          <div class="col-6 col-md-3 form-group">
            <label for="reLastName">Last Name</label>
            <input type="text" class="form-control" name="reLastName" id="reLastName" formControlName="reLastName"/>
          </div>
        </ng-container>
        <div *ngIf="showEmailInput" class="col-12 col-md-6 form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" name="email" id="email" formControlName="email" required />
        </div>
        <div *ngIf="showMobileInput" class="col-12 col-md-6 form-group">
          <label for="sms">Mobile</label>
          <input type="text" class="form-control" (keypress)="numberOnly($event, false)" name="sms" id="sms" formControlName="mobile" required />
        </div>
        <div *ngIf="showAddressInput" class="col-12 col-md-6 form-group">
          <label for="address">Address (Between 3 and 5 lines. Blank lines will be removed)</label>
          <textarea name="address" class="form-control" id="address" formControlName="address" required rows="5">
          </textarea>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12" *ngIf="proposalInvalid">
          <div class="alert alert-danger">
            This message may no longer be valid to send for the below reasons. If these reasons only relate
            to changes you have made in preparation to send the message, then it is OK to proceed, otherwise
            you should only proceed if you are sure these are not an issue.
            <ul>
              <li *ngFor="let reason of reasonsProposalInvalid">{{reason}}</li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-template pTemplate="footer">
    <div class="col-12 text-right buttonRow">
      <ng-container *ngIf="messageDetail.status != 'Archived'">
        <button *ngIf="showSendButton" [disabled]="!isSendAllowed || hasPlanOrPriceErrors"
            (click)="updateMessage('Ready to Send')" class="btn btn-primary" type="button">
          Send using {{getSendMethods()}}
        </button>
        <button *ngIf="showSendLetterButton" [disabled]="!isSendLetterAllowed || !address.valid"
            (click)="sendLetter()" class="btn btn-primary" type="button">
          Send using {{getLetterSendMethods()}}
        </button>
        <button *ngIf="showResendButton" [disabled]="!isSendAllowed" type="button"
            (click)="updateMessage('Ready to Send')" class="btn btn-primary">
          Re-Send
        </button>
        <button *ngIf="showDoNotSendButton" [disabled]="isSubmitButtonDisabled" class="btn btn-danger"
            (click)="doNotSendDialog = true;" type="button">
          Do not Send
        </button>
      </ng-container>
      <button class="btn btn-primary" (click)="onHideDialog()" type="button">
        Close
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog class="do-not-send" [(visible)]="doNotSendDialog" (onHide)="onHideDoNotSendDialog()" header="Do not send reason"
  [modal]="true" [style]="{width:'60vw',height:'auto'}" [maximizable]="true" [minY]="70" [baseZIndex]="1000">
  <form>
    <div class="row">
      <div class="col-12 col-lg-12 form-group">
        <label>Why are you rejecting the message?</label>
        <select class="reason ml-2" name="reason" [(ngModel)]="selectedRejectionReason" required>
          <option disabled selected value></option>
          <option *ngFor="let rejectionReason of rejectionReasons" value="{{rejectionReason.value}}">
            {{rejectionReason.label}}
          </option>
        </select>
      </div>
      <div class="col-12 col-lg-12 form-group" *ngIf="selectedRejectionReason == 'Other'">
        <input type="text" class="form-control" name="otherRejectionReason" [(ngModel)]="otherRejectionReason" required 
          placeholder="Enter reason for rejection">
      </div>
      <div class="col-12 col-lg-12 form-group">
        <button type="button" class="btn btn-primary" [disabled]="!reasonSupplied" (click)="addMessageDeclinedReason()">
          OK
        </button>
      </div>
    </div>
  </form>
</p-dialog>
<p-confirmDialog [baseZIndex]="2000"></p-confirmDialog>
<router-outlet (window:unload)="close()"></router-outlet>